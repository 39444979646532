.node--page {
  &.node--vblock {
    .node__content {
      position: relative;

      .banner__description p {
        margin-bottom: 1rem;
      }

    }
  }
}

.cost-page {

  .banner--larger {
  //  height: $height-stopper-with-menu;
  }

  .banner__description {
   // margin-top: 25px;
   // font-size: $normal;
    line-height: 1.5;
    .key-points-list__title {
      position: relative;
      font-size: $normal;
      margin-bottom: 10px;
      &:before {
        top: 0;
      }
    }
  }

  .banner__left {
    .center-wrapper.inner {
    //  padding-top: 45px;
    }
  }

  .l-socialpromo-wrapper {
    padding: 90px 0;
    @include container($green-second);

    .l-socialpromo__text {
      @include container($socialpromo-container);
      margin-bottom: 50px; //26.2
    }
  }

}

.green-st-block-cost {

  .panels-outer-wrapper {
    @include container($green-second);
    padding: 55px 0 85px;
  }

  .ptitle--coloured {
    //margin-bottom: 75px;
    // font-size: $huge;
    // line-height: 1;
  }

  .cost-subtitle {
    color: $white;
    margin-bottom: 75px;
    font-size: $res-title;
  }

  .cost {
    @include span(4);
    &:last-child {
      @include span(4 last);
    }

    .cost-text {
      color: $white;
      font-size: $key-points;
      line-height: 1.1;
    }

    .cost-icon {
      //@include sprite($sprite-check-icon);
      width: 44px;
      height: 44px;
      background: $white;
      border-radius: 50%;
      padding: 5px 0px;
      color: $med-green;
      font-size: 24px;
      font-weight: 700;
      margin:  auto;
      margin-bottom: 15px;
    }

  }

}

.priv-cost-block {

  .banner {
    min-height: 680px;
  }

  .banner__description {
    margin: 50px 0 70px;
    font-size: $slight;
    line-height: 1.3;

    p {
      margin-bottom: 1rem;
    }

  }

  .rmore {
    text-align: center;
  }

  .banner__left {

    .center-wrapper {
      padding: 100px 20px 45px 20px;
    }

  }

  .banner-right {
    @include container($priv-banner);

    .center-wrapper {
      padding: 100px 20px 45px 20px;
      max-width: 41%;
      margin-right: 59%;
      margin-left: 0;
    }

  }

  &.right-image {

    .read-more {
      margin-top: 20px;
    }
  }

}

.pane-cost {
  clear: both;
}

.cost-subpages {
  .node--page {
    &.node--cost {
      position: relative;

      .group-banner-left-inner {
/*        max-width: 750px;
        padding: 110px 0;*/
        @include left_green;
      }

      .group-banner {
        background: $med-green;
        color: $white;

        .group-banner-left {
          @include container($gf-container);
          position: relative;
          @include clearfix;
          height: $height-stopper-with-menu;
        }

        .field--name-title-field {
         // margin-bottom: 20px;
      /*    h1 {
            font-size: $big;
            line-height: 1.1;
            margin-bottom: 30px;
          }*/
          @include title_green;
        }

        .field--name-body {
          color: $white;
          font-size: $slight;
         // line-height: 1.4;
        }

        .field--name-field-images {
/*          padding: 80px 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 10;
          width: 29%;*/
          @include right_green;
          @include outbox;
        }

      }

    }
  }

  .l-socialpromo-wrapper {
    padding: 95px 0;
    @include container($green-second);

    .l-socialpromo__text {
      @include container($socialpromo-container);
      margin-bottom: 0;
    }
  }

}

.view-cost {
  @include container($discount-coupons-container);

  .ad-ways-block-inner {
    padding: 100px 0 80px;
  }

}

