// Copyright Component

.l-copyright {
  @include clearfix;
  background: $footer-black;
  color: $med-grey;
  font-size: $normal;
  padding: 0 20px;

  &__left {
    float: left;
  }

  &__right {
    float: right;
    text-align: right;
  }

  &-wrapper {
    @include container($banner-container);
    text-align: center;
    padding: 30px 0;
  }

  .green-txt {
    color: $green;
    font-weight: 700;
  }
}