/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$sprite-active-slick-arrow-left-name: 'sprite_active-slick-arrow-left';
$sprite-active-slick-arrow-left-x: 634px;
$sprite-active-slick-arrow-left-y: 410px;
$sprite-active-slick-arrow-left-offset-x: -634px;
$sprite-active-slick-arrow-left-offset-y: -410px;
$sprite-active-slick-arrow-left-width: 59px;
$sprite-active-slick-arrow-left-height: 59px;
$sprite-active-slick-arrow-left-total-width: 774px;
$sprite-active-slick-arrow-left-total-height: 759px;
$sprite-active-slick-arrow-left-image: '../images/sprites/build/sprites.png';
$sprite-active-slick-arrow-left: (634px, 410px, -634px, -410px, 59px, 59px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_active-slick-arrow-left', );
$sprite-active-slick-arrow-right-name: 'sprite_active-slick-arrow-right';
$sprite-active-slick-arrow-right-x: 634px;
$sprite-active-slick-arrow-right-y: 471px;
$sprite-active-slick-arrow-right-offset-x: -634px;
$sprite-active-slick-arrow-right-offset-y: -471px;
$sprite-active-slick-arrow-right-width: 59px;
$sprite-active-slick-arrow-right-height: 59px;
$sprite-active-slick-arrow-right-total-width: 774px;
$sprite-active-slick-arrow-right-total-height: 759px;
$sprite-active-slick-arrow-right-image: '../images/sprites/build/sprites.png';
$sprite-active-slick-arrow-right: (634px, 471px, -634px, -471px, 59px, 59px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_active-slick-arrow-right', );
$sprite-arrow-right-name: 'sprite_arrow-right';
$sprite-arrow-right-x: 711px;
$sprite-arrow-right-y: 577px;
$sprite-arrow-right-offset-x: -711px;
$sprite-arrow-right-offset-y: -577px;
$sprite-arrow-right-width: 12px;
$sprite-arrow-right-height: 18px;
$sprite-arrow-right-total-width: 774px;
$sprite-arrow-right-total-height: 759px;
$sprite-arrow-right-image: '../images/sprites/build/sprites.png';
$sprite-arrow-right: (711px, 577px, -711px, -577px, 12px, 18px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_arrow-right', );
$sprite-benefit-1-name: 'sprite_benefit-1';
$sprite-benefit-1-x: 156px;
$sprite-benefit-1-y: 478px;
$sprite-benefit-1-offset-x: -156px;
$sprite-benefit-1-offset-y: -478px;
$sprite-benefit-1-width: 154px;
$sprite-benefit-1-height: 154px;
$sprite-benefit-1-total-width: 774px;
$sprite-benefit-1-total-height: 759px;
$sprite-benefit-1-image: '../images/sprites/build/sprites.png';
$sprite-benefit-1: (156px, 478px, -156px, -478px, 154px, 154px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_benefit-1', );
$sprite-benefit-2-name: 'sprite_benefit-2';
$sprite-benefit-2-x: 0px;
$sprite-benefit-2-y: 478px;
$sprite-benefit-2-offset-x: 0px;
$sprite-benefit-2-offset-y: -478px;
$sprite-benefit-2-width: 154px;
$sprite-benefit-2-height: 154px;
$sprite-benefit-2-total-width: 774px;
$sprite-benefit-2-total-height: 759px;
$sprite-benefit-2-image: '../images/sprites/build/sprites.png';
$sprite-benefit-2: (0px, 478px, 0px, -478px, 154px, 154px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_benefit-2', );
$sprite-benefit-3-name: 'sprite_benefit-3';
$sprite-benefit-3-x: 239px;
$sprite-benefit-3-y: 239px;
$sprite-benefit-3-offset-x: -239px;
$sprite-benefit-3-offset-y: -239px;
$sprite-benefit-3-width: 154px;
$sprite-benefit-3-height: 154px;
$sprite-benefit-3-total-width: 774px;
$sprite-benefit-3-total-height: 759px;
$sprite-benefit-3-image: '../images/sprites/build/sprites.png';
$sprite-benefit-3: (239px, 239px, -239px, -239px, 154px, 154px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_benefit-3', );
$sprite-benefit-4-name: 'sprite_benefit-4';
$sprite-benefit-4-x: 478px;
$sprite-benefit-4-y: 0px;
$sprite-benefit-4-offset-x: -478px;
$sprite-benefit-4-offset-y: 0px;
$sprite-benefit-4-width: 154px;
$sprite-benefit-4-height: 154px;
$sprite-benefit-4-total-width: 774px;
$sprite-benefit-4-total-height: 759px;
$sprite-benefit-4-image: '../images/sprites/build/sprites.png';
$sprite-benefit-4: (478px, 0px, -478px, 0px, 154px, 154px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_benefit-4', );
$sprite-benefit-5-name: 'sprite_benefit-5';
$sprite-benefit-5-x: 478px;
$sprite-benefit-5-y: 156px;
$sprite-benefit-5-offset-x: -478px;
$sprite-benefit-5-offset-y: -156px;
$sprite-benefit-5-width: 154px;
$sprite-benefit-5-height: 154px;
$sprite-benefit-5-total-width: 774px;
$sprite-benefit-5-total-height: 759px;
$sprite-benefit-5-image: '../images/sprites/build/sprites.png';
$sprite-benefit-5: (478px, 156px, -478px, -156px, 154px, 154px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_benefit-5', );
$sprite-benefit-6-name: 'sprite_benefit-6';
$sprite-benefit-6-x: 478px;
$sprite-benefit-6-y: 312px;
$sprite-benefit-6-offset-x: -478px;
$sprite-benefit-6-offset-y: -312px;
$sprite-benefit-6-width: 154px;
$sprite-benefit-6-height: 154px;
$sprite-benefit-6-total-width: 774px;
$sprite-benefit-6-total-height: 759px;
$sprite-benefit-6-image: '../images/sprites/build/sprites.png';
$sprite-benefit-6: (478px, 312px, -478px, -312px, 154px, 154px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_benefit-6', );
$sprite-call-us-green-name: 'sprite_call-us-green';
$sprite-call-us-green-x: 145px;
$sprite-call-us-green-y: 634px;
$sprite-call-us-green-offset-x: -145px;
$sprite-call-us-green-offset-y: -634px;
$sprite-call-us-green-width: 209px;
$sprite-call-us-green-height: 66px;
$sprite-call-us-green-total-width: 774px;
$sprite-call-us-green-total-height: 759px;
$sprite-call-us-green-image: '../images/sprites/build/sprites.png';
$sprite-call-us-green: (145px, 634px, -145px, -634px, 209px, 66px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_call-us-green', );
$sprite-call-us-name: 'sprite_call-us';
$sprite-call-us-x: 634px;
$sprite-call-us-y: 600px;
$sprite-call-us-offset-x: -634px;
$sprite-call-us-offset-y: -600px;
$sprite-call-us-width: 40px;
$sprite-call-us-height: 29px;
$sprite-call-us-total-width: 774px;
$sprite-call-us-total-height: 759px;
$sprite-call-us-image: '../images/sprites/build/sprites.png';
$sprite-call-us: (634px, 600px, -634px, -600px, 40px, 29px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_call-us', );
$sprite-callus-btn-name: 'sprite_callus-btn';
$sprite-callus-btn-x: 239px;
$sprite-callus-btn-y: 395px;
$sprite-callus-btn-offset-x: -239px;
$sprite-callus-btn-offset-y: -395px;
$sprite-callus-btn-width: 209px;
$sprite-callus-btn-height: 66px;
$sprite-callus-btn-total-width: 774px;
$sprite-callus-btn-total-height: 759px;
$sprite-callus-btn-image: '../images/sprites/build/sprites.png';
$sprite-callus-btn: (239px, 395px, -239px, -395px, 209px, 66px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_callus-btn', );
$sprite-carousel-left-arrow-small-name: 'sprite_carousel-left-arrow-small';
$sprite-carousel-left-arrow-small-x: 756px;
$sprite-carousel-left-arrow-small-y: 499px;
$sprite-carousel-left-arrow-small-offset-x: -756px;
$sprite-carousel-left-arrow-small-offset-y: -499px;
$sprite-carousel-left-arrow-small-width: 13px;
$sprite-carousel-left-arrow-small-height: 26px;
$sprite-carousel-left-arrow-small-total-width: 774px;
$sprite-carousel-left-arrow-small-total-height: 759px;
$sprite-carousel-left-arrow-small-image: '../images/sprites/build/sprites.png';
$sprite-carousel-left-arrow-small: (756px, 499px, -756px, -499px, 13px, 26px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_carousel-left-arrow-small', );
$sprite-carousel-left-arrow-name: 'sprite_carousel-left-arrow';
$sprite-carousel-left-arrow-x: 755px;
$sprite-carousel-left-arrow-y: 354px;
$sprite-carousel-left-arrow-offset-x: -755px;
$sprite-carousel-left-arrow-offset-y: -354px;
$sprite-carousel-left-arrow-width: 17px;
$sprite-carousel-left-arrow-height: 33px;
$sprite-carousel-left-arrow-total-width: 774px;
$sprite-carousel-left-arrow-total-height: 759px;
$sprite-carousel-left-arrow-image: '../images/sprites/build/sprites.png';
$sprite-carousel-left-arrow: (755px, 354px, -755px, -354px, 17px, 33px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_carousel-left-arrow', );
$sprite-carousel-right-arrow-small-name: 'sprite_carousel-right-arrow-small';
$sprite-carousel-right-arrow-small-x: 756px;
$sprite-carousel-right-arrow-small-y: 471px;
$sprite-carousel-right-arrow-small-offset-x: -756px;
$sprite-carousel-right-arrow-small-offset-y: -471px;
$sprite-carousel-right-arrow-small-width: 13px;
$sprite-carousel-right-arrow-small-height: 26px;
$sprite-carousel-right-arrow-small-total-width: 774px;
$sprite-carousel-right-arrow-small-total-height: 759px;
$sprite-carousel-right-arrow-small-image: '../images/sprites/build/sprites.png';
$sprite-carousel-right-arrow-small: (756px, 471px, -756px, -471px, 13px, 26px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_carousel-right-arrow-small', );
$sprite-carousel-right-arrow-name: 'sprite_carousel-right-arrow';
$sprite-carousel-right-arrow-x: 756px;
$sprite-carousel-right-arrow-y: 410px;
$sprite-carousel-right-arrow-offset-x: -756px;
$sprite-carousel-right-arrow-offset-y: -410px;
$sprite-carousel-right-arrow-width: 17px;
$sprite-carousel-right-arrow-height: 33px;
$sprite-carousel-right-arrow-total-width: 774px;
$sprite-carousel-right-arrow-total-height: 759px;
$sprite-carousel-right-arrow-image: '../images/sprites/build/sprites.png';
$sprite-carousel-right-arrow: (756px, 410px, -756px, -410px, 17px, 33px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_carousel-right-arrow', );
$sprite-check-icon-name: 'sprite_check-icon';
$sprite-check-icon-x: 709px;
$sprite-check-icon-y: 354px;
$sprite-check-icon-offset-x: -709px;
$sprite-check-icon-offset-y: -354px;
$sprite-check-icon-width: 44px;
$sprite-check-icon-height: 44px;
$sprite-check-icon-total-width: 774px;
$sprite-check-icon-total-height: 759px;
$sprite-check-icon-image: '../images/sprites/build/sprites.png';
$sprite-check-icon: (709px, 354px, -709px, -354px, 44px, 44px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_check-icon', );
$sprite-envelope-nl-name: 'sprite_envelope-nl';
$sprite-envelope-nl-x: 682px;
$sprite-envelope-nl-y: 577px;
$sprite-envelope-nl-offset-x: -682px;
$sprite-envelope-nl-offset-y: -577px;
$sprite-envelope-nl-width: 27px;
$sprite-envelope-nl-height: 20px;
$sprite-envelope-nl-total-width: 774px;
$sprite-envelope-nl-total-height: 759px;
$sprite-envelope-nl-image: '../images/sprites/build/sprites.png';
$sprite-envelope-nl: (682px, 577px, -682px, -577px, 27px, 20px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_envelope-nl', );
$sprite-ep-mail-plus-name: 'sprite_ep-mail-plus';
$sprite-ep-mail-plus-x: 0px;
$sprite-ep-mail-plus-y: 634px;
$sprite-ep-mail-plus-offset-x: 0px;
$sprite-ep-mail-plus-offset-y: -634px;
$sprite-ep-mail-plus-width: 143px;
$sprite-ep-mail-plus-height: 125px;
$sprite-ep-mail-plus-total-width: 774px;
$sprite-ep-mail-plus-total-height: 759px;
$sprite-ep-mail-plus-image: '../images/sprites/build/sprites.png';
$sprite-ep-mail-plus: (0px, 634px, 0px, -634px, 143px, 125px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_ep-mail-plus', );
$sprite-ep-mail-name: 'sprite_ep-mail';
$sprite-ep-mail-x: 634px;
$sprite-ep-mail-y: 354px;
$sprite-ep-mail-offset-x: -634px;
$sprite-ep-mail-offset-y: -354px;
$sprite-ep-mail-width: 73px;
$sprite-ep-mail-height: 54px;
$sprite-ep-mail-total-width: 774px;
$sprite-ep-mail-total-height: 759px;
$sprite-ep-mail-image: '../images/sprites/build/sprites.png';
$sprite-ep-mail: (634px, 354px, -634px, -354px, 73px, 54px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_ep-mail', );
$sprite-ep-phone-name: 'sprite_ep-phone';
$sprite-ep-phone-x: 707px;
$sprite-ep-phone-y: 142px;
$sprite-ep-phone-offset-x: -707px;
$sprite-ep-phone-offset-y: -142px;
$sprite-ep-phone-width: 63px;
$sprite-ep-phone-height: 63px;
$sprite-ep-phone-total-width: 774px;
$sprite-ep-phone-total-height: 759px;
$sprite-ep-phone-image: '../images/sprites/build/sprites.png';
$sprite-ep-phone: (707px, 142px, -707px, -142px, 63px, 63px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_ep-phone', );
$sprite-ep-pin-name: 'sprite_ep-pin';
$sprite-ep-pin-x: 634px;
$sprite-ep-pin-y: 532px;
$sprite-ep-pin-offset-x: -634px;
$sprite-ep-pin-offset-y: -532px;
$sprite-ep-pin-width: 46px;
$sprite-ep-pin-height: 66px;
$sprite-ep-pin-total-width: 774px;
$sprite-ep-pin-total-height: 759px;
$sprite-ep-pin-image: '../images/sprites/build/sprites.png';
$sprite-ep-pin: (634px, 532px, -634px, -532px, 46px, 66px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_ep-pin', );
$sprite-facebook-large-name: 'sprite_facebook-large';
$sprite-facebook-large-x: 705px;
$sprite-facebook-large-y: 216px;
$sprite-facebook-large-offset-x: -705px;
$sprite-facebook-large-offset-y: -216px;
$sprite-facebook-large-width: 68px;
$sprite-facebook-large-height: 67px;
$sprite-facebook-large-total-width: 774px;
$sprite-facebook-large-total-height: 759px;
$sprite-facebook-large-image: '../images/sprites/build/sprites.png';
$sprite-facebook-large: (705px, 216px, -705px, -216px, 68px, 67px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_facebook-large', );
$sprite-facebook-medium-name: 'sprite_facebook-medium';
$sprite-facebook-medium-x: 728px;
$sprite-facebook-medium-y: 532px;
$sprite-facebook-medium-offset-x: -728px;
$sprite-facebook-medium-offset-y: -532px;
$sprite-facebook-medium-width: 44px;
$sprite-facebook-medium-height: 43px;
$sprite-facebook-medium-total-width: 774px;
$sprite-facebook-medium-total-height: 759px;
$sprite-facebook-medium-image: '../images/sprites/build/sprites.png';
$sprite-facebook-medium: (728px, 532px, -728px, -532px, 44px, 43px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_facebook-medium', );
$sprite-facebook-small-name: 'sprite_facebook-small';
$sprite-facebook-small-x: 596px;
$sprite-facebook-small-y: 558px;
$sprite-facebook-small-offset-x: -596px;
$sprite-facebook-small-offset-y: -558px;
$sprite-facebook-small-width: 30px;
$sprite-facebook-small-height: 30px;
$sprite-facebook-small-total-width: 774px;
$sprite-facebook-small-total-height: 759px;
$sprite-facebook-small-image: '../images/sprites/build/sprites.png';
$sprite-facebook-small: (596px, 558px, -596px, -558px, 30px, 30px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_facebook-small', );
$sprite-google-plus-large-name: 'sprite_google-plus-large';
$sprite-google-plus-large-x: 634px;
$sprite-google-plus-large-y: 285px;
$sprite-google-plus-large-offset-x: -634px;
$sprite-google-plus-large-offset-y: -285px;
$sprite-google-plus-large-width: 68px;
$sprite-google-plus-large-height: 67px;
$sprite-google-plus-large-total-width: 774px;
$sprite-google-plus-large-total-height: 759px;
$sprite-google-plus-large-image: '../images/sprites/build/sprites.png';
$sprite-google-plus-large: (634px, 285px, -634px, -285px, 68px, 67px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_google-plus-large', );
$sprite-google-plus-medium-name: 'sprite_google-plus-medium';
$sprite-google-plus-medium-x: 395px;
$sprite-google-plus-medium-y: 239px;
$sprite-google-plus-medium-offset-x: -395px;
$sprite-google-plus-medium-offset-y: -239px;
$sprite-google-plus-medium-width: 44px;
$sprite-google-plus-medium-height: 43px;
$sprite-google-plus-medium-total-width: 774px;
$sprite-google-plus-medium-total-height: 759px;
$sprite-google-plus-medium-image: '../images/sprites/build/sprites.png';
$sprite-google-plus-medium: (395px, 239px, -395px, -239px, 44px, 43px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_google-plus-medium', );
$sprite-google-plus-small-name: 'sprite_google-plus-small';
$sprite-google-plus-small-x: 356px;
$sprite-google-plus-small-y: 634px;
$sprite-google-plus-small-offset-x: -356px;
$sprite-google-plus-small-offset-y: -634px;
$sprite-google-plus-small-width: 30px;
$sprite-google-plus-small-height: 30px;
$sprite-google-plus-small-total-width: 774px;
$sprite-google-plus-small-total-height: 759px;
$sprite-google-plus-small-image: '../images/sprites/build/sprites.png';
$sprite-google-plus-small: (356px, 634px, -356px, -634px, 30px, 30px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_google-plus-small', );
$sprite-help-icon-name: 'sprite_help-icon';
$sprite-help-icon-x: 751px;
$sprite-help-icon-y: 600px;
$sprite-help-icon-offset-x: -751px;
$sprite-help-icon-offset-y: -600px;
$sprite-help-icon-width: 21px;
$sprite-help-icon-height: 21px;
$sprite-help-icon-total-width: 774px;
$sprite-help-icon-total-height: 759px;
$sprite-help-icon-image: '../images/sprites/build/sprites.png';
$sprite-help-icon: (751px, 600px, -751px, -600px, 21px, 21px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_help-icon', );
$sprite-inactive-slick-arrow-left-name: 'sprite_inactive-slick-arrow-left';
$sprite-inactive-slick-arrow-left-x: 695px;
$sprite-inactive-slick-arrow-left-y: 410px;
$sprite-inactive-slick-arrow-left-offset-x: -695px;
$sprite-inactive-slick-arrow-left-offset-y: -410px;
$sprite-inactive-slick-arrow-left-width: 59px;
$sprite-inactive-slick-arrow-left-height: 59px;
$sprite-inactive-slick-arrow-left-total-width: 774px;
$sprite-inactive-slick-arrow-left-total-height: 759px;
$sprite-inactive-slick-arrow-left-image: '../images/sprites/build/sprites.png';
$sprite-inactive-slick-arrow-left: (695px, 410px, -695px, -410px, 59px, 59px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_inactive-slick-arrow-left', );
$sprite-inactive-slick-arrow-right-name: 'sprite_inactive-slick-arrow-right';
$sprite-inactive-slick-arrow-right-x: 704px;
$sprite-inactive-slick-arrow-right-y: 285px;
$sprite-inactive-slick-arrow-right-offset-x: -704px;
$sprite-inactive-slick-arrow-right-offset-y: -285px;
$sprite-inactive-slick-arrow-right-width: 59px;
$sprite-inactive-slick-arrow-right-height: 59px;
$sprite-inactive-slick-arrow-right-total-width: 774px;
$sprite-inactive-slick-arrow-right-total-height: 759px;
$sprite-inactive-slick-arrow-right-image: '../images/sprites/build/sprites.png';
$sprite-inactive-slick-arrow-right: (704px, 285px, -704px, -285px, 59px, 59px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_inactive-slick-arrow-right', );
$sprite-key-points-name: 'sprite_key-points';
$sprite-key-points-x: 676px;
$sprite-key-points-y: 600px;
$sprite-key-points-offset-x: -676px;
$sprite-key-points-offset-y: -600px;
$sprite-key-points-width: 25px;
$sprite-key-points-height: 25px;
$sprite-key-points-total-width: 774px;
$sprite-key-points-total-height: 759px;
$sprite-key-points-image: '../images/sprites/build/sprites.png';
$sprite-key-points: (676px, 600px, -676px, -600px, 25px, 25px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_key-points', );
$sprite-large-cart-name: 'sprite_large-cart';
$sprite-large-cart-x: 441px;
$sprite-large-cart-y: 239px;
$sprite-large-cart-offset-x: -441px;
$sprite-large-cart-offset-y: -239px;
$sprite-large-cart-width: 30px;
$sprite-large-cart-height: 30px;
$sprite-large-cart-total-width: 774px;
$sprite-large-cart-total-height: 759px;
$sprite-large-cart-image: '../images/sprites/build/sprites.png';
$sprite-large-cart: (441px, 239px, -441px, -239px, 30px, 30px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_large-cart', );
$sprite-linkedin-large-name: 'sprite_linkedin-large';
$sprite-linkedin-large-x: 634px;
$sprite-linkedin-large-y: 216px;
$sprite-linkedin-large-offset-x: -634px;
$sprite-linkedin-large-offset-y: -216px;
$sprite-linkedin-large-width: 69px;
$sprite-linkedin-large-height: 67px;
$sprite-linkedin-large-total-width: 774px;
$sprite-linkedin-large-total-height: 759px;
$sprite-linkedin-large-image: '../images/sprites/build/sprites.png';
$sprite-linkedin-large: (634px, 216px, -634px, -216px, 69px, 67px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_linkedin-large', );
$sprite-linkedin-medium-name: 'sprite_linkedin-medium';
$sprite-linkedin-medium-x: 395px;
$sprite-linkedin-medium-y: 284px;
$sprite-linkedin-medium-offset-x: -395px;
$sprite-linkedin-medium-offset-y: -284px;
$sprite-linkedin-medium-width: 44px;
$sprite-linkedin-medium-height: 43px;
$sprite-linkedin-medium-total-width: 774px;
$sprite-linkedin-medium-total-height: 759px;
$sprite-linkedin-medium-image: '../images/sprites/build/sprites.png';
$sprite-linkedin-medium: (395px, 284px, -395px, -284px, 44px, 43px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_linkedin-medium', );
$sprite-linkedin-small-name: 'sprite_linkedin-small';
$sprite-linkedin-small-x: 441px;
$sprite-linkedin-small-y: 284px;
$sprite-linkedin-small-offset-x: -441px;
$sprite-linkedin-small-offset-y: -284px;
$sprite-linkedin-small-width: 30px;
$sprite-linkedin-small-height: 30px;
$sprite-linkedin-small-total-width: 774px;
$sprite-linkedin-small-total-height: 759px;
$sprite-linkedin-small-image: '../images/sprites/build/sprites.png';
$sprite-linkedin-small: (441px, 284px, -441px, -284px, 30px, 30px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_linkedin-small', );
$sprite-mang-glass-name: 'sprite_mang-glass';
$sprite-mang-glass-x: 756px;
$sprite-mang-glass-y: 445px;
$sprite-mang-glass-offset-x: -756px;
$sprite-mang-glass-offset-y: -445px;
$sprite-mang-glass-width: 16px;
$sprite-mang-glass-height: 16px;
$sprite-mang-glass-total-width: 774px;
$sprite-mang-glass-total-height: 759px;
$sprite-mang-glass-image: '../images/sprites/build/sprites.png';
$sprite-mang-glass: (756px, 445px, -756px, -445px, 16px, 16px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_mang-glass', );
$sprite-next-arrow-big-shadow-name: 'sprite_next-arrow-big-shadow';
$sprite-next-arrow-big-shadow-x: 395px;
$sprite-next-arrow-big-shadow-y: 329px;
$sprite-next-arrow-big-shadow-offset-x: -395px;
$sprite-next-arrow-big-shadow-offset-y: -329px;
$sprite-next-arrow-big-shadow-width: 30px;
$sprite-next-arrow-big-shadow-height: 52px;
$sprite-next-arrow-big-shadow-total-width: 774px;
$sprite-next-arrow-big-shadow-total-height: 759px;
$sprite-next-arrow-big-shadow-image: '../images/sprites/build/sprites.png';
$sprite-next-arrow-big-shadow: (395px, 329px, -395px, -329px, 30px, 52px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_next-arrow-big-shadow', );
$sprite-next-arrow-big-name: 'sprite_next-arrow-big';
$sprite-next-arrow-big-x: 596px;
$sprite-next-arrow-big-y: 478px;
$sprite-next-arrow-big-offset-x: -596px;
$sprite-next-arrow-big-offset-y: -478px;
$sprite-next-arrow-big-width: 24px;
$sprite-next-arrow-big-height: 46px;
$sprite-next-arrow-big-total-width: 774px;
$sprite-next-arrow-big-total-height: 759px;
$sprite-next-arrow-big-image: '../images/sprites/build/sprites.png';
$sprite-next-arrow-big: (596px, 478px, -596px, -478px, 24px, 46px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_next-arrow-big', );
$sprite-phone-icon-small-name: 'sprite_phone-icon-small';
$sprite-phone-icon-small-x: 596px;
$sprite-phone-icon-small-y: 526px;
$sprite-phone-icon-small-offset-x: -596px;
$sprite-phone-icon-small-offset-y: -526px;
$sprite-phone-icon-small-width: 30px;
$sprite-phone-icon-small-height: 30px;
$sprite-phone-icon-small-total-width: 774px;
$sprite-phone-icon-small-total-height: 759px;
$sprite-phone-icon-small-image: '../images/sprites/build/sprites.png';
$sprite-phone-icon-small: (596px, 526px, -596px, -526px, 30px, 30px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_phone-icon-small', );
$sprite-pl-1-name: 'sprite_pl-1';
$sprite-pl-1-x: 239px;
$sprite-pl-1-y: 0px;
$sprite-pl-1-offset-x: -239px;
$sprite-pl-1-offset-y: 0px;
$sprite-pl-1-width: 237px;
$sprite-pl-1-height: 237px;
$sprite-pl-1-total-width: 774px;
$sprite-pl-1-total-height: 759px;
$sprite-pl-1-image: '../images/sprites/build/sprites.png';
$sprite-pl-1: (239px, 0px, -239px, 0px, 237px, 237px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_pl-1', );
$sprite-pl-1-resp-name: 'sprite_pl-1_resp';
$sprite-pl-1-resp-x: 634px;
$sprite-pl-1-resp-y: 0px;
$sprite-pl-1-resp-offset-x: -634px;
$sprite-pl-1-resp-offset-y: 0px;
$sprite-pl-1-resp-width: 140px;
$sprite-pl-1-resp-height: 140px;
$sprite-pl-1-resp-total-width: 774px;
$sprite-pl-1-resp-total-height: 759px;
$sprite-pl-1-resp-image: '../images/sprites/build/sprites.png';
$sprite-pl-1-resp: (634px, 0px, -634px, 0px, 140px, 140px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_pl-1_resp', );
$sprite-pl-2-name: 'sprite_pl-2';
$sprite-pl-2-x: 0px;
$sprite-pl-2-y: 239px;
$sprite-pl-2-offset-x: 0px;
$sprite-pl-2-offset-y: -239px;
$sprite-pl-2-width: 237px;
$sprite-pl-2-height: 237px;
$sprite-pl-2-total-width: 774px;
$sprite-pl-2-total-height: 759px;
$sprite-pl-2-image: '../images/sprites/build/sprites.png';
$sprite-pl-2: (0px, 239px, 0px, -239px, 237px, 237px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_pl-2', );
$sprite-pl-2-resp-name: 'sprite_pl-2_resp';
$sprite-pl-2-resp-x: 454px;
$sprite-pl-2-resp-y: 478px;
$sprite-pl-2-resp-offset-x: -454px;
$sprite-pl-2-resp-offset-y: -478px;
$sprite-pl-2-resp-width: 140px;
$sprite-pl-2-resp-height: 140px;
$sprite-pl-2-resp-total-width: 774px;
$sprite-pl-2-resp-total-height: 759px;
$sprite-pl-2-resp-image: '../images/sprites/build/sprites.png';
$sprite-pl-2-resp: (454px, 478px, -454px, -478px, 140px, 140px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_pl-2_resp', );
$sprite-pl-3-name: 'sprite_pl-3';
$sprite-pl-3-x: 0px;
$sprite-pl-3-y: 0px;
$sprite-pl-3-offset-x: 0px;
$sprite-pl-3-offset-y: 0px;
$sprite-pl-3-width: 237px;
$sprite-pl-3-height: 237px;
$sprite-pl-3-total-width: 774px;
$sprite-pl-3-total-height: 759px;
$sprite-pl-3-image: '../images/sprites/build/sprites.png';
$sprite-pl-3: (0px, 0px, 0px, 0px, 237px, 237px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_pl-3', );
$sprite-pl-3-resp-name: 'sprite_pl-3_resp';
$sprite-pl-3-resp-x: 312px;
$sprite-pl-3-resp-y: 478px;
$sprite-pl-3-resp-offset-x: -312px;
$sprite-pl-3-resp-offset-y: -478px;
$sprite-pl-3-resp-width: 140px;
$sprite-pl-3-resp-height: 140px;
$sprite-pl-3-resp-total-width: 774px;
$sprite-pl-3-resp-total-height: 759px;
$sprite-pl-3-resp-image: '../images/sprites/build/sprites.png';
$sprite-pl-3-resp: (312px, 478px, -312px, -478px, 140px, 140px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_pl-3_resp', );
$sprite-prev-arrow-big-shadow-name: 'sprite_prev-arrow-big-shadow';
$sprite-prev-arrow-big-shadow-x: 427px;
$sprite-prev-arrow-big-shadow-y: 329px;
$sprite-prev-arrow-big-shadow-offset-x: -427px;
$sprite-prev-arrow-big-shadow-offset-y: -329px;
$sprite-prev-arrow-big-shadow-width: 30px;
$sprite-prev-arrow-big-shadow-height: 52px;
$sprite-prev-arrow-big-shadow-total-width: 774px;
$sprite-prev-arrow-big-shadow-total-height: 759px;
$sprite-prev-arrow-big-shadow-image: '../images/sprites/build/sprites.png';
$sprite-prev-arrow-big-shadow: (427px, 329px, -427px, -329px, 30px, 52px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_prev-arrow-big-shadow', );
$sprite-prev-arrow-big-name: 'sprite_prev-arrow-big';
$sprite-prev-arrow-big-x: 450px;
$sprite-prev-arrow-big-y: 395px;
$sprite-prev-arrow-big-offset-x: -450px;
$sprite-prev-arrow-big-offset-y: -395px;
$sprite-prev-arrow-big-width: 24px;
$sprite-prev-arrow-big-height: 46px;
$sprite-prev-arrow-big-total-width: 774px;
$sprite-prev-arrow-big-total-height: 759px;
$sprite-prev-arrow-big-image: '../images/sprites/build/sprites.png';
$sprite-prev-arrow-big: (450px, 395px, -450px, -395px, 24px, 46px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_prev-arrow-big', );
$sprite-quick-links-top-name: 'sprite_quick-links-top';
$sprite-quick-links-top-x: 388px;
$sprite-quick-links-top-y: 634px;
$sprite-quick-links-top-offset-x: -388px;
$sprite-quick-links-top-offset-y: -634px;
$sprite-quick-links-top-width: 30px;
$sprite-quick-links-top-height: 30px;
$sprite-quick-links-top-total-width: 774px;
$sprite-quick-links-top-total-height: 759px;
$sprite-quick-links-top-image: '../images/sprites/build/sprites.png';
$sprite-quick-links-top: (388px, 634px, -388px, -634px, 30px, 30px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_quick-links-top', );
$sprite-radio-button-active-name: 'sprite_radio-button-active';
$sprite-radio-button-active-x: 727px;
$sprite-radio-button-active-y: 600px;
$sprite-radio-button-active-offset-x: -727px;
$sprite-radio-button-active-offset-y: -600px;
$sprite-radio-button-active-width: 22px;
$sprite-radio-button-active-height: 22px;
$sprite-radio-button-active-total-width: 774px;
$sprite-radio-button-active-total-height: 759px;
$sprite-radio-button-active-image: '../images/sprites/build/sprites.png';
$sprite-radio-button-active: (727px, 600px, -727px, -600px, 22px, 22px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_radio-button-active', );
$sprite-radio-button-name: 'sprite_radio-button';
$sprite-radio-button-x: 703px;
$sprite-radio-button-y: 600px;
$sprite-radio-button-offset-x: -703px;
$sprite-radio-button-offset-y: -600px;
$sprite-radio-button-width: 22px;
$sprite-radio-button-height: 22px;
$sprite-radio-button-total-width: 774px;
$sprite-radio-button-total-height: 759px;
$sprite-radio-button-image: '../images/sprites/build/sprites.png';
$sprite-radio-button: (703px, 600px, -703px, -600px, 22px, 22px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_radio-button', );
$sprite-rounded-arrow-nav-name: 'sprite_rounded-arrow-nav';
$sprite-rounded-arrow-nav-x: 695px;
$sprite-rounded-arrow-nav-y: 471px;
$sprite-rounded-arrow-nav-offset-x: -695px;
$sprite-rounded-arrow-nav-offset-y: -471px;
$sprite-rounded-arrow-nav-width: 59px;
$sprite-rounded-arrow-nav-height: 59px;
$sprite-rounded-arrow-nav-total-width: 774px;
$sprite-rounded-arrow-nav-total-height: 759px;
$sprite-rounded-arrow-nav-image: '../images/sprites/build/sprites.png';
$sprite-rounded-arrow-nav: (695px, 471px, -695px, -471px, 59px, 59px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_rounded-arrow-nav', );
$sprite-select-arrow-name: 'sprite_select-arrow';
$sprite-select-arrow-x: 725px;
$sprite-select-arrow-y: 577px;
$sprite-select-arrow-offset-x: -725px;
$sprite-select-arrow-offset-y: -577px;
$sprite-select-arrow-width: 18px;
$sprite-select-arrow-height: 11px;
$sprite-select-arrow-total-width: 774px;
$sprite-select-arrow-total-height: 759px;
$sprite-select-arrow-image: '../images/sprites/build/sprites.png';
$sprite-select-arrow: (725px, 577px, -725px, -577px, 18px, 11px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_select-arrow', );
$sprite-twitter-medium-name: 'sprite_twitter-medium';
$sprite-twitter-medium-x: 682px;
$sprite-twitter-medium-y: 532px;
$sprite-twitter-medium-offset-x: -682px;
$sprite-twitter-medium-offset-y: -532px;
$sprite-twitter-medium-width: 44px;
$sprite-twitter-medium-height: 43px;
$sprite-twitter-medium-total-width: 774px;
$sprite-twitter-medium-total-height: 759px;
$sprite-twitter-medium-image: '../images/sprites/build/sprites.png';
$sprite-twitter-medium: (682px, 532px, -682px, -532px, 44px, 43px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_twitter-medium', );
$sprite-video-play-large-name: 'sprite_video-play-large';
$sprite-video-play-large-x: 634px;
$sprite-video-play-large-y: 142px;
$sprite-video-play-large-offset-x: -634px;
$sprite-video-play-large-offset-y: -142px;
$sprite-video-play-large-width: 71px;
$sprite-video-play-large-height: 72px;
$sprite-video-play-large-total-width: 774px;
$sprite-video-play-large-total-height: 759px;
$sprite-video-play-large-image: '../images/sprites/build/sprites.png';
$sprite-video-play-large: (634px, 142px, -634px, -142px, 71px, 72px, 774px, 759px, '../images/sprites/build/sprites.png', 'sprite_video-play-large', );
$spritesheet-width: 774px;
$spritesheet-height: 759px;
$spritesheet-image: '../images/sprites/build/sprites.png';
$spritesheet-sprites: ($sprite-active-slick-arrow-left, $sprite-active-slick-arrow-right, $sprite-arrow-right, $sprite-benefit-1, $sprite-benefit-2, $sprite-benefit-3, $sprite-benefit-4, $sprite-benefit-5, $sprite-benefit-6, $sprite-call-us-green, $sprite-call-us, $sprite-callus-btn, $sprite-carousel-left-arrow-small, $sprite-carousel-left-arrow, $sprite-carousel-right-arrow-small, $sprite-carousel-right-arrow, $sprite-check-icon, $sprite-envelope-nl, $sprite-ep-mail-plus, $sprite-ep-mail, $sprite-ep-phone, $sprite-ep-pin, $sprite-facebook-large, $sprite-facebook-medium, $sprite-facebook-small, $sprite-google-plus-large, $sprite-google-plus-medium, $sprite-google-plus-small, $sprite-help-icon, $sprite-inactive-slick-arrow-left, $sprite-inactive-slick-arrow-right, $sprite-key-points, $sprite-large-cart, $sprite-linkedin-large, $sprite-linkedin-medium, $sprite-linkedin-small, $sprite-mang-glass, $sprite-next-arrow-big-shadow, $sprite-next-arrow-big, $sprite-phone-icon-small, $sprite-pl-1, $sprite-pl-1-resp, $sprite-pl-2, $sprite-pl-2-resp, $sprite-pl-3, $sprite-pl-3-resp, $sprite-prev-arrow-big-shadow, $sprite-prev-arrow-big, $sprite-quick-links-top, $sprite-radio-button-active, $sprite-radio-button, $sprite-rounded-arrow-nav, $sprite-select-arrow, $sprite-twitter-medium, $sprite-video-play-large, );
$spritesheet: (774px, 759px, '../images/sprites/build/sprites.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
