.printed-rolls {
  .ad-ways-block-inner {

    @include with-layout(24) {
      .ad-ways-block-left {
        //   @include span(4);
        @include span(9);

        .ad-ways-block-image {
          img {
            border: none;
          }
        }
      }

      .ad-ways-block-right {
        //   @include span(8 last);
        @include span(15 last);
      }

      .ad-ways-block-text {
        color: $black;
      }

      .ad-ways-block-left-2 {
      //  @include span(8);
        @include span(15);
        padding: 0 20px;
      }

      .ad-ways-block-right-2 {
      //  @include span(4 last);
        @include span(9 last);
      }
    }

    .rmore {
      text-align: center;
    }

  }

  .l-socialpromo-wrapper {
    padding: 95px 0;
    @include container($green-second);

    .l-socialpromo__text {
      @include container($socialpromo-container);
      margin-bottom: 0;
      
      &-lower {
        padding-top: 20px; //6.2
      }
    }
  }

}

.order-rolls-block {
  background: $basket;
}