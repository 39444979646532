// COLOR REFERENCE
$grey: #999999;
$border: #efefef;
$white-ct: #b3cc3c;
// $black: #202020;
// $med-green: #0849A3;
$red: #daab1d;

// base font size 16px;
//$small: 0.75rem;

.view-commerce-cart-form {
  .line-item-total-raw {
    color: $med-grey;
  }
}

.form-item {
  .description {
    margin-top: 3px;
    color: $med-grey;
    font-size: $very-small;
  }
}

.view-commerce-cart-form, .commerce-checkout-form-checkout, .view-commerce-line-item-table {

  .form-item {
    label {
      margin-bottom: 2px;
    }
  }

  td {
    &.views-field-title-field {
      width: 300px;
    }
  }

  select {
    padding: 8px;
    overflow: visible;
  }

  table {
    width: 100%;
  }

}

.page-user-password {

  input[type="submit"] {
    padding: 12px;
    overflow: visible;
    background: $med-green;
    border: 2px solid $green;
    color: $white;
    font-family: $roboto;
    font-size: 14px;

    &:hover, &:focus {
      cursor: pointer;
      background: $green;
      border: 2px solid $med-green;
    }
  }

    .form-item {
    label {
      margin-bottom: 2px;
    }
  }

  .l-content {
    margin: 60px 0;
    @include container($large-container);
  }

  h1 {
    border-bottom: none;
    line-height: 1.1;
    font-size: $big;
    margin-bottom: 20px;
  }

  input[type='pass'],
  input[type='text'] {
    padding: 12px;
    overflow: visible;
    border: 1px solid $shadow;
    font-size: $normal;
  }
}

#commerce-checkout-form-review {
  .form-item {
    label {
      font-weight: 700;
    }
  }
 }

.checkout-review {
  .pane-data-full {
    .field {
    //  @include clearfix;
    }

    .form-item {
      label {
        display: block;
        font-weight: 700;

        &:after {
          position: relative;
          left: -4px;
          content: ":";
          display: inline;
        }
      }
    }
  }
}

#payment-details {
  display: none;
}

.entity-commerce-order {

  .field {
    @include clearfix;
  }

  .commerce-price-formatted-components {
    width: 100%;

   tr.odd td, tr.even td {
      padding: 5px;
     border-bottom: 1px solid rgba(255,255,255,0.045);
     font-size: $normal;

    &.component-title {
      text-align: left;
      font-weight: 400;
    }

    &.component-total {
      text-align: right;
    }

      background: $deep-silver;
      color: $med-grey;
      font-weight: 700;
    }
  }

  .order-status-custom-field {
    margin: 20px 0;
    font-size: $carousel;

    .field-label {
      color: $med-green;
      font-weight: 700;
    }
  }

  .field--name-commerce-customer-billing, .field--name-commerce-customer-shipping, .field--name-field-order-comments {
    .field__label {
      font-weight: 700;
    }
  }

 /* .field--name-commerce-customer-billing {
    > .field__label {
      color: $dark-grey;
      font-size: $carousel;
    }
  }

  .field--name-commerce-customer-shipping {
    > .field__label {
      color: $dark-grey;
      font-size: $carousel;
    }
  } */
}

.view-commerce-user-orders {

  table {
    width: 100%;
  }

  tr td {
    font-weight: 400;
  }
}

#commerce-checkout-form-checkout fieldset {
  &.checkout-buttons {
   background: $light-grey;
  }
}

.commerce-line-item-views-form {
  .commerce-line-item-actions #edit-submit {
    display: none;
  }
}

#views-form-commerce-cart-form-default, #commerce-checkout-form-checkout,
#commerce-checkout-form-review, .view-commerce-line-item-table, .view-commerce-user-orders {

  .form-type-radio {
    label {
      position: relative;
      top: 1px;
    }
  }

  td {
    line-height: 1.3;
    &.views-field-field-commerce-init-price {
      text-decoration: line-through;
    }
  }

  th {
    line-height: 1.4;
  }

  input[type="radio"],
  input[type="checkbox"] {
    border: none;
    margin-top: 0;
    margin-left: 0;
  }

  input[type="text"] {
    font-size: 14px;
  }

  input.checkout-continue {
    border: 2px solid $green;
    color: $white;
  }

  .commerce-price-formatted-components {
   tr.odd td, tr.even td {
      border: none;
      padding: 5px;
     border-bottom: 1px solid rgba(255,255,255,0.045);

    &.component-title {
      text-align: left;
      font-weight: 400;
      color: $med-grey;
    }

    &.component-total {
      text-align: right;
      color: $med-grey;
    }
      background: $deep-silver;
      font-size: $normal;
      font-weight: 700;
    }
  }


  a {
    color: $med-green;
    font-weight: 700;
  }

    input {
      border: 1px solid $shadow;
    }

  .checkout-buttons {
    input {
      &:hover, &:focus {
        background: $green;
        border: 2px solid $med-green;
      }
    }
  }

  input[type="text"] {
    padding: 12px;
    overflow: visible;
  }

  .views-field-commerce-total {
      text-align: center;
  }

  td {
    text-align: center;

    &.views-field-edit-delete {
      .delete-line-item {
        padding: 13px 12px 11px 12px;
        border: 1px solid $shadow;
        background-color: white;
        color: $callus-black;
        font-size: $small;
        display: inline-block;

          &:hover, &:focus {
            background-color: $callus-black;
            color: white;
            cursor: pointer;
          }
        }
    }

    img {
      display: inline;
    }
  }

  thead th, th {
    background: $med-green;
    color: $white;
    border: none;
    font-weight: 400;
    vertical-align: middle;
    padding: 6px;
    text-align: center;
    font-size: $small;
  }

  tr {
    border: none;
  }

  tr.even td, tr.odd td {
    background: white;
    vertical-align: middle;
    border: 1px solid $cu;
    padding: 10px;

    &.views-field-title {
      width: 40%;
    }

    &.views-field-edit-quantity {
      width: 4%;

      input {
        width: 60px;
      }
    }

    &.views-field-field-product-init-price {
      text-decoration: line-through;
      color: $case_gray;
    }
  }

  tr.component-type-commerce-price-formatted-amount {
    &.even td, &.odd td {
      text-align: left;
    }

  }

/*  .commerce-quantity-plusminus-link-disabled {
    display: none;
  }*/

  tr.even.views-row-first td, tr.odd.views-row-first td {
      border-top: none;
    }

  .commerce-paypal-icons {
    padding-top: 5px;

    img {
      max-height: 25px;
      width: auto;
    }
  }

  .line-item-summary {
    text-align: right;
    margin-bottom: 10px;
  //  width: 195px;
    width: 33%;
    font-weight: 700;
    color: $med-grey;
    margin-right: 0;
    margin-left: auto;
    padding: 3px 8px;
    background: $deep-silver;
    border: none;

    .line-item-total-label {
      font-weight: 400;
      color: $med-grey;
      margin-right: 20px;
    }
  }

    tr.odd, tr.even {
        // border-bottom: 1px solid white;

        &.component-type-commerce-price-formatted-amount {
          border-bottom: none;
        }
      }

  .commerce-line-item-actions {
    margin-top: 20px;

    input {
      padding: 7px 10px 7px 10px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
        background: $med-green;
        color: $white;
        border: 2px solid $green;

        &:hover, &:focus {
          border: 2px solid $med-green;
          background: $white-ct;
        }
      }
    }
  }

  legend {
    padding: 4px 10px;
    background: $deep-silver;
    font-size: $carousel;
    color: $med-grey;

    span {
      color: $med-grey;
    }
  }

  label {
    font-weight: 400;
    line-height: 160%;
    font-style: normal;
    margin-bottom: 0.15rem;
    //font-weight: $small;
  }

  .fieldset-legend {
    color: $med-grey;
    font-weight: 400;
    padding: 0;
    padding-left: 0;
    display: block;
    font-family: $roboto-con;
  }

  fieldset {
    border: none;
    padding: 20px;
    border: 1px solid $cu;
    margin: 10px 0;
    background: $white;
  }

.commerce_shipping {
  input[type="submit"] {
    margin-top: 20px;
  }
}

  input[type="submit"] {
    &:focus {
      outline: $black;
    }
  }

  .description {
    margin-left: 0;
    color: $med-grey;
  }

  .form-item-commerce-payment-payment-method {

    input[type="radio"] {
      margin: 0 5px 0 0;
    }

    .md-wrapper {
      margin-top: 12px;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .ajax-progress {
      display: none;
    }

    .option {
      padding-bottom: 5px;
    }

    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $shadow;
}

  .accept_terms {
    margin: 10px 0;
    padding: 20px;
    background: white;
    border: 1px solid $cu;

    .form-item {
      margin-bottom: 0;
    }
  }

}

#views-form-commerce-cart-form-default {

  padding: 20px;
  background: white;
  border: 1px solid $cu;

  input[type="text"] {
    text-align: center;
  }

  .commerce-line-item-actions {
     #edit-submit {
        background: $callus-black;
        border: 2px solid $shadow;
        background-color: white;
        color: $callus-black;
        padding: 7px 10px 7px 10px;

        &:hover, &:focus {
          background-color: $green;
          border: 2px solid $med-green;
          color: $white;
          cursor: pointer;
        }
      }
    }
}

.checkout-buttons {

  .button-operator {
    margin-right: 10px;
  }

  .checkout-back, .checkout-continue, .checkout-cancel {
    background: transparent;
    border: 1px solid $shadow;
    color: $callus-black;
    padding: 7px 10px 7px 10px;
    position: relative;
    top: 1px;
    margin-right: 10px;

    &:hover, &:focus {
      background: $callus-black;
      color: white;
      cursor: pointer;
      text-decoration: none;
    }

     a {
      text-decoration: none;
      color: $callus-black;

      &:hover, &:focus {
        text-decoration: none;
        }
      }
    }

  .checkout-continue {
    margin-right: 10px;
    background: $med-green;
    color: white;

    a {
      color: white;
    }
  }
}

#commerce-checkout-form-review {

    table {
    width: 100%;
  }


tr.odd, tr.even {

  &.pane-title {
    text-align: left;
    border-bottom: none;
    margin-bottom: 20px;
      td {
        text-align: left;
        border: none;
        padding: 5px 5px 5px 10px;
        font-weight: 400;
        color: $callus-black;
        background: $cu;
      }
    }
    &.pane-data {
      td {
        text-align: left;
      }
    }
  }

  .checkout-help {
    font-weight: 700;
    font-size: $small;
    color: $med-green;
    margin-bottom: 20px;
  }

  .view-commerce-cart-summary {
    padding: 10px;
tr.odd, tr.even {
    td.views-field {
      text-align: center;
    }
  }
}

}

.md-wrapper ul.accepted-cards-payment {
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0;

  @include clearfix;
  padding-left: 0;
  margin-left: 0;
  list-style: none;

  li {
    list-style: none;
    float: left;
    margin-right: 10px;

    &.payment-nudge {
      position: relative;
      top: -2px;
    }
  }

}

.block--gtweaks-alphalogos {
  text-align: center;
  margin: 20px 0 0;
}

.accepted-cards-main {

  @include clearfix;
  padding: 10px;
  display: inline-block;
  text-align: center;
  background: rgba(255,255,255,0.35);
  margin-bottom: 5px;
  list-style: none;

  li {
    list-style: none;
    float: left;
    margin-right: 10px;
    width: 40px;
    height: 25px;

    &.verified-by-visa {
      width: 48px;
    }

    &.discover {
      width: 39px;
    }

    &.mastercard {
      width: 41px;
    }

    &.piraeus-bank {
      width: 25px;
      margin-right: 0;
    }
  }

  $cards: visa mastercard maestro amex diners discover verified-by-visa mastercard-secure-code piraeus-bank;

  @each $card in $cards {
    li.#{$card} {
      background: url('/sites/all/modules/custom/module_images/' + $card + '.jpg') no-repeat;
    }
  }
}

#block-commerce-checkout-progress-indication {
  margin: 30px 0;
}

ol.inline.commerce-checkout-progress {
  li {
    &:nth-child(2) {
   //   width: 24%;
    }
  }
}

#commerce-checkout-form-review {
  .commerce-order-handler-area-order-total .commerce-price-formatted-components {
    width: 33%;
    margin-left: auto;
  }
}

.l-main {
  .commerce-checkout-progress.inline {
    padding-left: 0;
    margin-left: 0;
    list-style: none;
    font-size: $small;

    li {
      margin-bottom: 0;
      float: left;
    //  width: 115px;
      width: auto;
      margin-right: 50px;
      list-style: none;
      font-weight: 400;
      color: $med-grey;

      a {
        color: $med-grey;
      }

      &.active {
        color: $med-green;
        font-weight: 700;

        a {
          color: $med-green;
        }
      }
    }

    em {
      text-align: center;
      display: inline-block;
      font-style: normal;
      //   @include sprite($sprite-progress-inactive);
      padding: 0.2rem 0.15rem;
      border-radius: 50%;
      margin-right: 5px;
      color: $med-grey;
      font-weight: 400;
      background: $deep-silver;
      width: 28px;
      height: 28px;
    }

    .active em {
      //  @include sprite($sprite-progress-active);
      background: $med-green;
      color: $white;
    }
  }
}

#commerce-checkout-form-login {
  line-height: 1.5;
  font-size: $small;
  margin-bottom: 20px;

  .non-registered-user-info {
    line-height: 1.4;
    font-size: $slight;
  }

    ul.eggrafi-list {
        list-style: none;
        margin-left: 0;
        margin-top: 23px;
        margin-bottom: 37px;

        li {
          list-style: none;

          &:last-child {
            margin-bottom: 0;
          }

          &:before {
            content: "";
            display: inline-block;
            position: relative;
            top: -1px;
            vertical-align: middle;
            margin-right: 8px;
            @include sprite($sprite-key-points);
          }
        }
    }

    .button-operator {
      display: none;
    }

  fieldset {
    border: none;
    padding: 20px;
    border: 1px solid $shadow;
    margin: 10px 0;
  }

  input[type="submit"].checkout-cancel {
        background: $callus-black;
        border: 2px solid $shadow;
        background-color: white;
        color: $callus-black;
        padding: 7px 10px 7px 10px;

        &:hover, &:focus {
          background-color: $green;
          border: 2px solid $med-green;
          color: white;
          text-decoration: none;
        }
  }

  input.checkout-continue {
    color: white;
    background: $med-green;
    border: none;
    padding: 8px 14px;
  }

  .nonregistered_pane {
    /*width: 529px;
    float: left;*/
    @include span(8 last);
    min-height: 313px;

    .fieldset-wrapper {
    }

    .fieldset-legend {
      padding: 0 6px;
      font-weight: 700;
      color: $med-grey;
      display: block;
      font-size: $slight;
    }

    .non-registered-user-info {
      margin-bottom: 20px;
    }

    #non-registered-user-btn {
      background: none;
      color: $callus-black;
      border: 2px solid $shadow;
      height: 20px;
      padding: 310x;
      text-align: center;
      line-height: 30px;
      width: 180px;
      padding: 12px;

    &:hover, &:focus {
      cursor: pointer;
      color: white;
      text-decoration: none;
      background: $green;
      border: 2px solid $med-green;
      }

    }

    a.register {
      height: 20px;
      padding: 3px;
      color: white;
      background: $med-green;
      border: 2px solid $green;
      text-align: center;
      line-height: 30px;
      width: 180px;
      padding: 12px;
      margin-left: 10px;

    &:hover, &:focus {
      cursor: pointer;
      text-decoration: none;
      background: $green;
      border: 2px solid $med-green;
      }
    }
  }


  .login_pane {
    @include span(4);

    input.checkout-continue {
      border: 2px solid $green;
      background: $med-green;
      color: $white;

      &:hover, &:focus {
        border: 2px solid $med-green;
        background: $green;
      }
    }

      a {
        color: $med-green;
      }

    //width: 360px;
   // margin-right: 20px;
 //   float: left;
    min-height: 260px;

     span.fieldset-legend {
       padding: 0 6px;
      color: $med-grey;
      font-weight: 400;
      display: block;
      font-size: $slight;
      font-weight: 700;
    }

    .checkout-continue {
   //   padding: 8px 10px 4px;
    }

    ul {
      margin-left: 0;
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;

      li {
        list-style: none;
        font-size: $small;
        margin-bottom: 2px;

        a {
          color: $med-green;
        }
      }
    }

    .form-item {
      margin-top: 0;

      label {
        font-weight: 400;
        color: $callus-black;
      }

      input {
        width: 100%;
        padding: 8px;
        font-size: $small;
      }
    }

    .checkout-continue {
      display: block;
      margin-top: 17px;
    }

    .fieldset-wrapper {
      padding: 10px;
    }

    .fieldset-legend {
      color: $med-grey;
      font-weight: 400;
      display: block;
      font-size: $slight;
    }
  }

  .checkout-buttons {
    clear: both;
    border: none;
    padding: 0;
    margin: 0;

    .checkout-continue {
      display: none;
    }

  }

}

    .form-item-commerce-payment-payment-method {
      ul {
        margin-bottom: 2px;
      }
    }

.request-new-pass-pane {
  font-size: $small;
}

.page-checkout-complete {
  .l-content {
    padding: 20px 0;
  }
}

.checkout-completion-message {

  .order-completed-succesfully {
    border: 1px $med-green;
    padding: 15px;
    text-align: center;
    font-size: $slight;
  //  background: url('../images/tick.png') transparent 302px center no-repeat;
    margin-bottom: 20px;
    border: 1px solid $med-green;
    color: $med-green;
    position: relative;
  }

  .oc-msg-icon {
    content: "";
    display: block;
    @include sprite($sprite-key-points);
    position: absolute;
    left: 20px;
    // correction for line-height
    top: 50%;
    margin-top: -12px;
  }

  .order-num-success {
   /* font-size: $small;*/
  }

}

.field-name-field-order-comments {
  label {
    font-size: $small;
  }

  .form-textarea-wrapper textarea {
    min-height: 150px;
    border: 1px solid $shadow;
  }
}
//  drupal login page

.user-login {
  @include clearfix;
  @include container($large-container);
  padding: 50px 0;

  .form-item {
    label {
      margin-bottom: 2px;
    }
  }

  h1 {
    border-bottom: none;
    line-height: 1.1;
    font-size: $big;
    margin-bottom: 20px;
  }

  .form-item {
    label {
      font-weight: 400;
    }
  }

  h2.cs-legend {
      color: $med-grey;
      font-family: $roboto-con;
      font-weight: 400;
      display: block;
      font-size: $key-points;
      font-weight: 400;
      margin-bottom: 6px;
  }

  #user_login_form {
    @include span(6);
   // height: 350px;
    border-right: 1px solid $shadow;
    padding-right: 15px;

    input[type="text"],
    input[type="password"] {
      padding: 12px;
      overflow: visible;
      border: 1px solid $shadow;
      font-size: $normal;
      width: 70%;
    }

    label {
      margin-bottom: 3px;
    }

    .login-password {
      color: $med-green;
      margin-bottom: 20px;
    }

    .form-actions {
      margin-top: 10px;
    }
    input[type="submit"] {
      background: $med-green;
      color: $white;
      border: none;
      padding: 8px 18px;
      font-size: $normal;
      font-family: $roboto;
      cursor: pointer;
      border: 2px solid $green;

      &:hover, &:focus {
        background: $green;
        border: 2px solid $green;
      }
    }
  }
  .create-account {
    @include span(6 last);
    text-align: center;
    position: relative;
    height: 400px;

    .content-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

    .login-register {
    //  @include icons-sprite('create-new-account');
    //  width: icons-sprite-width('create-new-account');
     // height: icons-sprite-height('create-new-account');
      padding: 12px 30px;
      display: block;
      line-height: 40px;
      vertical-align: middle;
      color: white;
      background: $med-green;
      font-size: $what-for-you;
      font-weight: 700;
      min-width: 420px;
      border: 2px solid $green;

    //  padding: 10px 16px;
     // margin-left: 30px;
   //   border: 1px solid transparent;

      &:hover, &:focus {
        background: $green;
        border-color: $med-green;
     //   border: 1px solid $med-green;
        text-decoration: none;
      }
    }
  }
}

.user-login > div {
  @include clearfix;
}

.user-register-form,
.user-profile-form {
  @include container($large-container);
  padding: 50px 0;

  input[type="submit"] {
    background-color: $med-green;
    border: 2px solid $green;
    color: white;
    padding: 8px 14px;
    font-family: $roboto;
    font-size: $normal;

      &:hover, &:focus{
        background-color: $med-green;
        background: $green;
        cursor: pointer;
      }
  }

  h1 {
    line-height: 1.1;
    font-size: $big;
    margin-bottom: 20px;
  }
}

.user-profile-form {
  padding: 0;
}

#user-register-form {
  .form-item {
    width: 50%;

    input {
      width: 100%;
    }
  }
}

#user_register_form,
#user-profile-form {

  .form-item {
    label {
      font-weight: 400;
      margin-bottom: 3px;
    }
  }

  .password-confirm, .password-strength {
    float: none;
  }


      input[type="text"],
      input[type="password"] {
        padding: 12px;
        overflow: visible;
        border: 1px solid $shadow;
        font-size: $normal;
        font-family: $roboto;
        max-width: 100%;
      }

  .form-type-password-confirm {
    width: 440px;

    .form-item {
      width: 74%;
    }
  }
}

.form-actions {
  .back-to-login {
    margin-top: 20px;
  }
}

#user_pass_form {
  .form-item {
    label {
      font-weight: 400;
    }
  }
}

.field--name-field-commerce-product {
  #edit-submit {
    display: block;
  }
}

.view-commerce-user-orders {

  td.views-field-order-number {
    font-weight: 400;

    a {
      color: $med-green;
      font-weight: 700;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }


     td.views-field-nothing {
      a {
        color: $med-green;

        &:hover, &:focus {
          text-decoration: underline;
        }
      }
     }

  table {
    tr td {
      background: white;
      &.active {
        background: white;
        }
      }

      th {
        color: $white;
        a {
          font-weight: 400;
          font-size: $small;
          color: $white;
        }
      }
    }
}

.page-user-orders {

.commerce-order-commerce-order {

  .field--name-commerce-order-total {
    text-align: right;
    margin-bottom: 30px;
    width: 440px;
    margin-right: 0;
    margin-left: auto;
    background: $deep-silver;
    color: $med-grey;
    font-size: $small;
    font-weight: 400;
    border: none;

    .component-title {
      font-weight: 400;
    }

    tr.even, tr.odd {
      td {
        color: $med-grey;
        background: $deep-silver;
        padding: 3px 8px;

        &.component-total {
          color: $med-grey;
        }
      }
    }
  }

  .field--name-commerce-customer-billing, .field--name-commerce-customer-shipping, .field--name-field-order-comments {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $cu;

   > .field__label {
      font-size: $carousel;
     font-weight: 400;
      margin-bottom: 10px;
    }
  }

  }

}

//  add to cart styles
/*
.view-commerce-cart-block {
  position: absolute;
  top: 0;
  right: 110px;
  width: 350px;
  z-index: 900;
  @include clearfix;

  .total-item {
    float: left;
    margin-right: 5px;
  }

  .total-wrapper, .shopping-cart-is-empty {
    height: 26px;
    border-bottom: 1px solid $cu;
    border-right: 1px solid $cu;
    border-left: 1px solid $cu;
    padding: 5px;
    float: left;
    margin-left: 5px;
    color: $callus-black;
    padding-left: 25px;
    background: image-url('cart_small_gray.png') no-repeat 5px center;
  }

  .total-checkout {
    padding: 5px 8px 5px 26px;
    height: 26px;
    color: white;
    background: image-url('tick_small.png') 5px center no-repeat $red;
    a {
      color: white;
    }
  }

  .views-table {
    tr.even, tr.odd {
      td {
        background: white;
      }
    }
  }
}
*/

.view-shopping-cart-block-custom {
  @include clearfix;
  float: right;
  margin-left: gutters();

  .cs-shopping-cart-icon {
    display: block;
    float: left;
    position: relative;
    top: 1px;
    @include sprite($sprite-large-cart);
  }

  .total-wrapper-custom {
    height: 30px;
    float: left;
    color: $white;
    font-size: $normal;

/*    background: url('../images/large_cart.png') no-repeat 12px center;*/
    a {
      color: $white;
    }
  }

  .total-item {
    float: left;
  }

  .total-items-wrapper {
    margin-left: 6px;
    float: left;
    display: block;
    padding: 3px 8px;
    background: $green;
    color: $white;
    border-radius: 3px;
  }

  .total-line-items-cart {
    margin-right: 4px;
    font-weight: 700;
  }


}

.roll-weight-length {
  margin-top: 4px;
  font-size: $small;
  color: $med-grey;
}

#commerce-checkout-form-checkout .selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  background: $med-green;
  color: $white;
}

.view-commerce-line-item-table {
  .view-content .views-table {
    a {
      color: $med-green;
    }
  }
}

.commerce-price-formatted-components {
  .component-total {
    color: $green;
  }
}

#commerce-checkout-form-checkout {
  input {
    &.checkout-back {
      border: 2px solid $shadow;
    }
  }
}

body .selectboxit-container {
  .selectboxit {
    border: 1px solid $shadow;
  }
  .selectboxit-options {
    border: 1px solid $shadow;
    border-top: none;
    li {
      border-bottom: 1px solid $cu;
      margin-bottom: 0;
    }
  }
}

.logged-in.page-user {
  .l-content {
    @include container($large-container);
    padding: 50px 0;
    h1 {
      font-size: $big;
      margin-bottom: 20px;
    }
    h2 {
      font-size: $mini-title;
      border-bottom: 2px solid $med-green;
      margin-bottom: 20px;
      padding-bottom: 5px;
    }
  }
}

fieldset {
/*  &.form-wrapper {
    @include span(6);
    margin-bottom: gutters();

    &:nth-child(2n) {
      @include span(6 last);
    }

    &:nth-child(2n+1) {
      @include span(6 break);
    }
  }*/
}

.page-checkout {
  .customer_profile_billing {
  //  @include span(6);
  //  margin-bottom: gutters();
    .group-invoice {
      @include clearfix;

      .form-wrapper {
        @include span(6);
        margin-bottom: gutters();

        .form-item {
          margin-bottom: 0;
        }

        input {
          width: 100%;
        }

        &:nth-child(2n) {
          @include span(6 last);
        }

        &:nth-child(2n+1) {
          @include span(6 break);
        }
      }
    }

    .field-name-commerce-customer-address {

      .form-item {
        margin-right: 0;
        margin-bottom: 0;
        float: none;
      }

      input {
        width: 100%;
      }

      div.name-block {
        @include span(6);
        margin-bottom: gutters();
      }

      div.street-block {
        @include span(6 last);
      }

      div.locality-block {
        clear: both;
        float: none;

        .form-item {
          @include span(6);

          &:nth-child(2n) {
            @include span(6 last);
          }
        }
      }
    }
  }

  .customer_profile_shipping {

    div.field-name-field-customer-phone {
      .form-item {
        @include span(6 first);
        margin-top: gutters();

        input {
          width: 100%;
        }
      }
    }

    .field-name-commerce-customer-address {

      input {
        width: 100%;
      }

      .form-item {
        margin-right: 0;
        margin-bottom: 0;
        float: none;
      }

      div.name-block {
        @include span(6);
        margin-bottom: gutters();
      }

      div.street-block {
        @include span(6 last);
      }

      div.locality-block {
        clear: both;
        float: none;

        .form-item {
          @include span(6);

          &:nth-child(2n) {
            @include span(6 last);
          }
        }
      }
    }
  }

  .commerce_fieldgroup_pane__group_comments {
    @include span(6 last);
    margin-bottom: gutters();
  }

  .commerce_payment {
    @include span(6);
  }

  .accept_terms {
    @include span(6 break);
    margin-bottom: gutters();
  }

  .commerce_shipping {
    @include span(6 last break);
    margin-bottom: gutters();
  }

  .checkout-buttons {
    clear: both;
  }

  // now fix the heights, will need revisit

/*  .customer_profile_shipping,
  .customer_profile_billing {
    min-height: 428px;
  }*/

  .commerce_shipping,
  .commerce_payment {
    min-height: 370px;

    .description {
      line-height: 1.6;
    }
  }

  .commerce_fieldgroup_pane__group_comments {
    min-height: 262px;
  }

  #commerce-checkout-form-checkout .accept_terms {
    //margin-top: 28px;
    min-height: 244px;
    text-align: center;
    position: relative;
    top: 18px;

    .form-item-accept-terms-terms-of-use {
      transform: translateY(-50%);
      top: 50%;
      left: 0;
      width: 100%;
      padding: 0 20px;
      position: absolute;
    }
  }
}

#views-form-commerce-cart-form-default {
  .checkout-back-cs {
    background: transparent;
    border: 2px solid $shadow;
    padding: 5px 10px;
    position: relative;
    top: 1px;
    margin-right: 10px;
    margin-bottom: 15px;
    display: inline-block;

    &:hover, &:focus {
      background-color: $green;
      border: 2px solid $med-green;
      cursor: pointer;
      text-decoration: none;

      a {
        color: $white;
      }
    }

    a {
      font-weight: 400;
      text-decoration: none;
      color: $callus-black;

      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
}