.where-adv-page {
  .pane {
    &-node {

      .where-advert {
        padding: 0;
        @include container($gf-container);
      }

      .check-spots {
        @include container($normal-container);
        font-size: $big;
        padding: 40px 0;
      }

      .check-spots-wrapper {
        box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5);
        position: relative;
      }

      .where-advert-wrapper {
        @include clearfix;
        background: $med-green;
        color: $white;
        position: relative;
        height: $height-stopper-with-menu;

        .wa-left {
          @include left_green;
/*          max-width: 530px;
          padding: 110px 0 60px;*/
        }

        .wa-right-icon {
          @include right_green;
       //   padding: 88px 0 78px;
        }

        .ptitle {
          @include title_green;
        }
      }
    }
  }

  .l-socialpromo-wrapper {
    padding: 95px 0;
    @include container($green-second);

    .l-socialpromo__text {
      @include container($socialpromo-container);
      margin-bottom: 0;
    }
  }

}

.wadv-wrapper {
  @include clearfix;
  background: $wadv-grey;
}

.wadv-left {
  @include span(6 inside no-gutters);

  .respond-gmap {
    padding-bottom: 68%;
    min-height: 660px;
  }
}

.wadv-right {
  @include span(6 inside no-gutters);
  min-height: 660px;
  position: relative;
}

.wadv-content {

  // scrollbar stuff

  .mCSB_inside > .mCSB_container {
    margin-right: 20px;
  }

  .mCSB_scrollTools .mCSB_draggerRail {
    background-color: $case_gray;
    // background-color: rgba(59,133,238,0.4);
  }

  .mCSB_scrollTools .mCSB_dragger {
    // background-color: rgba(238,238,238,0.75);

    .mCSB_dragger_bar {
      background-color: $med-green !important;
    }
  }

  .loc-search-results {
    position: relative;

    .results {
      height: 320px;
      overflow: hidden;
    }
  }

  .loc-search-results {

    .results {
      margin-left: 0;
      padding-left: 0;
      list-style: none;
      list-style-type: none;

      .active {
        color: $med-green;
      }

      li {
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid $deep-silver;

        &:hover, &:focus {
          cursor: pointer;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }

  .loc-search-form {

    input[type="text"] {
      padding: 10px;
      font-size: 1em;
      max-width: 570px;
      width: 100%;
      overflow: visible;
    }

    .form-type-textfield {
      label {
        font-family: $roboto-con;
        display: block;
        margin-bottom: 8px;
        font-size: $key-points;
        color: $med-green;
        font-weight: 400;
      }
    }
  }

  padding: 40px;
  @include container($wadv);

  .wadv-text {
    font-size: $key-points;
    margin-bottom: 25px;
  }

  .form-item {
    margin-bottom: 20px;
  }

  input[type="submit"] {
    width: 100%;
    padding: 10px 0;
    font-family: $roboto;
    color: $white;
    font-size: $slight;
    background: $green;
    border: none;
  }
}