// Import Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,400i,700,700i|Roboto:400,400i,700,700i&subset=greek');

$roboto: "Roboto", sans-serif;
$roboto-con: "Roboto Condensed", sans-serif;

// Font Sizes
$very-small: 0.75rem; // 12px
$small: 0.875rem; //14px
$normal: 1rem; //16px
$slight: 1.125rem; // 18px
$medium: 1.2rem;
$carousel: 1.25rem; // 20px
$large: 1.25rem; //20px
$what-for-you: 1.375rem; // 22px
$key-points: 1.5rem; // 24px
$res-title: 1.75rem; //28px
$mini-title: 1.875rem; //30px
$title: 2rem; //32px
$big: 2.25rem; // 36px
$bigger: 2.5rem; // 40px;
$huge: 3rem; //48px

@include breakpoint($tablet) {
  $huge: 1.5rem;
}