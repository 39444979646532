$white: #ffffff;
$green: #b3cc3c;
$light-green: #b8d04a;
$med-green: #9bb528;
$callus-black: #2f2f2f;
$dark-grey: #333333;
$dark-grey2: #444444;
$footer-text: #cccccc;
$med-grey: #666666;
$client-sample: #3277F0;
$dmed-grey: #999999;
$teaserc: #555555;
$light-grey: #f6f6f6;
$wf-grey: #efefef;
$black: #000;
$case_gray: #c2c2c0;
$carousel_bullets: #c5c5c5;
$med-black: #010101;
$footer-black: #1d1d1d;
$shadow: #bcbcbc;
$cu: #e1dfdf;
$deep-silver: #dddddd;
$wadv-grey: #e4e4e4;
$placeholder: #909090;
$ep_border: #e8e8e8;
$basket: #eeeeee;
$mustard: #daab1d;
$light-blue: #74a2ff;
$blue: #01B9E8;
$purple: #8442e8;
$icon-gray: #b1b1b1;
$orange: #eb5b0c;
$cart: #CF644A;
$new_green: #9BB528; //5.2