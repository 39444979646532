// start of core.scss

.slicknav_btn {
  position: relative;
  display: block;
  vertical-align: middle;
  float: right;
  padding: 0.438em 0.625em 0.438em 0.625em;
  line-height: 1.125em;
  cursor: pointer;
  .slicknav_icon-bar + .slicknav_icon-bar {
    margin-top: 0.188em;
  }
}

.slicknav_menu {
  *zoom: 1;
  .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left;
  }
  .slicknav_icon {
    float: left;
    width: 1.125em;
    height: 0.875em;
    margin: 0.188em 0 0 0.438em;
      &:before { // Firefox Mobile v42 bugfix
        background: transparent;
        width: 1.125em;
        height: 0.875em;
        display: block;
        content: "";
        position: absolute;
      }
  }
  .slicknav_no-text {
    margin: 0;
  }
  .slicknav_icon-bar {
    display: block;
    width: 1.125em;
    height: 0.125em;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  }
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}
.slicknav_nav {
  clear: both;
  ul {
    display: block;
  }
  li {
    display: block;
  }
  .slicknav_arrow {
    font-size: 0.8em;
    margin: 0 0 0 0.4em;
  }
  .slicknav_item {
    cursor: pointer;
    a {
      display: inline;
    }
  }
  .slicknav_row {
    display: block;
  }
  a {
    display: block;
  }
  .slicknav_parent-link a {
    display: inline;
  }
}
.slicknav_brand {
  float: left;
}

// end of slicknav scss styles core, copy paste scss if it changes here

.slicknav_menu {
  clear: both;
  background: $med-green;

  .slicknav_btn {
    position: static;
    display: block;
    vertical-align: middle;
    float: none;
    padding: 0;
    line-height: 0;
    cursor: pointer;
    .slicknav_icon-bar + .slicknav_icon-bar {
      margin-top: 0;
    }

    .slicknav_icon {
      float: none;
      display: none;
    }
  }

  div.slicknav_nav {
    margin: 0;
    padding: 0;

    .slicknav_arrow {
      color: white;
      margin-left: 0.1em;
      font-size: 16px;
    }

    li {

      &.slicknav_parent {
        a.menuparent, span.nolink {
        //  float: left; revision_1
          display: inline-block;
          margin-top: -3px;
          padding-top: 0;
          padding-bottom: 0;
          border-bottom: none;
          padding-right: 30px;
          ul {
            clear: both;
          }
        }
      }

      a, span.nolink {
          display: block;
          color: white;
          font-weight: 700;
          padding-left: 0.3em;
          position: relative;
          padding: 0.4em;
          // background: #000;
          font-weight: 700;
          border-bottom: 1px solid $green;

        &:last-child {
          a {
            border-bottom: none;
          }
      }
    }

        &.slicknav_parent {
          > a {
            padding-left: 0;
          }
        }

        .slicknav_arrow {
          position: absolute;
          top: 0;
          right: 0;
          height: 36px;
          width: 56px;
          display: block;
          line-height: 36px;
          vertical-align: middle;
          text-align: center;
        }
    }
  }

  .slicknav_nav .sf-hidden li{
    a{
      padding-left: 30px;
    }
  }


  .slicknav_menutxt {
    display: block;
    float: none;
    line-height: 160%;
   // float: right;
/*     margin-right: 0.8em;
    margin-bottom: -0.2em; */
    vertical-align: text-bottom;
    padding: 0.6em;
    background: $med-green;
    color: white;
    width: 2.8em;
    height: 2.8em;
    cursor: pointer;
   position: absolute;

    .resp-mnu {
      margin-top: 0.678em;
      display: block;
      background: white;
      width: 100%;
      height: 0.3em;
      border-radius: 2px;

      &:after {
        display: block;
        content: "";
        position: relative;
        top: 0.39em;
        background: white;
        width: 100%;
        height: 0.3em;
        border-radius: 2px;
      }

      &:before {
        display: block;
        content: "";
        position: relative;
        top: -0.69em;
        background: white;
        width: 100%;
        height: 0.3em;
        border-radius: 2px;
      }
    }
  }
}
