// Banner Component

.solo-fx-height {
  height: $height-stopper;
}

.banner {
  @include container(full);
 // height: $height-stopper; this one creates some bad results, hence we used fx-height to target
  position: relative;

  &.fx-height {
    height: $height-stopper;
    min-height: $height-stopper;
  }

/*  &--larger {
    min-height: $height-stopper;
  }*/

  &__right {

    &__hero-image {
      //background: url('../images/banner/banner.jpg') center center no-repeat;
      background-size: cover;
      @include backpos;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      // add variations to the main banner here!
      // &--adv-with-img {
      //   background: url('/sites/default/files/page/image/adv-with-img.jpg') center center no-repeat;
      //   background-size: cover;
      // }
    }

    bottom: 0;
    top: 0;
    right: 0;
    position: absolute;
    width: 50%;

    &.inner {
    }
  }

  &__left {
   // @include container($banner-container);
    @include container($promo-photos);

    &.inner {
      @include container($promo-photos);
    }

    .center-wrapper {
     // padding: 160px 20px 200px 20px;
      padding: $plt-padding;
      max-width: $plt-max-width;
      margin-right: $plt-margin-right;

      /*
      padding: 0 20px;
      max-width: 45%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      */

      &.inner {
     //   padding: 70px 100px 20px 20px;
      }
    }
  }

  &__description {
   // margin-top: 40px;
    margin-top: 25px;
    color: $med-black;
   // font-size: $medium;
    font-size: $slight;

    &--lheight {
      line-height: 1.7;
      font-size: $slight;
    }

    p {
      margin-bottom: 5px;
    }
  }
  &-left-image,
  &-right-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    .edit-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      @include backpos;
    }
  }
  &-right-image {
    left: auto;
    right: 0;
    .edit-img {
      right: 0;
    }
  }
}

// extra stopper styles

// when we have a second level menu open, we have even less available pixels

.two-menu-stopper {
  .fx-height {
    height: $height-stopper-with-menu;
    min-height: $height-stopper-with-menu;

/*    &--larger {
      min-height: $height-stopper-with-menu;
    }*/
  }
}

.view-slick-front-top {
  position: relative;

  .field--name-field-images {
    .field__items {
      .field__item {
        display: none;
        overflow: hidden;
        position: relative;

        &:first-child {
          display: block;
        }
      }
    }

    .slick-initialized {
      &.field__items {
        .field__item {
          display: block;
        }
      }
    }
  }


  .view-content {
    bottom: 0;
    top: 0;
    right: 0;
    position: absolute;
    width: 50%;

    // align the image of the slider to the middle then hide the overflow
    img {
      max-width: none;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

/*    .views-row {
      height: $height-stopper;
      overflow: hidden;
    }*/
  }

  .slick-dots {
    display: none !important;
  }

  .carousel-arrow-left {
    @include sprite($sprite-prev-arrow-big-shadow);
    transition: opacity 0.3s;
    opacity: 1 !important;// 28.2
  }

  .carousel-arrow-right {
    @include sprite($sprite-next-arrow-big-shadow);
    transition: opacity 0.3s;
    opacity: 1 !important;// 28.2
  }

  .view-content {
    &:hover {
      .carousel-arrow-left {
        opacity: 1;
      }

      .carousel-arrow-right {
        opacity: 1;
      }
    }
  }

/*  .banner__left .center-wrapper {
    padding: 0 20px;
    max-width: 45%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }*/

}