// Turntabled Component

.l-turntabled {
  padding: 0 0 40px;

  .carousel-arrow-left {
    @include sprite($sprite-carousel-left-arrow);
  }

  .carousel-arrow-right {
    @include sprite($sprite-carousel-right-arrow);
  }

  .arrow-wrapper {
    position : absolute;
    top: 50%;
    padding: 10px;
    opacity: 0.7;
    transition: opacity 0.2s;
    margin-top: -16px;

    &--left {
      left: -45px;
    }

    &--right {
      right: -45px;
    }

    &:hover, &:focus {
      cursor: pointer;
      opacity: 1;
    }
  }

  .ptitle {
    margin-bottom: 40px;

    a {
      color: $med-black;
      transition: color 0.2s;

      &:hover, &:focus {
        color: $med-green;
      }
    }
  }

  .gradientdiv-slide {
    padding-top: 90px;
    box-shadow: 0 8px 8px -8px rgba(0, 0, 0, 0.4);
    background: url('../images/lnbg-turntable.jpg') repeat-x center bottom;

    .ptitle {
      @include container($callus-container);
      text-align: center;
    }

    &-wrapper {
      @include container($what-for-you-container);
    }
  }

  .slide-region {
    &-wrapper {
      @include container($what-for-you-container);
    }
  }

  // hide tabs by default, was useful only when we had multiple tabs
  .slide-tab {
    margin: 40px 0;
  //  display: none;
  }

  .slick-track {
    padding: 30px 0;
  }

  // only show predefined tab
  .tab-10 {
    display: block;
  }

  .carousel-items-nav {
    text-align: center;

    &__item {
      display: inline-block;
      padding: 20px 26px;
      font-size: $slight;
      font-weight: 700;
      background: $med-green;
      color: $white;

      &:hover, &:focus {
        background: $med-green;
     //   cursor: pointer;
        color: white;
      }

      &.active-citem {
        background: $med-green;
      }
    }
  }

}

.not-front {
  .l-turntabled {
    padding: 0;
  }
}