.contact-us-pane-page {

  .pane {

    &-block-12 {
        background: $med-green;

      .panels-outer-wrapper {
        @include container($discount-coupons-container);
        position: relative;
        padding: 70px 0 170px;
      }

    }

      &-node {
        padding: 100px 0;

        .node--webform {
          @include container($discount-coupons-container);
        }

      }
  }

  .small-utitle {
    font-size: $key-points;
    color: white;
  }

  .field--name-body {
    @include span(5);
  }

  .wf-wrapper {
    @include span(7 last);
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    .ep-form-title {
      padding: 22px;
      color: $white;
      background: $green;
      border-radius: 8px 8px 0 0;
      font-size: $slight;
      line-height: 100%;
    }
  }

  .webform-client-form-1 {
    padding: 30px;
    border: 1px solid $ep_border;

    .form-item {
      margin-bottom: 50px;

      &.form-type-radio {
        margin-bottom: 10px;
        color: $placeholder;

        .iradio_minimal-green.checked {

          + label {
            color: $black;
          }
        }
      }

      input[type="text"],
      input[type="email"],
      textarea {
        width: 100%;
        padding: 4px 0;
        font-family: $roboto;
        font-size: 16px;
        overflow: visible;
        border: none;
        border-bottom: 1px solid $green;

        &::placeholder {
          color: $placeholder;
          opacity: 1;
        }

      }

      &:last-child {
        margin-bottom: 0;
      }

      textarea {
        min-height: 150px;
      }
    }

    input[type="submit"] {
      font-family: $roboto;
      font-size: 16px;
      color: white;
      background: $green;
      border: none;
      font-size: $slight;
      padding: 8px 18px;
      display: block;
      margin: 0 auto;

      &:hover, &:focus {
        cursor: pointer;
        background: $med-green;
      }
    }
  }

}

.not-logged-in {
  &.contact-us-pane-page {

    .webform-client-form-1 {
      .webform-component-textarea {
        margin-bottom: 19px;
      }

      .webform-component--proion {
        margin-bottom: 0;
      }
    }

  }
}

.cu-out {
  position: absolute;
  right: 0;
  bottom: -110px;
  z-index: 10;
  left: 0;
}

.cu {
  @include container(910px right);
  .cu-icon-wrap {
    height: 105px;
    border-bottom: 1px solid $case_gray;
    position: relative;
  }

  .cu-textin {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 100%;
  }

  .cu-wrapper {
    position: relative;
    text-align: center;
    background: $white;
    @include span(4 inside no-gutters);
    height: 215px;

    &:last-child {
      @include span(4 last inside no-gutters);
    }
  }

  &__phone {
    &__icon {
      @include sprite($sprite-ep-phone);
      transform: translate(-50%,-50%);
      left: 50%;
      top: 50%;
      position: absolute;
    }

    &__text {
      height: 110px;
      position: relative;
      width: 100%;
    }
  }

  &__mail {
    &__icon {
      @include sprite($sprite-ep-mail);
      transform: translate(-50%,-50%);
      left: 50%;
      position: absolute;
      top: 50%;
    }

    &__text {
      height: 110px;
      position: relative;
      width: 100%;
    }
  }

  &__local {
    &__icon {
      @include sprite($sprite-ep-pin);
      transform: translate(-50%,-50%);
      left: 50%;
      top: 50%;
      position: absolute;
    }

    &__text {
      height: 110px;
      position: relative;
      width: 100%;
    }
  }

  .middle-cu {
    background: $wf-grey;

    a {
      color: $black;
      &:hover, &:focus {
        color: $med-green;
      }
    }
  }

}

.respond-gmap {
  position: relative;
  padding-bottom: 30%; // This is the aspect ratio
  height: 0;
  overflow: hidden;
  text-align: center;
  min-height: 420px;
}

.promoticket-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

div.logo-bubble img {
  max-width: 42%;
  margin-bottom: 6px;
}

.wch-wrapper {
  max-width: 435px;
}

.wch {
  font-size: $huge;
  line-height: 1.2;
  margin-bottom: 10px;
}

.wch-below {
  line-height: 1.3;
  font-size: $key-points;
}

.wch-icon {
  margin-top: 50px;
  @include sprite($sprite-ep-mail-plus);
}