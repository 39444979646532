.research-efficiency {

  .where-advert-wrapper {

    .wa-left {
      padding: 120px 0 115px;
    }

    .wa-right-icon {
      padding: 36px 0;
    }

  }
}