
.l-main {
    ul {
      list-style: disc;
      padding-left: 1.5rem;
      margin-left: 1.5rem;
      margin-bottom: 1.5rem;
      overflow: hidden;

      li {
        margin-bottom: 0.8rem;
      }
    }

    ol {
      list-style: decimal;
      padding-left: 1.5rem;
      margin-left: 1.5rem;
      margin-bottom: 1.5rem;

      li {
        margin-bottom: 0.8rem;
      }
    }
    .node--page--full {
      .field--name-body {

        h3 {
          font-size: $key-points;
          margin-bottom: 10px;
        }

        h4 {
          font-weight: 700;
        }

      }
    }
}


.l-breadcrumb {
  background: $deep-silver;
  padding: 8px 35px;
  color: $med-grey;

  ul.breadcrumb {
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
    list-style: none;
  }

  @include links($med-grey,$med-grey,underline);
}

#user-register-form,
#user-profile-form {
  .field-name-field-account-newsletter {
    color: $black;

    label {
      font-weight: 700;
    }

    input[type="checkbox"] {
      position: relative;
      top: -2px;
      width: auto;
      margin-left: 0;
    }
  }
}

.messages-regi {
  @include container($large-container);
  padding: 15px 0;
}

.ptitle {
  &--large {
    color: $black;
    font-size: $huge;
    line-height: 1.1;
  }

  &--footer {
    color: $white;
    margin-bottom: 40px;
    font-size: $key-points;
  }

  &--coloured {
    color: white;
    font-size: $big;
    line-height: 1.2;
    padding: 0 20px;
  }

  &--coloured-large {
    color: white;
    font-size: $bigger;
    line-height: 1.2;
  }

  &--coloured-huge {
    color: white;
    font-size: $huge;
    line-height: 1.2;
  }

  &--medium {
    font-size: $big;
    line-height: 1.2;
  }
}




p {
  margin-bottom: 1rem;
}

.l-page {
  ul.key-points-list {
    padding-left: 0;
    margin-left: 0;
    overflow: visible;
    list-style: none;
    list-style-type: none;
  }
}

.l-page {
  .key-points-list {

    li {
      margin-bottom: 25px;
      position: relative;
      list-style: none;
      list-style-type: none;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      font-size: $key-points;
      color: $med-black;
      margin-bottom: 15px;
      padding-left: 45px;

      &:before {
        content: "";
        display: block;
        @include sprite($sprite-key-points);
        position: absolute;
        left: 0;
        // correction for line-height
        top: 0.25em;
      }
    }

    &__text {
      color: $med-grey;
      font-size: $slight;
    }
  }
}

.google-maps-bubble-txt {
  text-align: center;

  .image {
    margin-bottom: 3px;
  }
}

.pvideo {
  position: relative;

  iframe {
    line-height: 0;
    display: block;
  }

  img {
    display: block;
  }

  transition: box-shadow 0.3s;

  &:hover, &:focus {
    cursor: pointer;
    box-shadow: 3px 6px 12px -2px rgba(0, 0, 0, 0.8);
  }

  &__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -35px;
    margin-left: -35px;
    @include sprite($sprite-video-play-large);
  }
}

.read-more {
  padding: 8px 18px;
  color: white;
  font-size: $slight;
  background: $med-green;
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 20px;

  &:hover, &:focus {
    background: $green;
  }
}

.zoom-wrapper-outer {

/*  .zoom-wrapper-outer {
    position: relative;
  }*/

  .zoom-wrapper-inner {
    margin-bottom: 30px;
    overflow: hidden;
    height: 315px;

    img {
      display: block;
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scale(1.20);
      }
    }
  }
}

.small-box {
  .image-title-box {
    position: relative;
  }

  &__image {
    margin-bottom: 30px;
    overflow: hidden;
    height: 315px;

    img {
      display: block;
    }
  }

  &__text {
    padding: 0 28px;
  }

  &__title {
    font-size: $what-for-you;
    line-height: 1.2;
  }

  &--absolute {

    .small-box__image {
      img {
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.28);
        }
      }
    }

    .table-wrap {
      display: table-cell;
      height: 52px;
      vertical-align: middle;
    }

    .small-box__title {
      display: table;
      width: 100%;
      position: absolute;
      bottom: 18px;
      left: 0;
      right: 0;
      color: white;
      font-size: $what-for-you;
      line-height: 1.2;
    }
  }
}

.cta {
  padding: 18px 22px;
  @include sprite($sprite-callus-btn);
  display: inline-block;

  &__text {
    vertical-align: middle;
    display: inline-block;
    color: white;
    font-weight: 700;
    font-size: $slight;
    padding-left: 18px;
  }

  &__icon {
    vertical-align: middle;
    display: inline-block;
    @include sprite($sprite-call-us);
  }

  &:hover, &:focus {
    @include sprite($sprite-call-us-green);
  }
}

.block--multiblock-1,
.block--mailchimp-signup,
.pane-mailchimp-signup-thepromoticket-newsletter {

  .mailchimp-signup-subscribe-form {
    position: relative;
  }

  .mailchimp-signup-subscribe-form-description {
    color: $med-grey;
    margin: 25px 0 20px;
    line-height: 1.35;
  }

  .form-required {
    display: none;
  }

  input[type="text"] {
    width: 100%;
    color: $dark-grey;
    padding: 14px 15px;
    border-radius: 18px;
    overflow: visible;
    font-size: $small;
    font-weight: 700;
    border: none;
    font-family: $roboto;

    &::placeholder {
      color: $dark-grey;
    }
  }

input[type="submit"] {
  position: absolute;
  right: 6px;
  bottom: 0;
  border: none;
  text-indent: -9999px;
  overflow: hidden;
  width: 56px;
  height: 46px;
  background: url('../images/envelope-nl.png') center center no-repeat;

    &:hover, &:focus {
      cursor: pointer;
    }
  }

  .block__title {
    font-size: $large;
    color: white;
  }

  .form-item-mail {
    margin-bottom: 0;

    .form-required {
      display: none;
    }
  }
}

.block--multiblock-1 {
  .mailchimp-signup-subscribe-form-description {
    margin: 10px 0 20px;  
  }
}

* {
  &:focus, &:hover {
    outline: none;
  }
}


.taxonomy-term--carousel {

  // prevent blue outline from showing after click
  &:focus, &:hover {
    outline: none;
    cursor: pointer;
  }

  .taxonomy-term__content {
    margin: 0 16px;
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
    padding-bottom: 14px;
    transition: transform 0.3s ease-in-out;
    background: $white;
  }

  .field--name-field-artc-icon {
    position: absolute;
    right: 15px;
    bottom: -24px;
  }

  .group-image-icon {
    position: relative;
  }

  .field--name-field-image {

    img {
      display: block;
    }
  }

  .field--name-name-field {
    line-height: 1.2;
    padding: 24px;
    font-size: $slight;
    transition: color 0.2s;

    a {
      color: $black;

      &:hover, &:focus {
        color: $med-green;
      }
    }
  }

  .field--name-name-field {
    color: $black;
  }

  // styles only for the center item
  &.slick-center {

    .field--name-name-field {
      font-weight: 700;
      font-size: $carousel;
      color: $med-green;
    }

    .taxonomy-term__content {
      transform: scale(1.15);
    }

/*    .field--type-image {
        img {
          transition: transform 0.2s ease-in-out;

          &:hover {
            transform: scale(1.18);
          }
        }
      }*/
  }

}

// Language Switcher
.block--locale {
  right: 36px;
  position: absolute;
  @include span(1 last);
  @include clearfix;
  margin-top: 16px;
  top: 112px;

  .language-switcher-locale-url {
    float: right;

    li {
      float: left;

      a {
        color: $dmed-grey;

        &.active {
          color: $med-black;
        }
      }

      &:after {
        content: "|";
        margin: 0 3px;
        color: $med-green;
      }

      &:last-child {
        margin-right: 0;

        &:after {
          display: none;
        }

      }
    }
  }
}

.rounded-arrow-nav {
  @include sprite($sprite-rounded-arrow-nav);
  position: absolute;
  z-index: 99;
  bottom: -35px;
  left: 50%;
  margin-left: -30px;

  &:hover, &:focus {
    cursor: pointer;
  }
}

.field--name-field-artc-icon {

  .field__item {
    background: $case_gray;
    border-radius: 50%;
    display: block;
    position: relative;

    img {
      transform: translate(-50%,-50%);
      top: 50%;
      position: absolute;
      left: 50%;
    }
  }
}

.site-logo {
  img {
    display: block;
  }
}

.choose-arrow {
  text-align: center;
  color: $white;
  font-size: $key-points;
  position: relative;
  bottom: -25px;
}

.taxonomy-term {

  &--artc,
  &--art-dia {
    .artc-active-wrap {
      &.active {

        .field--name-field-artc-icon .field__item {
          background: $green;
        }

        .field--name-name-field {
          color: $green;
        }

      }
    }
  }

  &--minimal {

    .field {

      &--name-name-field {
        color: $med-black;
      }

      &--name-field-artc-icon {
        .field__item {
          width: 144px;
          height: 144px;
          margin: 0 auto;
        }
      }

    }

    &:hover,
    &:focus {

      .field--name-field-artc-icon .field__item {
        background: $green;
      }

      .field--name-name-field {
        color: $green;
      }

    }
  }

  &--view {
    .field {

      &--name-name-field {
        color: $med-black;
      }

      &--name-field-artc-icon {
        .field__item {
          width: 64px;
          height: 64px;
        }
      }
    }
  }

  &--carousel {

/*    &:hover, &:focus {
      .field {
        &--name-field-artc-icon {
          .field__item {
            background: $green;
          }
        }
      }
    }*/

    .green-on {
      background: $green;
    }

    .field {
      &--name-field-artc-icon {
        .field__item {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

}

.view-taxonomy-views {
  .taxonomy-term--view {
   // .artc-active-wrap {
      &:hover, &:focus {

        .field--name-field-artc-icon {
          .field__item {
            background: $green;
          }
        }

        .field--name-name-field {
          color: $green;
        }
      }
   // }
  }
}


.page-sitemap {

  .l-content {
    @include container($large-container);
    padding: 50px 0;

    h1 {
      color: $black;
      font-size: $huge;
      line-height: 1.1;
      margin-bottom: 25px;
    }

    .site-map-box-menu {
      ul {
        list-style-type: none;
        li {
          font-size: $carousel;
          a {
            color: $black;
            margin-bottom: 10px;
            display: inline-block;
            &:hover, &:focus {
              color: $med-green;
            }
          }
          ul li {
            font-size: $normal;
            list-style-type: disc;
            a {
              margin-bottom: 0;
            }
          }
        }
      }
    }

  }
}

.mCSB_scrollTools  {
  .mCSB_dragger .mCSB_dragger_bar {
    width: 6px;
  }
  .mCSB_draggerRail {
    width: 4px;
  }
}

.iradio_minimal-green {
  @include sprite($sprite-radio-button);
  //background-position: -520px -439px;
  &.hover {
    @include sprite($sprite-radio-button);
  }
  &.checked {
    @include sprite($sprite-radio-button-active);
    &.hover {
      @include sprite($sprite-radio-button-active);
    }
  }
}

#webform_drawer {
  .messages {
    padding: 5px 10px;
    border: 1px solid $dmed-grey;
    ul {
      margin: 0;
      li {
        color: $teaserc;
        font-size: $small;
      }
    }
  }
}

.captcha {
  margin-bottom: 10px;
  text-align: center;
  .g-recaptcha {
    display: inline-block;
  }
}

.l-main .messages {
  ul {
    margin-bottom: 0;
  }
}

input[type="submit"] {

  &:hover, &:focus {
    cursor: pointer;
  }
}

// adds green color to text in product accordion
.green-text {
  color: $new_green;
}