.about-us-page {

  .group-wrapper {
    background: $basket;
  }

  .group-inner {
    @include container ($discount-coupons-container);
    position: relative;
    height: 406px;
  }

  .about-us-title {
    font-size: $big;
    margin-bottom: 20px;
    &:before {
      content: "";
      background: $med-green;
      width: 15px;
      height: 27px;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .about-us-inner {
    max-width: 45%;
    padding: 40px 20px 60px 0px;
    margin-right: 2%;
    float: left;
  }

  .field--name-field-images {
    position: absolute;
    right: 0;
    padding-top: 40px;
  }

}

.about-us-block {

  .about-us-wrapper {
    padding: 140px 0 50px;
    @include container ($discount-coupons-container);
  }

  .about-us-title {
    @include span(5);
    &:before {
      content: "";
      background: $light-blue;
    }
  }

  .about-us-body {
    @include span(7 last);
  }

  &.about-us-block-2 {
    background: $basket;

    .about-us-wrapper {
      padding: 140px 0 160px;

    }

    .about-us-title {
      &:before {
        background: $purple;
      }
    }

  }

}

