// What for you Component

@include with-layout(12 1/1.4) {
  .l-what-for-you {
    background: $wf-grey;
    .ptitle {
      margin-bottom: 60px;
    }

    &-wrapper {
      @include container($what-for-you-container);
      text-align: center;
      padding: 90px 0;
    }

    @include with-layout((last-flow: from)) {
      .small-box {
        box-shadow: 0px 8px 22px -2px rgba(0, 0, 0, 0.25);
        @include span(4);
        background: $white;

        // min-width to fix responsive
        min-width: 315px;

        &:last-child {
          @include span(4 last);
        }

        &__text {
          min-height: 72px;
        }
      }
    }
  }
}

.not-front {
  .region-full-wrapper.l-what-for-you {
    background: none;

    .l-what-for-you-wrapper {
      padding: 0;
    }
  }
}

.ways-adv-block-1 {
  background: $wf-grey;

  .way-adv-wrapper {
    h2 {
      margin-bottom: 20px;
    }
  }
}