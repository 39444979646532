.pane-block {
  &-10 {
    background: $med-green;

    h1 {
      font-size: $huge;
    }

    .panels-outer-wrapper {
      @include container($discount-coupons-container);
      color: $white;
      padding: 65px 0px;
    }

    .content-title {
      @include container($concerning-coupons left);
    }
  }
  &-11 {
    text-align: center;
    @include container($concerning-coupons);
    margin: 0 auto;
    padding: 40px 0 40px; //6.2 40-60px;

    h2 {
      font-size: $big;
      margin-bottom: 30px;
    }

  }

  .subtitle {
    font-size: $slight;
  }

  &-47 {
    background: $wf-grey;
    .panels-outer-wrapper {
      text-align: center;
      @include container($concerning-coupons);
      margin: 0 auto;
      padding: 0 0 60px;
    }

    h2 {
      font-size: $mini-title;
      margin-bottom: 30px;
    }

  }
}

.case-study-descs {
  position: relative;
  background: $med-green;
  //margin-bottom: 120px; //5.2
  h1 {
    font-size: $big;
    line-height: 1;
    margin-bottom: 14px;
    color: $white;
  }

  .panels-outer-wrapper {
    @include container($discount-coupons-container);
    color: $white;
    padding: 55px 0px; // ex 65px
    .choose-arrow {
      font-size: $slight; //15.2
    }
  }

  .dcoup {
    @include container($normal-container left);

    .field--name-description-field,
    .subtitle {
      color: $white;
      margin-top: 0;
      margin-bottom: 0;
      font-size: $slight;
    }
  }

}

.discounts-page-noarg {
  .rounded-arrow-nav {
    bottom: -100px;
  }
}

.discount-icons-view-panel-block {
  @include container($discount-coupons-container);
  padding: 53px 0 40px;
  text-align: center;


  .view-taxonomy-views {
  @include clearfix;
}

  .views-row {
    text-align: center;
   // @include span(2 inside no-gutters);
   // margin-bottom: gutter();
    width: 206px;
    margin-bottom: 20px;
    display: inline-block;

    &:nth-child(6n) {
    //  @include span(2 inside no-gutters);
    }

    &:nth-child(6n+1) {
    //  @include span(2 inside no-gutters break);
    }

    .field--name-field-artc-icon {
      a {
        display: block;
        margin: 0 auto;
      }
    }

  }

}

@include with-layout(16 1/4) {

  .adv-categories-block {
    @include container($discount-coupons-container);
    padding: 53px 0 40px;
    text-align: center;

    .views-row {
      text-align: center;
      display: inline-block;
      width: 145px;
      vertical-align: top;
      margin-bottom: 20px;
    //  @include span(2 inside no-gutters);

      &:nth-child(8n) {
      //  @include span(2 inside no-gutters);
      }

      &:nth-child(8n+1) {
     //   @include span(2 inside no-gutters break);
      }

      .field--name-field-artc-icon {
        a {
          display: block;
          margin: 0 auto;
        }
      }
    }

    .taxonomy-term--minimal {
      .field--name-field-artc-icon .field__item {
        width: 120px;
        height: 120px;
      }

      .field--name-name-field {
        font-size: $slight;
        letter-spacing: -0.01em;
      }

    }

  }
}

.taxonomy-term {

  &--minimal {

    .field {

      &--name-name-field {
        font-size: $large;
        margin-top: 30px;
        line-height: 1.2;
      }

    }

  }

}

.case-studies-caru {
  @include container($discount-coupons-container);

  .slick-list {
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
    background: $white;
  }
}

.node {
  &--case-study {
    &.node--full {
      @include clearfix;

      .cs-misc-info {
        margin-top: 30px;
        line-height: 1.28;

        .field {
          margin-bottom: 6px;
        }

        .field__item {
          font-size: $carousel;
        }

        span {
          display: block;
          margin-bottom: 6px;
          color: $med-grey;
          max-width: 280px;
          line-height: 1.5;
        }
      }

      .field {
        margin-bottom: 4px;

        &--name-field-case-study-trev {
          font-size: $key-points;
          color: $new_green;
          padding-top: 10px;
          .field__item {
            color: $new_green;
          }
        }

        &--name-field-image {
          width: 50%;
          float: left;
          margin-bottom: 0;
        }

        &--name-body {
          width: 50%;
          float: left;
          padding: 60px;
          background: $white;
          min-height: 533px;
          margin-bottom: 0;

          .field {

            &__label,
            &__items,
            &__item {
              display: inline;
              float: none;
            }

            &__label {
              font-weight: 400;
            }

            &__items {
              //color: $med-grey;
              font-weight: 600;
            }
          }
        }

      }

    }

  }

}

// Slick Carousel

.case-studies-caru {
  position: relative;
  margin-top: 120px; //6.2
}

.arrow-wrapper {
  display: block;
  position: absolute;
  z-index: 10;

  &:hover, &:focus {
    cursor: pointer;
  }

  &--left {
    left: 10px;
    top: 50%;
    margin-top: -30px;
  }

  &--right {
    right: 10px;
    top: 50%;
    margin-top: -30px;
  }
}

/*.field-collection-container {
  margin: 0;
  border: none;
}*/

.view-case-studies {
  .carousel-arrow-left {
    @include sprite($sprite-inactive-slick-arrow-left);

    &:hover, &:focus {
      @include sprite($sprite-active-slick-arrow-left);
    }
  }

  .carousel-arrow-right {
    @include sprite($sprite-inactive-slick-arrow-right);

    &:hover, &:focus {
      @include sprite($sprite-active-slick-arrow-right);
    }
  }
}

ul.slick-dots {
  text-align: center;
  padding: 20px 0;
  border: none;
  position: relative;
  z-index: 2;
  margin-left: 0;
  margin-bottom: 0;

  li {
    display: inline-block;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &.slick-active {
      .slick-paging,
      button {
        background: $green;
      }
    }

    .slick-paging, button {
      display: block;
      height: 16px;
      width: 16px;
      font-size: $small;
      font-weight: 700;
      background: $carousel_bullets;
      border-radius: 50%;
      overflow: hidden;
      text-indent: -9999px;
      border: none;
      outline: none;

      &:hover, &:focus {
        cursor: pointer;
      }
    }
  }
}