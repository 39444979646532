.block--commerce-overrides-shopping-cart-custom {
  display: inline-block;
  margin-right: 2%;
  vertical-align: middle;
  text-align: left;
  min-width: 164px;
  //min-width: 141px; //28.2
}

.about-mak-msg {

  &:before {
    content: "";
    display: table;
    clear: both;
  }

  .about-mak-content {
    padding: 15px;
    background: $light-grey;
    color: $med-grey;
    font-size: $small;
    margin: 10px 0 20px;
  }
}

.messages {
  @include clearfix;

  &.only-cart {
    position: relative;
    padding: 10px;
    background: $med-green;
    color: $white;
    border: 2px solid $green;

    .add-to-cart-status-msg {
      padding-top: 3px;
      padding-left: 40px;
    }

    a {
      font-weight: 700;
      text-decoration: underline;
      color: $white;
    }

    &:before {
      @include sprite($sprite-large-cart);
      content: "";
      display: block;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
}

.messages--add-to-cart-popup {
  display: none;
}

.popup-addtocart-wrapper {

  .button-wrapper {
    text-align: center;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 6px solid $footer-text;
  }

  .inline-prices {
    margin-top: 6px;

    .price-suffix-pop {
      margin-left: 5px;
      font-size: $very-small;
    }

    .init-price {
      text-decoration: line-through;
      margin-right: 8px;
      display: inline-block;
    }

    .normal-price {
      font-weight: 700;
      color: $med-green;
      display: inline-block;
    }
  }

  .button {

    &.checkout {
      background: $med-green;
      border: 2px solid $green;
      padding: 5px 10px;
      position: relative;
      top: 1px;
      display: inline-block;

      a {
        color: $white;
      }
    }

    &.continue {
      background: transparent;
      border: 2px solid $shadow;
      padding: 5px 10px;
      position: relative;
      top: 1px;
      margin-right: 15px;
      display: inline-block;
      margin-bottom: 10px;
    }

    &.continue,
    &.checkout {
      &:hover,
      &:focus {
        background-color: $green;
        border: 2px solid $med-green;
        cursor: pointer;
        text-decoration: none;
        color: $white;
      }
    }
  }

  .product-details-wrapper {
    @include clearfix;
  }

  .added-product-image {
    float: left;
    margin-right: 10px;
  }
}

#pgwModal.pgwCart {
  line-height: 1.3;

  .pm-body {
    padding: 20px 20px 10px 20px;
  }

  .pm-title {
    padding: 10px 28px 10px 46px;
    background: $med-green;
    color: $white;
    border: 2px solid $green;
    letter-spacing: -0.02em;
    position: relative;

    &:before {
      @include sprite($sprite-large-cart);
      content: "";
      display: block;
      position: absolute;
      top: 8px;
      left: 10px;
    }
  }

  .pm-close {
    top: 28px;
    right: 32px;
  }
}

input[type="submit"] {
  font-size: $roboto;
  font-size: 1em;
}

.simple-node-wrapper {
  padding: 50px 0;
  @include container($banner-container);

  h1.ptitle {
    margin-bottom: 25px;
  }
}

.way-adv-body-txt {
  font-size: $slight;
}

/* ----------- Eshop Pages ------------ */

.page-cart,
.page-checkout {

  .selectboxit-container {
    max-width: 480px;
  }

  .selectboxit-container .selectboxit span,
  .selectboxit-container .selectboxit .selectboxit-options a {
    height: 42px;
    border: none;
    line-height: 44px;
  }

  .l-main {
    @include container($large-container);
    margin-top: 40px;
    margin-bottom: 40px;
  }

  h1 {
    margin: 20px 0;
    color: $black;
    font-size: $big;
    line-height: 1.2;
  }

  input[type="submit"] {
    &:hover, &:focus {
      cursor: pointer;
    }
  }
}

.label-mobile {
  display: none;
}

.l-page .l-main {
  .commerce-checkout-progress {
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 0;
  }
}

.page-node-done {
  .l-main {
    @include container($large-container);
    margin-top: 40px;
    margin-bottom: 40px;
  }

  h1 {
    margin: 20px 0;
    color: $black;
    font-size: $big;
    line-height: 1.2;
  }

}

.page-node-1172 {
  .pane-e-shop {
    .pane-title {
      @include container($large-container);
      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;
      color: $black;
      font-size: $big;
      line-height: 1.2;
    }
  }
}

.slideshow-slick {
  position: relative;

  .carousel-arrow-left {
    @include sprite($sprite-prev-arrow-big);
  }

  .carousel-arrow-right {
    @include sprite($sprite-next-arrow-big);
  }

  .rounded-arrow-nav {
    bottom: 65px;
  }

  .arrow-wrapper {
    position: absolute;
    top: 41%;
    padding: 10px;
    opacity: 0.7;
    transition: opacity 0.2s;
    z-index: 10;

    &--left {
      left: 20px;
    }

    &--right {
      right: 20px;
    }

    &:hover, &:focus {
      cursor: pointer;
      opacity: 1;
    }
  }

}

.rolls-caru {
  margin-bottom: 80px;
  position: relative;
  z-index: 10;

  .carousel-arrow-left {
    @include sprite($sprite-prev-arrow-big);
  }

  .carousel-arrow-right {
    @include sprite($sprite-next-arrow-big);
  }

  .slick-track {
    padding: 30px 0;
  }

  .node--product-display {
    margin: 0 25px;
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
  }

  .arrow-wrapper {
    position : absolute;
    top: 50%;
    padding: 10px;
    opacity: 0.7;
    transition: opacity 0.2s;
    margin-top: -33px;

    &--left {
      left: -45px;
    }

    &--right {
      right: -45px;
    }

    &:hover, &:focus {
      cursor: pointer;
      opacity: 1;
    }
  }

  .view.view-e-shop {
    .views-row {
      clear: none;
      float: left;
      margin: 0;
      box-shadow: none;

      .field--name-field-product-roll-image {
        margin-bottom: 0;
      }

      .group-box-shadow {
        padding: 26px;
      //  min-height: 185px;
        min-height: 236px;
      }
    }
  }
}

.gray-pane-wrap {
  background: $wf-grey;
  padding: 50px 0 40px;
}

.user-profile {
  .field--name-field-account-newsletter {
    margin: 15px 0;
    @include clearfix;
  }
}

.rt-pwrap {
  @include container($large-container);
  padding-bottom: 60px;
  padding-top: 60px;

  img {
    display: block;
  }

  .toi {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    height: 66px;
    padding-bottom: 6px;
    @include clearfix;

    .toi-text {
      position: relative;
      top: 6px;
      font-size: $what-for-you;
      font-weight: 700;
      color: $white;
      float: left;
      margin-left: 20px;
    }

    .rmore {
      float: right;
      margin-right: 20px;
    }

    .read-more {
      margin: 0;
    }
  }

  .rt-left {
    position: relative;
    @include span(6);
  }

  .rt-right {
    position: relative;
    @include span(6 last);
  }
}

.views-row {
  .slick-slider {
    display: none;
  }

  .slick-initialized {
    display: block;
  }
}