.node--page {
  &.node-teaser {
    position: relative;

  }
}

.how-it-works {

  .l-socialpromo-wrapper {
    padding: 95px 0;
    @include container($green-second);

    .l-socialpromo__text {
      @include container($socialpromo-container);
      margin-bottom: 0;
    }
  }

  .where-advert {
    padding: 0;
   // @include container($basket-container);
    @include container($gf-container);
    position: relative;
  }

  .where-advert-wrapper {
    height: $height-stopper-with-menu;
    background: $med-green;
    color: $white;
    position: relative;
    @include clearfix;

    .wa-left {
      @include left_green;
     // max-width: 630px;
     // padding: 75px 0 30px;

      .ptitle--coloured-huge {
       // font-size: $big;
        @include title_green;
      }

      p {
      //  line-height: 1.4;
      }

    }

    .wa-right-icon {
      @include outbox;
      @include right_green;
   //   padding: 40px 0;
    //  position: absolute;
    //  right: 0;
    //  top: 0;
   //   z-index: 10;
    //  width: 46%;
    }

    .ptitle {
    //  margin-bottom: 40px;
    }
  }

}

.how-it-works-block {
  background: $white;

  .how-it-works-wrapper {
    @include container($basket-container);
    padding: 80px 0 64px;
   // display: table;
    display: block;
  }

  .how-it-works-left {
    display: table-cell;
    vertical-align: middle;
    @include span(7);
    float: none;
  }

  .how-it-works-right {
    display: table-cell;
    vertical-align: middle;
    @include span(5 last);
    float: none;
  }

  .how-it-works-image-1 {
    @include sprite($sprite-pl-1);
    display: table-cell;
    vertical-align: middle;
  }

  .how-it-works-image-2 {
    @include sprite($sprite-pl-2);
    display: table-cell;
    vertical-align: middle;
  }

  .how-it-works-image-3 {
    @include sprite($sprite-pl-3);
    display: table-cell;
    vertical-align: middle;
  }

  .how-it-works-text {
    display: table-cell;
    vertical-align: middle;
    span {
      font-size: $big;
      border-bottom: 2px solid $light-green;
      margin-left: 20px;
      padding-bottom: 5px;
    }
  }

  .how-it-works-title {
    font-size: $mini-title; //5.2
    line-height: 1.4;
    margin-bottom: 35px;
  }

  .how-it-works-body {
    font-size: $slight;
    line-height: 1.4;
  }

  &.how-it-works-block-2 {
    background-color: $basket;
  }

}
