// Social Promo + Call Us Component

.l-socialpromo {
  background: url('../images/prefooter/socialpromo.jpg') center center no-repeat;
  background-size: cover;

  .wlink {
    color: $white;
    font-weight: 700;
    text-decoration: underline;
  }

  .l-socialpromo-colour {
    background: rgba(151,172,36,0.92);
  }

  .social-media-icons {
    margin-top: 20px;
    display: inline-block;
    float: none;
    @include clearfix;
  }

  .sm-center-wrap {
    text-align: center;
  }

  &__text {
    font-family: $roboto-con;
    color: white;
    font-size: $large;
    text-align: center;
    margin-bottom: 50px;
  }

  &__text-lower {
    font-family: $roboto-con;
    color: white;
    font-size: $large; //$slight
    text-align: center;
  }

  .ptitle {
    text-align: center;
    margin-bottom: 30px;
  }

  .cta {
    margin-top: 20px;
    color: $white;
    font-weight: 700;
    padding: 20px 22px;
    background-image: none;
    background: #9BB528;
    box-shadow: 0 4px 14px rgba(0,0,0,0.15);
    
    &:hover {
      background: #9dbb1a;
    }

  }

  &-wrapper {
    @include container($socialpromo-container);
    padding: 90px 0;
    text-align: center;
  }
}

.l-callus {
  background: $light-green;
  @include clearfix;

  &__text {
    font-weight: 700;
    color: $callus-black;
    font-size: $key-points;
    display: inline-block;
    vertical-align: middle;
    margin-right: 40px;
  }

  &-wrapper {
    @include container($callus-container);
    padding: 40px 0;
    position: relative;

    .ajax-progress-throbber {
      position: absolute;
      top: 62px;
      right: -10px;
    }

  }
}

#pgwModal {

  input[type="text"] {
    width: 100%;
    padding: 7px 0;
    font-family: $roboto;
    font-size: $normal;
    overflow: visible;
    border: none;
    border-bottom: 1px solid $green;
  }

  input[type="submit"] {
    font-family: $roboto;
    font-size: $normal;
    color: white;
    background: $green;
    border: none;
    padding: 8px 18px;
    display: block;
    margin: 0 auto;

    &:hover, &:focus {
      cursor: pointer;
      background: $med-green;
    }

  }

  .pm-body {
    padding: 20px;
  }

  .pm-title {
    background: $green;
    padding: 10px 0 6px;
    text-align: left;
    color: $white;
    padding-left: 20px;
    font-size: $slight;
  }

  .pm-content {
    padding: 15px 0 0;
    .form-actions {
      margin: 30px 0 0;
    }

    .form-item {
      margin-bottom: 20px;
    }
  }

  .pm-close {
    top: 25px;
    right: 32px;
    z-index: 10;
    
    .pm-icon {
      background-image: none;
      width: auto;
      height: auto;
      &:before {
        content: "x";
        font-size: $what-for-you;
        font-weight: 700;
        color: $white;
      }
    }
  }

}
