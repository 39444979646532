.receipt-points-page, .research-efficiency {

.where-advert {
    padding: 0;
    @include container($gf-container);
    position: relative;
  }

  .where-advert-wrapper {
    background: $med-green;
    color: $white;
    @include clearfix;
    position: relative;
    height: $height-stopper-with-menu;
    //min-height: 383px;
    .wa-left {
/*      max-width: 750px;
      padding: 100px 0 84px;*/
      @include left_green;

      .ptitle--coloured-huge {
        @include title_green;
      }

      p {
      //  line-height: 1.4;
      }

    }

    .wa-right-icon {
/*      padding: 95px 0 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      width: 29%;*/
      @include right_green;
    }

    .ptitle {
    //  margin-bottom: 40px;
    }
  }

  .wa-right-icon {
/*    padding: 40px 0;
    position: absolute;
    right: -10%;
    top: 0;
    z-index: 10;
    width: 53%;*/
    @include left_green;
  }

  .basket-block > .panels-outer-wrapper {
    @include container($banner-container);
  }

  .way-adv-wrapper {
    padding: 0;

    .way-adv-body-txt {
      a {
        color: $black;
        font-weight: 700;
        text-decoration: underline;
      }
    }

  }

  .l-socialpromo-wrapper {

    .l-socialpromo__text {
      @include container($socialpromo-container);
      //margin-bottom: 0;
    }
  }

  .l-page 
    .key-points-list__title {
      font-size: $slight;
  }

}

.logos-block {
  .node--page {
    &.node--logos {
      @include container($discount-coupons-container);
      padding: 70px 0 50px;

      .field--name-field-images {
        text-align: center;

        .field__item {
         // float: left;
          display: inline-block;
          margin-right: 40px;

          width: 250px;
          height: 150px;
          line-height: 150px;
          text-align: center;
          margin-bottom: 40px;
          img {
            vertical-align: middle;
         //   position: relative;
          //  top: 50%;
           // transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.receipt-points-block-1 {
  background: $basket;
}

.receipt-points-block-2 {
  .banner-right {
    @include container($priv-banner);
    .center-wrapper {
      padding: 90px 20px 45px 20px;
      max-width: 41%;
      margin-right: 59%;
      margin-left: 0;
    }
  }

}