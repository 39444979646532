// Below Banner Component

.front .l-below-banner {
  background: $med-green;

  &-wrapper {
    @include container($what-for-you-container);//5.2 ($small-container);
    text-align: center;
    padding: 90px 0;
  }

  &__text {
    margin-top: 40px;
    color: white;
    font-size: $slight;
  }
}