.rolls-central-view {
  //@include container($large-container);
}

.view-slideshow {
  .view-content {

    .views-row {
      display: none;

      &.views-row-1 {
        display: block;
      }
    }

    &.slick-initialized {
      .slick-slide {
        display: block;
      }
    }
  }
}

.node--slideshow {

  &.node-teaser {
    margin-bottom: 30px;
    .node__content {
      @include clearfix;
      width: 100%;
      background: $med-green;
     // min-height: $height-stopper-with-menu;
      height: $height-stopper-with-menu;
      position: relative;
    }

    .group-wrapper {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      .group-inner {
        @include container($large-container);
        margin: 0 auto;
      }

      .group-left-side {
        max-width: 50%;
        padding: 50px;
        margin-right: 55%;
      }

    }

    .field--name-body {
      font-size: $key-points;
      line-height: 1.2;
      margin-bottom: 40px;
    }

    .field--name-title-field {
      margin-bottom: 20px;
    }

    .field--name-field-initial-price {
      font-size: $bigger;
      color: $white;
      text-decoration: line-through;
      line-height: 1.1;
      margin-bottom: 10px;
      .from-price {
        display: inline-block;
        text-decoration: none;
        margin-right: 10px;
      }
    }

    .field--name-field-final-price {
      font-size: $huge;
      font-weight: 700;
      color: $white;
      line-height: 1.1;
    }

    .field--name-field-slideshow-image {
      width: 50%;
    //  float: right;
    }

    .field--name-field-link {
      margin-top: 30px;
      font-size: $slight;
      a {
        color: $white;

        &:after {
          content: "";
          @include sprite($sprite-arrow-right);
          display: inline-block;
          vertical-align: middle;
          margin-left: 30px;
        }

      }
    }

  }
}