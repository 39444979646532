
//desktop//

.slicknav_menu {
  display: none;
}

.page-checkout {
  .ajax-progress.ajax-progress-throbber {
    display: none;
  }
}

#block-gtweaks-mobile-menu {
  display: none;
}

//end of desktop

@include breakpoint ($adv-new-container) {

  .l-region--navigation ul.menu > li {
      
      margin-right: 0.4%; //edit
      a {
      //padding: 12px 11px 12px 11px; //6.2
      }
    }
  

  .adv-with {

    .pane-block.pane-block-19 {

      .banner .edit-img {
        background-image: none !important;
        position: static;
        min-height: auto;
        img {
          display: block;
          margin: 0 auto;
        }
      }

      .banner-left-image {
        position: static;
        max-width: 695px;
        min-height: auto;
        margin: 0 auto;
      }

      &.priv-block {
        margin: 90px 0 30px;
        .banner__left {
          @include container($what-for-you-container);
          .center-wrapper {
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
            padding: 30px 20px 0;
          }
        }
        .banner {
          min-height: auto;
        }
      }

    }
  }

}

@include breakpoint (1340px) {
  .advertisers-categories-block {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@include breakpoint ($discount-coupons-container) {

  // add some spacing to all outer divs
  .front, .not-front {
    .l-key-points-wrapper,
    .l-what-for-you-wrapper,
    .l-socialpromo-wrapper,
    .how-op-block,
    .where-adv-block-1,
    .ad-ways-block-inner,
    &.page-search-results .l-content h1,
    &.page-search-results .l-content h2,
    .search-results,
    &.blog-section .l-main,
    &.node-type-blog .l-main,
    &.logged-in.page-user .l-content,
    &.contact-us-pane-page .pane-node,
    &.page-node-done .l-main,
    #block-block-7 .l-callus__text,
    .messages-wrapper,
    .how-it-works-block .how-it-works-wrapper,
    &.about-us-page .group-wrapper,
    &.about-us-page .panel-pane.pane-block .about-us-wrapper,
    ul.tabs--primary,
    &.page-sitemap .l-main,
    &.node-type-product-display .panel-2col-stacked {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  #block-block-7  {
    .l-callus-wrapper {
      margin: 0 auto;
    }
    .l-callus__text {
      margin-right: 0;
    }
  }

  .advertisers-categories-block {
    .views-row {
      .field--name-name-field {
        width: 145px;
      }
    }
  }

  .pane-block-12 {
    .ptitle--coloured-huge,
    .small-utitle {
      padding: 0 10px;
    }
  }

  .slideshow-slick {
    .arrow-wrapper--right {
      right: 10px;
    }
    .arrow-wrapper--left {
      left: 10px;
    }
  }

  .node--news {
    &.node--full {
      .field--name-field-image, .field--name-field-images {
        right: 10px;
      }
    }
  }

  #block-gtweaks-custom-main-menu {
    display: none;
  }

  /*#block-block-1 {
    display: none;
  }*/

  #block-gtweaks-mobile-menu, #block-search-form, #block-block-2, #block-system-user-menu {
    display: none;
  }

  //modification to display phone number in header-responsive 28.2
  #block-block-1 {
    margin-right: 5px;
  }
  .phone-top {
    &__icon {
      display: none;
    }
    &__text {
      font-size: 1rem;
    }
  }

  .l-header{
    position: relative;
    padding-bottom: 25px;
    height: 114px;

    .slicknav_menu{
      display: block;
      position: absolute;
      top: 112px;
      right: 0px;
      width: 100%;
      z-index: 9999;
      .slicknav_menutxt {
        right: 35px;
        top: -55px;
      }

      .sf-sub-indicator {
        display: none;
      }
      ul{

        margin: 0px;
        li{
          margin-bottom: 0px;
          clear: left;
          a {
            a {
              border-bottom: none;
              padding-top: 0;
              padding-bottom: 0;
            }
          }
          ul li a{
            padding-left: 30px;
          }
        }
      }
      #block-search-form {
        display: inline-block;
        padding: 10px 7px;
      }
      #block-block-2 {
        display: inline-block;
        ul.social-media-icons {
          display: inline-block;
          padding: 13px 7px 3px;
          li {
            clear: none;
          }
          a {
            display: inline-block;
          }
        }
      }
    }

  }

  #block-system-user-menu {
    display: none;
  }

  #block-system-user-menu {
    &.user-menu-mobile {
      display: block;
    }
  }

  .slicknav_menu div.slicknav_nav {

    #block-system-user-menu {
      position: static;
      display: block;
      padding: 5px 7px 5px;
      margin-right: 0;
      border-bottom: 1px solid $green;
      ul {
        display: block;
        float: none;
        color: $white;
      }
      li {
        display: inline-block;
        clear: none;
        a, span.nolink {
          border-bottom: none;
          display: inline-block;
          color: $white;
        }
      }
      .quick-links-top__icon {
        margin-top: 4px;
      }
    }
  }

  .l-header__right {
    position: absolute;
    right: 105px;
  }

  .l-header__left {
    margin-top: 5px;
    width: 170px;
  }

  .l-header-wrapper--sticky {

    .slicknav_menu{
      top: 70px;
      z-index: 20;
      .slicknav_menutxt {
        right: 35px;
        top: -56px;
      }

    }

    .l-header__left {
      margin-top: 0;
    }

    #block-commerce-overrides-shopping-cart-custom {
      right: 65px;
      min-width: 165px;
    }

  }

  #block-commerce-overrides-shopping-cart-custom {
    margin-right: 0;
    min-width: auto;
    margin-left: 10px;
  }
  .view-shopping-cart-block-custom {
    margin-left: 0;
  }

  .sticky-page {
    &.double-sticky {
      .l-header-wrapper--sticky {
        .l-header {
          height: 70px;
        }
      }
    }
  }

  #block-locale-language-content {
    position: absolute;
    right: 35px;
    top: 18px;
    width: 53px;
    margin-top: 0;
  }

  .site-logo {
    img {
      width: 130px;
    }
  }

  #block-system-user-menu {
    position: absolute;
    right: 100px;
    bottom: 19px;
  }


  .page-cart,
  .page-checkout,
  .logged-in.page-user-orders {

    .l-content {
      padding-right: 10px;
      padding-left: 10px;
    }

  }

  .l-what-for-you {
    .small-box {
      float: none;
      margin-right: 13px;
      width: 30%;
      display: inline-block;

      &:last-child {
        margin-right: 0;
        float: none;
        width: 30%;
      }
    }
  }

} //end of breakpoint

@include breakpoint ($eshop-table-container) {

  .page-checkout {
    .commerce_shipping,
    .commerce_payment {
      min-height: 386px;
    }
  }

  .logged-in.page-user-orders {
    .views-field-commerce-order-total {
      width: 10%;
    }
  }

}

@include breakpoint($midpoint) {

  //blog responsive siderbar
  #block-block-52,
  .pane-block-52 {
    float: left;
  }
  .block--views-blog-taxonomy-block-1 {
    float: left;
    padding-left: 20px;
  }

  .label-mobile {
    display: inline-block;
  }

  .checkout-buttons {
    .fieldset-wrapper {
      text-align: center;
    }
  }

  #commerce-checkout-form-login {
    .checkout-buttons .fieldset-wrapper {
      text-align: left;
    }
  }

  #views-form-commerce-cart-form-default,
  #commerce-checkout-form-checkout,
  #commerce-checkout-form-review,
  .view-commerce-line-item-table,
  .view-commerce-user-orders {
    padding: 0;
    border: none;

    thead {
      display: none;
    }

    tr {
      display: block;
      margin-bottom: 30px;
      &.even,
      &.odd {
        td {
          display: block;
          width: 100%;
          &.views-field-edit-quantity {
            width: 100%;
          }
          &.views-field {
            .field,
            .form-item {
              display: inline-block;
            }
          }
          .field--name-field-commerce-product-quantity {
            display: inline-block;
          }
        }
      }
      &.views-row-first {
        border-top: 1px solid $cu;
      }
      &.views-row-last {
        margin-bottom: 0;
      }
    }

    .commerce-price-formatted-components {
      tr {
        display: table;
        width: 100%;
        margin-bottom: 0;
        td {
          display: table-cell;
          width: auto;
        }
      }

    }

    .line-item-summary {
      width: 100%;
      text-align: center;
      margin: 0 auto;
    }

    .commerce-line-item-actions {
      text-align: center;
    }

  }

  #commerce-checkout-form-review {
    .commerce-order-handler-area-order-total {
      .commerce-price-formatted-components {
        width: 100%;
        background: $deep-silver;
        tbody {
          width: 38%;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }

  .l-main {
    .commerce-checkout-progress.inline {
      display: none;
       // li {
       //    margin-bottom: 15px;
       //    margin-right: 20px;
       // }
    }
  }

  .logged-in.page-user-orders {
    .views-field-commerce-order-total {
      width: 100%;
    }
  }

  .l-what-for-you-wrapper {
    padding-top: 10px;
  }

  .l-what-for-you {
    .small-box {
      float: none;
      margin-right: 25px;
      width: 30%;
      display: inline-block;

      &:nth-child(3) {
        margin-right: 0;
      }

      &:last-child {
        display: block;
        margin: 0 auto;
      }
    }
  }

  .way-adv-wrapper {
    .l-what-for-you {
      .small-box {

        &:nth-child(2) {
          margin-right: 0;
        }

      }
    }
  }

  .node--news {
    &.node--full {
      .field--name-field-image, .field--name-field-images {
        top: 50px;
      }
      .group-banner {
        min-height: 300px;
        .field--name-title-field {
          max-width: 470px;
        }
      }
    }
  }

  .advertisers-categories-block {
    padding: 50px 0 40px;

    .pane-title {
      margin-bottom: 60px;
    }

    .view-content {
      @include container($boxes-container);
      padding: 0 10px;
    }

    .views-row {
      @include span(6);
      max-width: 286px;

      .field--name-name-field {
        width: auto;
      }

      &:nth-child(2n) {
        @include span(6 last);
      }

      &:nth-child(2n+1) {
        @include span(6 break);
      }
    }
  }

  .adv-with {

    .pane-block.pane-block-19 {

      &.priv-block {
        margin: 40px 0 30px;
        .banner__left {
          .center-wrapper {
            //padding: 30px 20px 0;
          }
        }
      }

    }
  }


} //end of midpoint breakpoint

@include breakpoint($wadv) {

  .page-checkout {
    .commerce_shipping,
    .commerce_payment {
      min-height: 424px;
    }
  }

  .user-login {
    #user_login_form {
      width: 100%;
      float: none;
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }
    .create-account {
      float: none;
      height: auto;
      margin: 0 auto;
      width: auto;
      .content-wrapper {
        transform: none;
        position: static;
        a {
          display: inline-block;
        }
      }
    }
  }

}

@include breakpoint(753px) {
  .block--views-blog-taxonomy-block-1 {
    float: left;
    padding-left: 0;
  }
}

@include breakpoint($tablet) {

  .page-checkout {

    .commerce_payment,
    .commerce_shipping,
    .accept_terms,
    .commerce_fieldgroup_pane__group_comments {
      @include span(12);
      min-height: auto;
    }

  }

  #commerce-checkout-form-review {
    .commerce-order-handler-area-order-total {
      .commerce-price-formatted-components {
        tbody {
          width: 47%;
        }
      }
    }
  }

  .page-checkout {

    #commerce-checkout-form-checkout {
      .accept_terms {
        top: 0;
      }
    }

  }

  #edit-account-login {
    input {
      width: 100%;
    }
  }

  .l-content {
    ul.tabs--primary {
      border-bottom: none;
      li {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }

  .l-header__left {
    width: 130px;
  }

  .l-header__right {
    width: 68%;
  }

  .l-what-for-you {
    .small-box {
      display: block;
      &.small-box--absolute {
        margin: 0 auto;
        margin-bottom: 40px;
      }


    }
  }

  .way-adv-wrapper {
    .l-what-for-you {
      .small-box {

        &:nth-child(2) {
          margin: 0 auto;
          margin-bottom: 40px;
        }

      }
    }
  }

  .node--news {
    &.node--full {
      .field--name-field-image, .field--name-field-images {
        top: 15px;
      }
      .group-banner {
        min-height: 260px;
        .field--name-title-field {
          max-width: 420px;
          padding-top: 50px;
          padding-bottom: 50px;
        }
      }
    }
  }

  .l-what-for-you {
    .l-what-for-you-wrapper {
      #block-block-5 {
        .ptitle {
          margin-top: 0;
        }
      }
    }
  }

  #user_register_form {
    .form-item {
      width: 70%;
    }
  }

  #commerce-checkout-form-login {
    .login_pane {
      @include span(12);
    }
    .nonregistered_pane {
      @include span(12);
      min-height: 0;
    }
  }

  .adv-with  {
    .pane-block.pane-block-19 {
      &.priv-block {
        margin-bottom: 30px;
        .banner__left .center-wrapper {
          padding-top: 40px;
        }
      }
    }
  }

  .pane-block-47 h2 {
    font-size: $large;
  }




} // end tablet breakpoint


@include breakpoint($before_mobile) {

  #commerce-checkout-form-review {
    .commerce-order-handler-area-order-total {
      .commerce-price-formatted-components {
        tbody {
          width: 100%;
        }
      }
    }
  }

  .l-header__left {
    width: 110px;
    margin-left: -5px; //28.2
    margin-top: 10px;
  }

  .l-header__right {
    width: 72%;
  }

  /*#block-commerce-overrides-shopping-cart-custom {
    min-width: 152px;
  }*/ //28.2

  .node--news {
    &.node--full {

      .group-wrapper-inner {
        padding: 20px 10px;
      }

      .group-banner-left {
        max-height: none;
      }

      .field--name-field-image {
        padding: 20px 10px 20px 10px;
        width: 100%;
        position: static;
        max-width: none;
      }

      .group-banner {
        min-height: auto;
        .field--name-title-field {
          float: none;
          padding-top: 40px;
          padding-bottom: 20px;
          padding-right: 10px;
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  .advertisers-categories-block {

    .views-row {
      float: none;
      margin: 30px auto;

      &:nth-child(2n) {
        float: none;
        margin: 30px auto;
        width: 100%;
      }

      &:nth-child(2n+1) {
        float: none;
        width: 100%;
        margin: 20px auto;
        width: 100%;
      }
    }
  }

  //displays cart on the bottom of header
  #block-commerce-overrides-shopping-cart-custom {
    position: absolute;
    right: 4px;
    top: 48px;
  }

} //end of before_mobile breakpoint


@include breakpoint($mobile) {

  .phone-top {
    &__text {
      margin-right: 60px; //2.3
    }
  }

  #views-form-commerce-cart-form-default,
  #commerce-checkout-form-checkout,
  #commerce-checkout-form-review,
  .view-commerce-line-item-table,
  .view-commerce-user-orders {

    .form-actions {
      input {
        display: block;
        margin-left: auto;
        margin-right: 0;
      }
      input[type="submit"] {
        margin: 0 auto;
        margin-bottom: 15px;
      }
    }

  }

  .l-main {
    .commerce-checkout-progress.inline {
       li {
        margin-right: 0;
        width: 100%;
       }
    }
  }

  .page-checkout {

    .customer_profile_billing {
      .group-invoice {
        .form-wrapper:nth-child(n) {
          @include span(12);
        }
      }
    }

    .field-name-commerce-customer-address {
      div {
        &.name-block,
        &.street-block,
        &.locality-block .form-item.form-type-textfield {
          &:nth-child(n) {
            @include span(12);
            margin-bottom: gutter();
          }
        }
      }
    }

  }

  #commerce-checkout-form-review {
    .commerce-order-handler-area-order-total {
      .commerce-price-formatted-components {
        width: 100%;
        tr {
          td:nth-child(2n) {
            width: 34%;
          }
        }
      }
    }
  }

  

  .l-header {
    padding-left: 20px;
    padding-right: 20px;
    .l-header__right {
      width: 60%; //55% 28.2
      right: 20px;
    }
    .l-header__left {
      margin-top: 15px;

    }
    .slicknav_menu {
      .slicknav_menutxt {
        right: 20px;
        top: -55px;
      }
    }
  }

  .site-logo img {
    width: 80px;
    margin-left: 2px; //2.3
  }

  #block-locale-language-content {
    right: 20px;
  }

  .view-shopping-cart-block-custom {
    .total-wrapper-custom {
      font-size: $small;
    }

    .total-items-wrapper {
      margin-top: 2px;
    }
  }


  #block-commerce-overrides-shopping-cart-custom {
    position: absolute;
    right: 65px; //2.3
    top: 48px;
  }

  .l-header-wrapper--sticky {

    .l-header {
      padding-left: 20px;
      padding-right: 20px;
      .l-header__left {
        margin-top: 4px;
      }
    }

    #block-commerce-overrides-shopping-cart-custom {
      right: 55px;
      top: 30px;
    }

  }

  .user-login {
    .create-account {
      .content-wrapper {
        a {
          min-width: auto;
          font-size: $slight;
          padding: 8px 10px;
        }
      }
    }
    #user_login_form {
      input[type="text"], input[type="password"] {
        width: 100%;
      }
    }
  }


  #user_register_form {
    .form-type-password-confirm {
      width: 100%;
    }
    .form-item {
      width: 100%;
    }
  }

  .node--slideshow {
    &.node-teaser {
      .group-wrapper .group-left-side {
        min-height: 330px;
      }

    }

  }

  #commerce-checkout-form-login {
    .nonregistered_pane {
      a.register {
        width: auto;
        display: inline-block;
        height: auto;
        margin-top: 20px;
        margin-left: 0;
        margin-right: 0;
        padding: 6px 12px;
      }
      #non-registered-user-btn {
        margin-right: 30px;
      }
    }
  }

  .order-completed-succesfully {
    .oc-msg-txt {
      display: block;
      padding-left: 35px;
    }
  }




} //end of before_mobile breakpoint


@include breakpoint(380px) {


  #block-commerce-overrides-shopping-cart-custom {
    margin-right: 0;
    min-width: 155px;
    margin-left: 10px;
  }


  .phone-top {
    &__text {
      margin-right: 45px; //3.2
    }
  }

  .messages.only-cart:before {
    margin-bottom: 30px;
  }

  .l-header {
    padding-left: 10px;
    padding-right: 10px;
    .slicknav_menu {
      .slicknav_menutxt {
        right: 10px;
      }

    }
  }

  .l-header-wrapper--sticky {

    .l-header {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  #block-locale-language-content {
    right: 10px;
  }

  #block-commerce-overrides-shopping-cart-custom {
    right: 45px;
  }

  .captcha {
    margin-left: -10px;
  }


}

@include breakpoint(350px) {

  .captcha {
    margin-left: -30px;
  }

}