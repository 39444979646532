.ad-ways-page {

  .l-socialpromo-wrapper {
    padding: 95px 0;
    @include container($green-second);

    .l-socialpromo__text {
      @include container($socialpromo-container);
      margin-bottom: 0;
    }
  }

  .ad-ways-block-inner {
    .ad-ways-block-left {
      .ad-ways-block-image {
        transition: all 0.3s;
        &:hover, &:focus {
          box-shadow: 3px 6px 12px -2px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }


}

.pane-block-34 {
  clear: both;
}

.ad-ways-wrapper {
  background: $med-green;
  position: relative;
  height: $height-stopper-with-menu;

  .ad-ways-inner {
    @include container($gf-container);
    @include clearfix;
  }

  .ad-ways-title {
   // font-size: $mini-title;
  //  line-height: 1.2;
  //  margin-bottom: 30px;
    @include title_green;
    color: $white;
  }

  .ad-ways-text {
    color: $white;
    font-size: $slight;
  }

  .ad-ways-data {
   // max-width: 550px;
    float: left;
   // padding: 77px 0;
    @include left_green;
  }

  .ad-ways-image {
  //  width: 48%;
    float: right;
 //   padding-top: 60px;
    @include right_green;
  }

}


.ad-ways-block-inner {
  @include container($basket-container);
  padding: 80px 0;
  display: table;

  .ad-ways-block-left {
    @include span(6);
    display: table-cell;
    vertical-align: middle;
    float: none;
    img {
      border: 1px solid $footer-text;
      display: block;
    }
  }

  .ad-ways-block-right {
    @include span(6 last);
    display: table-cell;
    vertical-align: middle;
    float: none;
    padding: 0 20px;
  }

  .ad-ways-block-title {
    font-size: $mini-title;
    line-height: 1.3;
    margin-bottom: 20px;
  }

  .ad-ways-block-text {
    color: $black; //5.2 $med-grey;
    font-size: $slight;
    line-height: 1.3;
  }

  .ad-ways-block-subtitle {
    font-size: $key-points;
    margin: 30px 0 20px;;
    color: $black; //5.2 $med-black;
  }

  .key-points-list {
    li {
      padding-left: 50px;
      &:before {
        content: "";
        display: block;
        @include sprite($sprite-key-points);
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  .rmore {
    text-align: center;
  }

}

.ad_ways_block_2 {
  background: $basket;
}

.ad-ways-page, .cost-subpages {

  @include with-layout(12 1/2) {

    .ad-ways-block-inner {
      .ad-ways-block-left {
        @include span(6);
        // .ad-ways-block-image {
        //   transition: all 0.3s;
        //   &:hover, &:focus {
        //     box-shadow: 3px 6px 12px -2px rgba(0, 0, 0, 0.3);
        //   }
        // }
      }
    }

    .ad-ways-block-right {
      @include span(6 last);
    }

  }
}

.ad-ways-page {
  .ad-ways-wrapper {

    .ad-ways-data {
     // max-width: 550px;
      float: left;
     // padding: 77px 0;
      //@include left_green;
      width: 55%;
    }

    .ad-ways-image {
      max-width: 43%;
      float: right;
   //   padding-top: 60px;
      //@include right_green;
    }

  }
}