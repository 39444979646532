        // Override default variables of susy

$susy: (
        columns: 12,
        gutters: 1/3,
        global-box-sizing: border-box,
        debug: (
          image: hide,
          color: rgba(#66f, .25),
          output: overlay,
          toggle: top right,
        ),
        // breakpoint true looks for a breakpoint mixin provided by a third party
        // clearfix uses a custom mixin if it is named clearfix
        use-custom: (
          clearfix: true,
          breakpoint: true,
        )
  // math: fluid,
  // output: float,
  // gutter-position: inside,
);

// Switch breakpoints to desktop first
@include breakpoint-set('default feature', 'max-width');

// Misc vars
$ql-qutter: 40px;

/* --------- Height Containers ----------- */
$height-stopper: 493px;
$height-stopper-with-menu: 442px;

// Photo & Left Text
$promo-photos: 1460px;
$plt-padding: 40px 40px 20px 20px;
$plt-max-width: 47%;
$plt-margin-right: 53%;

// Green Face
$gf-container: 1320px;

/*5.2 Minor Fixes - Added Mixin for advertiser Benefits page, that uses 
left_green_new-right_green_new with opposite floats, instead of left_green() 
*/
@mixin left_green_new(){
  @include left_green();
  float: right;
}

@mixin right_green_new(){
  @include left_green();
  float: right;
}
/*----end-----*/

@mixin left_green() {
  max-width: 59%;
  float: left;
  padding: 40px 20px 20px 20px;
  margin-right: 2%;
}

@mixin right_green() {
  padding: 40px 20px 0 20px;
  max-width: 39%;
  margin-right: 0;
  float: right;
}

@mixin title_green() {
  font-size: $big;
  line-height: 1.2;
  margin-bottom: 30px;
}

@mixin outbox() {
  img {
    position: relative;
    bottom: -34px;
    z-index: 10;
  }
}


/* -------- End Height Containers ------- */

// Containers
$callus-container: 610px;
$wadv: 860px;
$socialpromo-container: 650px;
$small-container: 720px;
$what-for-you-container: 1020px;
$advertisers-banner: 1430px;
$green-second: 1140px;
$normal-container: 1070px;
$large-container: 1240px;
$adv-new-container: 1390px;
$concerning-coupons: 945px;
$discount-coupons-container: 1270px;
$priv-banner: 1530px;
$blog-container: 1170px;

// ------- check if used
$banner-container-inner: 1460px;
$banner-container: 1170px;
$basket-container: 1320px;

/* ------ Responsive -------- */
$start-point: 1200px;
$midpoint: 1060px;
$tablet: 768px;
$before_mobile: 630px;
$mobile: 500px;

/* ------ Responsive Containers -------- */
$boxes-container: 640px;
$eshop-table-container: 1200px;