.page-taxonomy-term {
  &.section-blog {
    .l-page {
      background: $white;
    }
  }
}

.blog-section {

  .views-row-last {
    .node--blog {
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .l-main {
    @include container($blog-container);
    padding: 30px 0;
  }

  .l-region--sidebar-second {

    .block__title {
      margin-bottom: 20px;
      color: $black;
      line-height: 1.34;
     // border-bottom: 4px solid $black;
      font-size: $key-points;
      padding-left: 10px;
    }

    @include span(4 at 9 isolate);
    //padding: 20px; edit
    //background: $wf-grey; edit
    //margin-bottom: 30px; edit

    .block {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .l-content {
    @include span(8 at 1 isolate);
  }

}

//.view-blog-taxonomy.view-display-id-block_1,
//.view-blog-taxonomy-page.view-id-blog_taxonomy_page {


//}

.view-blog {
  &.view-display-id-block_1 {
    .views-row {
      &:last-child {
        .node--blog.node--vblock {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}


.block--multiblock-1{
  padding: 20px;
  background: $wf-grey;
  margin-bottom: 30px;
  .block__title {
    display: inline-block;
    border-left: 8px solid #74a2ff;
  } 
}
.block--multiblock-2 {
  padding: 20px;
  background: $wf-grey;
  margin-bottom: 0 !important;
  .social-media-icons {
      list-style-type: none;
      float: none;     
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 0;
      }
    }
  .block__content {
      display: inline-block;
      vertical-align: middle;
      padding-left: 7.5%;    
      margin-bottom: 5px;
    }
    .block__title {
      display: inline-block;
      border-left: 8px solid #74a2ff;
    } 
}

//2.3
.node-type-blog {

  // fix panels css

  .panel-col-top {
    @include container($blog-container);
  }

  .panel-panel {
    .inside {
      margin: 0;
    }
  }

  .center-wrapper {
    @include container($blog-container);
    padding: 20px 0;

    .panel-col-first {
      @include span(8 at 1 isolate);
    }

    .panel-col-last {
      @include span(4 at 9 isolate);
    //  padding: gutters();
      //padding: 20px;
      //background: $wf-grey;

      inside {
        margin: 0;
      }

      .panel-pane {
      
        &:last-child {
          margin-bottom: 0;
        }
      }

      .pane-title {
        margin-bottom: 20px;
        color: $black;
        padding: 5px;
        padding-left: 10px;
        line-height: 1.34;
        font-size: $key-points;
      }
    }
  }

    /*Styling blog node's content after 1.3 update in structure*/

    .panel-col-first {
      .field--name-title-field {
        font-size: $title;
        font-weight: 700;
      }  
      .custom-post-date {
        margin: 15px 0;
      }
    }

    
    .panel-col-last {

      .social-media-icons {
        list-style-type: none;
        float: none;     
        margin: 0;
        padding: 0;
        li {
          margin-bottom: 0;
        }
      }

      .pane-block-2,
      .pane-mailchimp-signup-thepromoticket-newsletter {

        background: $wf-grey;
        padding: 20px;
        padding-bottom: 0;
        
        .panels-outer-wrapper {
          display: inline-block;
          vertical-align: middle;
          padding-left: 7.5%;    
          margin-bottom: 5px;
        }
        .pane-title {
          display: inline-block;
          border-left: 8px solid #74a2ff;
        }  
      }

      .pane-mailchimp-signup-thepromoticket-newsletter {
        .panels-outer-wrapper {
          padding-left: 0;
        }
        .pane-title {
          display: block;
          border-left: 8px solid #74a2ff; 
        }
      }
    }

  

}

.node--blog {
  @include clearfix;

  .field--name-field-image {
    position: relative;

    &:after {
      transition: background 0.3s;
      background: none;
      content: "";
      display: block;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }

  .link-wrap-of-blog {
    &:hover, &:focus {
      .field--name-field-image {
       &:after {
         position: absolute;
         content: "";
         display: block;
         top: 0;
         left: 0;
         bottom: 0;
         right: 0;
         background: rgba(151, 172, 36, 0.72);
       }
      }
    }
  }

  //added styling for blog posts view in front page only 28.2
  &.node--blog--blog-post-front {
    .group-blog-image {
      display: inline-block;
      position: relative;
      margin: 0 auto;

      .custom-post-date {
        color: $white;
        padding: 2px 5px;
        background: rgba(151, 172, 36, 0.72);
        position: absolute;
        top: 0;
        font-family: $roboto-con;
        left: 0;
        font-size: $small;
        z-index: 1;
      }
    }
  }

  &.node--teaser {

    .group-blog-image {
      float: left;
      margin-right: 20px;
      position: relative;

      .custom-post-date {
        color: $white;
        padding: 2px 5px;
        background: rgba(151, 172, 36, 0.72);
        position: absolute;
        top: 0;
        font-family: $roboto-con;
        left: 0;
        font-size: $small;
        z-index: 1;
      }
    }

    .group-blog-text {
      overflow: hidden;
    }

    .field--name-title-field {
      h2 {
        line-height: 1.38;
        //font-weight: 700; //edit
        font-size: $key-points;
        margin-bottom: 10px;
        color: $black;

        a {
          color: $black;
        }
      }
    }

    .field--name-field-image {

      img {
        display: block;
      }
    }

    margin-bottom: 30px;
    border-bottom: 1px solid $deep-silver;
    padding-bottom: 30px;
  }

  &.node--vblock {

    .field--name-title-field {
      h2 {
        color: $black;
        line-height: 1.35;
        font-size: $slight;

        a {
          color: $black;
        }
      }
    }

    .field--name-field-image {
      float: left;
      margin-right: 10px;

      img {
        display: block;
      }
    }

    margin-bottom: 15px;
    padding-bottom: 15px;
  }

  &.node--about {
    .group-blog-full-image {
      position: relative;
    }

    .field--name-title-field {

      h1 {
        font-size: $title;
        margin-bottom: 10px;
        line-height: 1.2;
      }
    }

    .group-blog-meta {
      color: white;
      font-family: $roboto-con;
      font-size: $slight;

      a {
        color: $white;
        text-decoration: underline;
      }

      .field--name-field-blog-category,
      .custom-post-date {
        display: inline;
      }

      .field--name-field-blog-category {
        .field__items,
        .field__item {
          display: inline;
          font-weight: 700;
        }
      }

      .custom-post-date {
        &:before {
          content: " x ";
          display: inline;
          font-weight: 400;
          font-size: $small;
          position: relative;
          top: -1px;
        }
      }
    }

    .group-blog-text {
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      padding: 20px;
      color: $white;
      background: rgba(151, 172, 36, 0.9);
    }
  }

  &.node--full {
    .field--name-body {
      font-size: $slight;
    }
  }
}

#block-gtweaks-page-title-block {
  background: $deep-silver;
  padding-bottom: 10px;
  margin-bottom: 25px;

  .blog_main_image {
    background: url("../images/blog_top_page.png");
    background-position: center;
    height: 350px;
  }

  .block__content {
    
    .header_container {
      @include container($blog-container);
      margin: 0 auto;
      padding-top: 10px;
    }
    h1 {      
      color: $dark-grey2;
      padding-left: 5px;//2.3
    }
  }
}

// add colours to each sidebar block
.block--views-blog-block-1 {
  .block__title,
  .pane-title {
    border-left: 8px solid $mustard;
  }
}

#block-block-52,
.pane-block-52 {
  
  //margin: 20px -20px -20px -20px !important;
  padding-top: 20px;  
  //padding-bottom: 30px;

  background: $white;


  .container {
    background: url('../images/promo_banner.jpg');
    max-width: 374px;
    height: 284px;    
  }
  .title {
    color: $white;
    padding: 20px;
    font-size: 1.2rem;
    text-align: center;
  }
  .btn{
    color: $white;
    margin-top: 25px;
    text-align: center;
    a {
      color: $white;
      padding: 10px;
      border: 1px solid $white;
      border-radius: 10px;
      &:hover {
       box-shadow: 0 0 8px rgba(255,255,255,0.5);  
      }
    }    
  }
}
.block--views-blog-taxonomy-block-1 {

  background: $white;
  //margin: 20px -20px -20px -20px !important;
  padding-top: 20px;

  .block__content {
    //padding-left: 10px;
  }
  .view-blog-taxonomy {
    padding-left: 10px;
  }
  .block__title,
  .pane-title {
    //border-left: 8px solid $light-blue;
    font-weight: 700;
  }

  .views-row {
    a {
      color: $black;
      font-size: 1.2rem;
      line-height: 1.9;
      &:hover, &:focus {
        color: $med-green;
      }
    }
    
  }

  .field--name-name-field {
    margin-bottom: 3px;
  }
}

