// Footer Component

.l-footer {
  background: $callus-black;
  color: $footer-text;
  padding: 0 20px;

  ul.menu {
    margin-left: 0;
    padding-left: 0;

    li {
      position: relative;
      margin-bottom: 14px;
      font-size: $normal;
      list-style: none;
      padding-left: 24px;
      line-height: 1.4;

      &:before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $light-green;
        position: absolute;
        left: 0;
        // correction for line-height
        top: 8px;
      }

      @include links($footer-text,$light-green, none);
    }

  }

  &-wrapper {
    @include container($banner-container);
    padding: 90px 0;
  }

  @include with-layout(14 4) {
  //  #block-gtweaks-promotickets-newsletter {
    #block-block-9 {
      @include span(5);
    }

    .mailchimp-signup-subscribe-form {
      max-width: 320px;
    }

    #block-menu-menu-footer-select-menu,
    #block-menu-menu-footer-menu-middle {
      @include span(3);
    }

    #block-menu-menu-footer-menu-right {
      @include span(3 last);
    }

    // fix this and add classes programmatically if possible later
    #block-menu-menu-footer-select-menu,
    #block-menu-menu-footer-menu-middle,
    #block-menu-menu-footer-menu-right {

      .block__title {
        color: $white;
        margin-bottom: 40px;
        font-size: $key-points;
      }
    }
  }
}

.about-block {
  &__text {
    margin-bottom: 40px;
  }
}