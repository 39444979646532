@mixin links($color,$hover,$decoration) {

  a {
    &:link, &:visited, &:active {
      text-decoration: none;
      color: $color;
    }

    &:hover, &:focus {
      color: $hover;
      text-decoration: $decoration;
    }
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin backpos {
  background-position: center center;
}

strong {
  font-weight: 700;
}

a, textarea, input, button {
  &:focus {
    outline: none;
  }
}