// Header Component

.l-header-wrapper {
  box-shadow: 0 8px 8px -8px rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 400;
  background: $white;
  //transition: all 0.4s ease;
  //height: 172px;

  .l-header {
  //  transition: all 0.4s ease;
  }

  &--sticky {
    width: 100%;
    position: fixed;
    z-index: 100;
    background: $white;
    top: 0;
    left: 0;

    // change height on sticky
   // height: 68px;

    .l-header {
      //height: 68px;
      height: 70px;
      padding: 6px 35px;
    }

    .l-header-left-right-wrapper {
      float: left;
      //min-width: 120px;
      //min-width: 107px;
      min-width: 95px;
      width: 5%;
     // height: 68px;
      margin-right: 2%;
    }

      .l-header__left {
        width: 100%;
      }

    .block--commerce-overrides-shopping-cart-custom {
      position: absolute;
      top: 50%;
      margin-right: 0;
      display: block;
      right: 0;
      transform: translateY(-50%);
    }

      .l-header__right {
        top: 5px;
        right: 35px;
        position: absolute;
        height: 62px;
        float: none;
        width: 10%;
        z-index: 5;

        #block-search-form,
        #block-block-1,
        #block-block-2,
        #block-system-user-menu {
          display: none;
        }
      }

    #block-locale-language-content {
      display: none;
    }

  .l-header__bottom {
        width: 76%;
        margin-right: 2%;
        float: left;
        clear: none;
      }
  }
}

.logged-in {
  #block-gtweaks-custom-main-menu {
    &.contextual-links-region {
     position: static;
    }

    .contextual-links-wrapper {
      display: none !important;
    }
  }
}

.double-menu-height {

  .block--gtweaks-custom-main-menu {
    padding-bottom: 51px;
  }

  ul.dropdown-mn.menu {
    ul.menu {
      height: 51px; // so we make absolutetly sure this is 51px in height
    }
  }
}

.sticky-page {

  .l-region--navigation {
    .block--gtweaks-custom-main-menu {
      float: none;
      width: 100%;
      margin-right: 0;
    }
  }

  .l-header {
    &__left {
      position: relative;
      z-index: 5;
    }
  }

  ul.dropdown-mn.menu {
    position: absolute;
    left: 0;
    padding: 0 35px 0 178px;
    margin: 0;

    ul.menu {
      padding: 0 35px 0 178px;
      height: 51px; // so we make absolutetly sure this is 51px in height
    }

    li:first-child {
    //  padding-left: 7%;
    }
  }

  .l-region--navigation ul.menu > li {
    
    a {
      font-size: $normal;
      //padding: 14px 15px 24px 16px;
      padding: 14px 0 24px 0;
    }

    ul.menu a {
      //padding: 13px 15px 14px 16px;
      padding: 13px 0 14px 0; //6.2
    }
  }

 // .l-page {
    padding-top: 68px;
 // }

  &.double-sticky {
    padding-top: 120px;

    .l-header-wrapper {

      &--sticky {
        // change height on sticky
      //  height: 120px;

        .l-header {
       //   height: 120px;
          //    padding: 6px 35px;
        }

        .l-header-left-right-wrapper {
        //  height: 120px;
        }

        .l-header__right {
       //   height: 58px;
        }
      }
    }
  }
}

.l-header {
  @include container(full);
 // margin: 16px 35px 0 35px;
  padding: 16px 35px 0 35px;

  &__right {
    float: right;
    width: 83%;
    text-align: right;
  }

  &__left {
    float: left;
    width: 17%;
  }

  &__bottom {
  }
}

.l-header-left-right-wrapper {
  @include clearfix;
}

.l-region {

  &--navigation {
    @include clearfix;
    margin-top: 5px;

    .block--gtweaks-custom-main-menu {

   //   @include span(11);
      @include span(full);
      float: none;
      @include clearfix;
    }

    ul.menu {
      width: 100%;
      box-sizing: content-box;
      margin-left: -35px;
      margin-right: -35px;
      padding-left: 35px;
      padding-right: 35px;

      // double the negative margin to align it
      ul.menu {
        box-sizing: border-box;
        margin-left: 0;
        margin-right: 0;
        padding-left: 35px;
        padding-right: 35px;
      }

     > li {
     //    float: left;
        list-style: none;
        margin-left: 1%; //6.2
        margin-right: 1%; //16px;

/*       &.expanded {
         padding-bottom: 51px;
       }*/

        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          //margin-left: -16px;
          margin-left: 0;
        }

       ul.menu {
/*         height: 51px;
         position: absolute;
         left: 0;
         bottom: 0;
         padding: 0 35px;
         background: $basket;
         width: 100%;*/

         a {
           font-weight: 400;
           //padding: 12px 16px 12px 16px;
           padding: 12px 0 12px 0; //6.2
           
         }
       }

       a {
         display: block;
      //   padding: 8px 15px 16px 16px;
         //padding: 12px 16px 12px 16px;
         padding: 12px 0;//!important; //6.2
         
         color: $dark-grey;
         font-weight: 700;
         font-size: $slight;
         position: relative;

         &.active-trail {
           &:after {
             position: absolute;
             left: 0;
             bottom: 0;
             content: "";
             display: block;
             right: 0;
             height: 3px;
             background: $med-green;
           }
         }

         &:hover, &:focus {
           color: $med-green;
         }
       }
      }
    }
  }

}

// Search Form
.search-block-form {
  color: $med-grey;
  position: relative;
  @include clearfix;

  input[type="submit"] {
    border: none;
    text-indent: -9999px;
    overflow: hidden;
    width: 32px;
    height: 30px;
    background: url('../images/magn-glass.png') center center no-repeat;
    border-radius: 16px 0 0 16px;

    &:hover, &:focus {
      cursor: pointer;
    }
  }

  input[type="text"] {
    overflow: visible;
    padding: 6px 14px 6px 36px;
    font-size: $small;
    border: none;
    background: $light-grey;
    border-radius: 16px;
    font-weight: 700;

    &::placeholder {
      color: $med-grey;
    }
  }

  .form-actions {
    position: absolute;
    left: 0;
    top: 0;
  }

}

#block-block-2 {
  .block__title {
    display: none;
  }
}

// Social Media Small
.social-media-icons {
  float: left;
 

  &__item {
    float: left;
    margin-right: 7px;

    &--facebook {
      @include sprite($sprite-facebook-small);
    }

    &--linkedin {
      @include sprite($sprite-linkedin-small);
      margin-right: 0;
    }

    &--google-plus {
      @include sprite($sprite-google-plus-small);
    }
  }

  &--large {

    .social-media-icons__item {
      margin-right: 20px;

      &--facebook {
        @include sprite($sprite-facebook-large);
      }

      &--linkedin {
        @include sprite($sprite-linkedin-large);
        margin-right: 0;
      }

      &--google-plus {
        @include sprite($sprite-google-plus-large);
      }
    }

  }
}

.quick-links-top {
  float: left;
  color: $med-grey;
  @include clearfix;
  margin-top: 3px;

  &__icon {
    @include sprite($sprite-quick-links-top);
    float: left;
    margin-right: 10px;
  }

  &__item.leaf {
    list-style-type: none;
    list-style-image: none;
    float: left;

    &:after {
      content: "/";
      margin: 0 5px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    a {
      font-weight: 700;

      &:link, &:visited, &:active {
        color: $med-grey;
      }

      &:hover, &:focus {
        color: $med-green;
      }
    }
  }

}

.phone-top {
  float: left;
  @include clearfix;

  &__icon {
    @include sprite($sprite-phone-icon-small);
    display: block;
    float: left;
    margin-right: 10px;
  }

  &__text {
    font-family: $roboto-con;
    color: $green;
    font-weight: 700;
    font-size: $large;
  }
}

#block-search-form,
#block-block-1,
#block-block-2,
//#block-gtweaks-quick-links-top
#block-system-user-menu {
  display: inline-block;
  margin-right: 2%;
  vertical-align: middle;
  text-align: left;

  &:last-child {
    margin-right: 0;
  }
}