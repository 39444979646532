.node-type-product-display {
  .l-content {
    @include container($large-container);
  }
}

.panel-2col-stacked {
  .center-wrapper {
    padding-top: 20px;
    clear: both;
  }
}

body.node-type-product-display {

  .panel-2col-stacked {
    margin: 30px 0;

    .inside {
      margin: 0;
    }

    .center-wrapper {
      .panel-col-first {
        @include span(8 last);
        margin-top: 53px;

        .panels-outer-wrapper {
          padding-left: 4%;
        }
      }

      .panel-col-last {
        @include span(4);

        .panels-outer-wrapper {
          border-right: 5px solid $footer-text;
          padding-right: 15%;
        }

      }
    }
  }
}

.field-product-availability {
  margin: 20px 0;
}

.node--product-display {

  .field-product-request-link {
    padding: 10px 15px;
    background: $ep_border;
    margin-top: 20px;
    display: inline-block;

    a {
      color: $dark-grey2;

      &:after {
        content: " ";
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid $green;
        width: 0;
        height: 0;
        display: inline-block;
        margin-left: 10px;
        margin-bottom: -4px;
      }

      &:hover, &:focus {
        color: $med-green;
      }
    }
  }

  .selectboxit-container .selectboxit span,
  .selectboxit-container .selectboxit .selectboxit-options a {
    height: 42px;
    border: none;
    line-height: 44px;
  }

  .field__label,
  .commerce-product-sku-label
  {
    color: $dark-grey2;
    font-weight: 400;
  }

  .attribute-widgets {
    @include clearfix;

    margin-bottom: 34px;

    .form-item {
      margin-bottom: 0;
    }

    .form-type-radio {
      margin-bottom: 0;
      margin-right: 0;
      display: block;

      > label {
        position: relative;
        top: 2px;
      }

      input {
        margin-top: 0;
        margin-left: 0;
      }
    }

    .form-type-radios {
      position: relative;
      overflow: hidden;
    }

   .form-type-radios,
   .form-type-select {
     > label {
        color: $dark-grey2;
        font-weight: 700;
        display: block;
        margin-bottom: 5px;
      }
    }
  }

  &.node--full {

    .form-item-attributes-field-commerce-product-maquette,
    .form-item-attributes-field-commerce-product-quantity {
      .help {
        @include sprite($sprite-help-icon);
        display: inline-block;
        position: relative;
        top: 4px;
        margin-left: 2px;

        &:hover,
        &:focus {
          cursor: pointer;
        }
      }
    }

    .field--name-field-product-quantity-desc {
      display: none;
    }

    .group-separator {
      border-bottom: 6px solid $footer-text;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    .group-inline-fields {

      .field,
      .commerce-product-field {
        display: inline-block;
        margin-right: 15px;

        &.commerce-product-field-empty {
          margin-right: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .field__label,
    .field,
    .commerce-product-sku,
    .commerce-product-sku-label
    {
      color: $dark-grey2;
      font-weight: 400;
    }

    .field--name-field-slick-node-view {

     .views-field-field-product-roll-image {
        margin-bottom: gutter();
        position: relative;
      }

      .client-sample-sticker {
        position: absolute;
        right: 0;
        bottom: 0;
        background: $client-sample;
        color: $white;
        padding: 5px 10px;
        z-index: 10;
        max-width: 82px;
        line-height: 1.4;
        font-size: $small;
      }

      .views-field-field-product-roll-image-1 {

        .picture-wrapper {
          cursor: pointer;
          display: block;
          position: relative;
          margin-bottom: gutter();
          @include span(3);
          &:nth-child(4n) {
            @include span(3 last);
          }
          &:nth-child(4n+1) {
            @include span(3 break);
          }
          &.active, &:hover {
            &:after {
              content: "";
              width: 100%;
              background: $med-green;
              -webkit-transition: 0.7s;
              transition: 0.7s;
            }
          }
          &:after {
            content: "";
            height: 3px;
            width: 0;
            position: absolute;
            bottom: 0;
            display: block;
          }
        }
        img {
          display: block;
        }
      }
    }

    .group-wrapper-right {
      @include span(6 last);
    }

    .group-wrapper-left {
      position: relative;
      @include span(6);
    }

    .field--name-field-commerce-product {
      border-bottom: 6px solid $footer-text;
      padding: 20px 0;

      input[type="submit"] {
        clear: both;
        background: $green;
        border: none;
        color: $white;
        padding: 18px 48px;
        font-size: $slight;

        &:hover, &:focus {
        //  background: $med-green;
          cursor: pointer;
        }
      }
    }


    .field--name-commerce-price {
      color: $med-green;
      font-size: $mini-title;
      font-weight: 700;

      .price-suffix {
        color: $dark-grey2;
        margin-left: 10px;
        font-size: $small;
        font-weight: 400;
      }

    }

    .field-product-discount {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      background: $orange;
      color: $white;
      padding: 5px 10px;
      .label {
        display: block;
        font-size: $small;
      }
      .value {
        font-weight: 700;
        line-height: 100%;
        display: block;
      }
    }

    .field--name-field-commerce-init-price {
      font-size: $what-for-you;
      text-decoration: line-through;
    }

    .field--name-field-product-new {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      background: $blue;
      color: $white;
      padding: 4px 10px 1px;
      font-size: $small;
     // width: 90px;
      max-width: 82px;
    }

    .field--name-title-field {
      font-size: $title;
      color: $dark-grey2;
      line-height: 1.1;
      margin-bottom: 10px;
    }

    .group-wrapper-social-roll {
      position: relative;
    }

    .field-custom-this {
      position: absolute;
      right: 0;
      top: 25px;

      ul {
        list-style-type: none;
        .service-facebook {
          @include sprite($sprite-facebook-medium);
        }
        .service-google-plus {
          @include sprite($sprite-google-plus-medium);
        }
        .service-linkedin {
          @include sprite($sprite-linkedin-medium);
        }
      }

      a {
        display: block;
        height: 43px;
      }

    }

    .commerce-product-extra-field-sku {
      margin-bottom: 12px;
    }

    .field--name-field-product-roll-length,
    .field--name-field-product-roll-weight,
    .field-product-availability {
      float: none;
      clear: both;
      margin-bottom: 0;

      &.field--label-inline {
        .field__label, .field__items {
          float: none;
          display: inline-block;
        }
      }
    }

    .field--name-field-product-roll-length,
    .field--name-field-product-roll-weight {
      .field__label {
        font-weight: 700;
      }
    }

    .field-product-availability {
      background: $green;
      padding: 6px 10px;
      color: $white;
    }

    .form-item-attributes-field-commerce-product-quantity {
      float: left;
      margin-right: 40px;
      width: 20%;
    }

    .form-item-attributes-field-commerce-product-maquette {
      .plus {
        font-size: $small;
        display: inline-block;
        margin-left: 10px;
      }
      .ajax-progress {
        position: relative;
        .throbber {
          position: absolute;
          right: -140px;
          top: -20px;
        }
        .message {
          display: none;
        }
      }
    }

  }
}

.pane-gtweaks-products-accordion {

  .ui-accordion-header {
    border: none;
    border-radius: 0;
    font-family: $roboto;
    font-size: $slight;
    font-weight: 700;
    padding: 15px 20px;
    background: $ep_border;
    color: $dark-grey2;
    &.ui-state-active {
      background: $green;
      color: $white;
      font-weight: 400;
      .ui-accordion-header-icon {
        top: 5%;
        &:after {
          content: "-";
          color: $white;
          display: inline-block;
          font-size: $big;
        }
      }
    }
    .ui-accordion-header-icon {
      right: 20px;
      left: auto;
      top: 14%;
      background-image: none;
      text-indent: 0;
      overflow: visible;
      background-position: 0 0;
      width: auto;
      height: auto;
      margin-top: 0;
      &:after {
        content: "+";
        color: $med-green;
        font-size: 28px;
      }
    }
  }

  .ui-accordion-content {
    border: none;
    font-family: $roboto;
    color: $dark-grey2;
    line-height: 1.6;
    padding: 20px;
  }
}

.pane-gtweaks-related-products {

  .goto-eshop-cat {
    a {
      font-size: $small;
      color: $black;
      display: block;

      &:hover,
      &:focus {
        color: $med-green;
      }

      &:after {
        content: " ";
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid $med-green;
        width: 0;
        height: 0;
        display: inline-block;
        margin-left: 6px;
        margin-bottom: -2px;
      }
    }
  }

  h2.pane-title {
    color: $dark-grey2;
    font-size: $key-points;
    margin-bottom: 20px;
    padding-right: 16%;
  }

}

// Teaser of product

// .view-products-related-products {
//   .views-row {
//     box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
//   }

//   .node--product-display {
//     &.node--block {

//       .field--name-body {
//         font-size: $small;
//         line-height: 1.4;
//         margin-bottom: 8px;
//       }

//       .group-box-shadow {
//         padding: 0 12px 12px 12px;
//         min-height: 200px;
//       }

//       .field--name-title-field {
//         font-size: $slight;
//         line-height: 1.4;
//         margin-bottom: 8px;
//         a {
//           color: $black;
//         }
//       }

//       .commerce-product-field-commerce-price {
//         font-size: $slight;
//         color: $med-green;
//         font-weight: 700;
//         clear: both;
//         line-height: 1.5;
//         margin-top: 10px;
//       }

//       .field--name-field-product-roll-image {
//         margin-bottom: 8px;
//       }
//     }
//   }


// }


// NOT a good class. CHANGE IT
/*.page-rola-tameiakwn {

  .l-main {

  }
}*/

.view-e-shop {
// .view-views-search {

  .view-content {
    @include container($large-container);
  }

  .swrapper-title {
    @include container($large-container);

    h1 {
      margin: 30px 0;
      color: $black;
      font-size: $big;
      line-height: 1.2;
    }
  }

  .views-row {
    @include span(3);
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
    margin-bottom: gutters();

    &:nth-child(4n) {
      @include span(3 last);
    }

    &:nth-child(4n+1) {
      @include span(3 break);
    }
  }


  &.view-display-id-page {
    #block-block-45 {

      .ad-ways-block-text {
        line-height: 1.4;
      }

      .read-more {
        margin-top: 26px;
        margin-bottom: 0px;
      }

      background: $basket;

      .ad-ways-block-inner {
        padding: 40px 0;
      }
    }
  }
}

.view-views-search {

  &.node--search-index {
    .group-box-shadow {
      border-top: 8px solid $med-green;
    }
  }
}

.node--product-display,
.page-search .node--news,
.page-search .node--page {
  &.node--teaser,
  &.node--search-index,
  &.node--vblock {
    position: relative;

    a {
      color: $black;
      &:hover, &:focus {
        .group-box-shadow {
          background: $wadv-grey;
        }
      }
    }

    .group-box-shadow {
      background: $white;
    }

    .field--name-body {
      font-size: $small;
      line-height: 1.32;
      margin-bottom: 12px;
      color: $dark-grey2;
    }

    .field--name-field-commerce-product-quantity {
      font-size: $small;
      @include clearfix;
    }

    .group-box-shadow {
      padding: 16px;
    //  min-height: 175px;
      min-height: 237px;
    }

    .field--name-title-field {
      font-size: $slight;
      line-height: 1.32;
      margin-bottom: 12px;
      color: $dark-grey2;

      a {
        color: $black;
        transition: 0.2s linear;

        &:hover, &:focus {
          color: $med-green;
        }
      }
    }

    .field--name-field-product-new {
      position: absolute;
      right: 0;
      top: 0;
      background: $blue;
      color: $white;
      padding: 4px 10px 1px;
      font-size: $small;
     // width: 90px;
      max-width: 82px;
    }

    .field-product-discount {
      position: absolute;
      left: 0;
      top: 0;
      background: $orange;
      color: $white;
      padding: 5px 10px;
      max-width: 82px;

      .label {
        display: block;
        font-size: $small;
      }
      .value {
        font-weight: 700;
        line-height: 100%;
        display: block;
      }
    }

    .commerce-product-field-field-commerce-init-price {
      margin-top: 12px;
      text-decoration: line-through;
      color: $dark-grey2;
    }

    .commerce-product-field-commerce-price {
      font-size: $key-points;
      color: $med-green;
      font-weight: 700;
      line-height: 1.4;
      clear: both;
    }

    .field--name-field-product-roll-image,
    .field--name-field-images,
    .field__images {
      border-bottom: 8px solid $med-green;

      img {
        display: block;
      }

    }
  }
}

.view-products-related-products {
  .views-row {
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
    margin-bottom: 25px;
  }

  .node--product-display {
    &.node--vblock {
     .group-box-shadow {
        min-height: auto;
      }
    }
  }

}

.node--product-display,
.page-search .node--news,
.page-search .node--page {
  &.node--search-index {
    .group-box-shadow {
      min-height: 222px;
    }
  }
}

.page-request-form {
  .l-main {
    @include container($large-container);
    margin-top: 50px;
    margin-bottom: 50px;

    .l-content {
      @include span(9 last);

      .node__content h1 {
        padding: 22px;
        color: $white;
        background: $green;
        border-radius: 8px 8px 0 0;
        font-size: $slight;
        line-height: 100%;
      }

    }

    .node--webform {
      &.node--full {
        box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
      }
    }

    .l-region--sidebar-first {
      @include span(3)
    }

    .webform-client-form {
      padding: 30px;
      border: 1px solid $ep_border;

      .form-item {
        margin-bottom: 32px;

        input[type="text"],
        input[type="email"],
        textarea {
          width: 100%;
          padding: 4px 0;
          font-family: $roboto;
          font-size: 16px;
          overflow: visible;
          border: none;
          border-bottom: 1px solid $green;

          &::placeholder {
            color: $placeholder;
            opacity: 1;
          }

        }

        &:last-child {
          margin-bottom: 0;
        }

        textarea {
          min-height: 130px;
        }
      }

      input[type="submit"] {
        font-family: $roboto;
        font-size: 16px;
        color: white;
        background: $green;
        border: none;
        font-size: $slight;
        padding: 8px 18px;
        display: block;
        margin: 0 auto;

        &:hover, &:focus {
          cursor: pointer;
          background: $med-green;
        }
      }

      .form-actions {
        a{
          display: block;
          margin-top: 20px;
          color: $dark-grey2;
          &:hover, &:focus {
            color: $med-green;
          }
        }
      }

    }

  }

  .view-e-shop  {
    .views-row {
      width: 100%;
    }
  }

  .node--product-display {
    &.node--vblock {

      .group-box-shadow {
        min-height: auto;
      }

    }
  }

}

.not-logged-in {
  &.page-request-form {
    .l-main {

      .webform-client-form {
        .webform-component-textarea {
          margin-bottom: 19px;
        }

        .webform-component--proion {
          margin-bottom: 0;
        }
      }

    }

  }

}

.iradio_minimal-green {
  margin-right: 3px;
}

.addthis_inline_share_toolbox {
  margin-top: 20px;
}

.addthis-smartlayers {
  #at4-share, #at4-soc {
    top: 24% !important;
  }
}

body.node-type-product-display {

  .selectboxit-container  {
    .selectboxit {
      border: 2px solid $deep-silver;
      border-radius: 0;

      span {
        color: $dark-grey2;
        font-weight: 700;
      }

    }

  }

}

.mz-zoom-window {
  z-index: 99;
}