/*** ESSENTIAL STYLES ***/

.sf-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    position: relative;
  }
  ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 99;
  }
  > li {
    float: left;
  }
  li {
    &:hover > ul, &.sfHover > ul {
      display: block;
    }
  }
  a {
    display: block;
    position: relative;
  }
  ul {
    ul {
      top: 0;
      left: 100%;
    }
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    min-width: 12em;
    /* allow long menu items to determine submenu width */
    *width: 12em;
    /* no auto sub width for IE7, see white-space comment below */
  }
  float: left;
  margin-bottom: 1em;
  a {
    border-left: 1px solid #fff;
    border-top: 1px solid #dFeEFF;
    /* fallback colour must use full shorthand */
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding: .75em 1em;
    text-decoration: none;
    zoom: 1;
    /* IE7 */
    color: #13a;
  }
  li {
    background: #BDD2FF;
    white-space: nowrap;
    /* no need for Supersubs plugin */
    *white-space: normal;
    /* ...unless you support IE7 (let it wrap) */
    -webkit-transition: background .2s;
    transition: background .2s;
  }
  ul {
    li {
   //   background: #AABDE6;
    }
    ul li {
   //   background: #9AAEDB;
    }
  }
  li {
    &:hover, &.sfHover {
    //  background: #CFDEFF;
      /* only transition out, not in */
      -webkit-transition: none;
      transition: none;
    }
  }
}

/*** adding the class sf-navbar in addition to sf-menu creates an all-horizontal nav-bar menu ***/

.sf-navbar {
  background: $white;
  position: relative;
 // margin-bottom: 5em;
  margin-bottom: 0;

  // it's part of the superfish.css but we don't really need it
/*  &:before {
    content: '';
    position: absolute;
    left: 0;
    z-index: -1;
    height: 200%;
    width: 100%;
  }*/

  ul {
    box-shadow: none;
    background-color: $basket;
    box-shadow: 0 8px 8px -8px rgba(0, 0, 0, 0.4);
  }

  li {
    background: $white;
    position: static;
  }

  > li > {

    a {
      border: none;
    }

    ul {
      > li > a {
        border: none;
      }
      min-width: 36em;
      /* set this to whatever suits your design */
    }
  }

  ul {
    li {
      background: $basket;
      position: relative;
    }

    ul {
      left: 0;
      top: 100%;
      li {
        width: 100%;
      }
    }
  }

  > li > ul > li {
    float: left;
  }

  ul {
    ul li {
      background: $dark-grey;
    }
  }
}

/* provide background colour for submenu strip */
/* you should just set the menu's container bg colour rather than use pseudo-elements */

/*** point all arrows down ***/

.sf-arrows.sf-navbar ul {
  .sf-with-ul:after {
    margin-top: -3px;
    margin-right: 0;
    border-color: transparent;
    border-top-color: #dFeEFF;
    /* edit this to suit design (no rgba in IE8) */
    border-top-color: rgba(255, 255, 255, 0.5);
  }
  > {
    li {
      > .sf-with-ul:focus:after, &:hover > .sf-with-ul:after {
        border-color: transparent;
        border-top-color: white;
      }
    }
    .sfHover > .sf-with-ul:after {
      border-color: transparent;
      border-top-color: white;
    }
  }
}
