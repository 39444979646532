// fix case sliders

// hide the second menu indefinetetly
#block-gtweaks-mobile-menu {
  display: none;
}

.page-user {
  .l-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.l-header {
  .slicknav_menu {
    z-index: 9000;
  }
}

@include breakpoint(1366px) {

  .node--product-display {

    .attribute-widgets {
      margin-bottom: 24px;
    }

    .field-product-availability {
      margin: 15px 0;
    }

    &.node--full {

      .group-separator {
        margin-bottom: 15px;
        padding-bottom: 15px;
      }

      .field--name-field-commerce-product {
        padding: 15px 0;
      }
    }
  }

  /* 5.2 Updates: Smaller container-Page: how it works
    changes previous 1320px max-width to 1020, as requested
  */
  .how-it-works-block  {
    .how-it-works-wrapper {
      max-width: 1020px;
    }  
  }

  .node--product-display.node--full {
    .field-custom-this {
      top: 15px;
    }
  }
  

}

@include breakpoint(1270px) {

  

  .case-studies-caru,
  .view-case-studies.view-display-id-panel_pane_2 {
    max-width: 600px;
    padding: 0 10px;
  }

  .view-case-studies.view-display-id-panel_pane_2 {
    .view-header {
      width: 100%;
      float: none;
      min-height: 0;
    }

    .view-content {
      width: 100%;
      float: none;
    }
  }

  .node--case-study {
    margin: 0 auto;

    &.node--full {
      .field--name-field-image {
        max-width: 635px;
        float: none;
        margin-bottom: 0;
        width: 100%;
      }

      .field--name-body {
        width: 100%;
        float: none;
        background: #ffffff;
        min-height: 0;
        max-width: 635px;
      }
    }
  }
}


// eshop and misc stuff
@include breakpoint(1239px) {

  .page-request-form {

    .view-e-shop {
      &.view-display-id-block_1 {
        .views-row {
          width: 290px;
          margin-right: 0;
        }
      }
    }

    .l-main {
      padding-left: 300px;

      .l-content {
        width: 100%;
        padding-left: gutters();
        padding-right: 10px;
      }
    }

    .l-region--sidebar-first {
      width: 290px;
      margin-left: -290px;
    }
  }

  .rt-pwrap {
    padding: 20px 10px;

    .zoom-wrapper-outer {
      .zoom-wrapper-inner {
        height: auto;
      }
    }

    .toi {
      text-align: center;
      bottom: 54px;
      display: block;
      padding-left: 5px;
      padding-right: 5px;

      .toi-text {
        margin: 0 auto;
        float: none;
        position: static;
       // margin-bottom: 6px;
        font-size: $large;
      }

      .rmore {
        float: none;
        margin: 0 auto;
        margin-top: 4px;
      }
    }
  }

  .gray-pane-wrap {
    padding: 20px 0 20px;
  }
}



@include breakpoint ($basket-container) {
  .view-e-shop {
    &.view-display-id-page {
      padding-left: 10px;
      padding-right: 10px;

      .view-content {
        @include container(928px);

        .views-row {
          @include span(4);

          &:nth-child(4n+1) {
            clear: none;
          }

          &:nth-child(3n) {
            @include span(4 last);
          }

          &:nth-child(3n+1) {
            @include span(4 break);
          }
        }
      }
    }
  }

  .panel-pane.rolls-caru {
    margin-bottom: 40px;

    .node--product-display {
      margin: 0 10px;
    }

    .view.view-e-shop {
      &.view-display-id-page {
        .view-content {
          .views-row {
            clear: none;
            float: left;
            margin: 0;

            .group-box-shadow {
              padding: 16px;
              // min-height: 230px;
              min-height: 236px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($large-container) {

  .priv-cost-block .banner,
  .two-menu-stopper .fx-height {
    height: auto;
  }

} //end large-container breakpoint

// Desktop to tablet
@include breakpoint($start-point) {

  //.where-adv-page .pane-node,
  //.how-it-works,
  //.advertisers-benefits {
  //
  //  .where-advert-wrapper {
  //    .wa-right-icon {
  //      max-width: 70%;
  //
  //      img {
  //        position: static;
  //      }
  //    }
  //  }
  //}
  //
  //.ad-ways-wrapper {
  //  .ad-ways-image {
  //    max-width: 70%;
  //
  //    img {
  //      position: static;
  //    }
  //  }
  //}
  //
  //.cost-subpages {
  //  .node--page {
  //    &.node--cost {
  //
  //      .group-banner {
  //        .field--name-field-images {
  //          max-width: 70%;
  //
  //          img {
  //            position: static;
  //          }
  //        }
  //      }
  //    }
  //  }
  //}

  .view-slick-front-top {
    .ptitle--large {
      font-size: $bigger;
    }
  }

  .banner.fx-height {
    height: auto;
  }

  .simple-node-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }

  body.node-type-product-display {
    .panel-2col-stacked {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .case-study-descs {
    .panels-outer-wrapper {
   //   padding: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .node--news {

    &.node--full {

      .group-banner {
        .field--name-title-field {
          padding: 100px 0 100px 10px;
        }
      }

        .field--name-field-image {
        max-width: 500px;
          top: 60px;
      }

      .group-wrapper-inner {
        padding: 140px 20px 80px;
      }
    }

  }

/* ------ Responsive Corrections Green Face Start ------ */
  .cost-subpages {
    .node--page {
      &.node--cost {

        .group-banner-left-inner {
          float: none;
          width: 100%;
          max-width: 100%;
          margin-right: 0;
        }

        .group-banner {

          .group-banner-left {
            height: auto;
          }

          .field--name-field-images {
            float: none;
            text-align: center;
            margin: 0 auto;
            padding: 0 10px 40px 10px;

            // fix images resp
            max-width: 42%;
            min-width: 480px;

            img {
              position: static;
            }
          }
        }

      }
    }
  }

  .where-adv-page .pane-node,
  .how-it-works,
  .advertisers-benefits,
  .receipt-points-page {
    .where-advert-wrapper {
      height: auto;

      .wa-left {
        float: none;
        width: 100%;
        max-width: 100%;
        margin-right: 0;
      }

      .wa-right-icon {
        float: none;
        text-align: center;
        margin: 0 auto;

        // fix images resp
        max-width: 42%;
        min-width: 480px;

        padding: 0 10px 40px 10px;

        img {
          position: static;
        }
      }
    }
  }

  .ad-ways-wrapper {
    height: auto;

    .ad-ways-data {
      float: none;
      width: 100%;
      max-width: 100%;
      margin-right: 0;
    }

    .ad-ways-image {
      float: none;
      text-align: center;
      margin: 0 auto;

      // fix images resp
      max-width: 42%;
      min-width: 480px;

      padding: 0 10px 40px 10px;

      img {
        position: static;
      }
    }
  }

  .ad-ways-page {
    .ad-ways-wrapper {
      height: auto;

      .ad-ways-data {
        float: none;
        width: 100%;
        max-width: 100%;
        margin-right: 0;
      }

      .ad-ways-image {
        float: none;
        text-align: center;

        // fix images resp
        max-width: 42%;
        min-width: 480px;

        margin: 0 auto;
        padding: 0 10px 40px 10px;

        img {
          position: static;
        }
      }
    }
  }

  /* ------ Responsive Corrections Green Face End ------ */

  .contact-us-pane-page {

    .pane-node {
      padding: 40px 0;
    }

    .field--name-body {
      width: 100%;
      float: none;
      margin-right: 0;
    }

    .wch-wrapper {
      max-width: 100%;
      padding: 0 10px;
      margin-bottom: 40px;
    }

    .wf-wrapper {
      float: none;
      max-width: 604px;
      margin: 0 auto;
      width: 100%;
    }
  }

  .banner,
  .pane-block.panel-pane .banner {
    height: auto;

    &--larger {
      min-height: 0;
      height: auto;
    }
  }

  .two-menu-stopper {
    .banner {
      height: auto;

      &--larger {
        min-height: 0;
        height: auto;
      }
    }
  }

  .about-us-page {

    .group-inner {
      height: auto;
    }

    .about-us-inner {
      max-width: 100%;
      padding: 0;
      margin-right: 0;
      float: none;
    }

    .field--name-field-images {
      position: static;
      padding-top: 0;

      text-align: center;
    }

   .panel-pane.pane-block .about-us-wrapper {
      padding: 0 0 20px;
    }

    .about-us-title {
      padding: 40px 10px;
      margin-right: 0;
      float: none;
      width: 100%;
    }

    .about-us-body,
    .about-us-text {
      margin-right: 0;
      float: none;
      padding: 0 10px;
      width: 100%;
    }
  }

}

@include breakpoint($midpoint) {

  // Responsive Font Sizes
  $bigger: 2rem; // 40px;
  $huge: 2.5rem; //48px
  $title: 2rem; //32px
  $big: 1.85rem; // 36px
  $key-points: 1.5rem;
  $large: 1.25rem;

  //27.2 addition
  .basket-block {
    .info-banner {
      float: none;
      width: 100%;
      padding: 0 10px;
    }
  }

  .front {
    .l-key-points__right {
      padding-left: 30px;
    }
  }

  .l-page  {
    .key-points-list__title {
      font-size: $carousel;
    }
    .key-points-list__text {
      font-size: $normal;
    }
  }

  body.node-type-product-display {

    .panel-2col-stacked {
      .center-wrapper {

        .panel-col-first {
          .panels-outer-wrapper {
            padding-left: 1%;
          }
        }

        .panel-col-last {

          .panels-outer-wrapper {
            padding-right: 8%;
            //padding-left: 10px;
          }

        }
      }
    }
  }

  .pane-gtweaks-related-products {

    h2.pane-title {
      padding-right: 10%;
    }

  }


  .l-footer {
    nav {
      margin-bottom: 20px;
    }

    #block-menu-menu-footer-select-menu {
      margin-top: 20px;
    }

    #block-menu-menu-footer-select-menu,
    #block-menu-menu-footer-menu-middle,
    #block-menu-menu-footer-menu-right {
      float: none;
      width: 100%;

      .block__title {
        margin-bottom: 20px;
      }
    }
  }

  .blog-section {
    .l-content {
      width: 100%;
      float: none;
      margin: 0 0 20px 0;
    }

    .l-region--sidebar-second {
      width: 100%;
      float: none;
      margin: 0;
    }
  }

  .node-type-blog {
    .center-wrapper {

      .panel-col-last {
        width: 100%;
        float: none;
        margin: 0;
      }

      .panel-col-first {
        width: 100%;
        float: none;
        margin: 0 0 20px 0;

        .node__content {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }


  .blog-section .l-main {
    padding-left: 10px;
    padding-right: 10px;
  }

  .where-adv-page {
    .pane-node {
      .check-spots-wrapper {
        padding: 0 10px;
      }
    }
  }

  .pane-block-11 {
    padding: 20px 10px;
  }

  .l-turntabled {
    .gradientdiv-slide {
      padding: 40px 10px 0 10px;
    }
  }

  .block--views-slick-front-top-block {
    .banner.fx-height {
      height: 493px;
    }
  }

  .node--news {
    &.node--full {

      .group-wrapper-inner {
        padding: 20px 10px;
      }

      .group-banner {
        .field--name-title-field {
          padding: 100px 0 100px 10px;
        }
          .field--name-title-field {
            max-width: 480px;
            width: auto;

            h1 {
            font-size: $big;
          }
        }
      }

      .group-banner-left {
        max-height: none;
      }

      .field--name-field-image {
        padding-top: 20px;
        max-width: 300px;
      }
    }
  }

  .not-front {
    .view-display-id-news_page.l-news-wrapper {

      .view-content {
        padding-left: 10px;
        padding-right: 10px;
      }

      .view-header {
        padding-left: 10px;
        padding-right: 10px;
      }

    }
  }

  .basket-block > .panels-outer-wrapper {
    padding: 40px 10px;
  }

  .logos-block {
    .node--page.node--logos {
      padding: 40px 10px;
    }
  }

  .advertisers-benefits-block {
    padding: 40px 10px;
  }

  .how-it-works-block {

    .how-it-works-image-1,
    .how-it-works-image-2,
    .how-it-works-image-3 {
      display: block;
      float: left;
    }

    .how-it-works-text {
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    .how-it-works-left {
      @include clearfix;
      display: block;
      width: 100%;
      padding: 20px 0;
      float: none;
      height: 317px;
    }

    .how-it-works-right {
      display: block;
      width: 100%;
      padding: 20px 0;
      float: none;
    }
  }

  .proof-block {
    .l-what-for-you-wrapper {
      padding: 40px 0 40px;
    }
  }

  .where-advert {
    padding: 40px 10px;

    .wa-left {
      max-width: 100%;
      float: none;
    }

    .wa-right-icon {
      float: none;
      margin-top: 40px;
      text-align: center;
    }
  }

  .way-adv-wrapper {
    padding: 40px 10px 0;
  }

  .basket-block {

    .left-side-basket {
      float: none;
      width: 100%;
      margin-right: 0;
    }

    .basket-icon {
      float: none;
      margin: 30px 0;
      width: 100%;
      text-align: center;

      img {
        max-width: 420px;
        width: 100%;
      }
    }
  }

  .green-st-block {
    .panels-outer-wrapper {
      padding: 40px 10px;
    }
  }

  .l-below-banner {
    padding: 0 10px;
  }

  .l-footer-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .l-footer {
  /*  nav {
      display: none;
    }*/

    #block-block-9 {
      float: none;
      margin-right: 0;
      margin: 0 auto;
      width: 100%;
    }
  }

  .l-what-for-you {

    .ptitle {
      margin-top: 30px;
      padding-top: 30px;
    }

    .small-box {
      margin-bottom: 40px;
    }
  }

  @include with-layout(12 1/2) {

    .l-news {
      .ptitle {
        margin-bottom: 30px;
        padding-top: 30px;
      }
    }

    .l-news-wrapper {
      padding: 20px 10px;

      .views-row {
        @include span(6);
        margin-bottom: 40px;

        &:nth-child(2n) {
          @include span(6 last);
        }

        &:nth-child(2n+1) {
          @include span(6 break);
        }
      }
    }
  }

  // removes extra padding from ptitle in front page green section 6.2
  .ptitle {
    &.ptitle--coloured {
      padding: 0;
    }
  }
}

// eshop and misc stuff
@include breakpoint(1020px) {

  .view-e-shop {
    &.view-display-id-page {

      .view-content {
        @include container(610px);

        .views-row {
          @include span(6);

          &:nth-child(4n+1) {
            clear: none;
          }

          &:nth-child(3n) {
            @include span(6);
          }

          &:nth-child(3n+1) {
            clear: none;
          }

          &:nth-child(2n) {
            @include span(6 last);
          }

          &:nth-child(2n+1) {
            @include span(6 break);
          }
        }
      }
    }
  }

  .view-slick-front-top {
    .ptitle--large {
      font-size: $big;
    }

    .banner__description {
      font-size: $normal;
    }
  }

}

// eshop and misc stuff, mobile, single row
@include breakpoint(690px) {

  .view-e-shop {
    &.view-display-id-page {
      .view-content {
        .views-row {
          &.views-row-even,
          &.views-row-odd {
            width: 100%;
            margin: 0 auto 20px;
            max-width: 300px;
            float: none;
          }
        }
      }
    }
  }

  .panel-pane.rolls-caru {

    .node--product-display {
      margin: 0 10px;
    }

    .view.view-e-shop {
      &.view-display-id-page {
        .view-content {
          max-width: 300px;
        }
      }
    }
  }

}

@include breakpoint($wadv) {

  .l-copyright {
    &__left {
      float: none;
      margin-bottom: 15px;
      text-align: left;
    }

    &__right {
      float: none;
      text-align: left;
      line-height: 2.2;
    }
  }

  .view-slick-front-top {
    .banner__left .center-wrapper {
      padding: 40px 30px 20px 20px;
    }
  }

  .view-display-id-panel_pane_1 {
    .ptitle--large,
    .field--name-field-final-price {
      font-size: 44px;
    }
  }

}

// Tablet, stuff start breaking in single columns, neat-o!
@include breakpoint($tablet) {

  .solo-fx-height {
    height: auto;
  }

/*  .view-slick-front-top {
    .view-content {
      .views-row {
        height: auto;
        overflow: visible;
      }
    }
  }*/


  .node--product-display {
    &.node--full {

      #edit-submit {
        margin-top: 20px;
      }

      .form-item-attributes-field-commerce-product-quantity {
        float: none;
        margin-right: 0;
        margin-bottom: 20px;
        width: 30%;
      }

      .picture-wrapper {
        max-width: 142px;
      }

      .group-wrapper-left {
        float: none;
        width: auto;
        @include container(520px);
        clear: both;
      }

      .group-wrapper-right {
        padding-top: 20px;
        width: 100%;
        float: none;
        margin-right: 0;
        clear: both;
      }

      .field-custom-this {
        top: 17%;
      }

    }
  }

  .l-what-for-you-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }

  .l-what-for-you {

    .ptitle {
      margin-bottom: 40px;
    }

  }

  .l-turntabled {
    padding: 0;
  }

  body.node-type-product-display {

    .view-products-related-products {
      @include clearfix;
      @include container(610px);

      .views-row {
        @include span(6);

        &:nth-child(2n) {
          @include span(6 last);
        }

        &:nth-child(2n+1) {
          @include span(6 break);
        }
      }
    }

    .panel-2col-stacked {
      .center-wrapper {

        .panel-col-first {
          width: 100%;
          margin-right: 0;
          float: none;
          margin-top: 10px;

          .panels-outer-wrapper {
            border-right: none;
            padding-left: 0;
          }
        }

        .panel-col-last {
          width: 100%;
          float: none;
          margin-right: 0;
          margin-top: 20px;
          border-right: none;

          .panels-outer-wrapper {
            padding: 0;
            margin: 0;
            border: none;
          }

          }
        }
      }
    }

  .node--slideshow {
    &.node-teaser {

      .node__content {
        height: auto;
      }

      .group-wrapper {
        position: static;
        transform: none;

        .group-left-side {
          max-width: 100%;
          padding: 30px 10px;
          margin-right: 0;
        }
      }
      .field--name-body {
        margin-bottom: 30px;
      }
    }
  }

  .wadv-left {
    width: 100%;
    margin-right: 0;
    float: none;

    .respond-gmap {
      padding-bottom: 68%;
      min-height: 580px;
    }
  }

  .wadv-right {
    width: 100%;
    margin-right: 0;
    float: none;
    min-height: auto;
  }

  .block--views-slick-front-top-block {
    .banner.fx-height {
      height: auto;
    }
  }

  .ad-ways-page, .cost-subpages {

    .ad-ways-block-inner {
      padding: 50px 0 40px;

      .ad-ways-block-left {
        width: 100%;
        float: none;
        display: block;

        .ad-ways-block-image {
          margin-bottom: 40px;

          img {
            margin: 0 auto;
          }
        }
      }

      .ad-ways-block-right {
        width: 100%;
        float: none;
        display: block;
        padding: 0;
      }

    }
  }

  .printed-rolls {
    .ad-ways-block-inner {
      padding: 40px 0;

      .ad-ways-block-left,
      .ad-ways-block-left-2 {
        width: 100%;
        float: none;
        display: block;
        padding: 0 20px;

        .ad-ways-block-image {
          padding: 0 10px;
          margin-bottom: 40px;

          img {
            margin: 0 auto;
          }
        }
      }

      .ad-ways-block-right,
      .ad-ways-block-right-2 {
        width: 100%;
        float: none;
        display: block;

        .ad-ways-block-image {
          padding: 0 10px;
          margin-bottom: 40px;

          img {
            margin: 0 auto;
            display: block;
          }
        }
      }

    }
  }

  .how-it-works-block {

    .how-it-works-wrapper {
      padding: 50px 0 40px;
    }

    .how-it-works-left {
      height: 180px;
    }

    .how-it-works-image-1 {
      @include sprite($sprite-pl-1_resp);
    }

    .how-it-works-image-2 {
      @include sprite($sprite-pl-2_resp);
    }

    .how-it-works-image-3 {
      @include sprite($sprite-pl-3_resp);
    }

  }

  .l-socialpromo {
    .l-socialpromo-wrapper {
      padding: 40px 10px;
    }
    .l-socialpromo__text {
      font-size: $slight;
    }
  }

  .how-op-block {
    .panels-outer-wrapper {
      padding: 40px 10px;
    }

    .rmore {
      margin: 40px 0;
    }

    .how-op {
      &__item {
        margin-right: 0;
        width: 100%;
        float: none;
        max-width: 280px;
        margin: 0 auto 30px auto;

        &:last-child {
          width: 100%;
          max-width: 280px;
          float: none;
          margin: 0 auto 30px auto;
        }
      }
    }
  }

  .contact-us-pane-page {
    .pane-block-12 {
      .panels-outer-wrapper {
        padding: 40px 10px 0 10px;
      }
    }
  }

  .cu-out {
    margin-top: 40px;
    position: static;
    margin-left: -10px;
    margin-right: -10px;

    .cu-wrapper {
      float: none;
      width: 100%;

      &:last-child {
        float: none;
        width: 100%;
      }
    }
  }

  .ptitle {

    &--large {
      font-size: $res-title;
      line-height: 1.2;
    }

    &--coloured,
    &--medium {
      font-size: $key-points;
      line-height: 1.3;
    }

    &--coloured-large,
    &--coloured-huge {
      font-size: $res-title;
      line-height: 1.2;
    }
  }

  .view-display-id-panel_pane_1 {
    .ptitle--large,
    .field--name-field-final-price {
      font-size: $res-title;
    }
  }

  .view-slick-front-top {
    .ptitle--large {
      font-size: $res-title;
    }
  }

  .how-it-works-block .how-it-works-text span,
  .ad-ways-wrapper .ad-ways-title,
  .where-adv-page .pane-node .where-advert-wrapper .ptitle,
  .where-adv-page .pane-node .check-spots,
  .receipt-points-page .where-advert-wrapper .wa-left .ptitle--coloured-huge,
  .research-efficiency .where-advert-wrapper .wa-left .ptitle--coloured-huge,
  .cost-subpages .node--page.node--cost .group-banner .field--name-title-field,
  .case-study-descs h1,
  .about-us-page .about-us-title,
  .wch,
  .node--news.node--full .group-banner .field--name-title-field h1,
  .node--blog.node--about .field--name-title-field h1,
  .node--slideshow.node-teaser .field--name-field-final-price,
  .node--product-display.node--full .field--name-title-field,
  .node--product-display.node--full .field--name-commerce-price,
  .user-login h1,
  .user-register-form h1,
  .user-profile-form h1,
  .logged-in.page-user .l-content h1,
  .logged-in.page-user .l-content h2,
  .view-e-shop .swrapper-title h1,
  .page-cart h1, .page-checkout h1,
  #block-block-5 .ptitle--medium,
  .l-news .ptitle,
  .page-search-results .l-content h1,
  .page-sitemap .l-content h1 {
    font-size: $res-title;
    line-height: 1.2;
  }

  .not-front {
    .user-login {
      .cs-title {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .advertisers-benefits,
  .how-it-works {
    .where-advert-wrapper {
      .wa-left {
        .ptitle--coloured-huge {
          font-size: $res-title;
          line-height: 1.2;
        }
      }
    }
  }

  .ad-ways-block-inner .ad-ways-block-subtitle,
  .wadv-content .wadv-text,
  .wadv-content .loc-search-form .form-type-textfield label,
  .wch-below,
  .node--slideshow.node-teaser .field--name-field-initial-price,
  .node--slideshow.node-teaser .field--name-body,
  .how-op-block .text-op,
  .where-advert .check-spots,
  .choose-arrow,
  .l-main .node--page--full .field--name-body h3 {
    font-size: $carousel;
  }

  .ad-ways-block-inner .ad-ways-block-title,
  .priv-block .ptitle--large,
  .advertisers-benefits-block .advertisers-benefits-title,
  .advertisers-categories-block .pane-title,
  .page-node-1172 .pane-e-shop .pane-title,
  .pane-block-11 h2 {
      font-size: $key-points;
      line-height: 1.3;
  }

  .contact-us-pane-page .small-utitle {
    font-size: $slight;
  }

  .l-callus {

    &-wrapper {

      .ajax-progress-throbber {
        right: 10px;
      }

    }
  }

  .rolls-caru {
    .slick-track {
      padding-top: 0;
    }
  }

  .panel-pane.rolls-caru {
    margin-bottom: 10px;
  }

  .page-node-1172 {
    .pane-e-shop .pane-title {
      margin-top: 0;
    }
  }
  .gray-pane-wrap {
    padding: 30px 0 20px;
  }

  .rt-pwrap {
    padding: 0 10px;
  }

  .advertisers-benefits-block {
    .advertisers-benefits {
      margin-bottom: 50px;
    }
  }

  .advertisers-categories-block {
    padding: 50px 0 40px;
    .pane-title {
      margin-bottom: 40px;
    }
  }

  .priv-cost-block  {
    .banner__description {
      margin: 40px 0 30px;
    }
  }

  .case-study-descs {
    .panels-outer-wrapper {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .page-node-1180 {
    .case-study-descs {
      .panels-outer-wrapper {
        padding-top: 40px;
        padding-bottom: 65px;
      }
    }
  }

  .view-case-studies.view-display-id-panel_pane_2 .view-content .slick-list {
    padding: 0 !important;
  }

  .view-cost {
    .ad-ways-block-inner {
      padding: 50px 0 40px;
    }
  }

  .search-results {
    .search-result:nth-child(n) {
      @include span(4);
      &:nth-child(3n) {
        @include span(4 last);
      }
      &:nth-child(4n+1) {
        clear: none;
      }
      &:nth-child(3n+1) {
        @include span(4 break);
      }
    }

  }

  .green-st-block-cost {
    .panels-outer-wrapper {
      padding: 50px 10px 10px;
    }
    .ptitle--coloured {
      font-size: $res-title;
      margin-bottom: 30px;
    }

    .cost {
      width: 100%;
      float: none;
      max-width: 360px;
      margin: 40px auto;

      &:last-child {
        width: 100%;
        float: none;
        margin: 40px auto;
      }
      .cost-text {
        font-size: $slight;
      }
    }
  }

  .about-us-page {
    .about-us-title {
      padding-bottom: 0;
      &:before {
        height: 20px;
      }
    }
    .group-inner {
      padding-bottom: 30px;
    }
  }



  .taxonomy-term--carousel {
    .taxonomy-term__content {
      max-width: 180px;
      margin: 0 auto;
    }
  }

  // override slick slide

  /*.l-copyright {
    &__left {
      float: none;
      margin-bottom: 15px;
    }

    &__right {
      float: none;
    }
  }*/

  .l-callus-wrapper {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .l-callus {
    &__text {
      margin-right: 0;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .cta {
    display: block;
    margin: 0 auto;
  }

  .banner,
  .pane-block.panel-pane .banner {
    height: auto;

    .banner-left-image,
    .banner-right-image {
      position: static;
      min-height: 400px;
      width: 100%;
    }

    .banner-right {
      .center-wrapper {
        padding: 40px 40px 20px 20px;
        max-width: 100%;
        margin-right: 0;
        width: 100%;
        position: static;
        margin-left: 0;
      }
    }

    &__left {

      .center-wrapper {
        padding: 40px 40px 20px 20px;
        max-width: 100%;
        margin-right: 0;
        width: 100%;
        position: static;
        margin-left: 0;
      }

    }

    .edit-img {
      position: static;
      min-height: 400px;
    }

    &__right {
      width: 100%;
      position: static;

      &__hero-image {
        position: static;
        min-height: 400px;
      }
    }
  }

  .view-slick-front-top {
    .view-content {
      position: static;
      width: auto;
      max-width: 100%;

      // hide whats left of the image
      img {
        min-width: 640px;
        max-width: 100%;
      }
    }
  }

  .front {

    .l-below-banner-wrapper {
      padding: 40px 0;
    }

    .l-key-points-wrapper {
      padding: 40px 20px;
    }


    .l-key-points {

      &__left {
        width: 100%;
        float: none;
        margin: 0 auto 40px;
      }

      &__right {
        width: 100%;
        float: none;
        padding-left: 0;
      }
    }
  }


  //custom fix to slider in e-shop page 28.2
  .page-node-1172 {
    .view-display-id-panel_pane_1 {
      .slick-dots {
        position: absolute;
        top: 33%;
        right: 20px;
      }
    }
    .slideshow-slick {
      margin-bottom: 20px;
      .rounded-arrow-nav {
        bottom: 0;
      }
    }
  }
}

// misc fixes
@include breakpoint(730px) {
  .node--news {

    &.node--full {
      .field--name-field-image {
        padding-top: 45px;
        max-width: 200px;
      }
    }

  }
}

@include breakpoint($before_mobile) {

  .rt-pwrap {

    .toi {
      bottom: 20px;
    }
  }

  .search-results {
    .search-result:nth-child(n) {
      @include span(6);
      &:nth-child(2n) {
        @include span(6 last);
      }
      &:nth-child(3n+1) {
        clear: none;
      }
      &:nth-child(2n+1) {
        @include span(6 break);
      }
    }

  }

  .page-request-form {

    .view-e-shop {
      &.view-display-id-block_1 {
        .views-row {
          width: 100%;
          max-width: 290px;
          margin: 20px auto 0 auto;
          float: none;
        }
      }
    }

    .l-main {
      padding-left: 10px;
      padding-right: 10px;

      .l-region--sidebar-first {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
      }

      .l-content {
        width: 100%;
        padding-left: 0;
      }
    }
  }

  body.node-type-product-display {
    .view-products-related-products {
      @include clearfix;
      @include container(290px);

      .views-row {
        width: 100%;
        float: none;

        &:nth-child(2n) {
          width: 100%;
          float: none;
        }

        &:nth-child(2n+1) {
          width: 100%;
          float: none;
        }
      }
    }
  }

  .rt-pwrap {

    .rt-left {
      float: none;
      margin-bottom: 20px;
      width: 100%;
    }

    .rt-right {
      float: none;
      width: 100%;
    }
  }

  .node--blog {
    &.node--teaser {
      text-align: center;

      .group-blog-image {
        float: none;
        margin-right: 0;
        text-align: center;
        margin-bottom: 20px;
        display: inline-block;
      }

      .group-blog-text {
        text-align: left;
      }

    }

    .group-blog-full-image {

      .group-blog-meta {
        font-size: $normal;
      }

      .field--name-title-field {
        h1 {
          font-size: $key-points;
          margin-bottom: 5px;
        }
      }
    }
  }

  .case-studies-caru {
    max-width: 100%;

    .arrow-wrapper--right {
      top: 22%;
    }

    .arrow-wrapper--left {
      top: 22%;
    }
  }

  .node--case-study {
    margin: 0 auto;

    &.node--full {
      .field--name-field-image {
        max-width: 100%;
        float: none;
        margin-bottom: 0;
        width: 100%;
      }

      .field--name-body {
        width: 100%;
        float: none;
        padding: 20px 10px;
        background: #ffffff;
        min-height: 0;
        max-width: 100%;
      }
    }
  }

  .logos-block {
    .node--page.node--logos {
      padding: 40px 10px 0;
      .field--name-field-images {
        .field__item {
          display: block;
          margin: 0 auto;
          max-width: 250px;
          height: auto;
          line-height: 100%;
          margin-bottom: 40px;
        }
      }
    }
  }

  .advertisers-benefits-block {
    .panels-outer-wrapper {
      .advertisers-benefits {
        width: 100%;
        margin-bottom: 30px;
        float: none;
        margin-right: 0;
        &.read-more-benefits {
          margin-bottom: 0;
        }
      }
    }
  }

  .case-studies-caru {
    margin-top: 35px;
  }

}

// Mobile
@include breakpoint($mobile) {

  .node--product-display {
    .attribute-widgets {
      margin-bottom: 34px;
    }
  }

  #pgwModal.pgwCart {
    line-height: 1.3;

    .pm-body {
      padding: 20px;
    }
  }

  // Product add to cart Modal
  .popup-addtocart-wrapper {

    .product-details-wrapper {
      .added-product-image {
        float: none;
        margin-right: 0;
        text-align: center;
        margin-bottom: 15px;
      }
    }

    .button-wrapper {
      .button {
        &.continue {
          margin-bottom: 10px;
        }

        &.checkout {
        }
      }
    }

  }

  /* ------ Responsive Corrections Green Face Start ------ */
  .cost-subpages {
    .node--page {
      &.node--cost {
        .group-banner {
          .field--name-field-images {
            // fix images resp
            max-width: 100%;
            min-width: 0;
          }
        }

      }
    }
  }

  .where-adv-page .pane-node,
  .how-it-works,
  .advertisers-benefits,
  .receipt-points-page {
    .where-advert-wrapper {

      .wa-right-icon {
        // fix images resp
        max-width: 100%;
        min-width: 0;
      }
    }
  }

  .ad-ways-wrapper {
    .ad-ways-image {

      // fix images resp
      max-width: 100%;
      min-width: 0;
    }
  }

  .ad-ways-page {
    .ad-ways-wrapper {

      .ad-ways-image {
        // fix images resp
        max-width: 100%;
        min-width: 0;
      }
    }
  }

  /* ------ Responsive Corrections Green Face End ------ */

  .not-front {
    .view-display-id-news_page.l-news-wrapper {
      .view-content {
        padding-top: 20px;
        padding-bottom: 10px;
      }
    }
  }

  .search-results {
    article {
      &.search-result {
        &:nth-child(n){
          &:nth-child(2n),
          &:nth-child(3n),
          &:nth-child(2n+1),
          &:nth-child(3n+1) {
            @include span(12);
          }
        }
      }
    }
  }

  .node--blog {
    &.node--vblock {
      text-align: center;

      .field--name-field-image {
        text-align: center;
        display: inline-block;
        float: none;
        margin-right: 0;
        margin-bottom: 10px;
      }

      .group-blog-text {
        text-align: center;
      }

      .field--type-text {
        text-align: left;
      }
    }
  }

  .how-it-works-block {
    .how-it-works-wrapper {
      padding: 10px 0 10px;
    }
  }

  .how-it-works {

    .how-it-works-image-1,
    .how-it-works-image-2,
    .how-it-works-image-3 {
      display: block;
      float: none;
      margin: 0 auto;
    }

    .how-it-works-text {
      display: block;
      position: static;
      transform: none;
      text-align: center;
    }

    .how-it-works-left {
      @include clearfix;
      height: auto;
      margin-top: 30px;
    }
  }

  .l-what-for-you {
    .small-box {
      float: none;
      margin-left: auto;
      margin-right: auto;
      width: 280px;
      min-width: 280px;

      &__image {
        height: 280px;
      }

      &:last-child {
        float: none;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @include with-layout(12 1/3) {
    .l-news-wrapper {

      .views-row {
        &.views-row-odd,
        &.views-row-even {
          float: none;
          width: 100%;
        }
      }
    }
  }
}

@include breakpoint(390px) {
  .rolls-caru {
    .arrow-wrapper--left {
      left: -32px;
      .carousel-arrow-left {
        @include sprite($sprite-carousel-left-arrow);

      }
    }
    .arrow-wrapper--right {
      right: -32px;
      .carousel-arrow-right {
        @include sprite($sprite-carousel-right-arrow);
      }
    }
  }

  .node--product-display.node--full {
    .field--name-field-commerce-product {
      input[type="submit"] {
        padding: 18px 32px;
      }
    }
  }

}

@include breakpoint(360px) {

  .view-e-shop {
    &.view-display-id-page {
      .view-content {
        .views-row {
          &.views-row-even,
          &.views-row-odd {
            width: 100%;
            margin: 0 auto 20px;
            max-width: 260px;
            float: none;
          }
        }
      }
    }
  }

  .panel-pane.rolls-caru {

    .node--product-display {
      margin: 0 10px;
    }

    .view.view-e-shop {
      &.view-display-id-page {
        .view-content {
          max-width: 260px;
        }
      }
    }
  }

  //custom fix to slider in e-shop page 28.2
  .page-node-1172 {
    .view-display-id-panel_pane_1 {
      .slick-dots {   
        top: 35%;      
      }
    }
  //}
}

}