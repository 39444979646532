/* --------- Height Containers ----------- */
/*5.2 Minor Fixes - Added Mixin for advertiser Benefits page, that uses 
left_green_new-right_green_new with opposite floats, instead of left_green() 
*/
/*----end-----*/
/* -------- End Height Containers ------- */
/* ------ Responsive -------- */
/* ------ Responsive Containers -------- */
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400,400i,700,700i|Roboto:400,400i,700,700i&subset=greek");
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
strong {
  font-weight: 700; }

a:focus, textarea:focus, input:focus, button:focus {
  outline: none; }

.view-commerce-cart-form .line-item-total-raw {
  color: #666666; }

.form-item .description {
  margin-top: 3px;
  color: #666666;
  font-size: 0.75rem; }

.view-commerce-cart-form .form-item label, .commerce-checkout-form-checkout .form-item label, .view-commerce-line-item-table .form-item label {
  margin-bottom: 2px; }

.view-commerce-cart-form td.views-field-title-field, .commerce-checkout-form-checkout td.views-field-title-field, .view-commerce-line-item-table td.views-field-title-field {
  width: 300px; }

.view-commerce-cart-form select, .commerce-checkout-form-checkout select, .view-commerce-line-item-table select {
  padding: 8px;
  overflow: visible; }

.view-commerce-cart-form table, .commerce-checkout-form-checkout table, .view-commerce-line-item-table table {
  width: 100%; }

.page-user-password input[type="submit"] {
  padding: 12px;
  overflow: visible;
  background: #9bb528;
  border: 2px solid #b3cc3c;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 14px; }
  .page-user-password input[type="submit"]:hover, .page-user-password input[type="submit"]:focus {
    cursor: pointer;
    background: #b3cc3c;
    border: 2px solid #9bb528; }

.page-user-password .form-item label {
  margin-bottom: 2px; }

.page-user-password .l-content {
  margin: 60px 0;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto; }
  .page-user-password .l-content:after {
    content: "";
    display: table;
    clear: both; }

.page-user-password h1 {
  border-bottom: none;
  line-height: 1.1;
  font-size: 2.25rem;
  margin-bottom: 20px; }

.page-user-password input[type='pass'],
.page-user-password input[type='text'] {
  padding: 12px;
  overflow: visible;
  border: 1px solid #bcbcbc;
  font-size: 1rem; }

#commerce-checkout-form-review .form-item label {
  font-weight: 700; }

.checkout-review .pane-data-full .form-item label {
  display: block;
  font-weight: 700; }
  .checkout-review .pane-data-full .form-item label:after {
    position: relative;
    left: -4px;
    content: ":";
    display: inline; }

#payment-details {
  display: none; }

.entity-commerce-order {
  /* .field--name-commerce-customer-billing {
    > .field__label {
      color: $dark-grey;
      font-size: $carousel;
    }
  }

  .field--name-commerce-customer-shipping {
    > .field__label {
      color: $dark-grey;
      font-size: $carousel;
    }
  } */ }
  .entity-commerce-order .field:after {
    content: "";
    display: table;
    clear: both; }
  .entity-commerce-order .commerce-price-formatted-components {
    width: 100%; }
    .entity-commerce-order .commerce-price-formatted-components tr.odd td, .entity-commerce-order .commerce-price-formatted-components tr.even td {
      padding: 5px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.045);
      font-size: 1rem;
      background: #dddddd;
      color: #666666;
      font-weight: 700; }
      .entity-commerce-order .commerce-price-formatted-components tr.odd td.component-title, .entity-commerce-order .commerce-price-formatted-components tr.even td.component-title {
        text-align: left;
        font-weight: 400; }
      .entity-commerce-order .commerce-price-formatted-components tr.odd td.component-total, .entity-commerce-order .commerce-price-formatted-components tr.even td.component-total {
        text-align: right; }
  .entity-commerce-order .order-status-custom-field {
    margin: 20px 0;
    font-size: 1.25rem; }
    .entity-commerce-order .order-status-custom-field .field-label {
      color: #9bb528;
      font-weight: 700; }
  .entity-commerce-order .field--name-commerce-customer-billing .field__label, .entity-commerce-order .field--name-commerce-customer-shipping .field__label, .entity-commerce-order .field--name-field-order-comments .field__label {
    font-weight: 700; }

.view-commerce-user-orders table {
  width: 100%; }

.view-commerce-user-orders tr td {
  font-weight: 400; }

#commerce-checkout-form-checkout fieldset.checkout-buttons {
  background: #f6f6f6; }

.commerce-line-item-views-form .commerce-line-item-actions #edit-submit {
  display: none; }

#views-form-commerce-cart-form-default, #commerce-checkout-form-checkout,
#commerce-checkout-form-review, .view-commerce-line-item-table, .view-commerce-user-orders {
  /*  .commerce-quantity-plusminus-link-disabled {
    display: none;
  }*/ }
  #views-form-commerce-cart-form-default .form-type-radio label, #commerce-checkout-form-checkout .form-type-radio label,
  #commerce-checkout-form-review .form-type-radio label, .view-commerce-line-item-table .form-type-radio label, .view-commerce-user-orders .form-type-radio label {
    position: relative;
    top: 1px; }
  #views-form-commerce-cart-form-default td, #commerce-checkout-form-checkout td,
  #commerce-checkout-form-review td, .view-commerce-line-item-table td, .view-commerce-user-orders td {
    line-height: 1.3; }
    #views-form-commerce-cart-form-default td.views-field-field-commerce-init-price, #commerce-checkout-form-checkout td.views-field-field-commerce-init-price,
    #commerce-checkout-form-review td.views-field-field-commerce-init-price, .view-commerce-line-item-table td.views-field-field-commerce-init-price, .view-commerce-user-orders td.views-field-field-commerce-init-price {
      text-decoration: line-through; }
  #views-form-commerce-cart-form-default th, #commerce-checkout-form-checkout th,
  #commerce-checkout-form-review th, .view-commerce-line-item-table th, .view-commerce-user-orders th {
    line-height: 1.4; }
  #views-form-commerce-cart-form-default input[type="radio"],
  #views-form-commerce-cart-form-default input[type="checkbox"], #commerce-checkout-form-checkout input[type="radio"],
  #commerce-checkout-form-checkout input[type="checkbox"],
  #commerce-checkout-form-review input[type="radio"],
  #commerce-checkout-form-review input[type="checkbox"], .view-commerce-line-item-table input[type="radio"],
  .view-commerce-line-item-table input[type="checkbox"], .view-commerce-user-orders input[type="radio"],
  .view-commerce-user-orders input[type="checkbox"] {
    border: none;
    margin-top: 0;
    margin-left: 0; }
  #views-form-commerce-cart-form-default input[type="text"], #commerce-checkout-form-checkout input[type="text"],
  #commerce-checkout-form-review input[type="text"], .view-commerce-line-item-table input[type="text"], .view-commerce-user-orders input[type="text"] {
    font-size: 14px; }
  #views-form-commerce-cart-form-default input.checkout-continue, #commerce-checkout-form-checkout input.checkout-continue,
  #commerce-checkout-form-review input.checkout-continue, .view-commerce-line-item-table input.checkout-continue, .view-commerce-user-orders input.checkout-continue {
    border: 2px solid #b3cc3c;
    color: #ffffff; }
  #views-form-commerce-cart-form-default .commerce-price-formatted-components tr.odd td, #views-form-commerce-cart-form-default .commerce-price-formatted-components tr.even td, #commerce-checkout-form-checkout .commerce-price-formatted-components tr.odd td, #commerce-checkout-form-checkout .commerce-price-formatted-components tr.even td,
  #commerce-checkout-form-review .commerce-price-formatted-components tr.odd td,
  #commerce-checkout-form-review .commerce-price-formatted-components tr.even td, .view-commerce-line-item-table .commerce-price-formatted-components tr.odd td, .view-commerce-line-item-table .commerce-price-formatted-components tr.even td, .view-commerce-user-orders .commerce-price-formatted-components tr.odd td, .view-commerce-user-orders .commerce-price-formatted-components tr.even td {
    border: none;
    padding: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.045);
    background: #dddddd;
    font-size: 1rem;
    font-weight: 700; }
    #views-form-commerce-cart-form-default .commerce-price-formatted-components tr.odd td.component-title, #views-form-commerce-cart-form-default .commerce-price-formatted-components tr.even td.component-title, #commerce-checkout-form-checkout .commerce-price-formatted-components tr.odd td.component-title, #commerce-checkout-form-checkout .commerce-price-formatted-components tr.even td.component-title,
    #commerce-checkout-form-review .commerce-price-formatted-components tr.odd td.component-title,
    #commerce-checkout-form-review .commerce-price-formatted-components tr.even td.component-title, .view-commerce-line-item-table .commerce-price-formatted-components tr.odd td.component-title, .view-commerce-line-item-table .commerce-price-formatted-components tr.even td.component-title, .view-commerce-user-orders .commerce-price-formatted-components tr.odd td.component-title, .view-commerce-user-orders .commerce-price-formatted-components tr.even td.component-title {
      text-align: left;
      font-weight: 400;
      color: #666666; }
    #views-form-commerce-cart-form-default .commerce-price-formatted-components tr.odd td.component-total, #views-form-commerce-cart-form-default .commerce-price-formatted-components tr.even td.component-total, #commerce-checkout-form-checkout .commerce-price-formatted-components tr.odd td.component-total, #commerce-checkout-form-checkout .commerce-price-formatted-components tr.even td.component-total,
    #commerce-checkout-form-review .commerce-price-formatted-components tr.odd td.component-total,
    #commerce-checkout-form-review .commerce-price-formatted-components tr.even td.component-total, .view-commerce-line-item-table .commerce-price-formatted-components tr.odd td.component-total, .view-commerce-line-item-table .commerce-price-formatted-components tr.even td.component-total, .view-commerce-user-orders .commerce-price-formatted-components tr.odd td.component-total, .view-commerce-user-orders .commerce-price-formatted-components tr.even td.component-total {
      text-align: right;
      color: #666666; }
  #views-form-commerce-cart-form-default a, #commerce-checkout-form-checkout a,
  #commerce-checkout-form-review a, .view-commerce-line-item-table a, .view-commerce-user-orders a {
    color: #9bb528;
    font-weight: 700; }
  #views-form-commerce-cart-form-default input, #commerce-checkout-form-checkout input,
  #commerce-checkout-form-review input, .view-commerce-line-item-table input, .view-commerce-user-orders input {
    border: 1px solid #bcbcbc; }
  #views-form-commerce-cart-form-default .checkout-buttons input:hover, #views-form-commerce-cart-form-default .checkout-buttons input:focus, #commerce-checkout-form-checkout .checkout-buttons input:hover, #commerce-checkout-form-checkout .checkout-buttons input:focus,
  #commerce-checkout-form-review .checkout-buttons input:hover,
  #commerce-checkout-form-review .checkout-buttons input:focus, .view-commerce-line-item-table .checkout-buttons input:hover, .view-commerce-line-item-table .checkout-buttons input:focus, .view-commerce-user-orders .checkout-buttons input:hover, .view-commerce-user-orders .checkout-buttons input:focus {
    background: #b3cc3c;
    border: 2px solid #9bb528; }
  #views-form-commerce-cart-form-default input[type="text"], #commerce-checkout-form-checkout input[type="text"],
  #commerce-checkout-form-review input[type="text"], .view-commerce-line-item-table input[type="text"], .view-commerce-user-orders input[type="text"] {
    padding: 12px;
    overflow: visible; }
  #views-form-commerce-cart-form-default .views-field-commerce-total, #commerce-checkout-form-checkout .views-field-commerce-total,
  #commerce-checkout-form-review .views-field-commerce-total, .view-commerce-line-item-table .views-field-commerce-total, .view-commerce-user-orders .views-field-commerce-total {
    text-align: center; }
  #views-form-commerce-cart-form-default td, #commerce-checkout-form-checkout td,
  #commerce-checkout-form-review td, .view-commerce-line-item-table td, .view-commerce-user-orders td {
    text-align: center; }
    #views-form-commerce-cart-form-default td.views-field-edit-delete .delete-line-item, #commerce-checkout-form-checkout td.views-field-edit-delete .delete-line-item,
    #commerce-checkout-form-review td.views-field-edit-delete .delete-line-item, .view-commerce-line-item-table td.views-field-edit-delete .delete-line-item, .view-commerce-user-orders td.views-field-edit-delete .delete-line-item {
      padding: 13px 12px 11px 12px;
      border: 1px solid #bcbcbc;
      background-color: white;
      color: #2f2f2f;
      font-size: 0.875rem;
      display: inline-block; }
      #views-form-commerce-cart-form-default td.views-field-edit-delete .delete-line-item:hover, #views-form-commerce-cart-form-default td.views-field-edit-delete .delete-line-item:focus, #commerce-checkout-form-checkout td.views-field-edit-delete .delete-line-item:hover, #commerce-checkout-form-checkout td.views-field-edit-delete .delete-line-item:focus,
      #commerce-checkout-form-review td.views-field-edit-delete .delete-line-item:hover,
      #commerce-checkout-form-review td.views-field-edit-delete .delete-line-item:focus, .view-commerce-line-item-table td.views-field-edit-delete .delete-line-item:hover, .view-commerce-line-item-table td.views-field-edit-delete .delete-line-item:focus, .view-commerce-user-orders td.views-field-edit-delete .delete-line-item:hover, .view-commerce-user-orders td.views-field-edit-delete .delete-line-item:focus {
        background-color: #2f2f2f;
        color: white;
        cursor: pointer; }
    #views-form-commerce-cart-form-default td img, #commerce-checkout-form-checkout td img,
    #commerce-checkout-form-review td img, .view-commerce-line-item-table td img, .view-commerce-user-orders td img {
      display: inline; }
  #views-form-commerce-cart-form-default thead th, #views-form-commerce-cart-form-default th, #commerce-checkout-form-checkout thead th, #commerce-checkout-form-checkout th,
  #commerce-checkout-form-review thead th,
  #commerce-checkout-form-review th, .view-commerce-line-item-table thead th, .view-commerce-line-item-table th, .view-commerce-user-orders thead th, .view-commerce-user-orders th {
    background: #9bb528;
    color: #ffffff;
    border: none;
    font-weight: 400;
    vertical-align: middle;
    padding: 6px;
    text-align: center;
    font-size: 0.875rem; }
  #views-form-commerce-cart-form-default tr, #commerce-checkout-form-checkout tr,
  #commerce-checkout-form-review tr, .view-commerce-line-item-table tr, .view-commerce-user-orders tr {
    border: none; }
  #views-form-commerce-cart-form-default tr.even td, #views-form-commerce-cart-form-default tr.odd td, #commerce-checkout-form-checkout tr.even td, #commerce-checkout-form-checkout tr.odd td,
  #commerce-checkout-form-review tr.even td,
  #commerce-checkout-form-review tr.odd td, .view-commerce-line-item-table tr.even td, .view-commerce-line-item-table tr.odd td, .view-commerce-user-orders tr.even td, .view-commerce-user-orders tr.odd td {
    background: white;
    vertical-align: middle;
    border: 1px solid #e1dfdf;
    padding: 10px; }
    #views-form-commerce-cart-form-default tr.even td.views-field-title, #views-form-commerce-cart-form-default tr.odd td.views-field-title, #commerce-checkout-form-checkout tr.even td.views-field-title, #commerce-checkout-form-checkout tr.odd td.views-field-title,
    #commerce-checkout-form-review tr.even td.views-field-title,
    #commerce-checkout-form-review tr.odd td.views-field-title, .view-commerce-line-item-table tr.even td.views-field-title, .view-commerce-line-item-table tr.odd td.views-field-title, .view-commerce-user-orders tr.even td.views-field-title, .view-commerce-user-orders tr.odd td.views-field-title {
      width: 40%; }
    #views-form-commerce-cart-form-default tr.even td.views-field-edit-quantity, #views-form-commerce-cart-form-default tr.odd td.views-field-edit-quantity, #commerce-checkout-form-checkout tr.even td.views-field-edit-quantity, #commerce-checkout-form-checkout tr.odd td.views-field-edit-quantity,
    #commerce-checkout-form-review tr.even td.views-field-edit-quantity,
    #commerce-checkout-form-review tr.odd td.views-field-edit-quantity, .view-commerce-line-item-table tr.even td.views-field-edit-quantity, .view-commerce-line-item-table tr.odd td.views-field-edit-quantity, .view-commerce-user-orders tr.even td.views-field-edit-quantity, .view-commerce-user-orders tr.odd td.views-field-edit-quantity {
      width: 4%; }
      #views-form-commerce-cart-form-default tr.even td.views-field-edit-quantity input, #views-form-commerce-cart-form-default tr.odd td.views-field-edit-quantity input, #commerce-checkout-form-checkout tr.even td.views-field-edit-quantity input, #commerce-checkout-form-checkout tr.odd td.views-field-edit-quantity input,
      #commerce-checkout-form-review tr.even td.views-field-edit-quantity input,
      #commerce-checkout-form-review tr.odd td.views-field-edit-quantity input, .view-commerce-line-item-table tr.even td.views-field-edit-quantity input, .view-commerce-line-item-table tr.odd td.views-field-edit-quantity input, .view-commerce-user-orders tr.even td.views-field-edit-quantity input, .view-commerce-user-orders tr.odd td.views-field-edit-quantity input {
        width: 60px; }
    #views-form-commerce-cart-form-default tr.even td.views-field-field-product-init-price, #views-form-commerce-cart-form-default tr.odd td.views-field-field-product-init-price, #commerce-checkout-form-checkout tr.even td.views-field-field-product-init-price, #commerce-checkout-form-checkout tr.odd td.views-field-field-product-init-price,
    #commerce-checkout-form-review tr.even td.views-field-field-product-init-price,
    #commerce-checkout-form-review tr.odd td.views-field-field-product-init-price, .view-commerce-line-item-table tr.even td.views-field-field-product-init-price, .view-commerce-line-item-table tr.odd td.views-field-field-product-init-price, .view-commerce-user-orders tr.even td.views-field-field-product-init-price, .view-commerce-user-orders tr.odd td.views-field-field-product-init-price {
      text-decoration: line-through;
      color: #c2c2c0; }
  #views-form-commerce-cart-form-default tr.component-type-commerce-price-formatted-amount.even td, #views-form-commerce-cart-form-default tr.component-type-commerce-price-formatted-amount.odd td, #commerce-checkout-form-checkout tr.component-type-commerce-price-formatted-amount.even td, #commerce-checkout-form-checkout tr.component-type-commerce-price-formatted-amount.odd td,
  #commerce-checkout-form-review tr.component-type-commerce-price-formatted-amount.even td,
  #commerce-checkout-form-review tr.component-type-commerce-price-formatted-amount.odd td, .view-commerce-line-item-table tr.component-type-commerce-price-formatted-amount.even td, .view-commerce-line-item-table tr.component-type-commerce-price-formatted-amount.odd td, .view-commerce-user-orders tr.component-type-commerce-price-formatted-amount.even td, .view-commerce-user-orders tr.component-type-commerce-price-formatted-amount.odd td {
    text-align: left; }
  #views-form-commerce-cart-form-default tr.even.views-row-first td, #views-form-commerce-cart-form-default tr.odd.views-row-first td, #commerce-checkout-form-checkout tr.even.views-row-first td, #commerce-checkout-form-checkout tr.odd.views-row-first td,
  #commerce-checkout-form-review tr.even.views-row-first td,
  #commerce-checkout-form-review tr.odd.views-row-first td, .view-commerce-line-item-table tr.even.views-row-first td, .view-commerce-line-item-table tr.odd.views-row-first td, .view-commerce-user-orders tr.even.views-row-first td, .view-commerce-user-orders tr.odd.views-row-first td {
    border-top: none; }
  #views-form-commerce-cart-form-default .commerce-paypal-icons, #commerce-checkout-form-checkout .commerce-paypal-icons,
  #commerce-checkout-form-review .commerce-paypal-icons, .view-commerce-line-item-table .commerce-paypal-icons, .view-commerce-user-orders .commerce-paypal-icons {
    padding-top: 5px; }
    #views-form-commerce-cart-form-default .commerce-paypal-icons img, #commerce-checkout-form-checkout .commerce-paypal-icons img,
    #commerce-checkout-form-review .commerce-paypal-icons img, .view-commerce-line-item-table .commerce-paypal-icons img, .view-commerce-user-orders .commerce-paypal-icons img {
      max-height: 25px;
      width: auto; }
  #views-form-commerce-cart-form-default .line-item-summary, #commerce-checkout-form-checkout .line-item-summary,
  #commerce-checkout-form-review .line-item-summary, .view-commerce-line-item-table .line-item-summary, .view-commerce-user-orders .line-item-summary {
    text-align: right;
    margin-bottom: 10px;
    width: 33%;
    font-weight: 700;
    color: #666666;
    margin-right: 0;
    margin-left: auto;
    padding: 3px 8px;
    background: #dddddd;
    border: none; }
    #views-form-commerce-cart-form-default .line-item-summary .line-item-total-label, #commerce-checkout-form-checkout .line-item-summary .line-item-total-label,
    #commerce-checkout-form-review .line-item-summary .line-item-total-label, .view-commerce-line-item-table .line-item-summary .line-item-total-label, .view-commerce-user-orders .line-item-summary .line-item-total-label {
      font-weight: 400;
      color: #666666;
      margin-right: 20px; }
  #views-form-commerce-cart-form-default tr.odd.component-type-commerce-price-formatted-amount, #views-form-commerce-cart-form-default tr.even.component-type-commerce-price-formatted-amount, #commerce-checkout-form-checkout tr.odd.component-type-commerce-price-formatted-amount, #commerce-checkout-form-checkout tr.even.component-type-commerce-price-formatted-amount,
  #commerce-checkout-form-review tr.odd.component-type-commerce-price-formatted-amount,
  #commerce-checkout-form-review tr.even.component-type-commerce-price-formatted-amount, .view-commerce-line-item-table tr.odd.component-type-commerce-price-formatted-amount, .view-commerce-line-item-table tr.even.component-type-commerce-price-formatted-amount, .view-commerce-user-orders tr.odd.component-type-commerce-price-formatted-amount, .view-commerce-user-orders tr.even.component-type-commerce-price-formatted-amount {
    border-bottom: none; }
  #views-form-commerce-cart-form-default .commerce-line-item-actions, #commerce-checkout-form-checkout .commerce-line-item-actions,
  #commerce-checkout-form-review .commerce-line-item-actions, .view-commerce-line-item-table .commerce-line-item-actions, .view-commerce-user-orders .commerce-line-item-actions {
    margin-top: 20px; }
    #views-form-commerce-cart-form-default .commerce-line-item-actions input, #commerce-checkout-form-checkout .commerce-line-item-actions input,
    #commerce-checkout-form-review .commerce-line-item-actions input, .view-commerce-line-item-table .commerce-line-item-actions input, .view-commerce-user-orders .commerce-line-item-actions input {
      padding: 7px 10px 7px 10px;
      margin-right: 10px; }
      #views-form-commerce-cart-form-default .commerce-line-item-actions input:last-child, #commerce-checkout-form-checkout .commerce-line-item-actions input:last-child,
      #commerce-checkout-form-review .commerce-line-item-actions input:last-child, .view-commerce-line-item-table .commerce-line-item-actions input:last-child, .view-commerce-user-orders .commerce-line-item-actions input:last-child {
        margin-right: 0;
        background: #9bb528;
        color: #ffffff;
        border: 2px solid #b3cc3c; }
        #views-form-commerce-cart-form-default .commerce-line-item-actions input:last-child:hover, #views-form-commerce-cart-form-default .commerce-line-item-actions input:last-child:focus, #commerce-checkout-form-checkout .commerce-line-item-actions input:last-child:hover, #commerce-checkout-form-checkout .commerce-line-item-actions input:last-child:focus,
        #commerce-checkout-form-review .commerce-line-item-actions input:last-child:hover,
        #commerce-checkout-form-review .commerce-line-item-actions input:last-child:focus, .view-commerce-line-item-table .commerce-line-item-actions input:last-child:hover, .view-commerce-line-item-table .commerce-line-item-actions input:last-child:focus, .view-commerce-user-orders .commerce-line-item-actions input:last-child:hover, .view-commerce-user-orders .commerce-line-item-actions input:last-child:focus {
          border: 2px solid #9bb528;
          background: #b3cc3c; }
  #views-form-commerce-cart-form-default legend, #commerce-checkout-form-checkout legend,
  #commerce-checkout-form-review legend, .view-commerce-line-item-table legend, .view-commerce-user-orders legend {
    padding: 4px 10px;
    background: #dddddd;
    font-size: 1.25rem;
    color: #666666; }
    #views-form-commerce-cart-form-default legend span, #commerce-checkout-form-checkout legend span,
    #commerce-checkout-form-review legend span, .view-commerce-line-item-table legend span, .view-commerce-user-orders legend span {
      color: #666666; }
  #views-form-commerce-cart-form-default label, #commerce-checkout-form-checkout label,
  #commerce-checkout-form-review label, .view-commerce-line-item-table label, .view-commerce-user-orders label {
    font-weight: 400;
    line-height: 160%;
    font-style: normal;
    margin-bottom: 0.15rem; }
  #views-form-commerce-cart-form-default .fieldset-legend, #commerce-checkout-form-checkout .fieldset-legend,
  #commerce-checkout-form-review .fieldset-legend, .view-commerce-line-item-table .fieldset-legend, .view-commerce-user-orders .fieldset-legend {
    color: #666666;
    font-weight: 400;
    padding: 0;
    padding-left: 0;
    display: block;
    font-family: "Roboto Condensed", sans-serif; }
  #views-form-commerce-cart-form-default fieldset, #commerce-checkout-form-checkout fieldset,
  #commerce-checkout-form-review fieldset, .view-commerce-line-item-table fieldset, .view-commerce-user-orders fieldset {
    border: none;
    padding: 20px;
    border: 1px solid #e1dfdf;
    margin: 10px 0;
    background: #ffffff; }
  #views-form-commerce-cart-form-default .commerce_shipping input[type="submit"], #commerce-checkout-form-checkout .commerce_shipping input[type="submit"],
  #commerce-checkout-form-review .commerce_shipping input[type="submit"], .view-commerce-line-item-table .commerce_shipping input[type="submit"], .view-commerce-user-orders .commerce_shipping input[type="submit"] {
    margin-top: 20px; }
  #views-form-commerce-cart-form-default input[type="submit"]:focus, #commerce-checkout-form-checkout input[type="submit"]:focus,
  #commerce-checkout-form-review input[type="submit"]:focus, .view-commerce-line-item-table input[type="submit"]:focus, .view-commerce-user-orders input[type="submit"]:focus {
    outline: #000; }
  #views-form-commerce-cart-form-default .description, #commerce-checkout-form-checkout .description,
  #commerce-checkout-form-review .description, .view-commerce-line-item-table .description, .view-commerce-user-orders .description {
    margin-left: 0;
    color: #666666; }
  #views-form-commerce-cart-form-default .form-item-commerce-payment-payment-method, #commerce-checkout-form-checkout .form-item-commerce-payment-payment-method,
  #commerce-checkout-form-review .form-item-commerce-payment-payment-method, .view-commerce-line-item-table .form-item-commerce-payment-payment-method, .view-commerce-user-orders .form-item-commerce-payment-payment-method {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #bcbcbc; }
    #views-form-commerce-cart-form-default .form-item-commerce-payment-payment-method input[type="radio"], #commerce-checkout-form-checkout .form-item-commerce-payment-payment-method input[type="radio"],
    #commerce-checkout-form-review .form-item-commerce-payment-payment-method input[type="radio"], .view-commerce-line-item-table .form-item-commerce-payment-payment-method input[type="radio"], .view-commerce-user-orders .form-item-commerce-payment-payment-method input[type="radio"] {
      margin: 0 5px 0 0; }
    #views-form-commerce-cart-form-default .form-item-commerce-payment-payment-method .md-wrapper, #commerce-checkout-form-checkout .form-item-commerce-payment-payment-method .md-wrapper,
    #commerce-checkout-form-review .form-item-commerce-payment-payment-method .md-wrapper, .view-commerce-line-item-table .form-item-commerce-payment-payment-method .md-wrapper, .view-commerce-user-orders .form-item-commerce-payment-payment-method .md-wrapper {
      margin-top: 12px; }
    #views-form-commerce-cart-form-default .form-item-commerce-payment-payment-method:last-child, #commerce-checkout-form-checkout .form-item-commerce-payment-payment-method:last-child,
    #commerce-checkout-form-review .form-item-commerce-payment-payment-method:last-child, .view-commerce-line-item-table .form-item-commerce-payment-payment-method:last-child, .view-commerce-user-orders .form-item-commerce-payment-payment-method:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0; }
    #views-form-commerce-cart-form-default .form-item-commerce-payment-payment-method .ajax-progress, #commerce-checkout-form-checkout .form-item-commerce-payment-payment-method .ajax-progress,
    #commerce-checkout-form-review .form-item-commerce-payment-payment-method .ajax-progress, .view-commerce-line-item-table .form-item-commerce-payment-payment-method .ajax-progress, .view-commerce-user-orders .form-item-commerce-payment-payment-method .ajax-progress {
      display: none; }
    #views-form-commerce-cart-form-default .form-item-commerce-payment-payment-method .option, #commerce-checkout-form-checkout .form-item-commerce-payment-payment-method .option,
    #commerce-checkout-form-review .form-item-commerce-payment-payment-method .option, .view-commerce-line-item-table .form-item-commerce-payment-payment-method .option, .view-commerce-user-orders .form-item-commerce-payment-payment-method .option {
      padding-bottom: 5px; }
  #views-form-commerce-cart-form-default .accept_terms, #commerce-checkout-form-checkout .accept_terms,
  #commerce-checkout-form-review .accept_terms, .view-commerce-line-item-table .accept_terms, .view-commerce-user-orders .accept_terms {
    margin: 10px 0;
    padding: 20px;
    background: white;
    border: 1px solid #e1dfdf; }
    #views-form-commerce-cart-form-default .accept_terms .form-item, #commerce-checkout-form-checkout .accept_terms .form-item,
    #commerce-checkout-form-review .accept_terms .form-item, .view-commerce-line-item-table .accept_terms .form-item, .view-commerce-user-orders .accept_terms .form-item {
      margin-bottom: 0; }

#views-form-commerce-cart-form-default {
  padding: 20px;
  background: white;
  border: 1px solid #e1dfdf; }
  #views-form-commerce-cart-form-default input[type="text"] {
    text-align: center; }
  #views-form-commerce-cart-form-default .commerce-line-item-actions #edit-submit {
    background: #2f2f2f;
    border: 2px solid #bcbcbc;
    background-color: white;
    color: #2f2f2f;
    padding: 7px 10px 7px 10px; }
    #views-form-commerce-cart-form-default .commerce-line-item-actions #edit-submit:hover, #views-form-commerce-cart-form-default .commerce-line-item-actions #edit-submit:focus {
      background-color: #b3cc3c;
      border: 2px solid #9bb528;
      color: #ffffff;
      cursor: pointer; }

.checkout-buttons .button-operator {
  margin-right: 10px; }

.checkout-buttons .checkout-back, .checkout-buttons .checkout-continue, .checkout-buttons .checkout-cancel {
  background: transparent;
  border: 1px solid #bcbcbc;
  color: #2f2f2f;
  padding: 7px 10px 7px 10px;
  position: relative;
  top: 1px;
  margin-right: 10px; }
  .checkout-buttons .checkout-back:hover, .checkout-buttons .checkout-back:focus, .checkout-buttons .checkout-continue:hover, .checkout-buttons .checkout-continue:focus, .checkout-buttons .checkout-cancel:hover, .checkout-buttons .checkout-cancel:focus {
    background: #2f2f2f;
    color: white;
    cursor: pointer;
    text-decoration: none; }
  .checkout-buttons .checkout-back a, .checkout-buttons .checkout-continue a, .checkout-buttons .checkout-cancel a {
    text-decoration: none;
    color: #2f2f2f; }
    .checkout-buttons .checkout-back a:hover, .checkout-buttons .checkout-back a:focus, .checkout-buttons .checkout-continue a:hover, .checkout-buttons .checkout-continue a:focus, .checkout-buttons .checkout-cancel a:hover, .checkout-buttons .checkout-cancel a:focus {
      text-decoration: none; }

.checkout-buttons .checkout-continue {
  margin-right: 10px;
  background: #9bb528;
  color: white; }
  .checkout-buttons .checkout-continue a {
    color: white; }

#commerce-checkout-form-review table {
  width: 100%; }

#commerce-checkout-form-review tr.odd.pane-title, #commerce-checkout-form-review tr.even.pane-title {
  text-align: left;
  border-bottom: none;
  margin-bottom: 20px; }
  #commerce-checkout-form-review tr.odd.pane-title td, #commerce-checkout-form-review tr.even.pane-title td {
    text-align: left;
    border: none;
    padding: 5px 5px 5px 10px;
    font-weight: 400;
    color: #2f2f2f;
    background: #e1dfdf; }

#commerce-checkout-form-review tr.odd.pane-data td, #commerce-checkout-form-review tr.even.pane-data td {
  text-align: left; }

#commerce-checkout-form-review .checkout-help {
  font-weight: 700;
  font-size: 0.875rem;
  color: #9bb528;
  margin-bottom: 20px; }

#commerce-checkout-form-review .view-commerce-cart-summary {
  padding: 10px; }
  #commerce-checkout-form-review .view-commerce-cart-summary tr.odd td.views-field, #commerce-checkout-form-review .view-commerce-cart-summary tr.even td.views-field {
    text-align: center; }

.md-wrapper ul.accepted-cards-payment {
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0;
  padding-left: 0;
  margin-left: 0;
  list-style: none; }
  .md-wrapper ul.accepted-cards-payment:after {
    content: "";
    display: table;
    clear: both; }
  .md-wrapper ul.accepted-cards-payment li {
    list-style: none;
    float: left;
    margin-right: 10px; }
    .md-wrapper ul.accepted-cards-payment li.payment-nudge {
      position: relative;
      top: -2px; }

.block--gtweaks-alphalogos {
  text-align: center;
  margin: 20px 0 0; }

.accepted-cards-main {
  padding: 10px;
  display: inline-block;
  text-align: center;
  background: rgba(255, 255, 255, 0.35);
  margin-bottom: 5px;
  list-style: none; }
  .accepted-cards-main:after {
    content: "";
    display: table;
    clear: both; }
  .accepted-cards-main li {
    list-style: none;
    float: left;
    margin-right: 10px;
    width: 40px;
    height: 25px; }
    .accepted-cards-main li.verified-by-visa {
      width: 48px; }
    .accepted-cards-main li.discover {
      width: 39px; }
    .accepted-cards-main li.mastercard {
      width: 41px; }
    .accepted-cards-main li.piraeus-bank {
      width: 25px;
      margin-right: 0; }
  .accepted-cards-main li.visa {
    background: url("/sites/all/modules/custom/module_images/visa.jpg") no-repeat; }
  .accepted-cards-main li.mastercard {
    background: url("/sites/all/modules/custom/module_images/mastercard.jpg") no-repeat; }
  .accepted-cards-main li.maestro {
    background: url("/sites/all/modules/custom/module_images/maestro.jpg") no-repeat; }
  .accepted-cards-main li.amex {
    background: url("/sites/all/modules/custom/module_images/amex.jpg") no-repeat; }
  .accepted-cards-main li.diners {
    background: url("/sites/all/modules/custom/module_images/diners.jpg") no-repeat; }
  .accepted-cards-main li.discover {
    background: url("/sites/all/modules/custom/module_images/discover.jpg") no-repeat; }
  .accepted-cards-main li.verified-by-visa {
    background: url("/sites/all/modules/custom/module_images/verified-by-visa.jpg") no-repeat; }
  .accepted-cards-main li.mastercard-secure-code {
    background: url("/sites/all/modules/custom/module_images/mastercard-secure-code.jpg") no-repeat; }
  .accepted-cards-main li.piraeus-bank {
    background: url("/sites/all/modules/custom/module_images/piraeus-bank.jpg") no-repeat; }

#block-commerce-checkout-progress-indication {
  margin: 30px 0; }

#commerce-checkout-form-review .commerce-order-handler-area-order-total .commerce-price-formatted-components {
  width: 33%;
  margin-left: auto; }

.l-main .commerce-checkout-progress.inline {
  padding-left: 0;
  margin-left: 0;
  list-style: none;
  font-size: 0.875rem; }
  .l-main .commerce-checkout-progress.inline li {
    margin-bottom: 0;
    float: left;
    width: auto;
    margin-right: 50px;
    list-style: none;
    font-weight: 400;
    color: #666666; }
    .l-main .commerce-checkout-progress.inline li a {
      color: #666666; }
    .l-main .commerce-checkout-progress.inline li.active {
      color: #9bb528;
      font-weight: 700; }
      .l-main .commerce-checkout-progress.inline li.active a {
        color: #9bb528; }
  .l-main .commerce-checkout-progress.inline em {
    text-align: center;
    display: inline-block;
    font-style: normal;
    padding: 0.2rem 0.15rem;
    border-radius: 50%;
    margin-right: 5px;
    color: #666666;
    font-weight: 400;
    background: #dddddd;
    width: 28px;
    height: 28px; }
  .l-main .commerce-checkout-progress.inline .active em {
    background: #9bb528;
    color: #ffffff; }

#commerce-checkout-form-login {
  line-height: 1.5;
  font-size: 0.875rem;
  margin-bottom: 20px; }
  #commerce-checkout-form-login .non-registered-user-info {
    line-height: 1.4;
    font-size: 1.125rem; }
  #commerce-checkout-form-login ul.eggrafi-list {
    list-style: none;
    margin-left: 0;
    margin-top: 23px;
    margin-bottom: 37px; }
    #commerce-checkout-form-login ul.eggrafi-list li {
      list-style: none; }
      #commerce-checkout-form-login ul.eggrafi-list li:last-child {
        margin-bottom: 0; }
      #commerce-checkout-form-login ul.eggrafi-list li:before {
        content: "";
        display: inline-block;
        position: relative;
        top: -1px;
        vertical-align: middle;
        margin-right: 8px;
        background-image: url(../images/sprites/build/sprites.png);
        background-position: -676px -600px;
        width: 25px;
        height: 25px; }
  #commerce-checkout-form-login .button-operator {
    display: none; }
  #commerce-checkout-form-login fieldset {
    border: none;
    padding: 20px;
    border: 1px solid #bcbcbc;
    margin: 10px 0; }
  #commerce-checkout-form-login input[type="submit"].checkout-cancel {
    background: #2f2f2f;
    border: 2px solid #bcbcbc;
    background-color: white;
    color: #2f2f2f;
    padding: 7px 10px 7px 10px; }
    #commerce-checkout-form-login input[type="submit"].checkout-cancel:hover, #commerce-checkout-form-login input[type="submit"].checkout-cancel:focus {
      background-color: #b3cc3c;
      border: 2px solid #9bb528;
      color: white;
      text-decoration: none; }
  #commerce-checkout-form-login input.checkout-continue {
    color: white;
    background: #9bb528;
    border: none;
    padding: 8px 14px; }
  #commerce-checkout-form-login .nonregistered_pane {
    /*width: 529px;
    float: left;*/
    width: 65.95745%;
    float: right;
    margin-right: 0;
    min-height: 313px; }
    #commerce-checkout-form-login .nonregistered_pane .fieldset-legend {
      padding: 0 6px;
      font-weight: 700;
      color: #666666;
      display: block;
      font-size: 1.125rem; }
    #commerce-checkout-form-login .nonregistered_pane .non-registered-user-info {
      margin-bottom: 20px; }
    #commerce-checkout-form-login .nonregistered_pane #non-registered-user-btn {
      background: none;
      color: #2f2f2f;
      border: 2px solid #bcbcbc;
      height: 20px;
      padding: 310x;
      text-align: center;
      line-height: 30px;
      width: 180px;
      padding: 12px; }
      #commerce-checkout-form-login .nonregistered_pane #non-registered-user-btn:hover, #commerce-checkout-form-login .nonregistered_pane #non-registered-user-btn:focus {
        cursor: pointer;
        color: white;
        text-decoration: none;
        background: #b3cc3c;
        border: 2px solid #9bb528; }
    #commerce-checkout-form-login .nonregistered_pane a.register {
      height: 20px;
      padding: 3px;
      color: white;
      background: #9bb528;
      border: 2px solid #b3cc3c;
      text-align: center;
      line-height: 30px;
      width: 180px;
      padding: 12px;
      margin-left: 10px; }
      #commerce-checkout-form-login .nonregistered_pane a.register:hover, #commerce-checkout-form-login .nonregistered_pane a.register:focus {
        cursor: pointer;
        text-decoration: none;
        background: #b3cc3c;
        border: 2px solid #9bb528; }
  #commerce-checkout-form-login .login_pane {
    width: 31.91489%;
    float: left;
    margin-right: 2.12766%;
    min-height: 260px; }
    #commerce-checkout-form-login .login_pane input.checkout-continue {
      border: 2px solid #b3cc3c;
      background: #9bb528;
      color: #ffffff; }
      #commerce-checkout-form-login .login_pane input.checkout-continue:hover, #commerce-checkout-form-login .login_pane input.checkout-continue:focus {
        border: 2px solid #9bb528;
        background: #b3cc3c; }
    #commerce-checkout-form-login .login_pane a {
      color: #9bb528; }
    #commerce-checkout-form-login .login_pane span.fieldset-legend {
      padding: 0 6px;
      color: #666666;
      font-weight: 400;
      display: block;
      font-size: 1.125rem;
      font-weight: 700; }
    #commerce-checkout-form-login .login_pane ul {
      margin-left: 0;
      padding-left: 0;
      list-style: none;
      margin-bottom: 0; }
      #commerce-checkout-form-login .login_pane ul li {
        list-style: none;
        font-size: 0.875rem;
        margin-bottom: 2px; }
        #commerce-checkout-form-login .login_pane ul li a {
          color: #9bb528; }
    #commerce-checkout-form-login .login_pane .form-item {
      margin-top: 0; }
      #commerce-checkout-form-login .login_pane .form-item label {
        font-weight: 400;
        color: #2f2f2f; }
      #commerce-checkout-form-login .login_pane .form-item input {
        width: 100%;
        padding: 8px;
        font-size: 0.875rem; }
    #commerce-checkout-form-login .login_pane .checkout-continue {
      display: block;
      margin-top: 17px; }
    #commerce-checkout-form-login .login_pane .fieldset-wrapper {
      padding: 10px; }
    #commerce-checkout-form-login .login_pane .fieldset-legend {
      color: #666666;
      font-weight: 400;
      display: block;
      font-size: 1.125rem; }
  #commerce-checkout-form-login .checkout-buttons {
    clear: both;
    border: none;
    padding: 0;
    margin: 0; }
    #commerce-checkout-form-login .checkout-buttons .checkout-continue {
      display: none; }

.form-item-commerce-payment-payment-method ul {
  margin-bottom: 2px; }

.request-new-pass-pane {
  font-size: 0.875rem; }

.page-checkout-complete .l-content {
  padding: 20px 0; }

.checkout-completion-message .order-completed-succesfully {
  border: 1px #9bb528;
  padding: 15px;
  text-align: center;
  font-size: 1.125rem;
  margin-bottom: 20px;
  border: 1px solid #9bb528;
  color: #9bb528;
  position: relative; }

.checkout-completion-message .oc-msg-icon {
  content: "";
  display: block;
  background-image: url(../images/sprites/build/sprites.png);
  background-position: -676px -600px;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -12px; }

.checkout-completion-message .order-num-success {
  /* font-size: $small;*/ }

.field-name-field-order-comments label {
  font-size: 0.875rem; }

.field-name-field-order-comments .form-textarea-wrapper textarea {
  min-height: 150px;
  border: 1px solid #bcbcbc; }

.user-login {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 0; }
  .user-login:after {
    content: "";
    display: table;
    clear: both; }
  .user-login:after {
    content: "";
    display: table;
    clear: both; }
  .user-login .form-item label {
    margin-bottom: 2px; }
  .user-login h1 {
    border-bottom: none;
    line-height: 1.1;
    font-size: 2.25rem;
    margin-bottom: 20px; }
  .user-login .form-item label {
    font-weight: 400; }
  .user-login h2.cs-legend {
    color: #666666;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    display: block;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 6px; }
  .user-login #user_login_form {
    width: 48.93617%;
    float: left;
    margin-right: 2.12766%;
    border-right: 1px solid #bcbcbc;
    padding-right: 15px; }
    .user-login #user_login_form input[type="text"],
    .user-login #user_login_form input[type="password"] {
      padding: 12px;
      overflow: visible;
      border: 1px solid #bcbcbc;
      font-size: 1rem;
      width: 70%; }
    .user-login #user_login_form label {
      margin-bottom: 3px; }
    .user-login #user_login_form .login-password {
      color: #9bb528;
      margin-bottom: 20px; }
    .user-login #user_login_form .form-actions {
      margin-top: 10px; }
    .user-login #user_login_form input[type="submit"] {
      background: #9bb528;
      color: #ffffff;
      border: none;
      padding: 8px 18px;
      font-size: 1rem;
      font-family: "Roboto", sans-serif;
      cursor: pointer;
      border: 2px solid #b3cc3c; }
      .user-login #user_login_form input[type="submit"]:hover, .user-login #user_login_form input[type="submit"]:focus {
        background: #b3cc3c;
        border: 2px solid #b3cc3c; }
  .user-login .create-account {
    width: 48.93617%;
    float: right;
    margin-right: 0;
    text-align: center;
    position: relative;
    height: 400px; }
    .user-login .create-account .content-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .user-login .create-account .login-register {
      padding: 12px 30px;
      display: block;
      line-height: 40px;
      vertical-align: middle;
      color: white;
      background: #9bb528;
      font-size: 1.375rem;
      font-weight: 700;
      min-width: 420px;
      border: 2px solid #b3cc3c; }
      .user-login .create-account .login-register:hover, .user-login .create-account .login-register:focus {
        background: #b3cc3c;
        border-color: #9bb528;
        text-decoration: none; }

.user-login > div:after {
  content: "";
  display: table;
  clear: both; }

.user-register-form,
.user-profile-form {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 0; }
  .user-register-form:after,
  .user-profile-form:after {
    content: "";
    display: table;
    clear: both; }
  .user-register-form input[type="submit"],
  .user-profile-form input[type="submit"] {
    background-color: #9bb528;
    border: 2px solid #b3cc3c;
    color: white;
    padding: 8px 14px;
    font-family: "Roboto", sans-serif;
    font-size: 1rem; }
    .user-register-form input[type="submit"]:hover, .user-register-form input[type="submit"]:focus,
    .user-profile-form input[type="submit"]:hover,
    .user-profile-form input[type="submit"]:focus {
      background-color: #9bb528;
      background: #b3cc3c;
      cursor: pointer; }
  .user-register-form h1,
  .user-profile-form h1 {
    line-height: 1.1;
    font-size: 2.25rem;
    margin-bottom: 20px; }

.user-profile-form {
  padding: 0; }

#user-register-form .form-item {
  width: 50%; }
  #user-register-form .form-item input {
    width: 100%; }

#user_register_form .form-item label,
#user-profile-form .form-item label {
  font-weight: 400;
  margin-bottom: 3px; }

#user_register_form .password-confirm, #user_register_form .password-strength,
#user-profile-form .password-confirm,
#user-profile-form .password-strength {
  float: none; }

#user_register_form input[type="text"],
#user_register_form input[type="password"],
#user-profile-form input[type="text"],
#user-profile-form input[type="password"] {
  padding: 12px;
  overflow: visible;
  border: 1px solid #bcbcbc;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  max-width: 100%; }

#user_register_form .form-type-password-confirm,
#user-profile-form .form-type-password-confirm {
  width: 440px; }
  #user_register_form .form-type-password-confirm .form-item,
  #user-profile-form .form-type-password-confirm .form-item {
    width: 74%; }

.form-actions .back-to-login {
  margin-top: 20px; }

#user_pass_form .form-item label {
  font-weight: 400; }

.field--name-field-commerce-product #edit-submit {
  display: block; }

.view-commerce-user-orders td.views-field-order-number {
  font-weight: 400; }
  .view-commerce-user-orders td.views-field-order-number a {
    color: #9bb528;
    font-weight: 700; }
    .view-commerce-user-orders td.views-field-order-number a:hover, .view-commerce-user-orders td.views-field-order-number a:focus {
      text-decoration: underline; }

.view-commerce-user-orders td.views-field-nothing a {
  color: #9bb528; }
  .view-commerce-user-orders td.views-field-nothing a:hover, .view-commerce-user-orders td.views-field-nothing a:focus {
    text-decoration: underline; }

.view-commerce-user-orders table tr td {
  background: white; }
  .view-commerce-user-orders table tr td.active {
    background: white; }

.view-commerce-user-orders table th {
  color: #ffffff; }
  .view-commerce-user-orders table th a {
    font-weight: 400;
    font-size: 0.875rem;
    color: #ffffff; }

.page-user-orders .commerce-order-commerce-order .field--name-commerce-order-total {
  text-align: right;
  margin-bottom: 30px;
  width: 440px;
  margin-right: 0;
  margin-left: auto;
  background: #dddddd;
  color: #666666;
  font-size: 0.875rem;
  font-weight: 400;
  border: none; }
  .page-user-orders .commerce-order-commerce-order .field--name-commerce-order-total .component-title {
    font-weight: 400; }
  .page-user-orders .commerce-order-commerce-order .field--name-commerce-order-total tr.even td, .page-user-orders .commerce-order-commerce-order .field--name-commerce-order-total tr.odd td {
    color: #666666;
    background: #dddddd;
    padding: 3px 8px; }
    .page-user-orders .commerce-order-commerce-order .field--name-commerce-order-total tr.even td.component-total, .page-user-orders .commerce-order-commerce-order .field--name-commerce-order-total tr.odd td.component-total {
      color: #666666; }

.page-user-orders .commerce-order-commerce-order .field--name-commerce-customer-billing, .page-user-orders .commerce-order-commerce-order .field--name-commerce-customer-shipping, .page-user-orders .commerce-order-commerce-order .field--name-field-order-comments {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e1dfdf; }
  .page-user-orders .commerce-order-commerce-order .field--name-commerce-customer-billing > .field__label, .page-user-orders .commerce-order-commerce-order .field--name-commerce-customer-shipping > .field__label, .page-user-orders .commerce-order-commerce-order .field--name-field-order-comments > .field__label {
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 10px; }

/*
.view-commerce-cart-block {
  position: absolute;
  top: 0;
  right: 110px;
  width: 350px;
  z-index: 900;
  @include clearfix;

  .total-item {
    float: left;
    margin-right: 5px;
  }

  .total-wrapper, .shopping-cart-is-empty {
    height: 26px;
    border-bottom: 1px solid $cu;
    border-right: 1px solid $cu;
    border-left: 1px solid $cu;
    padding: 5px;
    float: left;
    margin-left: 5px;
    color: $callus-black;
    padding-left: 25px;
    background: image-url('cart_small_gray.png') no-repeat 5px center;
  }

  .total-checkout {
    padding: 5px 8px 5px 26px;
    height: 26px;
    color: white;
    background: image-url('tick_small.png') 5px center no-repeat $red;
    a {
      color: white;
    }
  }

  .views-table {
    tr.even, tr.odd {
      td {
        background: white;
      }
    }
  }
}
*/
.view-shopping-cart-block-custom {
  float: right;
  margin-left: 2.12766%; }
  .view-shopping-cart-block-custom:after {
    content: "";
    display: table;
    clear: both; }
  .view-shopping-cart-block-custom .cs-shopping-cart-icon {
    display: block;
    float: left;
    position: relative;
    top: 1px;
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -441px -239px;
    width: 30px;
    height: 30px; }
  .view-shopping-cart-block-custom .total-wrapper-custom {
    height: 30px;
    float: left;
    color: #ffffff;
    font-size: 1rem;
    /*    background: url('../images/large_cart.png') no-repeat 12px center;*/ }
    .view-shopping-cart-block-custom .total-wrapper-custom a {
      color: #ffffff; }
  .view-shopping-cart-block-custom .total-item {
    float: left; }
  .view-shopping-cart-block-custom .total-items-wrapper {
    margin-left: 6px;
    float: left;
    display: block;
    padding: 3px 8px;
    background: #b3cc3c;
    color: #ffffff;
    border-radius: 3px; }
  .view-shopping-cart-block-custom .total-line-items-cart {
    margin-right: 4px;
    font-weight: 700; }

.roll-weight-length {
  margin-top: 4px;
  font-size: 0.875rem;
  color: #666666; }

#commerce-checkout-form-checkout .selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  background: #9bb528;
  color: #ffffff; }

.view-commerce-line-item-table .view-content .views-table a {
  color: #9bb528; }

.commerce-price-formatted-components .component-total {
  color: #b3cc3c; }

#commerce-checkout-form-checkout input.checkout-back {
  border: 2px solid #bcbcbc; }

body .selectboxit-container .selectboxit {
  border: 1px solid #bcbcbc; }

body .selectboxit-container .selectboxit-options {
  border: 1px solid #bcbcbc;
  border-top: none; }
  body .selectboxit-container .selectboxit-options li {
    border-bottom: 1px solid #e1dfdf;
    margin-bottom: 0; }

.logged-in.page-user .l-content {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 0; }
  .logged-in.page-user .l-content:after {
    content: "";
    display: table;
    clear: both; }
  .logged-in.page-user .l-content h1 {
    font-size: 2.25rem;
    margin-bottom: 20px; }
  .logged-in.page-user .l-content h2 {
    font-size: 1.875rem;
    border-bottom: 2px solid #9bb528;
    margin-bottom: 20px;
    padding-bottom: 5px; }

fieldset {
  /*  &.form-wrapper {
    @include span(6);
    margin-bottom: gutters();

    &:nth-child(2n) {
      @include span(6 last);
    }

    &:nth-child(2n+1) {
      @include span(6 break);
    }
  }*/ }

.page-checkout {
  /*  .customer_profile_shipping,
  .customer_profile_billing {
    min-height: 428px;
  }*/ }
  .page-checkout .customer_profile_billing .group-invoice:after {
    content: "";
    display: table;
    clear: both; }
  .page-checkout .customer_profile_billing .group-invoice .form-wrapper {
    width: 48.93617%;
    float: left;
    margin-right: 2.12766%;
    margin-bottom: 2.12766%; }
    .page-checkout .customer_profile_billing .group-invoice .form-wrapper .form-item {
      margin-bottom: 0; }
    .page-checkout .customer_profile_billing .group-invoice .form-wrapper input {
      width: 100%; }
    .page-checkout .customer_profile_billing .group-invoice .form-wrapper:nth-child(2n) {
      width: 48.93617%;
      float: right;
      margin-right: 0; }
    .page-checkout .customer_profile_billing .group-invoice .form-wrapper:nth-child(2n+1) {
      clear: both;
      width: 48.93617%;
      float: left;
      margin-right: 2.12766%; }
  .page-checkout .customer_profile_billing .field-name-commerce-customer-address .form-item {
    margin-right: 0;
    margin-bottom: 0;
    float: none; }
  .page-checkout .customer_profile_billing .field-name-commerce-customer-address input {
    width: 100%; }
  .page-checkout .customer_profile_billing .field-name-commerce-customer-address div.name-block {
    width: 48.93617%;
    float: left;
    margin-right: 2.12766%;
    margin-bottom: 2.12766%; }
  .page-checkout .customer_profile_billing .field-name-commerce-customer-address div.street-block {
    width: 48.93617%;
    float: right;
    margin-right: 0; }
  .page-checkout .customer_profile_billing .field-name-commerce-customer-address div.locality-block {
    clear: both;
    float: none; }
    .page-checkout .customer_profile_billing .field-name-commerce-customer-address div.locality-block .form-item {
      width: 48.93617%;
      float: left;
      margin-right: 2.12766%; }
      .page-checkout .customer_profile_billing .field-name-commerce-customer-address div.locality-block .form-item:nth-child(2n) {
        width: 48.93617%;
        float: right;
        margin-right: 0; }
  .page-checkout .customer_profile_shipping div.field-name-field-customer-phone .form-item {
    width: 48.93617%;
    float: left;
    margin-right: 2.12766%;
    margin-top: 2.12766%; }
    .page-checkout .customer_profile_shipping div.field-name-field-customer-phone .form-item input {
      width: 100%; }
  .page-checkout .customer_profile_shipping .field-name-commerce-customer-address input {
    width: 100%; }
  .page-checkout .customer_profile_shipping .field-name-commerce-customer-address .form-item {
    margin-right: 0;
    margin-bottom: 0;
    float: none; }
  .page-checkout .customer_profile_shipping .field-name-commerce-customer-address div.name-block {
    width: 48.93617%;
    float: left;
    margin-right: 2.12766%;
    margin-bottom: 2.12766%; }
  .page-checkout .customer_profile_shipping .field-name-commerce-customer-address div.street-block {
    width: 48.93617%;
    float: right;
    margin-right: 0; }
  .page-checkout .customer_profile_shipping .field-name-commerce-customer-address div.locality-block {
    clear: both;
    float: none; }
    .page-checkout .customer_profile_shipping .field-name-commerce-customer-address div.locality-block .form-item {
      width: 48.93617%;
      float: left;
      margin-right: 2.12766%; }
      .page-checkout .customer_profile_shipping .field-name-commerce-customer-address div.locality-block .form-item:nth-child(2n) {
        width: 48.93617%;
        float: right;
        margin-right: 0; }
  .page-checkout .commerce_fieldgroup_pane__group_comments {
    width: 48.93617%;
    float: right;
    margin-right: 0;
    margin-bottom: 2.12766%; }
  .page-checkout .commerce_payment {
    width: 48.93617%;
    float: left;
    margin-right: 2.12766%; }
  .page-checkout .accept_terms {
    clear: both;
    width: 48.93617%;
    float: left;
    margin-right: 2.12766%;
    margin-bottom: 2.12766%; }
  .page-checkout .commerce_shipping {
    clear: both;
    width: 48.93617%;
    float: right;
    margin-right: 0;
    margin-bottom: 2.12766%; }
  .page-checkout .checkout-buttons {
    clear: both; }
  .page-checkout .commerce_shipping,
  .page-checkout .commerce_payment {
    min-height: 370px; }
    .page-checkout .commerce_shipping .description,
    .page-checkout .commerce_payment .description {
      line-height: 1.6; }
  .page-checkout .commerce_fieldgroup_pane__group_comments {
    min-height: 262px; }
  .page-checkout #commerce-checkout-form-checkout .accept_terms {
    min-height: 244px;
    text-align: center;
    position: relative;
    top: 18px; }
    .page-checkout #commerce-checkout-form-checkout .accept_terms .form-item-accept-terms-terms-of-use {
      transform: translateY(-50%);
      top: 50%;
      left: 0;
      width: 100%;
      padding: 0 20px;
      position: absolute; }

#views-form-commerce-cart-form-default .checkout-back-cs {
  background: transparent;
  border: 2px solid #bcbcbc;
  padding: 5px 10px;
  position: relative;
  top: 1px;
  margin-right: 10px;
  margin-bottom: 15px;
  display: inline-block; }
  #views-form-commerce-cart-form-default .checkout-back-cs:hover, #views-form-commerce-cart-form-default .checkout-back-cs:focus {
    background-color: #b3cc3c;
    border: 2px solid #9bb528;
    cursor: pointer;
    text-decoration: none; }
    #views-form-commerce-cart-form-default .checkout-back-cs:hover a, #views-form-commerce-cart-form-default .checkout-back-cs:focus a {
      color: #ffffff; }
  #views-form-commerce-cart-form-default .checkout-back-cs a {
    font-weight: 400;
    text-decoration: none;
    color: #2f2f2f; }
    #views-form-commerce-cart-form-default .checkout-back-cs a:hover, #views-form-commerce-cart-form-default .checkout-back-cs a:focus {
      text-decoration: none; }

.l-main ul {
  list-style: disc;
  padding-left: 1.5rem;
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
  overflow: hidden; }
  .l-main ul li {
    margin-bottom: 0.8rem; }

.l-main ol {
  list-style: decimal;
  padding-left: 1.5rem;
  margin-left: 1.5rem;
  margin-bottom: 1.5rem; }
  .l-main ol li {
    margin-bottom: 0.8rem; }

.l-main .node--page--full .field--name-body h3 {
  font-size: 1.5rem;
  margin-bottom: 10px; }

.l-main .node--page--full .field--name-body h4 {
  font-weight: 700; }

.l-breadcrumb {
  background: #dddddd;
  padding: 8px 35px;
  color: #666666; }
  .l-breadcrumb ul.breadcrumb {
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
    list-style: none; }
  .l-breadcrumb a:link, .l-breadcrumb a:visited, .l-breadcrumb a:active {
    text-decoration: none;
    color: #666666; }
  .l-breadcrumb a:hover, .l-breadcrumb a:focus {
    color: #666666;
    text-decoration: underline; }

#user-register-form .field-name-field-account-newsletter,
#user-profile-form .field-name-field-account-newsletter {
  color: #000; }
  #user-register-form .field-name-field-account-newsletter label,
  #user-profile-form .field-name-field-account-newsletter label {
    font-weight: 700; }
  #user-register-form .field-name-field-account-newsletter input[type="checkbox"],
  #user-profile-form .field-name-field-account-newsletter input[type="checkbox"] {
    position: relative;
    top: -2px;
    width: auto;
    margin-left: 0; }

.messages-regi {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 0; }
  .messages-regi:after {
    content: "";
    display: table;
    clear: both; }

.ptitle--large {
  color: #000;
  font-size: 3rem;
  line-height: 1.1; }

.ptitle--footer {
  color: #ffffff;
  margin-bottom: 40px;
  font-size: 1.5rem; }

.ptitle--coloured {
  color: white;
  font-size: 2.25rem;
  line-height: 1.2;
  padding: 0 20px; }

.ptitle--coloured-large {
  color: white;
  font-size: 2.5rem;
  line-height: 1.2; }

.ptitle--coloured-huge {
  color: white;
  font-size: 3rem;
  line-height: 1.2; }

.ptitle--medium {
  font-size: 2.25rem;
  line-height: 1.2; }

p {
  margin-bottom: 1rem; }

.l-page ul.key-points-list {
  padding-left: 0;
  margin-left: 0;
  overflow: visible;
  list-style: none;
  list-style-type: none; }

.l-page .key-points-list li {
  margin-bottom: 25px;
  position: relative;
  list-style: none;
  list-style-type: none; }
  .l-page .key-points-list li:last-child {
    margin-bottom: 0; }

.l-page .key-points-list__title {
  font-size: 1.5rem;
  color: #010101;
  margin-bottom: 15px;
  padding-left: 45px; }
  .l-page .key-points-list__title:before {
    content: "";
    display: block;
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -676px -600px;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0;
    top: 0.25em; }

.l-page .key-points-list__text {
  color: #666666;
  font-size: 1.125rem; }

.google-maps-bubble-txt {
  text-align: center; }
  .google-maps-bubble-txt .image {
    margin-bottom: 3px; }

.pvideo {
  position: relative;
  transition: box-shadow 0.3s; }
  .pvideo iframe {
    line-height: 0;
    display: block; }
  .pvideo img {
    display: block; }
  .pvideo:hover, .pvideo:focus {
    cursor: pointer;
    box-shadow: 3px 6px 12px -2px rgba(0, 0, 0, 0.8); }
  .pvideo__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -35px;
    margin-left: -35px;
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -634px -142px;
    width: 71px;
    height: 72px; }

.read-more {
  padding: 8px 18px;
  color: white;
  font-size: 1.125rem;
  background: #9bb528;
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 20px; }
  .read-more:hover, .read-more:focus {
    background: #b3cc3c; }

.zoom-wrapper-outer {
  /*  .zoom-wrapper-outer {
    position: relative;
  }*/ }
  .zoom-wrapper-outer .zoom-wrapper-inner {
    margin-bottom: 30px;
    overflow: hidden;
    height: 315px; }
    .zoom-wrapper-outer .zoom-wrapper-inner img {
      display: block;
      transition: all 0.2s ease-in-out; }
      .zoom-wrapper-outer .zoom-wrapper-inner img:hover {
        transform: scale(1.2); }

.small-box .image-title-box {
  position: relative; }

.small-box__image {
  margin-bottom: 30px;
  overflow: hidden;
  height: 315px; }
  .small-box__image img {
    display: block; }

.small-box__text {
  padding: 0 28px; }

.small-box__title {
  font-size: 1.375rem;
  line-height: 1.2; }

.small-box--absolute .small-box__image img {
  transition: all 0.2s ease-in-out; }
  .small-box--absolute .small-box__image img:hover {
    transform: scale(1.28); }

.small-box--absolute .table-wrap {
  display: table-cell;
  height: 52px;
  vertical-align: middle; }

.small-box--absolute .small-box__title {
  display: table;
  width: 100%;
  position: absolute;
  bottom: 18px;
  left: 0;
  right: 0;
  color: white;
  font-size: 1.375rem;
  line-height: 1.2; }

.cta {
  padding: 18px 22px;
  background-image: url(../images/sprites/build/sprites.png);
  background-position: -239px -395px;
  width: 209px;
  height: 66px;
  display: inline-block; }
  .cta__text {
    vertical-align: middle;
    display: inline-block;
    color: white;
    font-weight: 700;
    font-size: 1.125rem;
    padding-left: 18px; }
  .cta__icon {
    vertical-align: middle;
    display: inline-block;
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -634px -600px;
    width: 40px;
    height: 29px; }
  .cta:hover, .cta:focus {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -145px -634px;
    width: 209px;
    height: 66px; }

.block--multiblock-1 .mailchimp-signup-subscribe-form,
.block--mailchimp-signup .mailchimp-signup-subscribe-form,
.pane-mailchimp-signup-thepromoticket-newsletter .mailchimp-signup-subscribe-form {
  position: relative; }

.block--multiblock-1 .mailchimp-signup-subscribe-form-description,
.block--mailchimp-signup .mailchimp-signup-subscribe-form-description,
.pane-mailchimp-signup-thepromoticket-newsletter .mailchimp-signup-subscribe-form-description {
  color: #666666;
  margin: 25px 0 20px;
  line-height: 1.35; }

.block--multiblock-1 .form-required,
.block--mailchimp-signup .form-required,
.pane-mailchimp-signup-thepromoticket-newsletter .form-required {
  display: none; }

.block--multiblock-1 input[type="text"],
.block--mailchimp-signup input[type="text"],
.pane-mailchimp-signup-thepromoticket-newsletter input[type="text"] {
  width: 100%;
  color: #333333;
  padding: 14px 15px;
  border-radius: 18px;
  overflow: visible;
  font-size: 0.875rem;
  font-weight: 700;
  border: none;
  font-family: "Roboto", sans-serif; }
  .block--multiblock-1 input[type="text"]::placeholder,
  .block--mailchimp-signup input[type="text"]::placeholder,
  .pane-mailchimp-signup-thepromoticket-newsletter input[type="text"]::placeholder {
    color: #333333; }

.block--multiblock-1 input[type="submit"],
.block--mailchimp-signup input[type="submit"],
.pane-mailchimp-signup-thepromoticket-newsletter input[type="submit"] {
  position: absolute;
  right: 6px;
  bottom: 0;
  border: none;
  text-indent: -9999px;
  overflow: hidden;
  width: 56px;
  height: 46px;
  background: url("../images/envelope-nl.png") center center no-repeat; }
  .block--multiblock-1 input[type="submit"]:hover, .block--multiblock-1 input[type="submit"]:focus,
  .block--mailchimp-signup input[type="submit"]:hover,
  .block--mailchimp-signup input[type="submit"]:focus,
  .pane-mailchimp-signup-thepromoticket-newsletter input[type="submit"]:hover,
  .pane-mailchimp-signup-thepromoticket-newsletter input[type="submit"]:focus {
    cursor: pointer; }

.block--multiblock-1 .block__title,
.block--mailchimp-signup .block__title,
.pane-mailchimp-signup-thepromoticket-newsletter .block__title {
  font-size: 1.25rem;
  color: white; }

.block--multiblock-1 .form-item-mail,
.block--mailchimp-signup .form-item-mail,
.pane-mailchimp-signup-thepromoticket-newsletter .form-item-mail {
  margin-bottom: 0; }
  .block--multiblock-1 .form-item-mail .form-required,
  .block--mailchimp-signup .form-item-mail .form-required,
  .pane-mailchimp-signup-thepromoticket-newsletter .form-item-mail .form-required {
    display: none; }

.block--multiblock-1 .mailchimp-signup-subscribe-form-description {
  margin: 10px 0 20px; }

*:focus, *:hover {
  outline: none; }

.taxonomy-term--carousel:focus, .taxonomy-term--carousel:hover {
  outline: none;
  cursor: pointer; }

.taxonomy-term--carousel .taxonomy-term__content {
  margin: 0 16px;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
  padding-bottom: 14px;
  transition: transform 0.3s ease-in-out;
  background: #ffffff; }

.taxonomy-term--carousel .field--name-field-artc-icon {
  position: absolute;
  right: 15px;
  bottom: -24px; }

.taxonomy-term--carousel .group-image-icon {
  position: relative; }

.taxonomy-term--carousel .field--name-field-image img {
  display: block; }

.taxonomy-term--carousel .field--name-name-field {
  line-height: 1.2;
  padding: 24px;
  font-size: 1.125rem;
  transition: color 0.2s; }
  .taxonomy-term--carousel .field--name-name-field a {
    color: #000; }
    .taxonomy-term--carousel .field--name-name-field a:hover, .taxonomy-term--carousel .field--name-name-field a:focus {
      color: #9bb528; }

.taxonomy-term--carousel .field--name-name-field {
  color: #000; }

.taxonomy-term--carousel.slick-center {
  /*    .field--type-image {
        img {
          transition: transform 0.2s ease-in-out;

          &:hover {
            transform: scale(1.18);
          }
        }
      }*/ }
  .taxonomy-term--carousel.slick-center .field--name-name-field {
    font-weight: 700;
    font-size: 1.25rem;
    color: #9bb528; }
  .taxonomy-term--carousel.slick-center .taxonomy-term__content {
    transform: scale(1.15); }

.block--locale {
  right: 36px;
  position: absolute;
  width: 6.38298%;
  float: right;
  margin-right: 0;
  margin-top: 16px;
  top: 112px; }
  .block--locale:after {
    content: "";
    display: table;
    clear: both; }
  .block--locale .language-switcher-locale-url {
    float: right; }
    .block--locale .language-switcher-locale-url li {
      float: left; }
      .block--locale .language-switcher-locale-url li a {
        color: #999999; }
        .block--locale .language-switcher-locale-url li a.active {
          color: #010101; }
      .block--locale .language-switcher-locale-url li:after {
        content: "|";
        margin: 0 3px;
        color: #9bb528; }
      .block--locale .language-switcher-locale-url li:last-child {
        margin-right: 0; }
        .block--locale .language-switcher-locale-url li:last-child:after {
          display: none; }

.rounded-arrow-nav {
  background-image: url(../images/sprites/build/sprites.png);
  background-position: -695px -471px;
  width: 59px;
  height: 59px;
  position: absolute;
  z-index: 99;
  bottom: -35px;
  left: 50%;
  margin-left: -30px; }
  .rounded-arrow-nav:hover, .rounded-arrow-nav:focus {
    cursor: pointer; }

.field--name-field-artc-icon .field__item {
  background: #c2c2c0;
  border-radius: 50%;
  display: block;
  position: relative; }
  .field--name-field-artc-icon .field__item img {
    transform: translate(-50%, -50%);
    top: 50%;
    position: absolute;
    left: 50%; }

.site-logo img {
  display: block; }

.choose-arrow {
  text-align: center;
  color: #ffffff;
  font-size: 1.5rem;
  position: relative;
  bottom: -25px; }

.taxonomy-term--artc .artc-active-wrap.active .field--name-field-artc-icon .field__item, .taxonomy-term--art-dia .artc-active-wrap.active .field--name-field-artc-icon .field__item {
  background: #b3cc3c; }

.taxonomy-term--artc .artc-active-wrap.active .field--name-name-field, .taxonomy-term--art-dia .artc-active-wrap.active .field--name-name-field {
  color: #b3cc3c; }

.taxonomy-term--minimal .field--name-name-field {
  color: #010101; }

.taxonomy-term--minimal .field--name-field-artc-icon .field__item {
  width: 144px;
  height: 144px;
  margin: 0 auto; }

.taxonomy-term--minimal:hover .field--name-field-artc-icon .field__item, .taxonomy-term--minimal:focus .field--name-field-artc-icon .field__item {
  background: #b3cc3c; }

.taxonomy-term--minimal:hover .field--name-name-field, .taxonomy-term--minimal:focus .field--name-name-field {
  color: #b3cc3c; }

.taxonomy-term--view .field--name-name-field {
  color: #010101; }

.taxonomy-term--view .field--name-field-artc-icon .field__item {
  width: 64px;
  height: 64px; }

.taxonomy-term--carousel {
  /*    &:hover, &:focus {
      .field {
        &--name-field-artc-icon {
          .field__item {
            background: $green;
          }
        }
      }
    }*/ }
  .taxonomy-term--carousel .green-on {
    background: #b3cc3c; }
  .taxonomy-term--carousel .field--name-field-artc-icon .field__item {
    width: 50px;
    height: 50px; }

.view-taxonomy-views .taxonomy-term--view:hover .field--name-field-artc-icon .field__item, .view-taxonomy-views .taxonomy-term--view:focus .field--name-field-artc-icon .field__item {
  background: #b3cc3c; }

.view-taxonomy-views .taxonomy-term--view:hover .field--name-name-field, .view-taxonomy-views .taxonomy-term--view:focus .field--name-name-field {
  color: #b3cc3c; }

.page-sitemap .l-content {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 0; }
  .page-sitemap .l-content:after {
    content: "";
    display: table;
    clear: both; }
  .page-sitemap .l-content h1 {
    color: #000;
    font-size: 3rem;
    line-height: 1.1;
    margin-bottom: 25px; }
  .page-sitemap .l-content .site-map-box-menu ul {
    list-style-type: none; }
    .page-sitemap .l-content .site-map-box-menu ul li {
      font-size: 1.25rem; }
      .page-sitemap .l-content .site-map-box-menu ul li a {
        color: #000;
        margin-bottom: 10px;
        display: inline-block; }
        .page-sitemap .l-content .site-map-box-menu ul li a:hover, .page-sitemap .l-content .site-map-box-menu ul li a:focus {
          color: #9bb528; }
      .page-sitemap .l-content .site-map-box-menu ul li ul li {
        font-size: 1rem;
        list-style-type: disc; }
        .page-sitemap .l-content .site-map-box-menu ul li ul li a {
          margin-bottom: 0; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px; }

.iradio_minimal-green {
  background-image: url(../images/sprites/build/sprites.png);
  background-position: -703px -600px;
  width: 22px;
  height: 22px; }
  .iradio_minimal-green.hover {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -703px -600px;
    width: 22px;
    height: 22px; }
  .iradio_minimal-green.checked {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -727px -600px;
    width: 22px;
    height: 22px; }
    .iradio_minimal-green.checked.hover {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -727px -600px;
      width: 22px;
      height: 22px; }

#webform_drawer .messages {
  padding: 5px 10px;
  border: 1px solid #999999; }
  #webform_drawer .messages ul {
    margin: 0; }
    #webform_drawer .messages ul li {
      color: #555555;
      font-size: 0.875rem; }

.captcha {
  margin-bottom: 10px;
  text-align: center; }
  .captcha .g-recaptcha {
    display: inline-block; }

.l-main .messages ul {
  margin-bottom: 0; }

input[type="submit"]:hover, input[type="submit"]:focus {
  cursor: pointer; }

.green-text {
  color: #9BB528; }

.block--commerce-overrides-shopping-cart-custom {
  display: inline-block;
  margin-right: 2%;
  vertical-align: middle;
  text-align: left;
  min-width: 164px; }

.about-mak-msg:before {
  content: "";
  display: table;
  clear: both; }

.about-mak-msg .about-mak-content {
  padding: 15px;
  background: #f6f6f6;
  color: #666666;
  font-size: 0.875rem;
  margin: 10px 0 20px; }

.messages:after {
  content: "";
  display: table;
  clear: both; }

.messages.only-cart {
  position: relative;
  padding: 10px;
  background: #9bb528;
  color: #ffffff;
  border: 2px solid #b3cc3c; }
  .messages.only-cart .add-to-cart-status-msg {
    padding-top: 3px;
    padding-left: 40px; }
  .messages.only-cart a {
    font-weight: 700;
    text-decoration: underline;
    color: #ffffff; }
  .messages.only-cart:before {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -441px -239px;
    width: 30px;
    height: 30px;
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    left: 10px; }

.messages--add-to-cart-popup {
  display: none; }

.popup-addtocart-wrapper .button-wrapper {
  text-align: center;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 6px solid #cccccc; }

.popup-addtocart-wrapper .inline-prices {
  margin-top: 6px; }
  .popup-addtocart-wrapper .inline-prices .price-suffix-pop {
    margin-left: 5px;
    font-size: 0.75rem; }
  .popup-addtocart-wrapper .inline-prices .init-price {
    text-decoration: line-through;
    margin-right: 8px;
    display: inline-block; }
  .popup-addtocart-wrapper .inline-prices .normal-price {
    font-weight: 700;
    color: #9bb528;
    display: inline-block; }

.popup-addtocart-wrapper .button.checkout {
  background: #9bb528;
  border: 2px solid #b3cc3c;
  padding: 5px 10px;
  position: relative;
  top: 1px;
  display: inline-block; }
  .popup-addtocart-wrapper .button.checkout a {
    color: #ffffff; }

.popup-addtocart-wrapper .button.continue {
  background: transparent;
  border: 2px solid #bcbcbc;
  padding: 5px 10px;
  position: relative;
  top: 1px;
  margin-right: 15px;
  display: inline-block;
  margin-bottom: 10px; }

.popup-addtocart-wrapper .button.continue:hover, .popup-addtocart-wrapper .button.continue:focus, .popup-addtocart-wrapper .button.checkout:hover, .popup-addtocart-wrapper .button.checkout:focus {
  background-color: #b3cc3c;
  border: 2px solid #9bb528;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff; }

.popup-addtocart-wrapper .product-details-wrapper:after {
  content: "";
  display: table;
  clear: both; }

.popup-addtocart-wrapper .added-product-image {
  float: left;
  margin-right: 10px; }

#pgwModal.pgwCart {
  line-height: 1.3; }
  #pgwModal.pgwCart .pm-body {
    padding: 20px 20px 10px 20px; }
  #pgwModal.pgwCart .pm-title {
    padding: 10px 28px 10px 46px;
    background: #9bb528;
    color: #ffffff;
    border: 2px solid #b3cc3c;
    letter-spacing: -0.02em;
    position: relative; }
    #pgwModal.pgwCart .pm-title:before {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -441px -239px;
      width: 30px;
      height: 30px;
      content: "";
      display: block;
      position: absolute;
      top: 8px;
      left: 10px; }
  #pgwModal.pgwCart .pm-close {
    top: 28px;
    right: 32px; }

input[type="submit"] {
  font-size: "Roboto", sans-serif;
  font-size: 1em; }

.simple-node-wrapper {
  padding: 50px 0;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto; }
  .simple-node-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .simple-node-wrapper h1.ptitle {
    margin-bottom: 25px; }

.way-adv-body-txt {
  font-size: 1.125rem; }

/* ----------- Eshop Pages ------------ */
.page-cart .selectboxit-container,
.page-checkout .selectboxit-container {
  max-width: 480px; }

.page-cart .selectboxit-container .selectboxit span,
.page-cart .selectboxit-container .selectboxit .selectboxit-options a,
.page-checkout .selectboxit-container .selectboxit span,
.page-checkout .selectboxit-container .selectboxit .selectboxit-options a {
  height: 42px;
  border: none;
  line-height: 44px; }

.page-cart .l-main,
.page-checkout .l-main {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px; }
  .page-cart .l-main:after,
  .page-checkout .l-main:after {
    content: "";
    display: table;
    clear: both; }

.page-cart h1,
.page-checkout h1 {
  margin: 20px 0;
  color: #000;
  font-size: 2.25rem;
  line-height: 1.2; }

.page-cart input[type="submit"]:hover, .page-cart input[type="submit"]:focus,
.page-checkout input[type="submit"]:hover,
.page-checkout input[type="submit"]:focus {
  cursor: pointer; }

.label-mobile {
  display: none; }

.l-page .l-main .commerce-checkout-progress {
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0; }

.page-node-done .l-main {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px; }
  .page-node-done .l-main:after {
    content: "";
    display: table;
    clear: both; }

.page-node-done h1 {
  margin: 20px 0;
  color: #000;
  font-size: 2.25rem;
  line-height: 1.2; }

.page-node-1172 .pane-e-shop .pane-title {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #000;
  font-size: 2.25rem;
  line-height: 1.2; }
  .page-node-1172 .pane-e-shop .pane-title:after {
    content: "";
    display: table;
    clear: both; }

.slideshow-slick {
  position: relative; }
  .slideshow-slick .carousel-arrow-left {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -450px -395px;
    width: 24px;
    height: 46px; }
  .slideshow-slick .carousel-arrow-right {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -596px -478px;
    width: 24px;
    height: 46px; }
  .slideshow-slick .rounded-arrow-nav {
    bottom: 65px; }
  .slideshow-slick .arrow-wrapper {
    position: absolute;
    top: 41%;
    padding: 10px;
    opacity: 0.7;
    transition: opacity 0.2s;
    z-index: 10; }
    .slideshow-slick .arrow-wrapper--left {
      left: 20px; }
    .slideshow-slick .arrow-wrapper--right {
      right: 20px; }
    .slideshow-slick .arrow-wrapper:hover, .slideshow-slick .arrow-wrapper:focus {
      cursor: pointer;
      opacity: 1; }

.rolls-caru {
  margin-bottom: 80px;
  position: relative;
  z-index: 10; }
  .rolls-caru .carousel-arrow-left {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -450px -395px;
    width: 24px;
    height: 46px; }
  .rolls-caru .carousel-arrow-right {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -596px -478px;
    width: 24px;
    height: 46px; }
  .rolls-caru .slick-track {
    padding: 30px 0; }
  .rolls-caru .node--product-display {
    margin: 0 25px;
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25); }
  .rolls-caru .arrow-wrapper {
    position: absolute;
    top: 50%;
    padding: 10px;
    opacity: 0.7;
    transition: opacity 0.2s;
    margin-top: -33px; }
    .rolls-caru .arrow-wrapper--left {
      left: -45px; }
    .rolls-caru .arrow-wrapper--right {
      right: -45px; }
    .rolls-caru .arrow-wrapper:hover, .rolls-caru .arrow-wrapper:focus {
      cursor: pointer;
      opacity: 1; }
  .rolls-caru .view.view-e-shop .views-row {
    clear: none;
    float: left;
    margin: 0;
    box-shadow: none; }
    .rolls-caru .view.view-e-shop .views-row .field--name-field-product-roll-image {
      margin-bottom: 0; }
    .rolls-caru .view.view-e-shop .views-row .group-box-shadow {
      padding: 26px;
      min-height: 236px; }

.gray-pane-wrap {
  background: #efefef;
  padding: 50px 0 40px; }

.user-profile .field--name-field-account-newsletter {
  margin: 15px 0; }
  .user-profile .field--name-field-account-newsletter:after {
    content: "";
    display: table;
    clear: both; }

.rt-pwrap {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
  padding-top: 60px; }
  .rt-pwrap:after {
    content: "";
    display: table;
    clear: both; }
  .rt-pwrap img {
    display: block; }
  .rt-pwrap .toi {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    height: 66px;
    padding-bottom: 6px; }
    .rt-pwrap .toi:after {
      content: "";
      display: table;
      clear: both; }
    .rt-pwrap .toi .toi-text {
      position: relative;
      top: 6px;
      font-size: 1.375rem;
      font-weight: 700;
      color: #ffffff;
      float: left;
      margin-left: 20px; }
    .rt-pwrap .toi .rmore {
      float: right;
      margin-right: 20px; }
    .rt-pwrap .toi .read-more {
      margin: 0; }
  .rt-pwrap .rt-left {
    position: relative;
    width: 48.93617%;
    float: left;
    margin-right: 2.12766%; }
  .rt-pwrap .rt-right {
    position: relative;
    width: 48.93617%;
    float: right;
    margin-right: 0; }

.views-row .slick-slider {
  display: none; }

.views-row .slick-initialized {
  display: block; }

.l-header-wrapper {
  box-shadow: 0 8px 8px -8px rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 400;
  background: #ffffff; }
  .l-header-wrapper--sticky {
    width: 100%;
    position: fixed;
    z-index: 100;
    background: #ffffff;
    top: 0;
    left: 0; }
    .l-header-wrapper--sticky .l-header {
      height: 70px;
      padding: 6px 35px; }
    .l-header-wrapper--sticky .l-header-left-right-wrapper {
      float: left;
      min-width: 95px;
      width: 5%;
      margin-right: 2%; }
    .l-header-wrapper--sticky .l-header__left {
      width: 100%; }
    .l-header-wrapper--sticky .block--commerce-overrides-shopping-cart-custom {
      position: absolute;
      top: 50%;
      margin-right: 0;
      display: block;
      right: 0;
      transform: translateY(-50%); }
    .l-header-wrapper--sticky .l-header__right {
      top: 5px;
      right: 35px;
      position: absolute;
      height: 62px;
      float: none;
      width: 10%;
      z-index: 5; }
      .l-header-wrapper--sticky .l-header__right #block-search-form,
      .l-header-wrapper--sticky .l-header__right #block-block-1,
      .l-header-wrapper--sticky .l-header__right #block-block-2,
      .l-header-wrapper--sticky .l-header__right #block-system-user-menu {
        display: none; }
    .l-header-wrapper--sticky #block-locale-language-content {
      display: none; }
    .l-header-wrapper--sticky .l-header__bottom {
      width: 76%;
      margin-right: 2%;
      float: left;
      clear: none; }

.logged-in #block-gtweaks-custom-main-menu.contextual-links-region {
  position: static; }

.logged-in #block-gtweaks-custom-main-menu .contextual-links-wrapper {
  display: none !important; }

.double-menu-height .block--gtweaks-custom-main-menu {
  padding-bottom: 51px; }

.double-menu-height ul.dropdown-mn.menu ul.menu {
  height: 51px; }

.sticky-page {
  padding-top: 68px; }
  .sticky-page .l-region--navigation .block--gtweaks-custom-main-menu {
    float: none;
    width: 100%;
    margin-right: 0; }
  .sticky-page .l-header__left {
    position: relative;
    z-index: 5; }
  .sticky-page ul.dropdown-mn.menu {
    position: absolute;
    left: 0;
    padding: 0 35px 0 178px;
    margin: 0; }
    .sticky-page ul.dropdown-mn.menu ul.menu {
      padding: 0 35px 0 178px;
      height: 51px; }
  .sticky-page .l-region--navigation ul.menu > li a {
    font-size: 1rem;
    padding: 14px 0 24px 0; }
  .sticky-page .l-region--navigation ul.menu > li ul.menu a {
    padding: 13px 0 14px 0; }
  .sticky-page.double-sticky {
    padding-top: 120px; }

.l-header {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 35px 0 35px; }
  .l-header:after {
    content: "";
    display: table;
    clear: both; }
  .l-header__right {
    float: right;
    width: 83%;
    text-align: right; }
  .l-header__left {
    float: left;
    width: 17%; }

.l-header-left-right-wrapper:after {
  content: "";
  display: table;
  clear: both; }

.l-region--navigation {
  margin-top: 5px; }
  .l-region--navigation:after {
    content: "";
    display: table;
    clear: both; }
  .l-region--navigation .block--gtweaks-custom-main-menu {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    float: none; }
    .l-region--navigation .block--gtweaks-custom-main-menu:after {
      content: "";
      display: table;
      clear: both; }
  .l-region--navigation ul.menu {
    width: 100%;
    box-sizing: content-box;
    margin-left: -35px;
    margin-right: -35px;
    padding-left: 35px;
    padding-right: 35px; }
    .l-region--navigation ul.menu ul.menu {
      box-sizing: border-box;
      margin-left: 0;
      margin-right: 0;
      padding-left: 35px;
      padding-right: 35px; }
    .l-region--navigation ul.menu > li {
      list-style: none;
      margin-left: 1%;
      margin-right: 1%;
      /*       &.expanded {
         padding-bottom: 51px;
       }*/ }
      .l-region--navigation ul.menu > li:last-child {
        margin-right: 0; }
      .l-region--navigation ul.menu > li:first-child {
        margin-left: 0; }
      .l-region--navigation ul.menu > li ul.menu {
        /*         height: 51px;
         position: absolute;
         left: 0;
         bottom: 0;
         padding: 0 35px;
         background: $basket;
         width: 100%;*/ }
        .l-region--navigation ul.menu > li ul.menu a {
          font-weight: 400;
          padding: 12px 0 12px 0; }
      .l-region--navigation ul.menu > li a {
        display: block;
        padding: 12px 0;
        color: #333333;
        font-weight: 700;
        font-size: 1.125rem;
        position: relative; }
        .l-region--navigation ul.menu > li a.active-trail:after {
          position: absolute;
          left: 0;
          bottom: 0;
          content: "";
          display: block;
          right: 0;
          height: 3px;
          background: #9bb528; }
        .l-region--navigation ul.menu > li a:hover, .l-region--navigation ul.menu > li a:focus {
          color: #9bb528; }

.search-block-form {
  color: #666666;
  position: relative; }
  .search-block-form:after {
    content: "";
    display: table;
    clear: both; }
  .search-block-form input[type="submit"] {
    border: none;
    text-indent: -9999px;
    overflow: hidden;
    width: 32px;
    height: 30px;
    background: url("../images/magn-glass.png") center center no-repeat;
    border-radius: 16px 0 0 16px; }
    .search-block-form input[type="submit"]:hover, .search-block-form input[type="submit"]:focus {
      cursor: pointer; }
  .search-block-form input[type="text"] {
    overflow: visible;
    padding: 6px 14px 6px 36px;
    font-size: 0.875rem;
    border: none;
    background: #f6f6f6;
    border-radius: 16px;
    font-weight: 700; }
    .search-block-form input[type="text"]::placeholder {
      color: #666666; }
  .search-block-form .form-actions {
    position: absolute;
    left: 0;
    top: 0; }

#block-block-2 .block__title {
  display: none; }

.social-media-icons {
  float: left; }
  .social-media-icons__item {
    float: left;
    margin-right: 7px; }
    .social-media-icons__item--facebook {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -596px -558px;
      width: 30px;
      height: 30px; }
    .social-media-icons__item--linkedin {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -441px -284px;
      width: 30px;
      height: 30px;
      margin-right: 0; }
    .social-media-icons__item--google-plus {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -356px -634px;
      width: 30px;
      height: 30px; }
  .social-media-icons--large .social-media-icons__item {
    margin-right: 20px; }
    .social-media-icons--large .social-media-icons__item--facebook {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -705px -216px;
      width: 68px;
      height: 67px; }
    .social-media-icons--large .social-media-icons__item--linkedin {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -634px -216px;
      width: 69px;
      height: 67px;
      margin-right: 0; }
    .social-media-icons--large .social-media-icons__item--google-plus {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -634px -285px;
      width: 68px;
      height: 67px; }

.quick-links-top {
  float: left;
  color: #666666;
  margin-top: 3px; }
  .quick-links-top:after {
    content: "";
    display: table;
    clear: both; }
  .quick-links-top__icon {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -388px -634px;
    width: 30px;
    height: 30px;
    float: left;
    margin-right: 10px; }
  .quick-links-top__item.leaf {
    list-style-type: none;
    list-style-image: none;
    float: left; }
    .quick-links-top__item.leaf:after {
      content: "/";
      margin: 0 5px; }
    .quick-links-top__item.leaf:last-child:after {
      display: none; }
    .quick-links-top__item.leaf a {
      font-weight: 700; }
      .quick-links-top__item.leaf a:link, .quick-links-top__item.leaf a:visited, .quick-links-top__item.leaf a:active {
        color: #666666; }
      .quick-links-top__item.leaf a:hover, .quick-links-top__item.leaf a:focus {
        color: #9bb528; }

.phone-top {
  float: left; }
  .phone-top:after {
    content: "";
    display: table;
    clear: both; }
  .phone-top__icon {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -596px -526px;
    width: 30px;
    height: 30px;
    display: block;
    float: left;
    margin-right: 10px; }
  .phone-top__text {
    font-family: "Roboto Condensed", sans-serif;
    color: #b3cc3c;
    font-weight: 700;
    font-size: 1.25rem; }

#block-search-form,
#block-block-1,
#block-block-2,
#block-system-user-menu {
  display: inline-block;
  margin-right: 2%;
  vertical-align: middle;
  text-align: left; }
  #block-search-form:last-child,
  #block-block-1:last-child,
  #block-block-2:last-child,
  #block-system-user-menu:last-child {
    margin-right: 0; }

.solo-fx-height {
  height: 493px; }

.banner {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  /*  &--larger {
    min-height: $height-stopper;
  }*/ }
  .banner:after {
    content: "";
    display: table;
    clear: both; }
  .banner.fx-height {
    height: 493px;
    min-height: 493px; }
  .banner__right {
    bottom: 0;
    top: 0;
    right: 0;
    position: absolute;
    width: 50%; }
    .banner__right__hero-image {
      background-size: cover;
      background-position: center center;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
  .banner__left {
    max-width: 1460px;
    margin-left: auto;
    margin-right: auto; }
    .banner__left:after {
      content: "";
      display: table;
      clear: both; }
    .banner__left.inner {
      max-width: 1460px;
      margin-left: auto;
      margin-right: auto; }
      .banner__left.inner:after {
        content: "";
        display: table;
        clear: both; }
    .banner__left .center-wrapper {
      padding: 40px 40px 20px 20px;
      max-width: 47%;
      margin-right: 53%;
      /*
      padding: 0 20px;
      max-width: 45%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      */ }
  .banner__description {
    margin-top: 25px;
    color: #010101;
    font-size: 1.125rem; }
    .banner__description--lheight {
      line-height: 1.7;
      font-size: 1.125rem; }
    .banner__description p {
      margin-bottom: 5px; }
  .banner-left-image, .banner-right-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%; }
    .banner-left-image .edit-img, .banner-right-image .edit-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center; }
  .banner-right-image {
    left: auto;
    right: 0; }
    .banner-right-image .edit-img {
      right: 0; }

.two-menu-stopper .fx-height {
  height: 442px;
  min-height: 442px;
  /*    &--larger {
      min-height: $height-stopper-with-menu;
    }*/ }

.view-slick-front-top {
  position: relative;
  /*  .banner__left .center-wrapper {
    padding: 0 20px;
    max-width: 45%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }*/ }
  .view-slick-front-top .field--name-field-images .field__items .field__item {
    display: none;
    overflow: hidden;
    position: relative; }
    .view-slick-front-top .field--name-field-images .field__items .field__item:first-child {
      display: block; }
  .view-slick-front-top .field--name-field-images .slick-initialized.field__items .field__item {
    display: block; }
  .view-slick-front-top .view-content {
    bottom: 0;
    top: 0;
    right: 0;
    position: absolute;
    width: 50%;
    /*    .views-row {
      height: $height-stopper;
      overflow: hidden;
    }*/ }
    .view-slick-front-top .view-content img {
      max-width: none;
      position: relative;
      left: 50%;
      transform: translateX(-50%); }
  .view-slick-front-top .slick-dots {
    display: none !important; }
  .view-slick-front-top .carousel-arrow-left {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -427px -329px;
    width: 30px;
    height: 52px;
    transition: opacity 0.3s;
    opacity: 1 !important; }
  .view-slick-front-top .carousel-arrow-right {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -395px -329px;
    width: 30px;
    height: 52px;
    transition: opacity 0.3s;
    opacity: 1 !important; }
  .view-slick-front-top .view-content:hover .carousel-arrow-left {
    opacity: 1; }
  .view-slick-front-top .view-content:hover .carousel-arrow-right {
    opacity: 1; }

.front .l-below-banner {
  background: #9bb528; }
  .front .l-below-banner-wrapper {
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 90px 0; }
    .front .l-below-banner-wrapper:after {
      content: "";
      display: table;
      clear: both; }
  .front .l-below-banner__text {
    margin-top: 40px;
    color: white;
    font-size: 1.125rem; }

.front .l-key-points-wrapper {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 90px 0; }
  .front .l-key-points-wrapper:after {
    content: "";
    display: table;
    clear: both; }

.front .l-key-points__left {
  width: 48.93617%;
  float: left;
  max-width: 607px; }

.front .l-key-points__right {
  padding-left: 90px;
  width: 48.93617%;
  float: right;
  margin-right: 0; }

.l-what-for-you {
  background: #efefef; }
  .l-what-for-you .ptitle {
    margin-bottom: 60px; }
  .l-what-for-you-wrapper {
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 90px 0; }
    .l-what-for-you-wrapper:after {
      content: "";
      display: table;
      clear: both; }
  .l-what-for-you .small-box {
    box-shadow: 0px 8px 22px -2px rgba(0, 0, 0, 0.25);
    width: 30.93525%;
    float: left;
    margin-right: 3.59712%;
    background: #ffffff;
    min-width: 315px; }
    .l-what-for-you .small-box:last-child {
      width: 30.93525%;
      float: left;
      margin-right: 0; }
    .l-what-for-you .small-box__text {
      min-height: 72px; }

.not-front .region-full-wrapper.l-what-for-you {
  background: none; }
  .not-front .region-full-wrapper.l-what-for-you .l-what-for-you-wrapper {
    padding: 0; }

.ways-adv-block-1 {
  background: #efefef; }
  .ways-adv-block-1 .way-adv-wrapper h2 {
    margin-bottom: 20px; }

.l-turntabled {
  padding: 0 0 40px; }
  .l-turntabled .carousel-arrow-left {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -755px -354px;
    width: 17px;
    height: 33px; }
  .l-turntabled .carousel-arrow-right {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -756px -410px;
    width: 17px;
    height: 33px; }
  .l-turntabled .arrow-wrapper {
    position: absolute;
    top: 50%;
    padding: 10px;
    opacity: 0.7;
    transition: opacity 0.2s;
    margin-top: -16px; }
    .l-turntabled .arrow-wrapper--left {
      left: -45px; }
    .l-turntabled .arrow-wrapper--right {
      right: -45px; }
    .l-turntabled .arrow-wrapper:hover, .l-turntabled .arrow-wrapper:focus {
      cursor: pointer;
      opacity: 1; }
  .l-turntabled .ptitle {
    margin-bottom: 40px; }
    .l-turntabled .ptitle a {
      color: #010101;
      transition: color 0.2s; }
      .l-turntabled .ptitle a:hover, .l-turntabled .ptitle a:focus {
        color: #9bb528; }
  .l-turntabled .gradientdiv-slide {
    padding-top: 90px;
    box-shadow: 0 8px 8px -8px rgba(0, 0, 0, 0.4);
    background: url("../images/lnbg-turntable.jpg") repeat-x center bottom; }
    .l-turntabled .gradientdiv-slide .ptitle {
      max-width: 610px;
      margin-left: auto;
      margin-right: auto;
      text-align: center; }
      .l-turntabled .gradientdiv-slide .ptitle:after {
        content: "";
        display: table;
        clear: both; }
    .l-turntabled .gradientdiv-slide-wrapper {
      max-width: 1020px;
      margin-left: auto;
      margin-right: auto; }
      .l-turntabled .gradientdiv-slide-wrapper:after {
        content: "";
        display: table;
        clear: both; }
  .l-turntabled .slide-region-wrapper {
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto; }
    .l-turntabled .slide-region-wrapper:after {
      content: "";
      display: table;
      clear: both; }
  .l-turntabled .slide-tab {
    margin: 40px 0; }
  .l-turntabled .slick-track {
    padding: 30px 0; }
  .l-turntabled .tab-10 {
    display: block; }
  .l-turntabled .carousel-items-nav {
    text-align: center; }
    .l-turntabled .carousel-items-nav__item {
      display: inline-block;
      padding: 20px 26px;
      font-size: 1.125rem;
      font-weight: 700;
      background: #9bb528;
      color: #ffffff; }
      .l-turntabled .carousel-items-nav__item:hover, .l-turntabled .carousel-items-nav__item:focus {
        background: #9bb528;
        color: white; }
      .l-turntabled .carousel-items-nav__item.active-citem {
        background: #9bb528; }

.not-front .l-turntabled {
  padding: 0; }

.l-news {
  background: #efefef; }
  .l-news .ptitle {
    margin-bottom: 60px; }

.field--name-body {
  color: #000; }

.l-news-wrapper {
  max-width: 1070px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 0; }
  .l-news-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .l-news-wrapper .views-row {
    text-align: center;
    width: 23.40426%;
    float: left;
    margin-right: 2.12766%; }
    .l-news-wrapper .views-row:nth-child(4n) {
      width: 23.40426%;
      float: right;
      margin-right: 0; }
    .l-news-wrapper .views-row:nth-child(4n+1) {
      clear: both;
      width: 23.40426%;
      float: left;
      margin-right: 2.12766%; }
  .l-news-wrapper .field--name-title-field {
    margin-top: 30px;
    line-height: 1.35;
    font-size: 1.125rem;
    margin-bottom: 15px;
    color: #000;
    font-weight: 700; }
    .l-news-wrapper .field--name-title-field a {
      transition: color 0.1s linear; }
    .l-news-wrapper .field--name-title-field a:link, .l-news-wrapper .field--name-title-field a:visited, .l-news-wrapper .field--name-title-field a:active {
      text-decoration: none;
      color: #000; }
    .l-news-wrapper .field--name-title-field a:hover, .l-news-wrapper .field--name-title-field a:focus {
      color: #9bb528;
      text-decoration: none; }
  .l-news-wrapper .field--type-image img {
    box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.3); }
  .l-news-wrapper .view-footer {
    clear: both;
    text-align: center; }
    .l-news-wrapper .view-footer .read-more {
      margin-top: 30px; }
    .l-news-wrapper .view-footer a {
      color: #ffffff; }

.not-front .l-news-wrapper .views-row {
  margin-bottom: 40px; }

.not-front .l-news-wrapper .view-header {
  margin-bottom: 25px; }

.not-front .l-news-wrapper {
  padding: 0; }

.not-front .view-display-id-news_page.l-news-wrapper {
  max-width: 100%; }
  .not-front .view-display-id-news_page.l-news-wrapper .field--name-title-field {
    font-weight: 700; }
  .not-front .view-display-id-news_page.l-news-wrapper .view-content {
    max-width: 1070px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 0; }
    .not-front .view-display-id-news_page.l-news-wrapper .view-content:after {
      content: "";
      display: table;
      clear: both; }
  .not-front .view-display-id-news_page.l-news-wrapper .view-header {
    background: #dddddd;
    padding: 28px 0; }
    .not-front .view-display-id-news_page.l-news-wrapper .view-header h1 {
      max-width: 1070px;
      margin-left: auto;
      margin-right: auto;
      color: #444444; }
      .not-front .view-display-id-news_page.l-news-wrapper .view-header h1:after {
        content: "";
        display: table;
        clear: both; }

.gback-news {
  font-weight: 700;
  margin: 50px 0; }
  .gback-news a:link, .gback-news a:visited, .gback-news a:active {
    text-decoration: none;
    color: #000; }
  .gback-news a:hover, .gback-news a:focus {
    color: #9bb528;
    text-decoration: none; }

.pager {
  padding: 30px 0 50px; }
  .pager .pager__item--current {
    font-weight: 400;
    padding: 8px 16px;
    display: inline-block;
    background: #dddddd;
    border: 1px solid #dddddd;
    color: #666666;
    margin-bottom: 0px; }
  .pager .pager__item a {
    padding: 8px 16px;
    display: inline-block;
    border: 1px solid #dddddd;
    color: #666666;
    margin: -6px; }
    .pager .pager__item a:hover, .pager .pager__item a:focus {
      background: #dddddd; }

.node-type-news h1 {
  display: none; }

.link-wrap-of-news img {
  transition: all 0.3s; }

.link-wrap-of-news:hover .field--type-image img {
  border-radius: 0; }

.node--news.node--full .group-banner {
  background: #eeeeee; }
  .node--news.node--full .group-banner .field--name-title-field {
    float: left;
    padding: 150px 0;
    width: 480px; }
    .node--news.node--full .group-banner .field--name-title-field h1 {
      display: block;
      font-size: 2.25rem;
      line-height: 110%;
      border-left: 15px solid #daab1d;
      padding-left: 10px; }

.node--news.node--full .field--name-field-image,
.node--news.node--full .field--name-field-images {
  position: absolute;
  right: 0;
  top: 40px; }

.node--news.node--full .group-banner-left {
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-height: 400px; }
  .node--news.node--full .group-banner-left:after {
    content: "";
    display: table;
    clear: both; }

.node--news.node--full .group-wrapper-inner {
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto;
  padding: 140px 0 80px; }
  .node--news.node--full .group-wrapper-inner:after {
    content: "";
    display: table;
    clear: both; }

.node--news.node--full .field--name-field-video {
  margin-top: 50px;
  text-align: center; }

#block-views-blog-block-2 .l-news-wrapper .field--name-title-field {
  font-weight: 700; }

.l-socialpromo {
  background: url("../images/prefooter/socialpromo.jpg") center center no-repeat;
  background-size: cover; }
  .l-socialpromo .wlink {
    color: #ffffff;
    font-weight: 700;
    text-decoration: underline; }
  .l-socialpromo .l-socialpromo-colour {
    background: rgba(151, 172, 36, 0.92); }
  .l-socialpromo .social-media-icons {
    margin-top: 20px;
    display: inline-block;
    float: none; }
    .l-socialpromo .social-media-icons:after {
      content: "";
      display: table;
      clear: both; }
  .l-socialpromo .sm-center-wrap {
    text-align: center; }
  .l-socialpromo__text {
    font-family: "Roboto Condensed", sans-serif;
    color: white;
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 50px; }
  .l-socialpromo__text-lower {
    font-family: "Roboto Condensed", sans-serif;
    color: white;
    font-size: 1.25rem;
    text-align: center; }
  .l-socialpromo .ptitle {
    text-align: center;
    margin-bottom: 30px; }
  .l-socialpromo .cta {
    margin-top: 20px;
    color: #ffffff;
    font-weight: 700;
    padding: 20px 22px;
    background-image: none;
    background: #9BB528;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15); }
    .l-socialpromo .cta:hover {
      background: #9dbb1a; }
  .l-socialpromo-wrapper {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    padding: 90px 0;
    text-align: center; }
    .l-socialpromo-wrapper:after {
      content: "";
      display: table;
      clear: both; }

.l-callus {
  background: #b8d04a; }
  .l-callus:after {
    content: "";
    display: table;
    clear: both; }
  .l-callus__text {
    font-weight: 700;
    color: #2f2f2f;
    font-size: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 40px; }
  .l-callus-wrapper {
    max-width: 610px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 0;
    position: relative; }
    .l-callus-wrapper:after {
      content: "";
      display: table;
      clear: both; }
    .l-callus-wrapper .ajax-progress-throbber {
      position: absolute;
      top: 62px;
      right: -10px; }

#pgwModal input[type="text"] {
  width: 100%;
  padding: 7px 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  overflow: visible;
  border: none;
  border-bottom: 1px solid #b3cc3c; }

#pgwModal input[type="submit"] {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: white;
  background: #b3cc3c;
  border: none;
  padding: 8px 18px;
  display: block;
  margin: 0 auto; }
  #pgwModal input[type="submit"]:hover, #pgwModal input[type="submit"]:focus {
    cursor: pointer;
    background: #9bb528; }

#pgwModal .pm-body {
  padding: 20px; }

#pgwModal .pm-title {
  background: #b3cc3c;
  padding: 10px 0 6px;
  text-align: left;
  color: #ffffff;
  padding-left: 20px;
  font-size: 1.125rem; }

#pgwModal .pm-content {
  padding: 15px 0 0; }
  #pgwModal .pm-content .form-actions {
    margin: 30px 0 0; }
  #pgwModal .pm-content .form-item {
    margin-bottom: 20px; }

#pgwModal .pm-close {
  top: 25px;
  right: 32px;
  z-index: 10; }
  #pgwModal .pm-close .pm-icon {
    background-image: none;
    width: auto;
    height: auto; }
    #pgwModal .pm-close .pm-icon:before {
      content: "x";
      font-size: 1.375rem;
      font-weight: 700;
      color: #ffffff; }

.l-footer {
  background: #2f2f2f;
  color: #cccccc;
  padding: 0 20px; }
  .l-footer ul.menu {
    margin-left: 0;
    padding-left: 0; }
    .l-footer ul.menu li {
      position: relative;
      margin-bottom: 14px;
      font-size: 1rem;
      list-style: none;
      padding-left: 24px;
      line-height: 1.4; }
      .l-footer ul.menu li:before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #b8d04a;
        position: absolute;
        left: 0;
        top: 8px; }
      .l-footer ul.menu li a:link, .l-footer ul.menu li a:visited, .l-footer ul.menu li a:active {
        text-decoration: none;
        color: #cccccc; }
      .l-footer ul.menu li a:hover, .l-footer ul.menu li a:focus {
        color: #b8d04a;
        text-decoration: none; }
  .l-footer-wrapper {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding: 90px 0; }
    .l-footer-wrapper:after {
      content: "";
      display: table;
      clear: both; }
  .l-footer #block-block-9 {
    width: 31.81818%;
    float: left;
    margin-right: 6.06061%; }
  .l-footer .mailchimp-signup-subscribe-form {
    max-width: 320px; }
  .l-footer #block-menu-menu-footer-select-menu,
  .l-footer #block-menu-menu-footer-menu-middle {
    width: 16.66667%;
    float: left;
    margin-right: 6.06061%; }
  .l-footer #block-menu-menu-footer-menu-right {
    width: 16.66667%;
    float: right;
    margin-right: 0; }
  .l-footer #block-menu-menu-footer-select-menu .block__title,
  .l-footer #block-menu-menu-footer-menu-middle .block__title,
  .l-footer #block-menu-menu-footer-menu-right .block__title {
    color: #ffffff;
    margin-bottom: 40px;
    font-size: 1.5rem; }

.about-block__text {
  margin-bottom: 40px; }

.l-copyright {
  background: #1d1d1d;
  color: #666666;
  font-size: 1rem;
  padding: 0 20px; }
  .l-copyright:after {
    content: "";
    display: table;
    clear: both; }
  .l-copyright__left {
    float: left; }
  .l-copyright__right {
    float: right;
    text-align: right; }
  .l-copyright-wrapper {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 30px 0; }
    .l-copyright-wrapper:after {
      content: "";
      display: table;
      clear: both; }
  .l-copyright .green-txt {
    color: #b3cc3c;
    font-weight: 700; }

.taxonomy-term--full {
  background: #ffffff; }
  .taxonomy-term--full .field--name-description-field {
    font-size: 1.125rem; }

.page-taxonomy {
  background: #efefef; }
  .page-taxonomy .l-socialpromo-wrapper {
    padding: 95px 0;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto; }
    .page-taxonomy .l-socialpromo-wrapper:after {
      content: "";
      display: table;
      clear: both; }
    .page-taxonomy .l-socialpromo-wrapper .l-socialpromo__text {
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0; }
      .page-taxonomy .l-socialpromo-wrapper .l-socialpromo__text:after {
        content: "";
        display: table;
        clear: both; }

.discounts-page-noarg .l-socialpromo-wrapper {
  padding: 95px 0;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .discounts-page-noarg .l-socialpromo-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .discounts-page-noarg .l-socialpromo-wrapper .l-socialpromo__text {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0; }
    .discounts-page-noarg .l-socialpromo-wrapper .l-socialpromo__text:after {
      content: "";
      display: table;
      clear: both; }

.advertisers-page .node--page {
  position: relative; }
  .advertisers-page .node--page .banner {
    /*        &__right {
                  &__hero-image--advertisers-image {
                    //background: url('/sites/default/files/taxonomy/artc/image/advertisers-image.jpg') center center no-repeat;
                    //background-size: cover;
                  }
                }*/ }
    .advertisers-page .node--page .banner__left .field--name-description-field.banner__description {
      margin-top: 0; }

.sboxes-panel {
  padding-bottom: 100px; }

.view-case-studies.view-display-id-panel_pane_2 {
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 85px;
  /* added title inside adv pages */ }
  .view-case-studies.view-display-id-panel_pane_2:after {
    content: "";
    display: table;
    clear: both; }
  .view-case-studies.view-display-id-panel_pane_2 .view-header {
    float: left;
    padding: 59px;
    background: #ffffff;
    min-height: 533px;
    margin-bottom: 0;
    width: 50%;
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
    position: relative;
    /* Added upon request h2 tag -> samples */ }
    .view-case-studies.view-display-id-panel_pane_2 .view-header .about-discount-coupons {
      padding-top: 10px;
      font-size: 1.125rem; }
  .view-case-studies.view-display-id-panel_pane_2 .view-content {
    width: 50%;
    float: left; }
    .view-case-studies.view-display-id-panel_pane_2 .view-content .slick-list {
      box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.25); }
  .view-case-studies.view-display-id-panel_pane_2 .arrow-wrapper--right {
    margin-top: -72px; }
  .view-case-studies.view-display-id-panel_pane_2 .arrow-wrapper--left {
    margin-top: -72px; }
  .view-case-studies.view-display-id-panel_pane_2 .adv-inner-title {
    font-size: 1.375rem;
    margin-bottom: 20px;
    line-height: 1.4; }

.advertisers-categories-block {
  background: #efefef;
  padding: 90px 0 50px; }
  .advertisers-categories-block .view-content {
    max-width: 1390px;
    margin-left: auto;
    margin-right: auto; }
    .advertisers-categories-block .view-content:after {
      content: "";
      display: table;
      clear: both; }
  .advertisers-categories-block .pane-title {
    text-align: center;
    font-size: 2.25rem;
    line-height: 1.2;
    margin-bottom: 90px; }
  .advertisers-categories-block .views-row {
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
    background: #ffffff;
    width: 23.40426%;
    float: left;
    margin-right: 2.12766%;
    margin-bottom: 2.12766%; }
    .advertisers-categories-block .views-row:nth-child(4n) {
      width: 23.40426%;
      float: right;
      margin-right: 0; }
    .advertisers-categories-block .views-row:nth-child(4n+1) {
      clear: both;
      width: 23.40426%;
      float: left;
      margin-right: 2.12766%; }
    .advertisers-categories-block .views-row .views-field-name {
      float: left; }
      .advertisers-categories-block .views-row .views-field-name a {
        color: #000; }
    .advertisers-categories-block .views-row .field--name-field-artc-icon {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right: 0px; }
    .advertisers-categories-block .views-row .field img {
      display: block; }
    .advertisers-categories-block .views-row .field--name-name-field {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      font-size: 1.25rem; }
      .advertisers-categories-block .views-row .field--name-name-field a {
        color: #010101; }
    .advertisers-categories-block .views-row .group-wrapper {
      background: #ffffff;
      padding: 20px; }
    .advertisers-categories-block .views-row .group-inner {
      position: relative;
      height: 60px; }
      .advertisers-categories-block .views-row .group-inner:hover .field--name-name-field a, .advertisers-categories-block .views-row .group-inner:focus .field--name-name-field a {
        color: #b3cc3c; }
      .advertisers-categories-block .views-row .group-inner:hover .field--name-field-artc-icon .field__item, .advertisers-categories-block .views-row .group-inner:focus .field--name-field-artc-icon .field__item {
        background: #b3cc3c; }

.panel-2col .panel-col-first, .panel-2col .panel-col-last {
  float: none;
  width: 100%; }
  .panel-2col .panel-col-first .inside, .panel-2col .panel-col-last .inside {
    margin: 0; }

.pane-block-10 {
  background: #9bb528; }
  .pane-block-10 h1 {
    font-size: 3rem; }
  .pane-block-10 .panels-outer-wrapper {
    max-width: 1270px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    padding: 65px 0px; }
    .pane-block-10 .panels-outer-wrapper:after {
      content: "";
      display: table;
      clear: both; }
  .pane-block-10 .content-title {
    max-width: 945px;
    margin-left: 0;
    margin-right: auto; }
    .pane-block-10 .content-title:after {
      content: "";
      display: table;
      clear: both; }

.pane-block-11 {
  text-align: center;
  max-width: 945px;
  margin-left: auto;
  margin-right: auto;
  margin: 0 auto;
  padding: 40px 0 40px; }
  .pane-block-11:after {
    content: "";
    display: table;
    clear: both; }
  .pane-block-11 h2 {
    font-size: 2.25rem;
    margin-bottom: 30px; }

.pane-block .subtitle {
  font-size: 1.125rem; }

.pane-block-47 {
  background: #efefef; }
  .pane-block-47 .panels-outer-wrapper {
    text-align: center;
    max-width: 945px;
    margin-left: auto;
    margin-right: auto;
    margin: 0 auto;
    padding: 0 0 60px; }
    .pane-block-47 .panels-outer-wrapper:after {
      content: "";
      display: table;
      clear: both; }
  .pane-block-47 h2 {
    font-size: 1.875rem;
    margin-bottom: 30px; }

.case-study-descs {
  position: relative;
  background: #9bb528; }
  .case-study-descs h1 {
    font-size: 2.25rem;
    line-height: 1;
    margin-bottom: 14px;
    color: #ffffff; }
  .case-study-descs .panels-outer-wrapper {
    max-width: 1270px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    padding: 55px 0px; }
    .case-study-descs .panels-outer-wrapper:after {
      content: "";
      display: table;
      clear: both; }
    .case-study-descs .panels-outer-wrapper .choose-arrow {
      font-size: 1.125rem; }
  .case-study-descs .dcoup {
    max-width: 1070px;
    margin-left: 0;
    margin-right: auto; }
    .case-study-descs .dcoup:after {
      content: "";
      display: table;
      clear: both; }
    .case-study-descs .dcoup .field--name-description-field,
    .case-study-descs .dcoup .subtitle {
      color: #ffffff;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1.125rem; }

.discounts-page-noarg .rounded-arrow-nav {
  bottom: -100px; }

.discount-icons-view-panel-block {
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto;
  padding: 53px 0 40px;
  text-align: center; }
  .discount-icons-view-panel-block:after {
    content: "";
    display: table;
    clear: both; }
  .discount-icons-view-panel-block .view-taxonomy-views:after {
    content: "";
    display: table;
    clear: both; }
  .discount-icons-view-panel-block .views-row {
    text-align: center;
    width: 206px;
    margin-bottom: 20px;
    display: inline-block; }
    .discount-icons-view-panel-block .views-row .field--name-field-artc-icon a {
      display: block;
      margin: 0 auto; }

.adv-categories-block {
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto;
  padding: 53px 0 40px;
  text-align: center; }
  .adv-categories-block:after {
    content: "";
    display: table;
    clear: both; }
  .adv-categories-block .views-row {
    text-align: center;
    display: inline-block;
    width: 145px;
    vertical-align: top;
    margin-bottom: 20px; }
    .adv-categories-block .views-row .field--name-field-artc-icon a {
      display: block;
      margin: 0 auto; }
  .adv-categories-block .taxonomy-term--minimal .field--name-field-artc-icon .field__item {
    width: 120px;
    height: 120px; }
  .adv-categories-block .taxonomy-term--minimal .field--name-name-field {
    font-size: 1.125rem;
    letter-spacing: -0.01em; }

.taxonomy-term--minimal .field--name-name-field {
  font-size: 1.25rem;
  margin-top: 30px;
  line-height: 1.2; }

.case-studies-caru {
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto; }
  .case-studies-caru:after {
    content: "";
    display: table;
    clear: both; }
  .case-studies-caru .slick-list {
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
    background: #ffffff; }

.node--case-study.node--full:after {
  content: "";
  display: table;
  clear: both; }

.node--case-study.node--full .cs-misc-info {
  margin-top: 30px;
  line-height: 1.28; }
  .node--case-study.node--full .cs-misc-info .field {
    margin-bottom: 6px; }
  .node--case-study.node--full .cs-misc-info .field__item {
    font-size: 1.25rem; }
  .node--case-study.node--full .cs-misc-info span {
    display: block;
    margin-bottom: 6px;
    color: #666666;
    max-width: 280px;
    line-height: 1.5; }

.node--case-study.node--full .field {
  margin-bottom: 4px; }
  .node--case-study.node--full .field--name-field-case-study-trev {
    font-size: 1.5rem;
    color: #9BB528;
    padding-top: 10px; }
    .node--case-study.node--full .field--name-field-case-study-trev .field__item {
      color: #9BB528; }
  .node--case-study.node--full .field--name-field-image {
    width: 50%;
    float: left;
    margin-bottom: 0; }
  .node--case-study.node--full .field--name-body {
    width: 50%;
    float: left;
    padding: 60px;
    background: #ffffff;
    min-height: 533px;
    margin-bottom: 0; }
    .node--case-study.node--full .field--name-body .field__label, .node--case-study.node--full .field--name-body .field__items, .node--case-study.node--full .field--name-body .field__item {
      display: inline;
      float: none; }
    .node--case-study.node--full .field--name-body .field__label {
      font-weight: 400; }
    .node--case-study.node--full .field--name-body .field__items {
      font-weight: 600; }

.case-studies-caru {
  position: relative;
  margin-top: 120px; }

.arrow-wrapper {
  display: block;
  position: absolute;
  z-index: 10; }
  .arrow-wrapper:hover, .arrow-wrapper:focus {
    cursor: pointer; }
  .arrow-wrapper--left {
    left: 10px;
    top: 50%;
    margin-top: -30px; }
  .arrow-wrapper--right {
    right: 10px;
    top: 50%;
    margin-top: -30px; }

/*.field-collection-container {
  margin: 0;
  border: none;
}*/
.view-case-studies .carousel-arrow-left {
  background-image: url(../images/sprites/build/sprites.png);
  background-position: -695px -410px;
  width: 59px;
  height: 59px; }
  .view-case-studies .carousel-arrow-left:hover, .view-case-studies .carousel-arrow-left:focus {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -634px -410px;
    width: 59px;
    height: 59px; }

.view-case-studies .carousel-arrow-right {
  background-image: url(../images/sprites/build/sprites.png);
  background-position: -704px -285px;
  width: 59px;
  height: 59px; }
  .view-case-studies .carousel-arrow-right:hover, .view-case-studies .carousel-arrow-right:focus {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -634px -471px;
    width: 59px;
    height: 59px; }

ul.slick-dots {
  text-align: center;
  padding: 20px 0;
  border: none;
  position: relative;
  z-index: 2;
  margin-left: 0;
  margin-bottom: 0; }
  ul.slick-dots li {
    display: inline-block;
    margin-right: 10px; }
    ul.slick-dots li:last-child {
      margin-right: 0; }
    ul.slick-dots li.slick-active .slick-paging,
    ul.slick-dots li.slick-active button {
      background: #b3cc3c; }
    ul.slick-dots li .slick-paging, ul.slick-dots li button {
      display: block;
      height: 16px;
      width: 16px;
      font-size: 0.875rem;
      font-weight: 700;
      background: #c5c5c5;
      border-radius: 50%;
      overflow: hidden;
      text-indent: -9999px;
      border: none;
      outline: none; }
      ul.slick-dots li .slick-paging:hover, ul.slick-dots li .slick-paging:focus, ul.slick-dots li button:hover, ul.slick-dots li button:focus {
        cursor: pointer; }

.contact-us-pane-page .pane-block-12 {
  background: #9bb528; }
  .contact-us-pane-page .pane-block-12 .panels-outer-wrapper {
    max-width: 1270px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding: 70px 0 170px; }
    .contact-us-pane-page .pane-block-12 .panels-outer-wrapper:after {
      content: "";
      display: table;
      clear: both; }

.contact-us-pane-page .pane-node {
  padding: 100px 0; }
  .contact-us-pane-page .pane-node .node--webform {
    max-width: 1270px;
    margin-left: auto;
    margin-right: auto; }
    .contact-us-pane-page .pane-node .node--webform:after {
      content: "";
      display: table;
      clear: both; }

.contact-us-pane-page .small-utitle {
  font-size: 1.5rem;
  color: white; }

.contact-us-pane-page .field--name-body {
  width: 40.42553%;
  float: left;
  margin-right: 2.12766%; }

.contact-us-pane-page .wf-wrapper {
  width: 57.44681%;
  float: right;
  margin-right: 0;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
  border-radius: 8px; }
  .contact-us-pane-page .wf-wrapper .ep-form-title {
    padding: 22px;
    color: #ffffff;
    background: #b3cc3c;
    border-radius: 8px 8px 0 0;
    font-size: 1.125rem;
    line-height: 100%; }

.contact-us-pane-page .webform-client-form-1 {
  padding: 30px;
  border: 1px solid #e8e8e8; }
  .contact-us-pane-page .webform-client-form-1 .form-item {
    margin-bottom: 50px; }
    .contact-us-pane-page .webform-client-form-1 .form-item.form-type-radio {
      margin-bottom: 10px;
      color: #909090; }
      .contact-us-pane-page .webform-client-form-1 .form-item.form-type-radio .iradio_minimal-green.checked + label {
        color: #000; }
    .contact-us-pane-page .webform-client-form-1 .form-item input[type="text"],
    .contact-us-pane-page .webform-client-form-1 .form-item input[type="email"],
    .contact-us-pane-page .webform-client-form-1 .form-item textarea {
      width: 100%;
      padding: 4px 0;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      overflow: visible;
      border: none;
      border-bottom: 1px solid #b3cc3c; }
      .contact-us-pane-page .webform-client-form-1 .form-item input[type="text"]::placeholder,
      .contact-us-pane-page .webform-client-form-1 .form-item input[type="email"]::placeholder,
      .contact-us-pane-page .webform-client-form-1 .form-item textarea::placeholder {
        color: #909090;
        opacity: 1; }
    .contact-us-pane-page .webform-client-form-1 .form-item:last-child {
      margin-bottom: 0; }
    .contact-us-pane-page .webform-client-form-1 .form-item textarea {
      min-height: 150px; }
  .contact-us-pane-page .webform-client-form-1 input[type="submit"] {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: white;
    background: #b3cc3c;
    border: none;
    font-size: 1.125rem;
    padding: 8px 18px;
    display: block;
    margin: 0 auto; }
    .contact-us-pane-page .webform-client-form-1 input[type="submit"]:hover, .contact-us-pane-page .webform-client-form-1 input[type="submit"]:focus {
      cursor: pointer;
      background: #9bb528; }

.not-logged-in.contact-us-pane-page .webform-client-form-1 .webform-component-textarea {
  margin-bottom: 19px; }

.not-logged-in.contact-us-pane-page .webform-client-form-1 .webform-component--proion {
  margin-bottom: 0; }

.cu-out {
  position: absolute;
  right: 0;
  bottom: -110px;
  z-index: 10;
  left: 0; }

.cu {
  max-width: 910px;
  margin-left: auto;
  margin-right: 0; }
  .cu:after {
    content: "";
    display: table;
    clear: both; }
  .cu .cu-icon-wrap {
    height: 105px;
    border-bottom: 1px solid #c2c2c0;
    position: relative; }
  .cu .cu-textin {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 100%; }
  .cu .cu-wrapper {
    position: relative;
    text-align: center;
    background: #ffffff;
    width: 33.33333%;
    float: left;
    height: 215px; }
    .cu .cu-wrapper:last-child {
      width: 33.33333%;
      float: right; }
  .cu__phone__icon {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -707px -142px;
    width: 63px;
    height: 63px;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: absolute; }
  .cu__phone__text {
    height: 110px;
    position: relative;
    width: 100%; }
  .cu__mail__icon {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -634px -354px;
    width: 73px;
    height: 54px;
    transform: translate(-50%, -50%);
    left: 50%;
    position: absolute;
    top: 50%; }
  .cu__mail__text {
    height: 110px;
    position: relative;
    width: 100%; }
  .cu__local__icon {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -634px -532px;
    width: 46px;
    height: 66px;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: absolute; }
  .cu__local__text {
    height: 110px;
    position: relative;
    width: 100%; }
  .cu .middle-cu {
    background: #efefef; }
    .cu .middle-cu a {
      color: #000; }
      .cu .middle-cu a:hover, .cu .middle-cu a:focus {
        color: #9bb528; }

.respond-gmap {
  position: relative;
  padding-bottom: 30%;
  height: 0;
  overflow: hidden;
  text-align: center;
  min-height: 420px; }

.promoticket-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

div.logo-bubble img {
  max-width: 42%;
  margin-bottom: 6px; }

.wch-wrapper {
  max-width: 435px; }

.wch {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 10px; }

.wch-below {
  line-height: 1.3;
  font-size: 1.5rem; }

.wch-icon {
  margin-top: 50px;
  background-image: url(../images/sprites/build/sprites.png);
  background-position: 0px -634px;
  width: 143px;
  height: 125px; }

.adv-with .pane-node {
  position: relative; }

.adv-with .pane-block-17 {
  background: #ffffff; }

.adv-with .pane-block-20 {
  background: #eeeeee; }

.adv-with .l-socialpromo-wrapper {
  padding: 95px 0;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .adv-with .l-socialpromo-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .adv-with .l-socialpromo-wrapper .l-socialpromo__text {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0; }
    .adv-with .l-socialpromo-wrapper .l-socialpromo__text:after {
      content: "";
      display: table;
      clear: both; }

.adv-with .l-page .key-points-list__title {
  font-size: 1.125rem; }

.adv-with .edit-img img {
  display: none; }

.read-more-1 {
  display: block;
  text-align: center; }

.priv-block {
  background: #ffffff; }
  .priv-block .key-points-list li {
    margin-bottom: 12px; }
  .priv-block .key-points-list__title {
    margin-bottom: 12px; }
  .priv-block .banner {
    min-height: 858px; }
    .priv-block .banner__right {
      bottom: 0;
      top: 0;
      left: 0;
      position: absolute;
      width: 50%; }
      .priv-block .banner__right__hero-image--advantages {
        /*      background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;*/ }
    .priv-block .banner__description {
      margin: 20px 0 20px;
      font-size: 1.125rem; }
    .priv-block .banner__left {
      max-width: 1530px;
      margin-left: auto;
      margin-right: auto; }
      .priv-block .banner__left .center-wrapper {
        padding: 90px 20px 45px 20px;
        max-width: 41%;
        margin-left: 59%;
        margin-right: 0; }
      .priv-block .banner__left .read-more {
        margin-top: 20px; }
      .priv-block .banner__left:after {
        content: "";
        display: table;
        clear: both; }

.proof-block .panels-outer-wrapper {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto; }
  .proof-block .panels-outer-wrapper:after {
    content: "";
    display: table;
    clear: both; }

.proof-block .l-what-for-you-wrapper {
  padding: 40px 0 70px; }

.proof-block .small-box {
  text-align: left; }

.proof-block .lspacing {
  display: block;
  text-align: center; }

.proof-block .small-box__text {
  font-size: 1.375rem;
  line-height: 1.4; }

.proof-block .normal-text {
  font-size: 1rem;
  padding: 0 28px;
  min-height: 72px; }

.way-adv-wrapper {
  text-align: center;
  padding-top: 90px; }

.green-st-block {
  background: #9bb528; }
  .green-st-block .panels-outer-wrapper {
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 90px 0; }
    .green-st-block .panels-outer-wrapper:after {
      content: "";
      display: table;
      clear: both; }
  .green-st-block .l-below-banner__text {
    margin-top: 30px;
    color: white;
    font-size: 1.125rem; }

.basket-block {
  background: #eeeeee; }
  .basket-block > .panels-outer-wrapper {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    padding: 90px 30px; }
    .basket-block > .panels-outer-wrapper:after {
      content: "";
      display: table;
      clear: both; }
  .basket-block .ptitle--medium {
    margin-bottom: 35px; }
  .basket-block .left-side-basket {
    float: left; }
  .basket-block .info-banner {
    float: left;
    width: 50%; }
  .basket-block .l-below-banner__text {
    line-height: 1.7; }
  .basket-block .basket-icon {
    float: right;
    width: 43%; }

.how-op-block .panels-outer-wrapper {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding: 90px 0 0; }
  .how-op-block .panels-outer-wrapper:after {
    content: "";
    display: table;
    clear: both; }

.how-op-block .read-more {
  margin: 0; }

.how-op-block .ptitle--medium {
  margin-bottom: 5px; }

.how-op-block .intro-op {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 65px; }
  .how-op-block .intro-op:after {
    content: "";
    display: table;
    clear: both; }

.how-op-block .text-op {
  font-size: 1.5rem; }

.how-op-block .rmore {
  margin: 90px 0 60px;
  text-align: center; }

.how-op-block .how-op:after {
  content: "";
  display: table;
  clear: both; }

.how-op-block .how-op__icon {
  margin: 0 auto;
  margin-bottom: 40px;
  border-radius: 50%;
  transition: all 0.5s; }
  .how-op-block .how-op__icon:hover, .how-op-block .how-op__icon:focus {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.6); }

.how-op-block .how-op__item {
  text-align: center;
  width: 31.91489%;
  float: left;
  margin-right: 2.12766%; }
  .how-op-block .how-op__item--first .how-op__icon {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -239px 0px;
    width: 237px;
    height: 237px; }
  .how-op-block .how-op__item--second .how-op__icon {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: 0px -239px;
    width: 237px;
    height: 237px; }
  .how-op-block .how-op__item--third .how-op__icon {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: 0px 0px;
    width: 237px;
    height: 237px; }
  .how-op-block .how-op__item:last-child {
    width: 31.91489%;
    float: right;
    margin-right: 0; }

.where-advert {
  padding: 100px 0;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto; }
  .where-advert:after {
    content: "";
    display: table;
    clear: both; }
  .where-advert .check-spots {
    font-size: 1.5rem; }
  .where-advert .wa-left {
    font-size: 1.125rem;
    float: left;
    max-width: 780px; }
  .where-advert .wa-right-icon {
    float: right; }

.where-adv-block-1 h2 {
  margin-bottom: 20px; }

.like-rm {
  min-width: 213px;
  text-align: center; }

.advertising-receipts .node--page--vblock .banner__description--lheight {
  font-size: 1rem; }

.where-adv-page .pane-node .where-advert {
  padding: 0;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto; }
  .where-adv-page .pane-node .where-advert:after {
    content: "";
    display: table;
    clear: both; }

.where-adv-page .pane-node .check-spots {
  max-width: 1070px;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.25rem;
  padding: 40px 0; }
  .where-adv-page .pane-node .check-spots:after {
    content: "";
    display: table;
    clear: both; }

.where-adv-page .pane-node .check-spots-wrapper {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5);
  position: relative; }

.where-adv-page .pane-node .where-advert-wrapper {
  background: #9bb528;
  color: #ffffff;
  position: relative;
  height: 442px; }
  .where-adv-page .pane-node .where-advert-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .where-adv-page .pane-node .where-advert-wrapper .wa-left {
    max-width: 59%;
    float: left;
    padding: 40px 20px 20px 20px;
    margin-right: 2%;
    /*          max-width: 530px;
          padding: 110px 0 60px;*/ }
  .where-adv-page .pane-node .where-advert-wrapper .wa-right-icon {
    padding: 40px 20px 0 20px;
    max-width: 39%;
    margin-right: 0;
    float: right; }
  .where-adv-page .pane-node .where-advert-wrapper .ptitle {
    font-size: 2.25rem;
    line-height: 1.2;
    margin-bottom: 30px; }

.where-adv-page .l-socialpromo-wrapper {
  padding: 95px 0;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .where-adv-page .l-socialpromo-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .where-adv-page .l-socialpromo-wrapper .l-socialpromo__text {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0; }
    .where-adv-page .l-socialpromo-wrapper .l-socialpromo__text:after {
      content: "";
      display: table;
      clear: both; }

.wadv-wrapper {
  background: #e4e4e4; }
  .wadv-wrapper:after {
    content: "";
    display: table;
    clear: both; }

.wadv-left {
  width: 50%;
  float: left; }
  .wadv-left .respond-gmap {
    padding-bottom: 68%;
    min-height: 660px; }

.wadv-right {
  width: 50%;
  float: left;
  min-height: 660px;
  position: relative; }

.wadv-content {
  padding: 40px;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto; }
  .wadv-content .mCSB_inside > .mCSB_container {
    margin-right: 20px; }
  .wadv-content .mCSB_scrollTools .mCSB_draggerRail {
    background-color: #c2c2c0; }
  .wadv-content .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #9bb528 !important; }
  .wadv-content .loc-search-results {
    position: relative; }
    .wadv-content .loc-search-results .results {
      height: 320px;
      overflow: hidden; }
  .wadv-content .loc-search-results .results {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
    list-style-type: none; }
    .wadv-content .loc-search-results .results .active {
      color: #9bb528; }
    .wadv-content .loc-search-results .results li {
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid #dddddd; }
      .wadv-content .loc-search-results .results li:hover, .wadv-content .loc-search-results .results li:focus {
        cursor: pointer; }
      .wadv-content .loc-search-results .results li:last-child {
        border: none; }
  .wadv-content .loc-search-form input[type="text"] {
    padding: 10px;
    font-size: 1em;
    max-width: 570px;
    width: 100%;
    overflow: visible; }
  .wadv-content .loc-search-form .form-type-textfield label {
    font-family: "Roboto Condensed", sans-serif;
    display: block;
    margin-bottom: 8px;
    font-size: 1.5rem;
    color: #9bb528;
    font-weight: 400; }
  .wadv-content:after {
    content: "";
    display: table;
    clear: both; }
  .wadv-content .wadv-text {
    font-size: 1.5rem;
    margin-bottom: 25px; }
  .wadv-content .form-item {
    margin-bottom: 20px; }
  .wadv-content input[type="submit"] {
    width: 100%;
    padding: 10px 0;
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    font-size: 1.125rem;
    background: #b3cc3c;
    border: none; }

.node--page.node--vblock .node__content {
  position: relative; }
  .node--page.node--vblock .node__content .banner__description p {
    margin-bottom: 1rem; }

.cost-page .banner__description {
  line-height: 1.5; }
  .cost-page .banner__description .key-points-list__title {
    position: relative;
    font-size: 1rem;
    margin-bottom: 10px; }
    .cost-page .banner__description .key-points-list__title:before {
      top: 0; }

.cost-page .l-socialpromo-wrapper {
  padding: 90px 0;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .cost-page .l-socialpromo-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .cost-page .l-socialpromo-wrapper .l-socialpromo__text {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px; }
    .cost-page .l-socialpromo-wrapper .l-socialpromo__text:after {
      content: "";
      display: table;
      clear: both; }

.green-st-block-cost .panels-outer-wrapper {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding: 55px 0 85px; }
  .green-st-block-cost .panels-outer-wrapper:after {
    content: "";
    display: table;
    clear: both; }

.green-st-block-cost .cost-subtitle {
  color: #ffffff;
  margin-bottom: 75px;
  font-size: 1.75rem; }

.green-st-block-cost .cost {
  width: 31.91489%;
  float: left;
  margin-right: 2.12766%; }
  .green-st-block-cost .cost:last-child {
    width: 31.91489%;
    float: right;
    margin-right: 0; }
  .green-st-block-cost .cost .cost-text {
    color: #ffffff;
    font-size: 1.5rem;
    line-height: 1.1; }
  .green-st-block-cost .cost .cost-icon {
    width: 44px;
    height: 44px;
    background: #ffffff;
    border-radius: 50%;
    padding: 5px 0px;
    color: #9bb528;
    font-size: 24px;
    font-weight: 700;
    margin: auto;
    margin-bottom: 15px; }

.priv-cost-block .banner {
  min-height: 680px; }

.priv-cost-block .banner__description {
  margin: 50px 0 70px;
  font-size: 1.125rem;
  line-height: 1.3; }
  .priv-cost-block .banner__description p {
    margin-bottom: 1rem; }

.priv-cost-block .rmore {
  text-align: center; }

.priv-cost-block .banner__left .center-wrapper {
  padding: 100px 20px 45px 20px; }

.priv-cost-block .banner-right {
  max-width: 1530px;
  margin-left: auto;
  margin-right: auto; }
  .priv-cost-block .banner-right:after {
    content: "";
    display: table;
    clear: both; }
  .priv-cost-block .banner-right .center-wrapper {
    padding: 100px 20px 45px 20px;
    max-width: 41%;
    margin-right: 59%;
    margin-left: 0; }

.priv-cost-block.right-image .read-more {
  margin-top: 20px; }

.pane-cost {
  clear: both; }

.cost-subpages .node--page.node--cost {
  position: relative; }
  .cost-subpages .node--page.node--cost .group-banner-left-inner {
    /*        max-width: 750px;
        padding: 110px 0;*/
    max-width: 59%;
    float: left;
    padding: 40px 20px 20px 20px;
    margin-right: 2%; }
  .cost-subpages .node--page.node--cost .group-banner {
    background: #9bb528;
    color: #ffffff; }
    .cost-subpages .node--page.node--cost .group-banner .group-banner-left {
      max-width: 1320px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      height: 442px; }
      .cost-subpages .node--page.node--cost .group-banner .group-banner-left:after {
        content: "";
        display: table;
        clear: both; }
      .cost-subpages .node--page.node--cost .group-banner .group-banner-left:after {
        content: "";
        display: table;
        clear: both; }
    .cost-subpages .node--page.node--cost .group-banner .field--name-title-field {
      /*    h1 {
            font-size: $big;
            line-height: 1.1;
            margin-bottom: 30px;
          }*/
      font-size: 2.25rem;
      line-height: 1.2;
      margin-bottom: 30px; }
    .cost-subpages .node--page.node--cost .group-banner .field--name-body {
      color: #ffffff;
      font-size: 1.125rem; }
    .cost-subpages .node--page.node--cost .group-banner .field--name-field-images {
      /*          padding: 80px 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 10;
          width: 29%;*/
      padding: 40px 20px 0 20px;
      max-width: 39%;
      margin-right: 0;
      float: right; }
      .cost-subpages .node--page.node--cost .group-banner .field--name-field-images img {
        position: relative;
        bottom: -34px;
        z-index: 10; }

.cost-subpages .l-socialpromo-wrapper {
  padding: 95px 0;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .cost-subpages .l-socialpromo-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .cost-subpages .l-socialpromo-wrapper .l-socialpromo__text {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0; }
    .cost-subpages .l-socialpromo-wrapper .l-socialpromo__text:after {
      content: "";
      display: table;
      clear: both; }

.view-cost {
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto; }
  .view-cost:after {
    content: "";
    display: table;
    clear: both; }
  .view-cost .ad-ways-block-inner {
    padding: 100px 0 80px; }

.node--page.node-teaser {
  position: relative; }

.how-it-works .l-socialpromo-wrapper {
  padding: 95px 0;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .how-it-works .l-socialpromo-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .how-it-works .l-socialpromo-wrapper .l-socialpromo__text {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0; }
    .how-it-works .l-socialpromo-wrapper .l-socialpromo__text:after {
      content: "";
      display: table;
      clear: both; }

.how-it-works .where-advert {
  padding: 0;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  .how-it-works .where-advert:after {
    content: "";
    display: table;
    clear: both; }

.how-it-works .where-advert-wrapper {
  height: 442px;
  background: #9bb528;
  color: #ffffff;
  position: relative; }
  .how-it-works .where-advert-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .how-it-works .where-advert-wrapper .wa-left {
    max-width: 59%;
    float: left;
    padding: 40px 20px 20px 20px;
    margin-right: 2%; }
    .how-it-works .where-advert-wrapper .wa-left .ptitle--coloured-huge {
      font-size: 2.25rem;
      line-height: 1.2;
      margin-bottom: 30px; }
  .how-it-works .where-advert-wrapper .wa-right-icon {
    padding: 40px 20px 0 20px;
    max-width: 39%;
    margin-right: 0;
    float: right; }
    .how-it-works .where-advert-wrapper .wa-right-icon img {
      position: relative;
      bottom: -34px;
      z-index: 10; }

.how-it-works-block {
  background: #ffffff; }
  .how-it-works-block .how-it-works-wrapper {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    padding: 80px 0 64px;
    display: block; }
    .how-it-works-block .how-it-works-wrapper:after {
      content: "";
      display: table;
      clear: both; }
  .how-it-works-block .how-it-works-left {
    display: table-cell;
    vertical-align: middle;
    width: 57.44681%;
    float: left;
    margin-right: 2.12766%;
    float: none; }
  .how-it-works-block .how-it-works-right {
    display: table-cell;
    vertical-align: middle;
    width: 40.42553%;
    float: right;
    margin-right: 0;
    float: none; }
  .how-it-works-block .how-it-works-image-1 {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -239px 0px;
    width: 237px;
    height: 237px;
    display: table-cell;
    vertical-align: middle; }
  .how-it-works-block .how-it-works-image-2 {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: 0px -239px;
    width: 237px;
    height: 237px;
    display: table-cell;
    vertical-align: middle; }
  .how-it-works-block .how-it-works-image-3 {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: 0px 0px;
    width: 237px;
    height: 237px;
    display: table-cell;
    vertical-align: middle; }
  .how-it-works-block .how-it-works-text {
    display: table-cell;
    vertical-align: middle; }
    .how-it-works-block .how-it-works-text span {
      font-size: 2.25rem;
      border-bottom: 2px solid #b8d04a;
      margin-left: 20px;
      padding-bottom: 5px; }
  .how-it-works-block .how-it-works-title {
    font-size: 1.875rem;
    line-height: 1.4;
    margin-bottom: 35px; }
  .how-it-works-block .how-it-works-body {
    font-size: 1.125rem;
    line-height: 1.4; }
  .how-it-works-block.how-it-works-block-2 {
    background-color: #eeeeee; }

.advertisers-benefits .where-advert {
  padding: 0;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  .advertisers-benefits .where-advert:after {
    content: "";
    display: table;
    clear: both; }

.advertisers-benefits .where-advert-wrapper {
  background: #9bb528;
  color: #ffffff;
  position: relative;
  height: 442px; }
  .advertisers-benefits .where-advert-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .advertisers-benefits .where-advert-wrapper .wa-left {
    max-width: 59%;
    float: left;
    padding: 40px 20px 20px 20px;
    margin-right: 2%; }
    .advertisers-benefits .where-advert-wrapper .wa-left .ptitle--coloured-huge {
      font-size: 2.25rem;
      line-height: 1.2;
      margin-bottom: 30px; }
  .advertisers-benefits .where-advert-wrapper .wa-right-icon {
    padding: 40px 20px 0 20px;
    max-width: 39%;
    margin-right: 0;
    float: right;
    /*      padding: 70px 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      width: 29%;*/ }
    .advertisers-benefits .where-advert-wrapper .wa-right-icon img {
      position: relative;
      bottom: -34px;
      z-index: 10; }
  .advertisers-benefits .where-advert-wrapper .ptitle {
    font-size: 2.25rem;
    line-height: 1.2;
    margin-bottom: 30px; }

.advertisers-benefits .wa-right-icon {
  padding: 40px 20px 0 20px;
  max-width: 39%;
  margin-right: 0;
  float: right;
  /*    padding: 40px 0;
    position: absolute;
    right: -10%;
    top: 0;
    z-index: 10;
    width: 53%;*/ }

.advertisers-benefits .l-socialpromo-wrapper {
  padding: 90px 0;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .advertisers-benefits .l-socialpromo-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .advertisers-benefits .l-socialpromo-wrapper .l-socialpromo__text {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px; }
    .advertisers-benefits .l-socialpromo-wrapper .l-socialpromo__text:after {
      content: "";
      display: table;
      clear: both; }

.advertisers-benefits-block {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 100px;
  padding-bottom: 60px; }
  .advertisers-benefits-block:after {
    content: "";
    display: table;
    clear: both; }
  .advertisers-benefits-block .advertisers-benefits {
    width: 48.93617%;
    float: left;
    margin-right: 2.12766%;
    padding-left: 60px;
    padding-right: 60px;
    text-align: center;
    margin-bottom: 100px; }
    .advertisers-benefits-block .advertisers-benefits:nth-child(2n) {
      width: 48.93617%;
      float: right;
      margin-right: 0; }
    .advertisers-benefits-block .advertisers-benefits:nth-child(2n+1) {
      clear: both;
      width: 48.93617%;
      float: left;
      margin-right: 2.12766%; }
  .advertisers-benefits-block .advertisers-benefits-title {
    font-size: 1.875rem;
    line-height: 1.3;
    margin-bottom: 25px; }
  .advertisers-benefits-block .advertisers-benefits-image {
    margin: 0 auto;
    margin-bottom: 20px; }
    .advertisers-benefits-block .advertisers-benefits-image.advertisers-image-1 {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -156px -478px;
      width: 154px;
      height: 154px; }
    .advertisers-benefits-block .advertisers-benefits-image.advertisers-image-2 {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: 0px -478px;
      width: 154px;
      height: 154px; }
    .advertisers-benefits-block .advertisers-benefits-image.advertisers-image-3 {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -239px -239px;
      width: 154px;
      height: 154px; }
    .advertisers-benefits-block .advertisers-benefits-image.advertisers-image-4 {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -478px 0px;
      width: 154px;
      height: 154px; }
    .advertisers-benefits-block .advertisers-benefits-image.advertisers-image-5 {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -478px -156px;
      width: 154px;
      height: 154px; }
    .advertisers-benefits-block .advertisers-benefits-image.advertisers-image-6 {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -478px -312px;
      width: 154px;
      height: 154px; }

.about-us-page .group-wrapper {
  background: #eeeeee; }

.about-us-page .group-inner {
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 406px; }
  .about-us-page .group-inner:after {
    content: "";
    display: table;
    clear: both; }

.about-us-page .about-us-title {
  font-size: 2.25rem;
  margin-bottom: 20px; }
  .about-us-page .about-us-title:before {
    content: "";
    background: #9bb528;
    width: 15px;
    height: 27px;
    display: inline-block;
    margin-right: 10px; }

.about-us-page .about-us-inner {
  max-width: 45%;
  padding: 40px 20px 60px 0px;
  margin-right: 2%;
  float: left; }

.about-us-page .field--name-field-images {
  position: absolute;
  right: 0;
  padding-top: 40px; }

.about-us-block .about-us-wrapper {
  padding: 140px 0 50px;
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto; }
  .about-us-block .about-us-wrapper:after {
    content: "";
    display: table;
    clear: both; }

.about-us-block .about-us-title {
  width: 40.42553%;
  float: left;
  margin-right: 2.12766%; }
  .about-us-block .about-us-title:before {
    content: "";
    background: #74a2ff; }

.about-us-block .about-us-body {
  width: 57.44681%;
  float: right;
  margin-right: 0; }

.about-us-block.about-us-block-2 {
  background: #eeeeee; }
  .about-us-block.about-us-block-2 .about-us-wrapper {
    padding: 140px 0 160px; }
  .about-us-block.about-us-block-2 .about-us-title:before {
    background: #8442e8; }

.ad-ways-page .l-socialpromo-wrapper {
  padding: 95px 0;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .ad-ways-page .l-socialpromo-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .ad-ways-page .l-socialpromo-wrapper .l-socialpromo__text {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0; }
    .ad-ways-page .l-socialpromo-wrapper .l-socialpromo__text:after {
      content: "";
      display: table;
      clear: both; }

.ad-ways-page .ad-ways-block-inner .ad-ways-block-left .ad-ways-block-image {
  transition: all 0.3s; }
  .ad-ways-page .ad-ways-block-inner .ad-ways-block-left .ad-ways-block-image:hover, .ad-ways-page .ad-ways-block-inner .ad-ways-block-left .ad-ways-block-image:focus {
    box-shadow: 3px 6px 12px -2px rgba(0, 0, 0, 0.3); }

.pane-block-34 {
  clear: both; }

.ad-ways-wrapper {
  background: #9bb528;
  position: relative;
  height: 442px; }
  .ad-ways-wrapper .ad-ways-inner {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto; }
    .ad-ways-wrapper .ad-ways-inner:after {
      content: "";
      display: table;
      clear: both; }
    .ad-ways-wrapper .ad-ways-inner:after {
      content: "";
      display: table;
      clear: both; }
  .ad-ways-wrapper .ad-ways-title {
    font-size: 2.25rem;
    line-height: 1.2;
    margin-bottom: 30px;
    color: #ffffff; }
  .ad-ways-wrapper .ad-ways-text {
    color: #ffffff;
    font-size: 1.125rem; }
  .ad-ways-wrapper .ad-ways-data {
    float: left;
    max-width: 59%;
    float: left;
    padding: 40px 20px 20px 20px;
    margin-right: 2%; }
  .ad-ways-wrapper .ad-ways-image {
    float: right;
    padding: 40px 20px 0 20px;
    max-width: 39%;
    margin-right: 0;
    float: right; }

.ad-ways-block-inner {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding: 80px 0;
  display: table; }
  .ad-ways-block-inner:after {
    content: "";
    display: table;
    clear: both; }
  .ad-ways-block-inner .ad-ways-block-left {
    width: 48.93617%;
    float: left;
    margin-right: 2.12766%;
    display: table-cell;
    vertical-align: middle;
    float: none; }
    .ad-ways-block-inner .ad-ways-block-left img {
      border: 1px solid #cccccc;
      display: block; }
  .ad-ways-block-inner .ad-ways-block-right {
    width: 48.93617%;
    float: right;
    margin-right: 0;
    display: table-cell;
    vertical-align: middle;
    float: none;
    padding: 0 20px; }
  .ad-ways-block-inner .ad-ways-block-title {
    font-size: 1.875rem;
    line-height: 1.3;
    margin-bottom: 20px; }
  .ad-ways-block-inner .ad-ways-block-text {
    color: #000;
    font-size: 1.125rem;
    line-height: 1.3; }
  .ad-ways-block-inner .ad-ways-block-subtitle {
    font-size: 1.5rem;
    margin: 30px 0 20px;
    color: #000; }
  .ad-ways-block-inner .key-points-list li {
    padding-left: 50px; }
    .ad-ways-block-inner .key-points-list li:before {
      content: "";
      display: block;
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -676px -600px;
      width: 25px;
      height: 25px;
      position: absolute;
      left: 0;
      top: 0; }
  .ad-ways-block-inner .rmore {
    text-align: center; }

.ad_ways_block_2 {
  background: #eeeeee; }

.ad-ways-page .ad-ways-block-inner .ad-ways-block-left, .cost-subpages .ad-ways-block-inner .ad-ways-block-left {
  width: 48.57143%;
  float: left;
  margin-right: 2.85714%; }

.ad-ways-page .ad-ways-block-right, .cost-subpages .ad-ways-block-right {
  width: 48.57143%;
  float: right;
  margin-right: 0; }

.ad-ways-page .ad-ways-wrapper .ad-ways-data {
  float: left;
  width: 55%; }

.ad-ways-page .ad-ways-wrapper .ad-ways-image {
  max-width: 43%;
  float: right; }

.receipt-points-page .where-advert, .research-efficiency .where-advert {
  padding: 0;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  .receipt-points-page .where-advert:after, .research-efficiency .where-advert:after {
    content: "";
    display: table;
    clear: both; }

.receipt-points-page .where-advert-wrapper, .research-efficiency .where-advert-wrapper {
  background: #9bb528;
  color: #ffffff;
  position: relative;
  height: 442px; }
  .receipt-points-page .where-advert-wrapper:after, .research-efficiency .where-advert-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .receipt-points-page .where-advert-wrapper .wa-left, .research-efficiency .where-advert-wrapper .wa-left {
    /*      max-width: 750px;
      padding: 100px 0 84px;*/
    max-width: 59%;
    float: left;
    padding: 40px 20px 20px 20px;
    margin-right: 2%; }
    .receipt-points-page .where-advert-wrapper .wa-left .ptitle--coloured-huge, .research-efficiency .where-advert-wrapper .wa-left .ptitle--coloured-huge {
      font-size: 2.25rem;
      line-height: 1.2;
      margin-bottom: 30px; }
  .receipt-points-page .where-advert-wrapper .wa-right-icon, .research-efficiency .where-advert-wrapper .wa-right-icon {
    /*      padding: 95px 0 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      width: 29%;*/
    padding: 40px 20px 0 20px;
    max-width: 39%;
    margin-right: 0;
    float: right; }

.receipt-points-page .wa-right-icon, .research-efficiency .wa-right-icon {
  /*    padding: 40px 0;
    position: absolute;
    right: -10%;
    top: 0;
    z-index: 10;
    width: 53%;*/
  max-width: 59%;
  float: left;
  padding: 40px 20px 20px 20px;
  margin-right: 2%; }

.receipt-points-page .basket-block > .panels-outer-wrapper, .research-efficiency .basket-block > .panels-outer-wrapper {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto; }
  .receipt-points-page .basket-block > .panels-outer-wrapper:after, .research-efficiency .basket-block > .panels-outer-wrapper:after {
    content: "";
    display: table;
    clear: both; }

.receipt-points-page .way-adv-wrapper, .research-efficiency .way-adv-wrapper {
  padding: 0; }
  .receipt-points-page .way-adv-wrapper .way-adv-body-txt a, .research-efficiency .way-adv-wrapper .way-adv-body-txt a {
    color: #000;
    font-weight: 700;
    text-decoration: underline; }

.receipt-points-page .l-socialpromo-wrapper .l-socialpromo__text, .research-efficiency .l-socialpromo-wrapper .l-socialpromo__text {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto; }
  .receipt-points-page .l-socialpromo-wrapper .l-socialpromo__text:after, .research-efficiency .l-socialpromo-wrapper .l-socialpromo__text:after {
    content: "";
    display: table;
    clear: both; }

.receipt-points-page .l-page
.key-points-list__title, .research-efficiency .l-page
.key-points-list__title {
  font-size: 1.125rem; }

.logos-block .node--page.node--logos {
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto;
  padding: 70px 0 50px; }
  .logos-block .node--page.node--logos:after {
    content: "";
    display: table;
    clear: both; }
  .logos-block .node--page.node--logos .field--name-field-images {
    text-align: center; }
    .logos-block .node--page.node--logos .field--name-field-images .field__item {
      display: inline-block;
      margin-right: 40px;
      width: 250px;
      height: 150px;
      line-height: 150px;
      text-align: center;
      margin-bottom: 40px; }
      .logos-block .node--page.node--logos .field--name-field-images .field__item img {
        vertical-align: middle; }

.receipt-points-block-1 {
  background: #eeeeee; }

.receipt-points-block-2 .banner-right {
  max-width: 1530px;
  margin-left: auto;
  margin-right: auto; }
  .receipt-points-block-2 .banner-right:after {
    content: "";
    display: table;
    clear: both; }
  .receipt-points-block-2 .banner-right .center-wrapper {
    padding: 90px 20px 45px 20px;
    max-width: 41%;
    margin-right: 59%;
    margin-left: 0; }

.research-efficiency .where-advert-wrapper .wa-left {
  padding: 120px 0 115px; }

.research-efficiency .where-advert-wrapper .wa-right-icon {
  padding: 36px 0; }

.printed-rolls .ad-ways-block-inner .ad-ways-block-left {
  width: 36.84211%;
  float: left;
  margin-right: 1.05263%; }
  .printed-rolls .ad-ways-block-inner .ad-ways-block-left .ad-ways-block-image img {
    border: none; }

.printed-rolls .ad-ways-block-inner .ad-ways-block-right {
  width: 62.10526%;
  float: right;
  margin-right: 0; }

.printed-rolls .ad-ways-block-inner .ad-ways-block-text {
  color: #000; }

.printed-rolls .ad-ways-block-inner .ad-ways-block-left-2 {
  width: 62.10526%;
  float: left;
  margin-right: 1.05263%;
  padding: 0 20px; }

.printed-rolls .ad-ways-block-inner .ad-ways-block-right-2 {
  width: 36.84211%;
  float: right;
  margin-right: 0; }

.printed-rolls .ad-ways-block-inner .rmore {
  text-align: center; }

.printed-rolls .l-socialpromo-wrapper {
  padding: 95px 0;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .printed-rolls .l-socialpromo-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .printed-rolls .l-socialpromo-wrapper .l-socialpromo__text {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0; }
    .printed-rolls .l-socialpromo-wrapper .l-socialpromo__text:after {
      content: "";
      display: table;
      clear: both; }
    .printed-rolls .l-socialpromo-wrapper .l-socialpromo__text-lower {
      padding-top: 20px; }

.order-rolls-block {
  background: #eeeeee; }

.node-type-product-display .l-content {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto; }
  .node-type-product-display .l-content:after {
    content: "";
    display: table;
    clear: both; }

.panel-2col-stacked .center-wrapper {
  padding-top: 20px;
  clear: both; }

body.node-type-product-display .panel-2col-stacked {
  margin: 30px 0; }
  body.node-type-product-display .panel-2col-stacked .inside {
    margin: 0; }
  body.node-type-product-display .panel-2col-stacked .center-wrapper .panel-col-first {
    width: 65.95745%;
    float: right;
    margin-right: 0;
    margin-top: 53px; }
    body.node-type-product-display .panel-2col-stacked .center-wrapper .panel-col-first .panels-outer-wrapper {
      padding-left: 4%; }
  body.node-type-product-display .panel-2col-stacked .center-wrapper .panel-col-last {
    width: 31.91489%;
    float: left;
    margin-right: 2.12766%; }
    body.node-type-product-display .panel-2col-stacked .center-wrapper .panel-col-last .panels-outer-wrapper {
      border-right: 5px solid #cccccc;
      padding-right: 15%; }

.field-product-availability {
  margin: 20px 0; }

.node--product-display .field-product-request-link {
  padding: 10px 15px;
  background: #e8e8e8;
  margin-top: 20px;
  display: inline-block; }
  .node--product-display .field-product-request-link a {
    color: #444444; }
    .node--product-display .field-product-request-link a:after {
      content: " ";
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid #b3cc3c;
      width: 0;
      height: 0;
      display: inline-block;
      margin-left: 10px;
      margin-bottom: -4px; }
    .node--product-display .field-product-request-link a:hover, .node--product-display .field-product-request-link a:focus {
      color: #9bb528; }

.node--product-display .selectboxit-container .selectboxit span,
.node--product-display .selectboxit-container .selectboxit .selectboxit-options a {
  height: 42px;
  border: none;
  line-height: 44px; }

.node--product-display .field__label,
.node--product-display .commerce-product-sku-label {
  color: #444444;
  font-weight: 400; }

.node--product-display .attribute-widgets {
  margin-bottom: 34px; }
  .node--product-display .attribute-widgets:after {
    content: "";
    display: table;
    clear: both; }
  .node--product-display .attribute-widgets .form-item {
    margin-bottom: 0; }
  .node--product-display .attribute-widgets .form-type-radio {
    margin-bottom: 0;
    margin-right: 0;
    display: block; }
    .node--product-display .attribute-widgets .form-type-radio > label {
      position: relative;
      top: 2px; }
    .node--product-display .attribute-widgets .form-type-radio input {
      margin-top: 0;
      margin-left: 0; }
  .node--product-display .attribute-widgets .form-type-radios {
    position: relative;
    overflow: hidden; }
  .node--product-display .attribute-widgets .form-type-radios > label,
  .node--product-display .attribute-widgets .form-type-select > label {
    color: #444444;
    font-weight: 700;
    display: block;
    margin-bottom: 5px; }

.node--product-display.node--full .form-item-attributes-field-commerce-product-maquette .help,
.node--product-display.node--full .form-item-attributes-field-commerce-product-quantity .help {
  background-image: url(../images/sprites/build/sprites.png);
  background-position: -751px -600px;
  width: 21px;
  height: 21px;
  display: inline-block;
  position: relative;
  top: 4px;
  margin-left: 2px; }
  .node--product-display.node--full .form-item-attributes-field-commerce-product-maquette .help:hover, .node--product-display.node--full .form-item-attributes-field-commerce-product-maquette .help:focus,
  .node--product-display.node--full .form-item-attributes-field-commerce-product-quantity .help:hover,
  .node--product-display.node--full .form-item-attributes-field-commerce-product-quantity .help:focus {
    cursor: pointer; }

.node--product-display.node--full .field--name-field-product-quantity-desc {
  display: none; }

.node--product-display.node--full .group-separator {
  border-bottom: 6px solid #cccccc;
  margin-bottom: 20px;
  padding-bottom: 20px; }

.node--product-display.node--full .group-inline-fields .field,
.node--product-display.node--full .group-inline-fields .commerce-product-field {
  display: inline-block;
  margin-right: 15px; }
  .node--product-display.node--full .group-inline-fields .field.commerce-product-field-empty,
  .node--product-display.node--full .group-inline-fields .commerce-product-field.commerce-product-field-empty {
    margin-right: 0; }
  .node--product-display.node--full .group-inline-fields .field:last-child,
  .node--product-display.node--full .group-inline-fields .commerce-product-field:last-child {
    margin-right: 0; }

.node--product-display.node--full .field__label,
.node--product-display.node--full .field,
.node--product-display.node--full .commerce-product-sku,
.node--product-display.node--full .commerce-product-sku-label {
  color: #444444;
  font-weight: 400; }

.node--product-display.node--full .field--name-field-slick-node-view .views-field-field-product-roll-image {
  margin-bottom: 2.12766%;
  position: relative; }

.node--product-display.node--full .field--name-field-slick-node-view .client-sample-sticker {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #3277F0;
  color: #ffffff;
  padding: 5px 10px;
  z-index: 10;
  max-width: 82px;
  line-height: 1.4;
  font-size: 0.875rem; }

.node--product-display.node--full .field--name-field-slick-node-view .views-field-field-product-roll-image-1 .picture-wrapper {
  cursor: pointer;
  display: block;
  position: relative;
  margin-bottom: 2.12766%;
  width: 23.40426%;
  float: left;
  margin-right: 2.12766%; }
  .node--product-display.node--full .field--name-field-slick-node-view .views-field-field-product-roll-image-1 .picture-wrapper:nth-child(4n) {
    width: 23.40426%;
    float: right;
    margin-right: 0; }
  .node--product-display.node--full .field--name-field-slick-node-view .views-field-field-product-roll-image-1 .picture-wrapper:nth-child(4n+1) {
    clear: both;
    width: 23.40426%;
    float: left;
    margin-right: 2.12766%; }
  .node--product-display.node--full .field--name-field-slick-node-view .views-field-field-product-roll-image-1 .picture-wrapper.active:after, .node--product-display.node--full .field--name-field-slick-node-view .views-field-field-product-roll-image-1 .picture-wrapper:hover:after {
    content: "";
    width: 100%;
    background: #9bb528;
    -webkit-transition: 0.7s;
    transition: 0.7s; }
  .node--product-display.node--full .field--name-field-slick-node-view .views-field-field-product-roll-image-1 .picture-wrapper:after {
    content: "";
    height: 3px;
    width: 0;
    position: absolute;
    bottom: 0;
    display: block; }

.node--product-display.node--full .field--name-field-slick-node-view .views-field-field-product-roll-image-1 img {
  display: block; }

.node--product-display.node--full .group-wrapper-right {
  width: 48.93617%;
  float: right;
  margin-right: 0; }

.node--product-display.node--full .group-wrapper-left {
  position: relative;
  width: 48.93617%;
  float: left;
  margin-right: 2.12766%; }

.node--product-display.node--full .field--name-field-commerce-product {
  border-bottom: 6px solid #cccccc;
  padding: 20px 0; }
  .node--product-display.node--full .field--name-field-commerce-product input[type="submit"] {
    clear: both;
    background: #b3cc3c;
    border: none;
    color: #ffffff;
    padding: 18px 48px;
    font-size: 1.125rem; }
    .node--product-display.node--full .field--name-field-commerce-product input[type="submit"]:hover, .node--product-display.node--full .field--name-field-commerce-product input[type="submit"]:focus {
      cursor: pointer; }

.node--product-display.node--full .field--name-commerce-price {
  color: #9bb528;
  font-size: 1.875rem;
  font-weight: 700; }
  .node--product-display.node--full .field--name-commerce-price .price-suffix {
    color: #444444;
    margin-left: 10px;
    font-size: 0.875rem;
    font-weight: 400; }

.node--product-display.node--full .field-product-discount {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  background: #eb5b0c;
  color: #ffffff;
  padding: 5px 10px; }
  .node--product-display.node--full .field-product-discount .label {
    display: block;
    font-size: 0.875rem; }
  .node--product-display.node--full .field-product-discount .value {
    font-weight: 700;
    line-height: 100%;
    display: block; }

.node--product-display.node--full .field--name-field-commerce-init-price {
  font-size: 1.375rem;
  text-decoration: line-through; }

.node--product-display.node--full .field--name-field-product-new {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  background: #01B9E8;
  color: #ffffff;
  padding: 4px 10px 1px;
  font-size: 0.875rem;
  max-width: 82px; }

.node--product-display.node--full .field--name-title-field {
  font-size: 2rem;
  color: #444444;
  line-height: 1.1;
  margin-bottom: 10px; }

.node--product-display.node--full .group-wrapper-social-roll {
  position: relative; }

.node--product-display.node--full .field-custom-this {
  position: absolute;
  right: 0;
  top: 25px; }
  .node--product-display.node--full .field-custom-this ul {
    list-style-type: none; }
    .node--product-display.node--full .field-custom-this ul .service-facebook {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -728px -532px;
      width: 44px;
      height: 43px; }
    .node--product-display.node--full .field-custom-this ul .service-google-plus {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -395px -239px;
      width: 44px;
      height: 43px; }
    .node--product-display.node--full .field-custom-this ul .service-linkedin {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -395px -284px;
      width: 44px;
      height: 43px; }
  .node--product-display.node--full .field-custom-this a {
    display: block;
    height: 43px; }

.node--product-display.node--full .commerce-product-extra-field-sku {
  margin-bottom: 12px; }

.node--product-display.node--full .field--name-field-product-roll-length,
.node--product-display.node--full .field--name-field-product-roll-weight,
.node--product-display.node--full .field-product-availability {
  float: none;
  clear: both;
  margin-bottom: 0; }
  .node--product-display.node--full .field--name-field-product-roll-length.field--label-inline .field__label, .node--product-display.node--full .field--name-field-product-roll-length.field--label-inline .field__items,
  .node--product-display.node--full .field--name-field-product-roll-weight.field--label-inline .field__label,
  .node--product-display.node--full .field--name-field-product-roll-weight.field--label-inline .field__items,
  .node--product-display.node--full .field-product-availability.field--label-inline .field__label,
  .node--product-display.node--full .field-product-availability.field--label-inline .field__items {
    float: none;
    display: inline-block; }

.node--product-display.node--full .field--name-field-product-roll-length .field__label,
.node--product-display.node--full .field--name-field-product-roll-weight .field__label {
  font-weight: 700; }

.node--product-display.node--full .field-product-availability {
  background: #b3cc3c;
  padding: 6px 10px;
  color: #ffffff; }

.node--product-display.node--full .form-item-attributes-field-commerce-product-quantity {
  float: left;
  margin-right: 40px;
  width: 20%; }

.node--product-display.node--full .form-item-attributes-field-commerce-product-maquette .plus {
  font-size: 0.875rem;
  display: inline-block;
  margin-left: 10px; }

.node--product-display.node--full .form-item-attributes-field-commerce-product-maquette .ajax-progress {
  position: relative; }
  .node--product-display.node--full .form-item-attributes-field-commerce-product-maquette .ajax-progress .throbber {
    position: absolute;
    right: -140px;
    top: -20px; }
  .node--product-display.node--full .form-item-attributes-field-commerce-product-maquette .ajax-progress .message {
    display: none; }

.pane-gtweaks-products-accordion .ui-accordion-header {
  border: none;
  border-radius: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 15px 20px;
  background: #e8e8e8;
  color: #444444; }
  .pane-gtweaks-products-accordion .ui-accordion-header.ui-state-active {
    background: #b3cc3c;
    color: #ffffff;
    font-weight: 400; }
    .pane-gtweaks-products-accordion .ui-accordion-header.ui-state-active .ui-accordion-header-icon {
      top: 5%; }
      .pane-gtweaks-products-accordion .ui-accordion-header.ui-state-active .ui-accordion-header-icon:after {
        content: "-";
        color: #ffffff;
        display: inline-block;
        font-size: 2.25rem; }
  .pane-gtweaks-products-accordion .ui-accordion-header .ui-accordion-header-icon {
    right: 20px;
    left: auto;
    top: 14%;
    background-image: none;
    text-indent: 0;
    overflow: visible;
    background-position: 0 0;
    width: auto;
    height: auto;
    margin-top: 0; }
    .pane-gtweaks-products-accordion .ui-accordion-header .ui-accordion-header-icon:after {
      content: "+";
      color: #9bb528;
      font-size: 28px; }

.pane-gtweaks-products-accordion .ui-accordion-content {
  border: none;
  font-family: "Roboto", sans-serif;
  color: #444444;
  line-height: 1.6;
  padding: 20px; }

.pane-gtweaks-related-products .goto-eshop-cat a {
  font-size: 0.875rem;
  color: #000;
  display: block; }
  .pane-gtweaks-related-products .goto-eshop-cat a:hover, .pane-gtweaks-related-products .goto-eshop-cat a:focus {
    color: #9bb528; }
  .pane-gtweaks-related-products .goto-eshop-cat a:after {
    content: " ";
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #9bb528;
    width: 0;
    height: 0;
    display: inline-block;
    margin-left: 6px;
    margin-bottom: -2px; }

.pane-gtweaks-related-products h2.pane-title {
  color: #444444;
  font-size: 1.5rem;
  margin-bottom: 20px;
  padding-right: 16%; }

/*.page-rola-tameiakwn {

  .l-main {

  }
}*/
.view-e-shop .view-content {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto; }
  .view-e-shop .view-content:after {
    content: "";
    display: table;
    clear: both; }

.view-e-shop .swrapper-title {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto; }
  .view-e-shop .swrapper-title:after {
    content: "";
    display: table;
    clear: both; }
  .view-e-shop .swrapper-title h1 {
    margin: 30px 0;
    color: #000;
    font-size: 2.25rem;
    line-height: 1.2; }

.view-e-shop .views-row {
  width: 23.40426%;
  float: left;
  margin-right: 2.12766%;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
  margin-bottom: 2.12766%; }
  .view-e-shop .views-row:nth-child(4n) {
    width: 23.40426%;
    float: right;
    margin-right: 0; }
  .view-e-shop .views-row:nth-child(4n+1) {
    clear: both;
    width: 23.40426%;
    float: left;
    margin-right: 2.12766%; }

.view-e-shop.view-display-id-page #block-block-45 {
  background: #eeeeee; }
  .view-e-shop.view-display-id-page #block-block-45 .ad-ways-block-text {
    line-height: 1.4; }
  .view-e-shop.view-display-id-page #block-block-45 .read-more {
    margin-top: 26px;
    margin-bottom: 0px; }
  .view-e-shop.view-display-id-page #block-block-45 .ad-ways-block-inner {
    padding: 40px 0; }

.view-views-search.node--search-index .group-box-shadow {
  border-top: 8px solid #9bb528; }

.node--product-display.node--teaser, .node--product-display.node--search-index, .node--product-display.node--vblock,
.page-search .node--news.node--teaser,
.page-search .node--news.node--search-index,
.page-search .node--news.node--vblock,
.page-search .node--page.node--teaser,
.page-search .node--page.node--search-index,
.page-search .node--page.node--vblock {
  position: relative; }
  .node--product-display.node--teaser a, .node--product-display.node--search-index a, .node--product-display.node--vblock a,
  .page-search .node--news.node--teaser a,
  .page-search .node--news.node--search-index a,
  .page-search .node--news.node--vblock a,
  .page-search .node--page.node--teaser a,
  .page-search .node--page.node--search-index a,
  .page-search .node--page.node--vblock a {
    color: #000; }
    .node--product-display.node--teaser a:hover .group-box-shadow, .node--product-display.node--teaser a:focus .group-box-shadow, .node--product-display.node--search-index a:hover .group-box-shadow, .node--product-display.node--search-index a:focus .group-box-shadow, .node--product-display.node--vblock a:hover .group-box-shadow, .node--product-display.node--vblock a:focus .group-box-shadow,
    .page-search .node--news.node--teaser a:hover .group-box-shadow,
    .page-search .node--news.node--teaser a:focus .group-box-shadow,
    .page-search .node--news.node--search-index a:hover .group-box-shadow,
    .page-search .node--news.node--search-index a:focus .group-box-shadow,
    .page-search .node--news.node--vblock a:hover .group-box-shadow,
    .page-search .node--news.node--vblock a:focus .group-box-shadow,
    .page-search .node--page.node--teaser a:hover .group-box-shadow,
    .page-search .node--page.node--teaser a:focus .group-box-shadow,
    .page-search .node--page.node--search-index a:hover .group-box-shadow,
    .page-search .node--page.node--search-index a:focus .group-box-shadow,
    .page-search .node--page.node--vblock a:hover .group-box-shadow,
    .page-search .node--page.node--vblock a:focus .group-box-shadow {
      background: #e4e4e4; }
  .node--product-display.node--teaser .group-box-shadow, .node--product-display.node--search-index .group-box-shadow, .node--product-display.node--vblock .group-box-shadow,
  .page-search .node--news.node--teaser .group-box-shadow,
  .page-search .node--news.node--search-index .group-box-shadow,
  .page-search .node--news.node--vblock .group-box-shadow,
  .page-search .node--page.node--teaser .group-box-shadow,
  .page-search .node--page.node--search-index .group-box-shadow,
  .page-search .node--page.node--vblock .group-box-shadow {
    background: #ffffff; }
  .node--product-display.node--teaser .field--name-body, .node--product-display.node--search-index .field--name-body, .node--product-display.node--vblock .field--name-body,
  .page-search .node--news.node--teaser .field--name-body,
  .page-search .node--news.node--search-index .field--name-body,
  .page-search .node--news.node--vblock .field--name-body,
  .page-search .node--page.node--teaser .field--name-body,
  .page-search .node--page.node--search-index .field--name-body,
  .page-search .node--page.node--vblock .field--name-body {
    font-size: 0.875rem;
    line-height: 1.32;
    margin-bottom: 12px;
    color: #444444; }
  .node--product-display.node--teaser .field--name-field-commerce-product-quantity, .node--product-display.node--search-index .field--name-field-commerce-product-quantity, .node--product-display.node--vblock .field--name-field-commerce-product-quantity,
  .page-search .node--news.node--teaser .field--name-field-commerce-product-quantity,
  .page-search .node--news.node--search-index .field--name-field-commerce-product-quantity,
  .page-search .node--news.node--vblock .field--name-field-commerce-product-quantity,
  .page-search .node--page.node--teaser .field--name-field-commerce-product-quantity,
  .page-search .node--page.node--search-index .field--name-field-commerce-product-quantity,
  .page-search .node--page.node--vblock .field--name-field-commerce-product-quantity {
    font-size: 0.875rem; }
    .node--product-display.node--teaser .field--name-field-commerce-product-quantity:after, .node--product-display.node--search-index .field--name-field-commerce-product-quantity:after, .node--product-display.node--vblock .field--name-field-commerce-product-quantity:after,
    .page-search .node--news.node--teaser .field--name-field-commerce-product-quantity:after,
    .page-search .node--news.node--search-index .field--name-field-commerce-product-quantity:after,
    .page-search .node--news.node--vblock .field--name-field-commerce-product-quantity:after,
    .page-search .node--page.node--teaser .field--name-field-commerce-product-quantity:after,
    .page-search .node--page.node--search-index .field--name-field-commerce-product-quantity:after,
    .page-search .node--page.node--vblock .field--name-field-commerce-product-quantity:after {
      content: "";
      display: table;
      clear: both; }
  .node--product-display.node--teaser .group-box-shadow, .node--product-display.node--search-index .group-box-shadow, .node--product-display.node--vblock .group-box-shadow,
  .page-search .node--news.node--teaser .group-box-shadow,
  .page-search .node--news.node--search-index .group-box-shadow,
  .page-search .node--news.node--vblock .group-box-shadow,
  .page-search .node--page.node--teaser .group-box-shadow,
  .page-search .node--page.node--search-index .group-box-shadow,
  .page-search .node--page.node--vblock .group-box-shadow {
    padding: 16px;
    min-height: 237px; }
  .node--product-display.node--teaser .field--name-title-field, .node--product-display.node--search-index .field--name-title-field, .node--product-display.node--vblock .field--name-title-field,
  .page-search .node--news.node--teaser .field--name-title-field,
  .page-search .node--news.node--search-index .field--name-title-field,
  .page-search .node--news.node--vblock .field--name-title-field,
  .page-search .node--page.node--teaser .field--name-title-field,
  .page-search .node--page.node--search-index .field--name-title-field,
  .page-search .node--page.node--vblock .field--name-title-field {
    font-size: 1.125rem;
    line-height: 1.32;
    margin-bottom: 12px;
    color: #444444; }
    .node--product-display.node--teaser .field--name-title-field a, .node--product-display.node--search-index .field--name-title-field a, .node--product-display.node--vblock .field--name-title-field a,
    .page-search .node--news.node--teaser .field--name-title-field a,
    .page-search .node--news.node--search-index .field--name-title-field a,
    .page-search .node--news.node--vblock .field--name-title-field a,
    .page-search .node--page.node--teaser .field--name-title-field a,
    .page-search .node--page.node--search-index .field--name-title-field a,
    .page-search .node--page.node--vblock .field--name-title-field a {
      color: #000;
      transition: 0.2s linear; }
      .node--product-display.node--teaser .field--name-title-field a:hover, .node--product-display.node--teaser .field--name-title-field a:focus, .node--product-display.node--search-index .field--name-title-field a:hover, .node--product-display.node--search-index .field--name-title-field a:focus, .node--product-display.node--vblock .field--name-title-field a:hover, .node--product-display.node--vblock .field--name-title-field a:focus,
      .page-search .node--news.node--teaser .field--name-title-field a:hover,
      .page-search .node--news.node--teaser .field--name-title-field a:focus,
      .page-search .node--news.node--search-index .field--name-title-field a:hover,
      .page-search .node--news.node--search-index .field--name-title-field a:focus,
      .page-search .node--news.node--vblock .field--name-title-field a:hover,
      .page-search .node--news.node--vblock .field--name-title-field a:focus,
      .page-search .node--page.node--teaser .field--name-title-field a:hover,
      .page-search .node--page.node--teaser .field--name-title-field a:focus,
      .page-search .node--page.node--search-index .field--name-title-field a:hover,
      .page-search .node--page.node--search-index .field--name-title-field a:focus,
      .page-search .node--page.node--vblock .field--name-title-field a:hover,
      .page-search .node--page.node--vblock .field--name-title-field a:focus {
        color: #9bb528; }
  .node--product-display.node--teaser .field--name-field-product-new, .node--product-display.node--search-index .field--name-field-product-new, .node--product-display.node--vblock .field--name-field-product-new,
  .page-search .node--news.node--teaser .field--name-field-product-new,
  .page-search .node--news.node--search-index .field--name-field-product-new,
  .page-search .node--news.node--vblock .field--name-field-product-new,
  .page-search .node--page.node--teaser .field--name-field-product-new,
  .page-search .node--page.node--search-index .field--name-field-product-new,
  .page-search .node--page.node--vblock .field--name-field-product-new {
    position: absolute;
    right: 0;
    top: 0;
    background: #01B9E8;
    color: #ffffff;
    padding: 4px 10px 1px;
    font-size: 0.875rem;
    max-width: 82px; }
  .node--product-display.node--teaser .field-product-discount, .node--product-display.node--search-index .field-product-discount, .node--product-display.node--vblock .field-product-discount,
  .page-search .node--news.node--teaser .field-product-discount,
  .page-search .node--news.node--search-index .field-product-discount,
  .page-search .node--news.node--vblock .field-product-discount,
  .page-search .node--page.node--teaser .field-product-discount,
  .page-search .node--page.node--search-index .field-product-discount,
  .page-search .node--page.node--vblock .field-product-discount {
    position: absolute;
    left: 0;
    top: 0;
    background: #eb5b0c;
    color: #ffffff;
    padding: 5px 10px;
    max-width: 82px; }
    .node--product-display.node--teaser .field-product-discount .label, .node--product-display.node--search-index .field-product-discount .label, .node--product-display.node--vblock .field-product-discount .label,
    .page-search .node--news.node--teaser .field-product-discount .label,
    .page-search .node--news.node--search-index .field-product-discount .label,
    .page-search .node--news.node--vblock .field-product-discount .label,
    .page-search .node--page.node--teaser .field-product-discount .label,
    .page-search .node--page.node--search-index .field-product-discount .label,
    .page-search .node--page.node--vblock .field-product-discount .label {
      display: block;
      font-size: 0.875rem; }
    .node--product-display.node--teaser .field-product-discount .value, .node--product-display.node--search-index .field-product-discount .value, .node--product-display.node--vblock .field-product-discount .value,
    .page-search .node--news.node--teaser .field-product-discount .value,
    .page-search .node--news.node--search-index .field-product-discount .value,
    .page-search .node--news.node--vblock .field-product-discount .value,
    .page-search .node--page.node--teaser .field-product-discount .value,
    .page-search .node--page.node--search-index .field-product-discount .value,
    .page-search .node--page.node--vblock .field-product-discount .value {
      font-weight: 700;
      line-height: 100%;
      display: block; }
  .node--product-display.node--teaser .commerce-product-field-field-commerce-init-price, .node--product-display.node--search-index .commerce-product-field-field-commerce-init-price, .node--product-display.node--vblock .commerce-product-field-field-commerce-init-price,
  .page-search .node--news.node--teaser .commerce-product-field-field-commerce-init-price,
  .page-search .node--news.node--search-index .commerce-product-field-field-commerce-init-price,
  .page-search .node--news.node--vblock .commerce-product-field-field-commerce-init-price,
  .page-search .node--page.node--teaser .commerce-product-field-field-commerce-init-price,
  .page-search .node--page.node--search-index .commerce-product-field-field-commerce-init-price,
  .page-search .node--page.node--vblock .commerce-product-field-field-commerce-init-price {
    margin-top: 12px;
    text-decoration: line-through;
    color: #444444; }
  .node--product-display.node--teaser .commerce-product-field-commerce-price, .node--product-display.node--search-index .commerce-product-field-commerce-price, .node--product-display.node--vblock .commerce-product-field-commerce-price,
  .page-search .node--news.node--teaser .commerce-product-field-commerce-price,
  .page-search .node--news.node--search-index .commerce-product-field-commerce-price,
  .page-search .node--news.node--vblock .commerce-product-field-commerce-price,
  .page-search .node--page.node--teaser .commerce-product-field-commerce-price,
  .page-search .node--page.node--search-index .commerce-product-field-commerce-price,
  .page-search .node--page.node--vblock .commerce-product-field-commerce-price {
    font-size: 1.5rem;
    color: #9bb528;
    font-weight: 700;
    line-height: 1.4;
    clear: both; }
  .node--product-display.node--teaser .field--name-field-product-roll-image,
  .node--product-display.node--teaser .field--name-field-images,
  .node--product-display.node--teaser .field__images, .node--product-display.node--search-index .field--name-field-product-roll-image,
  .node--product-display.node--search-index .field--name-field-images,
  .node--product-display.node--search-index .field__images, .node--product-display.node--vblock .field--name-field-product-roll-image,
  .node--product-display.node--vblock .field--name-field-images,
  .node--product-display.node--vblock .field__images,
  .page-search .node--news.node--teaser .field--name-field-product-roll-image,
  .page-search .node--news.node--teaser .field--name-field-images,
  .page-search .node--news.node--teaser .field__images,
  .page-search .node--news.node--search-index .field--name-field-product-roll-image,
  .page-search .node--news.node--search-index .field--name-field-images,
  .page-search .node--news.node--search-index .field__images,
  .page-search .node--news.node--vblock .field--name-field-product-roll-image,
  .page-search .node--news.node--vblock .field--name-field-images,
  .page-search .node--news.node--vblock .field__images,
  .page-search .node--page.node--teaser .field--name-field-product-roll-image,
  .page-search .node--page.node--teaser .field--name-field-images,
  .page-search .node--page.node--teaser .field__images,
  .page-search .node--page.node--search-index .field--name-field-product-roll-image,
  .page-search .node--page.node--search-index .field--name-field-images,
  .page-search .node--page.node--search-index .field__images,
  .page-search .node--page.node--vblock .field--name-field-product-roll-image,
  .page-search .node--page.node--vblock .field--name-field-images,
  .page-search .node--page.node--vblock .field__images {
    border-bottom: 8px solid #9bb528; }
    .node--product-display.node--teaser .field--name-field-product-roll-image img,
    .node--product-display.node--teaser .field--name-field-images img,
    .node--product-display.node--teaser .field__images img, .node--product-display.node--search-index .field--name-field-product-roll-image img,
    .node--product-display.node--search-index .field--name-field-images img,
    .node--product-display.node--search-index .field__images img, .node--product-display.node--vblock .field--name-field-product-roll-image img,
    .node--product-display.node--vblock .field--name-field-images img,
    .node--product-display.node--vblock .field__images img,
    .page-search .node--news.node--teaser .field--name-field-product-roll-image img,
    .page-search .node--news.node--teaser .field--name-field-images img,
    .page-search .node--news.node--teaser .field__images img,
    .page-search .node--news.node--search-index .field--name-field-product-roll-image img,
    .page-search .node--news.node--search-index .field--name-field-images img,
    .page-search .node--news.node--search-index .field__images img,
    .page-search .node--news.node--vblock .field--name-field-product-roll-image img,
    .page-search .node--news.node--vblock .field--name-field-images img,
    .page-search .node--news.node--vblock .field__images img,
    .page-search .node--page.node--teaser .field--name-field-product-roll-image img,
    .page-search .node--page.node--teaser .field--name-field-images img,
    .page-search .node--page.node--teaser .field__images img,
    .page-search .node--page.node--search-index .field--name-field-product-roll-image img,
    .page-search .node--page.node--search-index .field--name-field-images img,
    .page-search .node--page.node--search-index .field__images img,
    .page-search .node--page.node--vblock .field--name-field-product-roll-image img,
    .page-search .node--page.node--vblock .field--name-field-images img,
    .page-search .node--page.node--vblock .field__images img {
      display: block; }

.view-products-related-products .views-row {
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
  margin-bottom: 25px; }

.view-products-related-products .node--product-display.node--vblock .group-box-shadow {
  min-height: auto; }

.node--product-display.node--search-index .group-box-shadow,
.page-search .node--news.node--search-index .group-box-shadow,
.page-search .node--page.node--search-index .group-box-shadow {
  min-height: 222px; }

.page-request-form .l-main {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px; }
  .page-request-form .l-main:after {
    content: "";
    display: table;
    clear: both; }
  .page-request-form .l-main .l-content {
    width: 74.46809%;
    float: right;
    margin-right: 0; }
    .page-request-form .l-main .l-content .node__content h1 {
      padding: 22px;
      color: #ffffff;
      background: #b3cc3c;
      border-radius: 8px 8px 0 0;
      font-size: 1.125rem;
      line-height: 100%; }
  .page-request-form .l-main .node--webform.node--full {
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
    border-radius: 8px; }
  .page-request-form .l-main .l-region--sidebar-first {
    width: 23.40426%;
    float: left;
    margin-right: 2.12766%; }
  .page-request-form .l-main .webform-client-form {
    padding: 30px;
    border: 1px solid #e8e8e8; }
    .page-request-form .l-main .webform-client-form .form-item {
      margin-bottom: 32px; }
      .page-request-form .l-main .webform-client-form .form-item input[type="text"],
      .page-request-form .l-main .webform-client-form .form-item input[type="email"],
      .page-request-form .l-main .webform-client-form .form-item textarea {
        width: 100%;
        padding: 4px 0;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        overflow: visible;
        border: none;
        border-bottom: 1px solid #b3cc3c; }
        .page-request-form .l-main .webform-client-form .form-item input[type="text"]::placeholder,
        .page-request-form .l-main .webform-client-form .form-item input[type="email"]::placeholder,
        .page-request-form .l-main .webform-client-form .form-item textarea::placeholder {
          color: #909090;
          opacity: 1; }
      .page-request-form .l-main .webform-client-form .form-item:last-child {
        margin-bottom: 0; }
      .page-request-form .l-main .webform-client-form .form-item textarea {
        min-height: 130px; }
    .page-request-form .l-main .webform-client-form input[type="submit"] {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      color: white;
      background: #b3cc3c;
      border: none;
      font-size: 1.125rem;
      padding: 8px 18px;
      display: block;
      margin: 0 auto; }
      .page-request-form .l-main .webform-client-form input[type="submit"]:hover, .page-request-form .l-main .webform-client-form input[type="submit"]:focus {
        cursor: pointer;
        background: #9bb528; }
    .page-request-form .l-main .webform-client-form .form-actions a {
      display: block;
      margin-top: 20px;
      color: #444444; }
      .page-request-form .l-main .webform-client-form .form-actions a:hover, .page-request-form .l-main .webform-client-form .form-actions a:focus {
        color: #9bb528; }

.page-request-form .view-e-shop .views-row {
  width: 100%; }

.page-request-form .node--product-display.node--vblock .group-box-shadow {
  min-height: auto; }

.not-logged-in.page-request-form .l-main .webform-client-form .webform-component-textarea {
  margin-bottom: 19px; }

.not-logged-in.page-request-form .l-main .webform-client-form .webform-component--proion {
  margin-bottom: 0; }

.iradio_minimal-green {
  margin-right: 3px; }

.addthis_inline_share_toolbox {
  margin-top: 20px; }

.addthis-smartlayers #at4-share, .addthis-smartlayers #at4-soc {
  top: 24% !important; }

body.node-type-product-display .selectboxit-container .selectboxit {
  border: 2px solid #dddddd;
  border-radius: 0; }
  body.node-type-product-display .selectboxit-container .selectboxit span {
    color: #444444;
    font-weight: 700; }

.mz-zoom-window {
  z-index: 99; }

.page-search-results .l-content {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 0; }
  .page-search-results .l-content:after {
    content: "";
    display: table;
    clear: both; }
  .page-search-results .l-content h1 {
    font-size: 3rem;
    line-height: 1.1;
    margin-bottom: 30px; }

.search-results .search-result-inner {
  min-height: 107px; }

.search-results .search-result {
  border-top: 8px solid #9bb528;
  width: 23.40426%;
  float: left;
  margin-right: 2.12766%;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
  margin-bottom: 2.12766%;
  padding: 16px; }
  .search-results .search-result:nth-child(4n) {
    width: 23.40426%;
    float: right;
    margin-right: 0; }
  .search-results .search-result:nth-child(4n+1) {
    clear: both;
    width: 23.40426%;
    float: left;
    margin-right: 2.12766%; }
  .search-results .search-result .search-result__snippet {
    font-size: 0.875rem;
    line-height: 1.32;
    margin-bottom: 12px;
    color: #444444; }
  .search-results .search-result h3 {
    font-size: 1.125rem;
    line-height: 1.32;
    margin-bottom: 12px;
    color: #444444; }
    .search-results .search-result h3 a {
      color: #000;
      transition: 0.2s linear; }
      .search-results .search-result h3 a:hover, .search-results .search-result h3 a:focus {
        color: #9bb528; }
  .search-results .search-result:hover, .search-results .search-result:focus {
    background: #e4e4e4; }

.view-slideshow .view-content .views-row {
  display: none; }
  .view-slideshow .view-content .views-row.views-row-1 {
    display: block; }

.view-slideshow .view-content.slick-initialized .slick-slide {
  display: block; }

.node--slideshow.node-teaser {
  margin-bottom: 30px; }
  .node--slideshow.node-teaser .node__content {
    width: 100%;
    background: #9bb528;
    height: 442px;
    position: relative; }
    .node--slideshow.node-teaser .node__content:after {
      content: "";
      display: table;
      clear: both; }
  .node--slideshow.node-teaser .group-wrapper {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .node--slideshow.node-teaser .group-wrapper .group-inner {
      max-width: 1240px;
      margin-left: auto;
      margin-right: auto;
      margin: 0 auto; }
      .node--slideshow.node-teaser .group-wrapper .group-inner:after {
        content: "";
        display: table;
        clear: both; }
    .node--slideshow.node-teaser .group-wrapper .group-left-side {
      max-width: 50%;
      padding: 50px;
      margin-right: 55%; }
  .node--slideshow.node-teaser .field--name-body {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 40px; }
  .node--slideshow.node-teaser .field--name-title-field {
    margin-bottom: 20px; }
  .node--slideshow.node-teaser .field--name-field-initial-price {
    font-size: 2.5rem;
    color: #ffffff;
    text-decoration: line-through;
    line-height: 1.1;
    margin-bottom: 10px; }
    .node--slideshow.node-teaser .field--name-field-initial-price .from-price {
      display: inline-block;
      text-decoration: none;
      margin-right: 10px; }
  .node--slideshow.node-teaser .field--name-field-final-price {
    font-size: 3rem;
    font-weight: 700;
    color: #ffffff;
    line-height: 1.1; }
  .node--slideshow.node-teaser .field--name-field-slideshow-image {
    width: 50%; }
  .node--slideshow.node-teaser .field--name-field-link {
    margin-top: 30px;
    font-size: 1.125rem; }
    .node--slideshow.node-teaser .field--name-field-link a {
      color: #ffffff; }
      .node--slideshow.node-teaser .field--name-field-link a:after {
        content: "";
        background-image: url(../images/sprites/build/sprites.png);
        background-position: -711px -577px;
        width: 12px;
        height: 18px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 30px; }

.page-taxonomy-term.section-blog .l-page {
  background: #ffffff; }

.blog-section .views-row-last .node--blog {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0; }

.blog-section .l-main {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 0; }
  .blog-section .l-main:after {
    content: "";
    display: table;
    clear: both; }

.blog-section .l-region--sidebar-second {
  width: 31.91489%;
  float: left;
  margin-left: 68.08511%;
  margin-right: -100%; }
  .blog-section .l-region--sidebar-second .block__title {
    margin-bottom: 20px;
    color: #000;
    line-height: 1.34;
    font-size: 1.5rem;
    padding-left: 10px; }
  .blog-section .l-region--sidebar-second .block {
    margin-bottom: 20px; }
    .blog-section .l-region--sidebar-second .block:last-child {
      margin-bottom: 0; }

.blog-section .l-content {
  width: 65.95745%;
  float: left;
  margin-left: 0;
  margin-right: -100%; }

.view-blog.view-display-id-block_1 .views-row:last-child .node--blog.node--vblock {
  margin-bottom: 0;
  padding-bottom: 0; }

.block--multiblock-1 {
  padding: 20px;
  background: #efefef;
  margin-bottom: 30px; }
  .block--multiblock-1 .block__title {
    display: inline-block;
    border-left: 8px solid #74a2ff; }

.block--multiblock-2 {
  padding: 20px;
  background: #efefef;
  margin-bottom: 0 !important; }
  .block--multiblock-2 .social-media-icons {
    list-style-type: none;
    float: none;
    margin: 0;
    padding: 0; }
    .block--multiblock-2 .social-media-icons li {
      margin-bottom: 0; }
  .block--multiblock-2 .block__content {
    display: inline-block;
    vertical-align: middle;
    padding-left: 7.5%;
    margin-bottom: 5px; }
  .block--multiblock-2 .block__title {
    display: inline-block;
    border-left: 8px solid #74a2ff; }

.node-type-blog {
  /*Styling blog node's content after 1.3 update in structure*/ }
  .node-type-blog .panel-col-top {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto; }
    .node-type-blog .panel-col-top:after {
      content: "";
      display: table;
      clear: both; }
  .node-type-blog .panel-panel .inside {
    margin: 0; }
  .node-type-blog .center-wrapper {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0; }
    .node-type-blog .center-wrapper:after {
      content: "";
      display: table;
      clear: both; }
    .node-type-blog .center-wrapper .panel-col-first {
      width: 65.95745%;
      float: left;
      margin-left: 0;
      margin-right: -100%; }
    .node-type-blog .center-wrapper .panel-col-last {
      width: 31.91489%;
      float: left;
      margin-left: 68.08511%;
      margin-right: -100%; }
      .node-type-blog .center-wrapper .panel-col-last inside {
        margin: 0; }
      .node-type-blog .center-wrapper .panel-col-last .panel-pane:last-child {
        margin-bottom: 0; }
      .node-type-blog .center-wrapper .panel-col-last .pane-title {
        margin-bottom: 20px;
        color: #000;
        padding: 5px;
        padding-left: 10px;
        line-height: 1.34;
        font-size: 1.5rem; }
  .node-type-blog .panel-col-first .field--name-title-field {
    font-size: 2rem;
    font-weight: 700; }
  .node-type-blog .panel-col-first .custom-post-date {
    margin: 15px 0; }
  .node-type-blog .panel-col-last .social-media-icons {
    list-style-type: none;
    float: none;
    margin: 0;
    padding: 0; }
    .node-type-blog .panel-col-last .social-media-icons li {
      margin-bottom: 0; }
  .node-type-blog .panel-col-last .pane-block-2,
  .node-type-blog .panel-col-last .pane-mailchimp-signup-thepromoticket-newsletter {
    background: #efefef;
    padding: 20px;
    padding-bottom: 0; }
    .node-type-blog .panel-col-last .pane-block-2 .panels-outer-wrapper,
    .node-type-blog .panel-col-last .pane-mailchimp-signup-thepromoticket-newsletter .panels-outer-wrapper {
      display: inline-block;
      vertical-align: middle;
      padding-left: 7.5%;
      margin-bottom: 5px; }
    .node-type-blog .panel-col-last .pane-block-2 .pane-title,
    .node-type-blog .panel-col-last .pane-mailchimp-signup-thepromoticket-newsletter .pane-title {
      display: inline-block;
      border-left: 8px solid #74a2ff; }
  .node-type-blog .panel-col-last .pane-mailchimp-signup-thepromoticket-newsletter .panels-outer-wrapper {
    padding-left: 0; }
  .node-type-blog .panel-col-last .pane-mailchimp-signup-thepromoticket-newsletter .pane-title {
    display: block;
    border-left: 8px solid #74a2ff; }

.node--blog:after {
  content: "";
  display: table;
  clear: both; }

.node--blog .field--name-field-image {
  position: relative; }
  .node--blog .field--name-field-image:after {
    transition: background 0.3s;
    background: none;
    content: "";
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute; }

.node--blog .link-wrap-of-blog:hover .field--name-field-image:after, .node--blog .link-wrap-of-blog:focus .field--name-field-image:after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(151, 172, 36, 0.72); }

.node--blog.node--blog--blog-post-front .group-blog-image {
  display: inline-block;
  position: relative;
  margin: 0 auto; }
  .node--blog.node--blog--blog-post-front .group-blog-image .custom-post-date {
    color: #ffffff;
    padding: 2px 5px;
    background: rgba(151, 172, 36, 0.72);
    position: absolute;
    top: 0;
    font-family: "Roboto Condensed", sans-serif;
    left: 0;
    font-size: 0.875rem;
    z-index: 1; }

.node--blog.node--teaser {
  margin-bottom: 30px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 30px; }
  .node--blog.node--teaser .group-blog-image {
    float: left;
    margin-right: 20px;
    position: relative; }
    .node--blog.node--teaser .group-blog-image .custom-post-date {
      color: #ffffff;
      padding: 2px 5px;
      background: rgba(151, 172, 36, 0.72);
      position: absolute;
      top: 0;
      font-family: "Roboto Condensed", sans-serif;
      left: 0;
      font-size: 0.875rem;
      z-index: 1; }
  .node--blog.node--teaser .group-blog-text {
    overflow: hidden; }
  .node--blog.node--teaser .field--name-title-field h2 {
    line-height: 1.38;
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #000; }
    .node--blog.node--teaser .field--name-title-field h2 a {
      color: #000; }
  .node--blog.node--teaser .field--name-field-image img {
    display: block; }

.node--blog.node--vblock {
  margin-bottom: 15px;
  padding-bottom: 15px; }
  .node--blog.node--vblock .field--name-title-field h2 {
    color: #000;
    line-height: 1.35;
    font-size: 1.125rem; }
    .node--blog.node--vblock .field--name-title-field h2 a {
      color: #000; }
  .node--blog.node--vblock .field--name-field-image {
    float: left;
    margin-right: 10px; }
    .node--blog.node--vblock .field--name-field-image img {
      display: block; }

.node--blog.node--about .group-blog-full-image {
  position: relative; }

.node--blog.node--about .field--name-title-field h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  line-height: 1.2; }

.node--blog.node--about .group-blog-meta {
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.125rem; }
  .node--blog.node--about .group-blog-meta a {
    color: #ffffff;
    text-decoration: underline; }
  .node--blog.node--about .group-blog-meta .field--name-field-blog-category,
  .node--blog.node--about .group-blog-meta .custom-post-date {
    display: inline; }
  .node--blog.node--about .group-blog-meta .field--name-field-blog-category .field__items,
  .node--blog.node--about .group-blog-meta .field--name-field-blog-category .field__item {
    display: inline;
    font-weight: 700; }
  .node--blog.node--about .group-blog-meta .custom-post-date:before {
    content: " x ";
    display: inline;
    font-weight: 400;
    font-size: 0.875rem;
    position: relative;
    top: -1px; }

.node--blog.node--about .group-blog-text {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  padding: 20px;
  color: #ffffff;
  background: rgba(151, 172, 36, 0.9); }

.node--blog.node--full .field--name-body {
  font-size: 1.125rem; }

#block-gtweaks-page-title-block {
  background: #dddddd;
  padding-bottom: 10px;
  margin-bottom: 25px; }
  #block-gtweaks-page-title-block .blog_main_image {
    background: url("../images/blog_top_page.png");
    background-position: center;
    height: 350px; }
  #block-gtweaks-page-title-block .block__content .header_container {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    margin: 0 auto;
    padding-top: 10px; }
    #block-gtweaks-page-title-block .block__content .header_container:after {
      content: "";
      display: table;
      clear: both; }
  #block-gtweaks-page-title-block .block__content h1 {
    color: #444444;
    padding-left: 5px; }

.block--views-blog-block-1 .block__title,
.block--views-blog-block-1 .pane-title {
  border-left: 8px solid #daab1d; }

#block-block-52,
.pane-block-52 {
  padding-top: 20px;
  background: #ffffff; }
  #block-block-52 .container,
  .pane-block-52 .container {
    background: url("../images/promo_banner.jpg");
    max-width: 374px;
    height: 284px; }
  #block-block-52 .title,
  .pane-block-52 .title {
    color: #ffffff;
    padding: 20px;
    font-size: 1.2rem;
    text-align: center; }
  #block-block-52 .btn,
  .pane-block-52 .btn {
    color: #ffffff;
    margin-top: 25px;
    text-align: center; }
    #block-block-52 .btn a,
    .pane-block-52 .btn a {
      color: #ffffff;
      padding: 10px;
      border: 1px solid #ffffff;
      border-radius: 10px; }
      #block-block-52 .btn a:hover,
      .pane-block-52 .btn a:hover {
        box-shadow: 0 0 8px rgba(255, 255, 255, 0.5); }

.block--views-blog-taxonomy-block-1 {
  background: #ffffff;
  padding-top: 20px; }
  .block--views-blog-taxonomy-block-1 .view-blog-taxonomy {
    padding-left: 10px; }
  .block--views-blog-taxonomy-block-1 .block__title,
  .block--views-blog-taxonomy-block-1 .pane-title {
    font-weight: 700; }
  .block--views-blog-taxonomy-block-1 .views-row a {
    color: #000;
    font-size: 1.2rem;
    line-height: 1.9; }
    .block--views-blog-taxonomy-block-1 .views-row a:hover, .block--views-blog-taxonomy-block-1 .views-row a:focus {
      color: #9bb528; }
  .block--views-blog-taxonomy-block-1 .field--name-name-field {
    margin-bottom: 3px; }

/*! Gray v1.5.0 (https://github.com/karlhorky/gray) | MIT */
.grayscale {
  /* Firefox 10-34 */
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='saturate' values='0'/></filter></svg>#grayscale");
  /*
    Chrome 19+,
    Safari 6+,
    Safari 6+ iOS,
    Opera 15+
  */
  -webkit-filter: grayscale(1);
  /* Firefox 35+ */
  filter: grayscale(1);
  /* IE 6-9 */
  filter: gray; }

.grayscale.grayscale-fade {
  transition: filter .5s; }

/* Webkit hack until filter is unprefixed */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .grayscale.grayscale-fade {
    -webkit-transition: -webkit-filter .5s;
    transition: -webkit-filter .5s; } }

.grayscale.grayscale-off,
.grayscale.grayscale-fade:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0); }

/* Background element */
.grayscale.grayscale-replaced {
  -webkit-filter: none;
  filter: none; }

.grayscale.grayscale-replaced > svg {
  -webkit-transition: opacity .5s ease;
  transition: opacity .5s ease;
  opacity: 1; }

.grayscale.grayscale-replaced.grayscale-off > svg,
.grayscale.grayscale-replaced.grayscale-fade:hover > svg {
  opacity: 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.selectboxit-container {
  width: 100%; }
  .selectboxit-container .selectboxit-list {
    background-color: #ffffff;
    border-radius: 2px;
    border: none; }
  .selectboxit-container .selectboxit-options {
    border-radius: 2px; }
    .selectboxit-container .selectboxit-options .selectboxit-option-anchor {
      height: 36px;
      line-height: 38px; }
  .selectboxit-container .selectboxit {
    width: 100%;
    border-radius: 2px;
    background: #ffffff;
    border: none;
    float: none;
    height: 44px; }
    .selectboxit-container .selectboxit:hover, .selectboxit-container .selectboxit:focus {
      background: #ffffff; }
    .selectboxit-container .selectboxit span, .selectboxit-container .selectboxit .selectboxit-options a {
      background: #ffffff;
      border-radius: 2px;
      text-align: left;
      height: 50px;
      border: none;
      line-height: 44px;
      display: block;
      color: #909090;
      font-size: 0.875rem;
      font-weight: 400; }

.selectboxit .selectboxit-arrow-container .promoticket-select {
  margin-top: -4px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #b3cc3c; }

.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  color: white;
  background: #b3cc3c; }

.slicknav_btn {
  position: relative;
  display: block;
  vertical-align: middle;
  float: right;
  padding: 0.438em 0.625em 0.438em 0.625em;
  line-height: 1.125em;
  cursor: pointer; }
  .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
    margin-top: 0.188em; }

.slicknav_menu {
  *zoom: 1; }
  .slicknav_menu .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left; }
  .slicknav_menu .slicknav_icon {
    float: left;
    width: 1.125em;
    height: 0.875em;
    margin: 0.188em 0 0 0.438em; }
    .slicknav_menu .slicknav_icon:before {
      background: transparent;
      width: 1.125em;
      height: 0.875em;
      display: block;
      content: "";
      position: absolute; }
  .slicknav_menu .slicknav_no-text {
    margin: 0; }
  .slicknav_menu .slicknav_icon-bar {
    display: block;
    width: 1.125em;
    height: 0.125em;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); }
  .slicknav_menu:before {
    content: " ";
    display: table; }
  .slicknav_menu:after {
    content: " ";
    display: table;
    clear: both; }

.slicknav_nav {
  clear: both; }
  .slicknav_nav ul {
    display: block; }
  .slicknav_nav li {
    display: block; }
  .slicknav_nav .slicknav_arrow {
    font-size: 0.8em;
    margin: 0 0 0 0.4em; }
  .slicknav_nav .slicknav_item {
    cursor: pointer; }
    .slicknav_nav .slicknav_item a {
      display: inline; }
  .slicknav_nav .slicknav_row {
    display: block; }
  .slicknav_nav a {
    display: block; }
  .slicknav_nav .slicknav_parent-link a {
    display: inline; }

.slicknav_brand {
  float: left; }

.slicknav_menu {
  clear: both;
  background: #9bb528; }
  .slicknav_menu .slicknav_btn {
    position: static;
    display: block;
    vertical-align: middle;
    float: none;
    padding: 0;
    line-height: 0;
    cursor: pointer; }
    .slicknav_menu .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
      margin-top: 0; }
    .slicknav_menu .slicknav_btn .slicknav_icon {
      float: none;
      display: none; }
  .slicknav_menu div.slicknav_nav {
    margin: 0;
    padding: 0; }
    .slicknav_menu div.slicknav_nav .slicknav_arrow {
      color: white;
      margin-left: 0.1em;
      font-size: 16px; }
    .slicknav_menu div.slicknav_nav li.slicknav_parent a.menuparent, .slicknav_menu div.slicknav_nav li.slicknav_parent span.nolink {
      display: inline-block;
      margin-top: -3px;
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: none;
      padding-right: 30px; }
      .slicknav_menu div.slicknav_nav li.slicknav_parent a.menuparent ul, .slicknav_menu div.slicknav_nav li.slicknav_parent span.nolink ul {
        clear: both; }
    .slicknav_menu div.slicknav_nav li a, .slicknav_menu div.slicknav_nav li span.nolink {
      display: block;
      color: white;
      font-weight: 700;
      padding-left: 0.3em;
      position: relative;
      padding: 0.4em;
      font-weight: 700;
      border-bottom: 1px solid #b3cc3c; }
      .slicknav_menu div.slicknav_nav li a:last-child a, .slicknav_menu div.slicknav_nav li span.nolink:last-child a {
        border-bottom: none; }
    .slicknav_menu div.slicknav_nav li.slicknav_parent > a {
      padding-left: 0; }
    .slicknav_menu div.slicknav_nav li .slicknav_arrow {
      position: absolute;
      top: 0;
      right: 0;
      height: 36px;
      width: 56px;
      display: block;
      line-height: 36px;
      vertical-align: middle;
      text-align: center; }
  .slicknav_menu .slicknav_nav .sf-hidden li a {
    padding-left: 30px; }
  .slicknav_menu .slicknav_menutxt {
    display: block;
    float: none;
    line-height: 160%;
    /*     margin-right: 0.8em;
    margin-bottom: -0.2em; */
    vertical-align: text-bottom;
    padding: 0.6em;
    background: #9bb528;
    color: white;
    width: 2.8em;
    height: 2.8em;
    cursor: pointer;
    position: absolute; }
    .slicknav_menu .slicknav_menutxt .resp-mnu {
      margin-top: 0.678em;
      display: block;
      background: white;
      width: 100%;
      height: 0.3em;
      border-radius: 2px; }
      .slicknav_menu .slicknav_menutxt .resp-mnu:after {
        display: block;
        content: "";
        position: relative;
        top: 0.39em;
        background: white;
        width: 100%;
        height: 0.3em;
        border-radius: 2px; }
      .slicknav_menu .slicknav_menutxt .resp-mnu:before {
        display: block;
        content: "";
        position: relative;
        top: -0.69em;
        background: white;
        width: 100%;
        height: 0.3em;
        border-radius: 2px; }

/*** ESSENTIAL STYLES ***/
.sf-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
  margin-bottom: 1em; }
  .sf-menu * {
    margin: 0;
    padding: 0;
    list-style: none; }
  .sf-menu li {
    position: relative; }
  .sf-menu ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 99; }
  .sf-menu > li {
    float: left; }
  .sf-menu li:hover > ul, .sf-menu li.sfHover > ul {
    display: block; }
  .sf-menu a {
    display: block;
    position: relative; }
  .sf-menu ul {
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    min-width: 12em;
    /* allow long menu items to determine submenu width */
    *width: 12em;
    /* no auto sub width for IE7, see white-space comment below */ }
    .sf-menu ul ul {
      top: 0;
      left: 100%; }
  .sf-menu a {
    border-left: 1px solid #fff;
    border-top: 1px solid #dFeEFF;
    /* fallback colour must use full shorthand */
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding: .75em 1em;
    text-decoration: none;
    zoom: 1;
    /* IE7 */
    color: #13a; }
  .sf-menu li {
    background: #BDD2FF;
    white-space: nowrap;
    /* no need for Supersubs plugin */
    *white-space: normal;
    /* ...unless you support IE7 (let it wrap) */
    -webkit-transition: background .2s;
    transition: background .2s; }
  .sf-menu li:hover, .sf-menu li.sfHover {
    /* only transition out, not in */
    -webkit-transition: none;
    transition: none; }

/*** adding the class sf-navbar in addition to sf-menu creates an all-horizontal nav-bar menu ***/
.sf-navbar {
  background: #ffffff;
  position: relative;
  margin-bottom: 0;
  /*  &:before {
    content: '';
    position: absolute;
    left: 0;
    z-index: -1;
    height: 200%;
    width: 100%;
  }*/ }
  .sf-navbar ul {
    box-shadow: none;
    background-color: #eeeeee;
    box-shadow: 0 8px 8px -8px rgba(0, 0, 0, 0.4); }
  .sf-navbar li {
    background: #ffffff;
    position: static; }
  .sf-navbar > li > a {
    border: none; }
  .sf-navbar > li > ul {
    min-width: 36em;
    /* set this to whatever suits your design */ }
    .sf-navbar > li > ul > li > a {
      border: none; }
  .sf-navbar ul li {
    background: #eeeeee;
    position: relative; }
  .sf-navbar ul ul {
    left: 0;
    top: 100%; }
    .sf-navbar ul ul li {
      width: 100%; }
  .sf-navbar > li > ul > li {
    float: left; }
  .sf-navbar ul ul li {
    background: #333333; }

/* provide background colour for submenu strip */
/* you should just set the menu's container bg colour rather than use pseudo-elements */
/*** point all arrows down ***/
.sf-arrows.sf-navbar ul .sf-with-ul:after {
  margin-top: -3px;
  margin-right: 0;
  border-color: transparent;
  border-top-color: #dFeEFF;
  /* edit this to suit design (no rgba in IE8) */
  border-top-color: rgba(255, 255, 255, 0.5); }

.sf-arrows.sf-navbar ul > li > .sf-with-ul:focus:after, .sf-arrows.sf-navbar ul > li:hover > .sf-with-ul:after {
  border-color: transparent;
  border-top-color: white; }

.sf-arrows.sf-navbar ul > .sfHover > .sf-with-ul:after {
  border-color: transparent;
  border-top-color: white; }

#block-gtweaks-mobile-menu {
  display: none; }

.page-user .l-content {
  padding-left: 10px;
  padding-right: 10px; }

.l-header .slicknav_menu {
  z-index: 9000; }

@media (max-width: 1366px) {
  .node--product-display .attribute-widgets {
    margin-bottom: 24px; }
  .node--product-display .field-product-availability {
    margin: 15px 0; }
  .node--product-display.node--full .group-separator {
    margin-bottom: 15px;
    padding-bottom: 15px; }
  .node--product-display.node--full .field--name-field-commerce-product {
    padding: 15px 0; }
  /* 5.2 Updates: Smaller container-Page: how it works
    changes previous 1320px max-width to 1020, as requested
  */
  .how-it-works-block .how-it-works-wrapper {
    max-width: 1020px; }
  .node--product-display.node--full .field-custom-this {
    top: 15px; } }

@media (max-width: 1270px) {
  .case-studies-caru,
  .view-case-studies.view-display-id-panel_pane_2 {
    max-width: 600px;
    padding: 0 10px; }
  .view-case-studies.view-display-id-panel_pane_2 .view-header {
    width: 100%;
    float: none;
    min-height: 0; }
  .view-case-studies.view-display-id-panel_pane_2 .view-content {
    width: 100%;
    float: none; }
  .node--case-study {
    margin: 0 auto; }
    .node--case-study.node--full .field--name-field-image {
      max-width: 635px;
      float: none;
      margin-bottom: 0;
      width: 100%; }
    .node--case-study.node--full .field--name-body {
      width: 100%;
      float: none;
      background: #ffffff;
      min-height: 0;
      max-width: 635px; } }

@media (max-width: 1239px) {
  .page-request-form .view-e-shop.view-display-id-block_1 .views-row {
    width: 290px;
    margin-right: 0; }
  .page-request-form .l-main {
    padding-left: 300px; }
    .page-request-form .l-main .l-content {
      width: 100%;
      padding-left: 2.12766%;
      padding-right: 10px; }
  .page-request-form .l-region--sidebar-first {
    width: 290px;
    margin-left: -290px; }
  .rt-pwrap {
    padding: 20px 10px; }
    .rt-pwrap .zoom-wrapper-outer .zoom-wrapper-inner {
      height: auto; }
    .rt-pwrap .toi {
      text-align: center;
      bottom: 54px;
      display: block;
      padding-left: 5px;
      padding-right: 5px; }
      .rt-pwrap .toi .toi-text {
        margin: 0 auto;
        float: none;
        position: static;
        font-size: 1.25rem; }
      .rt-pwrap .toi .rmore {
        float: none;
        margin: 0 auto;
        margin-top: 4px; }
  .gray-pane-wrap {
    padding: 20px 0 20px; } }

@media (max-width: 1320px) {
  .view-e-shop.view-display-id-page {
    padding-left: 10px;
    padding-right: 10px; }
    .view-e-shop.view-display-id-page .view-content {
      max-width: 928px;
      margin-left: auto;
      margin-right: auto; }
      .view-e-shop.view-display-id-page .view-content:after {
        content: "";
        display: table;
        clear: both; }
      .view-e-shop.view-display-id-page .view-content .views-row {
        width: 31.91489%;
        float: left;
        margin-right: 2.12766%; }
        .view-e-shop.view-display-id-page .view-content .views-row:nth-child(4n+1) {
          clear: none; }
        .view-e-shop.view-display-id-page .view-content .views-row:nth-child(3n) {
          width: 31.91489%;
          float: right;
          margin-right: 0; }
        .view-e-shop.view-display-id-page .view-content .views-row:nth-child(3n+1) {
          clear: both;
          width: 31.91489%;
          float: left;
          margin-right: 2.12766%; }
  .panel-pane.rolls-caru {
    margin-bottom: 40px; }
    .panel-pane.rolls-caru .node--product-display {
      margin: 0 10px; }
    .panel-pane.rolls-caru .view.view-e-shop.view-display-id-page .view-content .views-row {
      clear: none;
      float: left;
      margin: 0; }
      .panel-pane.rolls-caru .view.view-e-shop.view-display-id-page .view-content .views-row .group-box-shadow {
        padding: 16px;
        min-height: 236px; } }

@media (max-width: 1240px) {
  .priv-cost-block .banner,
  .two-menu-stopper .fx-height {
    height: auto; } }

@media (max-width: 1200px) {
  .view-slick-front-top .ptitle--large {
    font-size: 2.5rem; }
  .banner.fx-height {
    height: auto; }
  .simple-node-wrapper {
    padding-left: 10px;
    padding-right: 10px; }
  body.node-type-product-display .panel-2col-stacked {
    padding-left: 10px;
    padding-right: 10px; }
  .case-study-descs .panels-outer-wrapper {
    padding-left: 10px;
    padding-right: 10px; }
  .node--news.node--full .group-banner .field--name-title-field {
    padding: 100px 0 100px 10px; }
  .node--news.node--full .field--name-field-image {
    max-width: 500px;
    top: 60px; }
  .node--news.node--full .group-wrapper-inner {
    padding: 140px 20px 80px; }
  /* ------ Responsive Corrections Green Face Start ------ */
  .cost-subpages .node--page.node--cost .group-banner-left-inner {
    float: none;
    width: 100%;
    max-width: 100%;
    margin-right: 0; }
  .cost-subpages .node--page.node--cost .group-banner .group-banner-left {
    height: auto; }
  .cost-subpages .node--page.node--cost .group-banner .field--name-field-images {
    float: none;
    text-align: center;
    margin: 0 auto;
    padding: 0 10px 40px 10px;
    max-width: 42%;
    min-width: 480px; }
    .cost-subpages .node--page.node--cost .group-banner .field--name-field-images img {
      position: static; }
  .where-adv-page .pane-node .where-advert-wrapper,
  .how-it-works .where-advert-wrapper,
  .advertisers-benefits .where-advert-wrapper,
  .receipt-points-page .where-advert-wrapper {
    height: auto; }
    .where-adv-page .pane-node .where-advert-wrapper .wa-left,
    .how-it-works .where-advert-wrapper .wa-left,
    .advertisers-benefits .where-advert-wrapper .wa-left,
    .receipt-points-page .where-advert-wrapper .wa-left {
      float: none;
      width: 100%;
      max-width: 100%;
      margin-right: 0; }
    .where-adv-page .pane-node .where-advert-wrapper .wa-right-icon,
    .how-it-works .where-advert-wrapper .wa-right-icon,
    .advertisers-benefits .where-advert-wrapper .wa-right-icon,
    .receipt-points-page .where-advert-wrapper .wa-right-icon {
      float: none;
      text-align: center;
      margin: 0 auto;
      max-width: 42%;
      min-width: 480px;
      padding: 0 10px 40px 10px; }
      .where-adv-page .pane-node .where-advert-wrapper .wa-right-icon img,
      .how-it-works .where-advert-wrapper .wa-right-icon img,
      .advertisers-benefits .where-advert-wrapper .wa-right-icon img,
      .receipt-points-page .where-advert-wrapper .wa-right-icon img {
        position: static; }
  .ad-ways-wrapper {
    height: auto; }
    .ad-ways-wrapper .ad-ways-data {
      float: none;
      width: 100%;
      max-width: 100%;
      margin-right: 0; }
    .ad-ways-wrapper .ad-ways-image {
      float: none;
      text-align: center;
      margin: 0 auto;
      max-width: 42%;
      min-width: 480px;
      padding: 0 10px 40px 10px; }
      .ad-ways-wrapper .ad-ways-image img {
        position: static; }
  .ad-ways-page .ad-ways-wrapper {
    height: auto; }
    .ad-ways-page .ad-ways-wrapper .ad-ways-data {
      float: none;
      width: 100%;
      max-width: 100%;
      margin-right: 0; }
    .ad-ways-page .ad-ways-wrapper .ad-ways-image {
      float: none;
      text-align: center;
      max-width: 42%;
      min-width: 480px;
      margin: 0 auto;
      padding: 0 10px 40px 10px; }
      .ad-ways-page .ad-ways-wrapper .ad-ways-image img {
        position: static; }
  /* ------ Responsive Corrections Green Face End ------ */
  .contact-us-pane-page .pane-node {
    padding: 40px 0; }
  .contact-us-pane-page .field--name-body {
    width: 100%;
    float: none;
    margin-right: 0; }
  .contact-us-pane-page .wch-wrapper {
    max-width: 100%;
    padding: 0 10px;
    margin-bottom: 40px; }
  .contact-us-pane-page .wf-wrapper {
    float: none;
    max-width: 604px;
    margin: 0 auto;
    width: 100%; }
  .banner,
  .pane-block.panel-pane .banner {
    height: auto; }
    .banner--larger,
    .pane-block.panel-pane .banner--larger {
      min-height: 0;
      height: auto; }
  .two-menu-stopper .banner {
    height: auto; }
    .two-menu-stopper .banner--larger {
      min-height: 0;
      height: auto; }
  .about-us-page .group-inner {
    height: auto; }
  .about-us-page .about-us-inner {
    max-width: 100%;
    padding: 0;
    margin-right: 0;
    float: none; }
  .about-us-page .field--name-field-images {
    position: static;
    padding-top: 0;
    text-align: center; }
  .about-us-page .panel-pane.pane-block .about-us-wrapper {
    padding: 0 0 20px; }
  .about-us-page .about-us-title {
    padding: 40px 10px;
    margin-right: 0;
    float: none;
    width: 100%; }
  .about-us-page .about-us-body,
  .about-us-page .about-us-text {
    margin-right: 0;
    float: none;
    padding: 0 10px;
    width: 100%; } }

@media (max-width: 1060px) {
  .basket-block .info-banner {
    float: none;
    width: 100%;
    padding: 0 10px; }
  .front .l-key-points__right {
    padding-left: 30px; }
  .l-page .key-points-list__title {
    font-size: 1.25rem; }
  .l-page .key-points-list__text {
    font-size: 1rem; }
  body.node-type-product-display .panel-2col-stacked .center-wrapper .panel-col-first .panels-outer-wrapper {
    padding-left: 1%; }
  body.node-type-product-display .panel-2col-stacked .center-wrapper .panel-col-last .panels-outer-wrapper {
    padding-right: 8%; }
  .pane-gtweaks-related-products h2.pane-title {
    padding-right: 10%; }
  .l-footer nav {
    margin-bottom: 20px; }
  .l-footer #block-menu-menu-footer-select-menu {
    margin-top: 20px; }
  .l-footer #block-menu-menu-footer-select-menu,
  .l-footer #block-menu-menu-footer-menu-middle,
  .l-footer #block-menu-menu-footer-menu-right {
    float: none;
    width: 100%; }
    .l-footer #block-menu-menu-footer-select-menu .block__title,
    .l-footer #block-menu-menu-footer-menu-middle .block__title,
    .l-footer #block-menu-menu-footer-menu-right .block__title {
      margin-bottom: 20px; }
  .blog-section .l-content {
    width: 100%;
    float: none;
    margin: 0 0 20px 0; }
  .blog-section .l-region--sidebar-second {
    width: 100%;
    float: none;
    margin: 0; }
  .node-type-blog .center-wrapper .panel-col-last {
    width: 100%;
    float: none;
    margin: 0; }
  .node-type-blog .center-wrapper .panel-col-first {
    width: 100%;
    float: none;
    margin: 0 0 20px 0; }
    .node-type-blog .center-wrapper .panel-col-first .node__content {
      padding-left: 10px;
      padding-right: 10px; }
  .blog-section .l-main {
    padding-left: 10px;
    padding-right: 10px; }
  .where-adv-page .pane-node .check-spots-wrapper {
    padding: 0 10px; }
  .pane-block-11 {
    padding: 20px 10px; }
  .l-turntabled .gradientdiv-slide {
    padding: 40px 10px 0 10px; }
  .block--views-slick-front-top-block .banner.fx-height {
    height: 493px; }
  .node--news.node--full .group-wrapper-inner {
    padding: 20px 10px; }
  .node--news.node--full .group-banner .field--name-title-field {
    padding: 100px 0 100px 10px; }
  .node--news.node--full .group-banner .field--name-title-field {
    max-width: 480px;
    width: auto; }
    .node--news.node--full .group-banner .field--name-title-field h1 {
      font-size: 1.85rem; }
  .node--news.node--full .group-banner-left {
    max-height: none; }
  .node--news.node--full .field--name-field-image {
    padding-top: 20px;
    max-width: 300px; }
  .not-front .view-display-id-news_page.l-news-wrapper .view-content {
    padding-left: 10px;
    padding-right: 10px; }
  .not-front .view-display-id-news_page.l-news-wrapper .view-header {
    padding-left: 10px;
    padding-right: 10px; }
  .basket-block > .panels-outer-wrapper {
    padding: 40px 10px; }
  .logos-block .node--page.node--logos {
    padding: 40px 10px; }
  .advertisers-benefits-block {
    padding: 40px 10px; }
  .how-it-works-block .how-it-works-image-1,
  .how-it-works-block .how-it-works-image-2,
  .how-it-works-block .how-it-works-image-3 {
    display: block;
    float: left; }
  .how-it-works-block .how-it-works-text {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .how-it-works-block .how-it-works-left {
    display: block;
    width: 100%;
    padding: 20px 0;
    float: none;
    height: 317px; }
    .how-it-works-block .how-it-works-left:after {
      content: "";
      display: table;
      clear: both; }
  .how-it-works-block .how-it-works-right {
    display: block;
    width: 100%;
    padding: 20px 0;
    float: none; }
  .proof-block .l-what-for-you-wrapper {
    padding: 40px 0 40px; }
  .where-advert {
    padding: 40px 10px; }
    .where-advert .wa-left {
      max-width: 100%;
      float: none; }
    .where-advert .wa-right-icon {
      float: none;
      margin-top: 40px;
      text-align: center; }
  .way-adv-wrapper {
    padding: 40px 10px 0; }
  .basket-block .left-side-basket {
    float: none;
    width: 100%;
    margin-right: 0; }
  .basket-block .basket-icon {
    float: none;
    margin: 30px 0;
    width: 100%;
    text-align: center; }
    .basket-block .basket-icon img {
      max-width: 420px;
      width: 100%; }
  .green-st-block .panels-outer-wrapper {
    padding: 40px 10px; }
  .l-below-banner {
    padding: 0 10px; }
  .l-footer-wrapper {
    padding-top: 40px;
    padding-bottom: 40px; }
  .l-footer {
    /*  nav {
      display: none;
    }*/ }
    .l-footer #block-block-9 {
      float: none;
      margin-right: 0;
      margin: 0 auto;
      width: 100%; }
  .l-what-for-you .ptitle {
    margin-top: 30px;
    padding-top: 30px; }
  .l-what-for-you .small-box {
    margin-bottom: 40px; }
  .l-news .ptitle {
    margin-bottom: 30px;
    padding-top: 30px; }
  .l-news-wrapper {
    padding: 20px 10px; }
    .l-news-wrapper .views-row {
      width: 48.57143%;
      float: left;
      margin-right: 2.85714%;
      margin-bottom: 40px; }
      .l-news-wrapper .views-row:nth-child(2n) {
        width: 48.57143%;
        float: right;
        margin-right: 0; }
      .l-news-wrapper .views-row:nth-child(2n+1) {
        clear: both;
        width: 48.57143%;
        float: left;
        margin-right: 2.85714%; }
  .ptitle.ptitle--coloured {
    padding: 0; } }

@media (max-width: 1020px) {
  .view-e-shop.view-display-id-page .view-content {
    max-width: 610px;
    margin-left: auto;
    margin-right: auto; }
    .view-e-shop.view-display-id-page .view-content:after {
      content: "";
      display: table;
      clear: both; }
    .view-e-shop.view-display-id-page .view-content .views-row {
      width: 48.93617%;
      float: left;
      margin-right: 2.12766%; }
      .view-e-shop.view-display-id-page .view-content .views-row:nth-child(4n+1) {
        clear: none; }
      .view-e-shop.view-display-id-page .view-content .views-row:nth-child(3n) {
        width: 48.93617%;
        float: left;
        margin-right: 2.12766%; }
      .view-e-shop.view-display-id-page .view-content .views-row:nth-child(3n+1) {
        clear: none; }
      .view-e-shop.view-display-id-page .view-content .views-row:nth-child(2n) {
        width: 48.93617%;
        float: right;
        margin-right: 0; }
      .view-e-shop.view-display-id-page .view-content .views-row:nth-child(2n+1) {
        clear: both;
        width: 48.93617%;
        float: left;
        margin-right: 2.12766%; }
  .view-slick-front-top .ptitle--large {
    font-size: 2.25rem; }
  .view-slick-front-top .banner__description {
    font-size: 1rem; } }

@media (max-width: 690px) {
  .view-e-shop.view-display-id-page .view-content .views-row.views-row-even, .view-e-shop.view-display-id-page .view-content .views-row.views-row-odd {
    width: 100%;
    margin: 0 auto 20px;
    max-width: 300px;
    float: none; }
  .panel-pane.rolls-caru .node--product-display {
    margin: 0 10px; }
  .panel-pane.rolls-caru .view.view-e-shop.view-display-id-page .view-content {
    max-width: 300px; } }

@media (max-width: 860px) {
  .l-copyright__left {
    float: none;
    margin-bottom: 15px;
    text-align: left; }
  .l-copyright__right {
    float: none;
    text-align: left;
    line-height: 2.2; }
  .view-slick-front-top .banner__left .center-wrapper {
    padding: 40px 30px 20px 20px; }
  .view-display-id-panel_pane_1 .ptitle--large,
  .view-display-id-panel_pane_1 .field--name-field-final-price {
    font-size: 44px; } }

@media (max-width: 768px) {
  .solo-fx-height {
    height: auto; }
  /*  .view-slick-front-top {
    .view-content {
      .views-row {
        height: auto;
        overflow: visible;
      }
    }
  }*/
  .node--product-display.node--full #edit-submit {
    margin-top: 20px; }
  .node--product-display.node--full .form-item-attributes-field-commerce-product-quantity {
    float: none;
    margin-right: 0;
    margin-bottom: 20px;
    width: 30%; }
  .node--product-display.node--full .picture-wrapper {
    max-width: 142px; }
  .node--product-display.node--full .group-wrapper-left {
    float: none;
    width: auto;
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
    clear: both; }
    .node--product-display.node--full .group-wrapper-left:after {
      content: "";
      display: table;
      clear: both; }
  .node--product-display.node--full .group-wrapper-right {
    padding-top: 20px;
    width: 100%;
    float: none;
    margin-right: 0;
    clear: both; }
  .node--product-display.node--full .field-custom-this {
    top: 17%; }
  .l-what-for-you-wrapper {
    padding-top: 0;
    padding-bottom: 0; }
  .l-what-for-you .ptitle {
    margin-bottom: 40px; }
  .l-turntabled {
    padding: 0; }
  body.node-type-product-display .view-products-related-products {
    max-width: 610px;
    margin-left: auto;
    margin-right: auto; }
    body.node-type-product-display .view-products-related-products:after {
      content: "";
      display: table;
      clear: both; }
    body.node-type-product-display .view-products-related-products:after {
      content: "";
      display: table;
      clear: both; }
    body.node-type-product-display .view-products-related-products .views-row {
      width: 48.93617%;
      float: left;
      margin-right: 2.12766%; }
      body.node-type-product-display .view-products-related-products .views-row:nth-child(2n) {
        width: 48.93617%;
        float: right;
        margin-right: 0; }
      body.node-type-product-display .view-products-related-products .views-row:nth-child(2n+1) {
        clear: both;
        width: 48.93617%;
        float: left;
        margin-right: 2.12766%; }
  body.node-type-product-display .panel-2col-stacked .center-wrapper .panel-col-first {
    width: 100%;
    margin-right: 0;
    float: none;
    margin-top: 10px; }
    body.node-type-product-display .panel-2col-stacked .center-wrapper .panel-col-first .panels-outer-wrapper {
      border-right: none;
      padding-left: 0; }
  body.node-type-product-display .panel-2col-stacked .center-wrapper .panel-col-last {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-top: 20px;
    border-right: none; }
    body.node-type-product-display .panel-2col-stacked .center-wrapper .panel-col-last .panels-outer-wrapper {
      padding: 0;
      margin: 0;
      border: none; }
  .node--slideshow.node-teaser .node__content {
    height: auto; }
  .node--slideshow.node-teaser .group-wrapper {
    position: static;
    transform: none; }
    .node--slideshow.node-teaser .group-wrapper .group-left-side {
      max-width: 100%;
      padding: 30px 10px;
      margin-right: 0; }
  .node--slideshow.node-teaser .field--name-body {
    margin-bottom: 30px; }
  .wadv-left {
    width: 100%;
    margin-right: 0;
    float: none; }
    .wadv-left .respond-gmap {
      padding-bottom: 68%;
      min-height: 580px; }
  .wadv-right {
    width: 100%;
    margin-right: 0;
    float: none;
    min-height: auto; }
  .block--views-slick-front-top-block .banner.fx-height {
    height: auto; }
  .ad-ways-page .ad-ways-block-inner, .cost-subpages .ad-ways-block-inner {
    padding: 50px 0 40px; }
    .ad-ways-page .ad-ways-block-inner .ad-ways-block-left, .cost-subpages .ad-ways-block-inner .ad-ways-block-left {
      width: 100%;
      float: none;
      display: block; }
      .ad-ways-page .ad-ways-block-inner .ad-ways-block-left .ad-ways-block-image, .cost-subpages .ad-ways-block-inner .ad-ways-block-left .ad-ways-block-image {
        margin-bottom: 40px; }
        .ad-ways-page .ad-ways-block-inner .ad-ways-block-left .ad-ways-block-image img, .cost-subpages .ad-ways-block-inner .ad-ways-block-left .ad-ways-block-image img {
          margin: 0 auto; }
    .ad-ways-page .ad-ways-block-inner .ad-ways-block-right, .cost-subpages .ad-ways-block-inner .ad-ways-block-right {
      width: 100%;
      float: none;
      display: block;
      padding: 0; }
  .printed-rolls .ad-ways-block-inner {
    padding: 40px 0; }
    .printed-rolls .ad-ways-block-inner .ad-ways-block-left,
    .printed-rolls .ad-ways-block-inner .ad-ways-block-left-2 {
      width: 100%;
      float: none;
      display: block;
      padding: 0 20px; }
      .printed-rolls .ad-ways-block-inner .ad-ways-block-left .ad-ways-block-image,
      .printed-rolls .ad-ways-block-inner .ad-ways-block-left-2 .ad-ways-block-image {
        padding: 0 10px;
        margin-bottom: 40px; }
        .printed-rolls .ad-ways-block-inner .ad-ways-block-left .ad-ways-block-image img,
        .printed-rolls .ad-ways-block-inner .ad-ways-block-left-2 .ad-ways-block-image img {
          margin: 0 auto; }
    .printed-rolls .ad-ways-block-inner .ad-ways-block-right,
    .printed-rolls .ad-ways-block-inner .ad-ways-block-right-2 {
      width: 100%;
      float: none;
      display: block; }
      .printed-rolls .ad-ways-block-inner .ad-ways-block-right .ad-ways-block-image,
      .printed-rolls .ad-ways-block-inner .ad-ways-block-right-2 .ad-ways-block-image {
        padding: 0 10px;
        margin-bottom: 40px; }
        .printed-rolls .ad-ways-block-inner .ad-ways-block-right .ad-ways-block-image img,
        .printed-rolls .ad-ways-block-inner .ad-ways-block-right-2 .ad-ways-block-image img {
          margin: 0 auto;
          display: block; }
  .how-it-works-block .how-it-works-wrapper {
    padding: 50px 0 40px; }
  .how-it-works-block .how-it-works-left {
    height: 180px; }
  .how-it-works-block .how-it-works-image-1 {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -634px 0px;
    width: 140px;
    height: 140px; }
  .how-it-works-block .how-it-works-image-2 {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -454px -478px;
    width: 140px;
    height: 140px; }
  .how-it-works-block .how-it-works-image-3 {
    background-image: url(../images/sprites/build/sprites.png);
    background-position: -312px -478px;
    width: 140px;
    height: 140px; }
  .l-socialpromo .l-socialpromo-wrapper {
    padding: 40px 10px; }
  .l-socialpromo .l-socialpromo__text {
    font-size: 1.125rem; }
  .how-op-block .panels-outer-wrapper {
    padding: 40px 10px; }
  .how-op-block .rmore {
    margin: 40px 0; }
  .how-op-block .how-op__item {
    margin-right: 0;
    width: 100%;
    float: none;
    max-width: 280px;
    margin: 0 auto 30px auto; }
    .how-op-block .how-op__item:last-child {
      width: 100%;
      max-width: 280px;
      float: none;
      margin: 0 auto 30px auto; }
  .contact-us-pane-page .pane-block-12 .panels-outer-wrapper {
    padding: 40px 10px 0 10px; }
  .cu-out {
    margin-top: 40px;
    position: static;
    margin-left: -10px;
    margin-right: -10px; }
    .cu-out .cu-wrapper {
      float: none;
      width: 100%; }
      .cu-out .cu-wrapper:last-child {
        float: none;
        width: 100%; }
  .ptitle--large {
    font-size: 1.75rem;
    line-height: 1.2; }
  .ptitle--coloured, .ptitle--medium {
    font-size: 1.5rem;
    line-height: 1.3; }
  .ptitle--coloured-large, .ptitle--coloured-huge {
    font-size: 1.75rem;
    line-height: 1.2; }
  .view-display-id-panel_pane_1 .ptitle--large,
  .view-display-id-panel_pane_1 .field--name-field-final-price {
    font-size: 1.75rem; }
  .view-slick-front-top .ptitle--large {
    font-size: 1.75rem; }
  .how-it-works-block .how-it-works-text span,
  .ad-ways-wrapper .ad-ways-title,
  .where-adv-page .pane-node .where-advert-wrapper .ptitle,
  .where-adv-page .pane-node .check-spots,
  .receipt-points-page .where-advert-wrapper .wa-left .ptitle--coloured-huge,
  .research-efficiency .where-advert-wrapper .wa-left .ptitle--coloured-huge,
  .cost-subpages .node--page.node--cost .group-banner .field--name-title-field,
  .case-study-descs h1,
  .about-us-page .about-us-title,
  .wch,
  .node--news.node--full .group-banner .field--name-title-field h1,
  .node--blog.node--about .field--name-title-field h1,
  .node--slideshow.node-teaser .field--name-field-final-price,
  .node--product-display.node--full .field--name-title-field,
  .node--product-display.node--full .field--name-commerce-price,
  .user-login h1,
  .user-register-form h1,
  .user-profile-form h1,
  .logged-in.page-user .l-content h1,
  .logged-in.page-user .l-content h2,
  .view-e-shop .swrapper-title h1,
  .page-cart h1, .page-checkout h1,
  #block-block-5 .ptitle--medium,
  .l-news .ptitle,
  .page-search-results .l-content h1,
  .page-sitemap .l-content h1 {
    font-size: 1.75rem;
    line-height: 1.2; }
  .not-front .user-login .cs-title {
    padding-right: 0;
    padding-left: 0; }
  .advertisers-benefits .where-advert-wrapper .wa-left .ptitle--coloured-huge,
  .how-it-works .where-advert-wrapper .wa-left .ptitle--coloured-huge {
    font-size: 1.75rem;
    line-height: 1.2; }
  .ad-ways-block-inner .ad-ways-block-subtitle,
  .wadv-content .wadv-text,
  .wadv-content .loc-search-form .form-type-textfield label,
  .wch-below,
  .node--slideshow.node-teaser .field--name-field-initial-price,
  .node--slideshow.node-teaser .field--name-body,
  .how-op-block .text-op,
  .where-advert .check-spots,
  .choose-arrow,
  .l-main .node--page--full .field--name-body h3 {
    font-size: 1.25rem; }
  .ad-ways-block-inner .ad-ways-block-title,
  .priv-block .ptitle--large,
  .advertisers-benefits-block .advertisers-benefits-title,
  .advertisers-categories-block .pane-title,
  .page-node-1172 .pane-e-shop .pane-title,
  .pane-block-11 h2 {
    font-size: 1.5rem;
    line-height: 1.3; }
  .contact-us-pane-page .small-utitle {
    font-size: 1.125rem; }
  .l-callus-wrapper .ajax-progress-throbber {
    right: 10px; }
  .rolls-caru .slick-track {
    padding-top: 0; }
  .panel-pane.rolls-caru {
    margin-bottom: 10px; }
  .page-node-1172 .pane-e-shop .pane-title {
    margin-top: 0; }
  .gray-pane-wrap {
    padding: 30px 0 20px; }
  .rt-pwrap {
    padding: 0 10px; }
  .advertisers-benefits-block .advertisers-benefits {
    margin-bottom: 50px; }
  .advertisers-categories-block {
    padding: 50px 0 40px; }
    .advertisers-categories-block .pane-title {
      margin-bottom: 40px; }
  .priv-cost-block .banner__description {
    margin: 40px 0 30px; }
  .case-study-descs .panels-outer-wrapper {
    padding-top: 40px;
    padding-bottom: 40px; }
  .page-node-1180 .case-study-descs .panels-outer-wrapper {
    padding-top: 40px;
    padding-bottom: 65px; }
  .view-case-studies.view-display-id-panel_pane_2 .view-content .slick-list {
    padding: 0 !important; }
  .view-cost .ad-ways-block-inner {
    padding: 50px 0 40px; }
  .search-results .search-result:nth-child(n) {
    width: 31.91489%;
    float: left;
    margin-right: 2.12766%; }
    .search-results .search-result:nth-child(n):nth-child(3n) {
      width: 31.91489%;
      float: right;
      margin-right: 0; }
    .search-results .search-result:nth-child(n):nth-child(4n+1) {
      clear: none; }
    .search-results .search-result:nth-child(n):nth-child(3n+1) {
      clear: both;
      width: 31.91489%;
      float: left;
      margin-right: 2.12766%; }
  .green-st-block-cost .panels-outer-wrapper {
    padding: 50px 10px 10px; }
  .green-st-block-cost .ptitle--coloured {
    font-size: 1.75rem;
    margin-bottom: 30px; }
  .green-st-block-cost .cost {
    width: 100%;
    float: none;
    max-width: 360px;
    margin: 40px auto; }
    .green-st-block-cost .cost:last-child {
      width: 100%;
      float: none;
      margin: 40px auto; }
    .green-st-block-cost .cost .cost-text {
      font-size: 1.125rem; }
  .about-us-page .about-us-title {
    padding-bottom: 0; }
    .about-us-page .about-us-title:before {
      height: 20px; }
  .about-us-page .group-inner {
    padding-bottom: 30px; }
  .taxonomy-term--carousel .taxonomy-term__content {
    max-width: 180px;
    margin: 0 auto; }
  /*.l-copyright {
    &__left {
      float: none;
      margin-bottom: 15px;
    }

    &__right {
      float: none;
    }
  }*/
  .l-callus-wrapper {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px; }
  .l-callus__text {
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center; }
  .cta {
    display: block;
    margin: 0 auto; }
  .banner,
  .pane-block.panel-pane .banner {
    height: auto; }
    .banner .banner-left-image,
    .banner .banner-right-image,
    .pane-block.panel-pane .banner .banner-left-image,
    .pane-block.panel-pane .banner .banner-right-image {
      position: static;
      min-height: 400px;
      width: 100%; }
    .banner .banner-right .center-wrapper,
    .pane-block.panel-pane .banner .banner-right .center-wrapper {
      padding: 40px 40px 20px 20px;
      max-width: 100%;
      margin-right: 0;
      width: 100%;
      position: static;
      margin-left: 0; }
    .banner__left .center-wrapper,
    .pane-block.panel-pane .banner__left .center-wrapper {
      padding: 40px 40px 20px 20px;
      max-width: 100%;
      margin-right: 0;
      width: 100%;
      position: static;
      margin-left: 0; }
    .banner .edit-img,
    .pane-block.panel-pane .banner .edit-img {
      position: static;
      min-height: 400px; }
    .banner__right,
    .pane-block.panel-pane .banner__right {
      width: 100%;
      position: static; }
      .banner__right__hero-image,
      .pane-block.panel-pane .banner__right__hero-image {
        position: static;
        min-height: 400px; }
  .view-slick-front-top .view-content {
    position: static;
    width: auto;
    max-width: 100%; }
    .view-slick-front-top .view-content img {
      min-width: 640px;
      max-width: 100%; }
  .front .l-below-banner-wrapper {
    padding: 40px 0; }
  .front .l-key-points-wrapper {
    padding: 40px 20px; }
  .front .l-key-points__left {
    width: 100%;
    float: none;
    margin: 0 auto 40px; }
  .front .l-key-points__right {
    width: 100%;
    float: none;
    padding-left: 0; }
  .page-node-1172 .view-display-id-panel_pane_1 .slick-dots {
    position: absolute;
    top: 33%;
    right: 20px; }
  .page-node-1172 .slideshow-slick {
    margin-bottom: 20px; }
    .page-node-1172 .slideshow-slick .rounded-arrow-nav {
      bottom: 0; } }

@media (max-width: 730px) {
  .node--news.node--full .field--name-field-image {
    padding-top: 45px;
    max-width: 200px; } }

@media (max-width: 630px) {
  .rt-pwrap .toi {
    bottom: 20px; }
  .search-results .search-result:nth-child(n) {
    width: 48.93617%;
    float: left;
    margin-right: 2.12766%; }
    .search-results .search-result:nth-child(n):nth-child(2n) {
      width: 48.93617%;
      float: right;
      margin-right: 0; }
    .search-results .search-result:nth-child(n):nth-child(3n+1) {
      clear: none; }
    .search-results .search-result:nth-child(n):nth-child(2n+1) {
      clear: both;
      width: 48.93617%;
      float: left;
      margin-right: 2.12766%; }
  .page-request-form .view-e-shop.view-display-id-block_1 .views-row {
    width: 100%;
    max-width: 290px;
    margin: 20px auto 0 auto;
    float: none; }
  .page-request-form .l-main {
    padding-left: 10px;
    padding-right: 10px; }
    .page-request-form .l-main .l-region--sidebar-first {
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
      margin-right: 0;
      margin-left: 0; }
    .page-request-form .l-main .l-content {
      width: 100%;
      padding-left: 0; }
  body.node-type-product-display .view-products-related-products {
    max-width: 290px;
    margin-left: auto;
    margin-right: auto; }
    body.node-type-product-display .view-products-related-products:after {
      content: "";
      display: table;
      clear: both; }
    body.node-type-product-display .view-products-related-products:after {
      content: "";
      display: table;
      clear: both; }
    body.node-type-product-display .view-products-related-products .views-row {
      width: 100%;
      float: none; }
      body.node-type-product-display .view-products-related-products .views-row:nth-child(2n) {
        width: 100%;
        float: none; }
      body.node-type-product-display .view-products-related-products .views-row:nth-child(2n+1) {
        width: 100%;
        float: none; }
  .rt-pwrap .rt-left {
    float: none;
    margin-bottom: 20px;
    width: 100%; }
  .rt-pwrap .rt-right {
    float: none;
    width: 100%; }
  .node--blog.node--teaser {
    text-align: center; }
    .node--blog.node--teaser .group-blog-image {
      float: none;
      margin-right: 0;
      text-align: center;
      margin-bottom: 20px;
      display: inline-block; }
    .node--blog.node--teaser .group-blog-text {
      text-align: left; }
  .node--blog .group-blog-full-image .group-blog-meta {
    font-size: 1rem; }
  .node--blog .group-blog-full-image .field--name-title-field h1 {
    font-size: 1.5rem;
    margin-bottom: 5px; }
  .case-studies-caru {
    max-width: 100%; }
    .case-studies-caru .arrow-wrapper--right {
      top: 22%; }
    .case-studies-caru .arrow-wrapper--left {
      top: 22%; }
  .node--case-study {
    margin: 0 auto; }
    .node--case-study.node--full .field--name-field-image {
      max-width: 100%;
      float: none;
      margin-bottom: 0;
      width: 100%; }
    .node--case-study.node--full .field--name-body {
      width: 100%;
      float: none;
      padding: 20px 10px;
      background: #ffffff;
      min-height: 0;
      max-width: 100%; }
  .logos-block .node--page.node--logos {
    padding: 40px 10px 0; }
    .logos-block .node--page.node--logos .field--name-field-images .field__item {
      display: block;
      margin: 0 auto;
      max-width: 250px;
      height: auto;
      line-height: 100%;
      margin-bottom: 40px; }
  .advertisers-benefits-block .panels-outer-wrapper .advertisers-benefits {
    width: 100%;
    margin-bottom: 30px;
    float: none;
    margin-right: 0; }
    .advertisers-benefits-block .panels-outer-wrapper .advertisers-benefits.read-more-benefits {
      margin-bottom: 0; }
  .case-studies-caru {
    margin-top: 35px; } }

@media (max-width: 500px) {
  .node--product-display .attribute-widgets {
    margin-bottom: 34px; }
  #pgwModal.pgwCart {
    line-height: 1.3; }
    #pgwModal.pgwCart .pm-body {
      padding: 20px; }
  .popup-addtocart-wrapper .product-details-wrapper .added-product-image {
    float: none;
    margin-right: 0;
    text-align: center;
    margin-bottom: 15px; }
  .popup-addtocart-wrapper .button-wrapper .button.continue {
    margin-bottom: 10px; }
  /* ------ Responsive Corrections Green Face Start ------ */
  .cost-subpages .node--page.node--cost .group-banner .field--name-field-images {
    max-width: 100%;
    min-width: 0; }
  .where-adv-page .pane-node .where-advert-wrapper .wa-right-icon,
  .how-it-works .where-advert-wrapper .wa-right-icon,
  .advertisers-benefits .where-advert-wrapper .wa-right-icon,
  .receipt-points-page .where-advert-wrapper .wa-right-icon {
    max-width: 100%;
    min-width: 0; }
  .ad-ways-wrapper .ad-ways-image {
    max-width: 100%;
    min-width: 0; }
  .ad-ways-page .ad-ways-wrapper .ad-ways-image {
    max-width: 100%;
    min-width: 0; }
  /* ------ Responsive Corrections Green Face End ------ */
  .not-front .view-display-id-news_page.l-news-wrapper .view-content {
    padding-top: 20px;
    padding-bottom: 10px; }
  .search-results article.search-result:nth-child(n):nth-child(2n), .search-results article.search-result:nth-child(n):nth-child(3n), .search-results article.search-result:nth-child(n):nth-child(2n+1), .search-results article.search-result:nth-child(n):nth-child(3n+1) {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .node--blog.node--vblock {
    text-align: center; }
    .node--blog.node--vblock .field--name-field-image {
      text-align: center;
      display: inline-block;
      float: none;
      margin-right: 0;
      margin-bottom: 10px; }
    .node--blog.node--vblock .group-blog-text {
      text-align: center; }
    .node--blog.node--vblock .field--type-text {
      text-align: left; }
  .how-it-works-block .how-it-works-wrapper {
    padding: 10px 0 10px; }
  .how-it-works .how-it-works-image-1,
  .how-it-works .how-it-works-image-2,
  .how-it-works .how-it-works-image-3 {
    display: block;
    float: none;
    margin: 0 auto; }
  .how-it-works .how-it-works-text {
    display: block;
    position: static;
    transform: none;
    text-align: center; }
  .how-it-works .how-it-works-left {
    height: auto;
    margin-top: 30px; }
    .how-it-works .how-it-works-left:after {
      content: "";
      display: table;
      clear: both; }
  .l-what-for-you .small-box {
    float: none;
    margin-left: auto;
    margin-right: auto;
    width: 280px;
    min-width: 280px; }
    .l-what-for-you .small-box__image {
      height: 280px; }
    .l-what-for-you .small-box:last-child {
      float: none;
      margin-left: auto;
      margin-right: auto; }
  .l-news-wrapper .views-row.views-row-odd, .l-news-wrapper .views-row.views-row-even {
    float: none;
    width: 100%; } }

@media (max-width: 390px) {
  .rolls-caru .arrow-wrapper--left {
    left: -32px; }
    .rolls-caru .arrow-wrapper--left .carousel-arrow-left {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -755px -354px;
      width: 17px;
      height: 33px; }
  .rolls-caru .arrow-wrapper--right {
    right: -32px; }
    .rolls-caru .arrow-wrapper--right .carousel-arrow-right {
      background-image: url(../images/sprites/build/sprites.png);
      background-position: -756px -410px;
      width: 17px;
      height: 33px; }
  .node--product-display.node--full .field--name-field-commerce-product input[type="submit"] {
    padding: 18px 32px; } }

@media (max-width: 360px) {
  .view-e-shop.view-display-id-page .view-content .views-row.views-row-even, .view-e-shop.view-display-id-page .view-content .views-row.views-row-odd {
    width: 100%;
    margin: 0 auto 20px;
    max-width: 260px;
    float: none; }
  .panel-pane.rolls-caru .node--product-display {
    margin: 0 10px; }
  .panel-pane.rolls-caru .view.view-e-shop.view-display-id-page .view-content {
    max-width: 260px; }
  .page-node-1172 .view-display-id-panel_pane_1 .slick-dots {
    top: 35%; } }

.slicknav_menu {
  display: none; }

.page-checkout .ajax-progress.ajax-progress-throbber {
  display: none; }

#block-gtweaks-mobile-menu {
  display: none; }

@media (max-width: 1390px) {
  .l-region--navigation ul.menu > li {
    margin-right: 0.4%; }
  .adv-with .pane-block.pane-block-19 .banner .edit-img {
    background-image: none !important;
    position: static;
    min-height: auto; }
    .adv-with .pane-block.pane-block-19 .banner .edit-img img {
      display: block;
      margin: 0 auto; }
  .adv-with .pane-block.pane-block-19 .banner-left-image {
    position: static;
    max-width: 695px;
    min-height: auto;
    margin: 0 auto; }
  .adv-with .pane-block.pane-block-19.priv-block {
    margin: 90px 0 30px; }
    .adv-with .pane-block.pane-block-19.priv-block .banner__left {
      max-width: 1020px;
      margin-left: auto;
      margin-right: auto; }
      .adv-with .pane-block.pane-block-19.priv-block .banner__left:after {
        content: "";
        display: table;
        clear: both; }
      .adv-with .pane-block.pane-block-19.priv-block .banner__left .center-wrapper {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        padding: 30px 20px 0; }
    .adv-with .pane-block.pane-block-19.priv-block .banner {
      min-height: auto; } }

@media (max-width: 1340px) {
  .advertisers-categories-block {
    padding-right: 10px;
    padding-left: 10px; } }

@media (max-width: 1270px) {
  .front .l-key-points-wrapper,
  .front .l-what-for-you-wrapper,
  .front .l-socialpromo-wrapper,
  .front .how-op-block,
  .front .where-adv-block-1,
  .front .ad-ways-block-inner,
  .front.page-search-results .l-content h1,
  .front.page-search-results .l-content h2,
  .front .search-results,
  .front.blog-section .l-main,
  .front.node-type-blog .l-main,
  .front.logged-in.page-user .l-content,
  .front.contact-us-pane-page .pane-node,
  .front.page-node-done .l-main,
  .front #block-block-7 .l-callus__text,
  .front .messages-wrapper,
  .front .how-it-works-block .how-it-works-wrapper,
  .front.about-us-page .group-wrapper,
  .front.about-us-page .panel-pane.pane-block .about-us-wrapper,
  .front ul.tabs--primary,
  .front.page-sitemap .l-main,
  .front.node-type-product-display .panel-2col-stacked, .not-front .l-key-points-wrapper,
  .not-front .l-what-for-you-wrapper,
  .not-front .l-socialpromo-wrapper,
  .not-front .how-op-block,
  .not-front .where-adv-block-1,
  .not-front .ad-ways-block-inner,
  .not-front.page-search-results .l-content h1,
  .not-front.page-search-results .l-content h2,
  .not-front .search-results,
  .not-front.blog-section .l-main,
  .not-front.node-type-blog .l-main,
  .not-front.logged-in.page-user .l-content,
  .not-front.contact-us-pane-page .pane-node,
  .not-front.page-node-done .l-main,
  .not-front #block-block-7 .l-callus__text,
  .not-front .messages-wrapper,
  .not-front .how-it-works-block .how-it-works-wrapper,
  .not-front.about-us-page .group-wrapper,
  .not-front.about-us-page .panel-pane.pane-block .about-us-wrapper,
  .not-front ul.tabs--primary,
  .not-front.page-sitemap .l-main,
  .not-front.node-type-product-display .panel-2col-stacked {
    padding-right: 10px;
    padding-left: 10px; }
  #block-block-7 .l-callus-wrapper {
    margin: 0 auto; }
  #block-block-7 .l-callus__text {
    margin-right: 0; }
  .advertisers-categories-block .views-row .field--name-name-field {
    width: 145px; }
  .pane-block-12 .ptitle--coloured-huge,
  .pane-block-12 .small-utitle {
    padding: 0 10px; }
  .slideshow-slick .arrow-wrapper--right {
    right: 10px; }
  .slideshow-slick .arrow-wrapper--left {
    left: 10px; }
  .node--news.node--full .field--name-field-image, .node--news.node--full .field--name-field-images {
    right: 10px; }
  #block-gtweaks-custom-main-menu {
    display: none; }
  /*#block-block-1 {
    display: none;
  }*/
  #block-gtweaks-mobile-menu, #block-search-form, #block-block-2, #block-system-user-menu {
    display: none; }
  #block-block-1 {
    margin-right: 5px; }
  .phone-top__icon {
    display: none; }
  .phone-top__text {
    font-size: 1rem; }
  .l-header {
    position: relative;
    padding-bottom: 25px;
    height: 114px; }
    .l-header .slicknav_menu {
      display: block;
      position: absolute;
      top: 112px;
      right: 0px;
      width: 100%;
      z-index: 9999; }
      .l-header .slicknav_menu .slicknav_menutxt {
        right: 35px;
        top: -55px; }
      .l-header .slicknav_menu .sf-sub-indicator {
        display: none; }
      .l-header .slicknav_menu ul {
        margin: 0px; }
        .l-header .slicknav_menu ul li {
          margin-bottom: 0px;
          clear: left; }
          .l-header .slicknav_menu ul li a a {
            border-bottom: none;
            padding-top: 0;
            padding-bottom: 0; }
          .l-header .slicknav_menu ul li ul li a {
            padding-left: 30px; }
      .l-header .slicknav_menu #block-search-form {
        display: inline-block;
        padding: 10px 7px; }
      .l-header .slicknav_menu #block-block-2 {
        display: inline-block; }
        .l-header .slicknav_menu #block-block-2 ul.social-media-icons {
          display: inline-block;
          padding: 13px 7px 3px; }
          .l-header .slicknav_menu #block-block-2 ul.social-media-icons li {
            clear: none; }
          .l-header .slicknav_menu #block-block-2 ul.social-media-icons a {
            display: inline-block; }
  #block-system-user-menu {
    display: none; }
  #block-system-user-menu.user-menu-mobile {
    display: block; }
  .slicknav_menu div.slicknav_nav #block-system-user-menu {
    position: static;
    display: block;
    padding: 5px 7px 5px;
    margin-right: 0;
    border-bottom: 1px solid #b3cc3c; }
    .slicknav_menu div.slicknav_nav #block-system-user-menu ul {
      display: block;
      float: none;
      color: #ffffff; }
    .slicknav_menu div.slicknav_nav #block-system-user-menu li {
      display: inline-block;
      clear: none; }
      .slicknav_menu div.slicknav_nav #block-system-user-menu li a, .slicknav_menu div.slicknav_nav #block-system-user-menu li span.nolink {
        border-bottom: none;
        display: inline-block;
        color: #ffffff; }
    .slicknav_menu div.slicknav_nav #block-system-user-menu .quick-links-top__icon {
      margin-top: 4px; }
  .l-header__right {
    position: absolute;
    right: 105px; }
  .l-header__left {
    margin-top: 5px;
    width: 170px; }
  .l-header-wrapper--sticky .slicknav_menu {
    top: 70px;
    z-index: 20; }
    .l-header-wrapper--sticky .slicknav_menu .slicknav_menutxt {
      right: 35px;
      top: -56px; }
  .l-header-wrapper--sticky .l-header__left {
    margin-top: 0; }
  .l-header-wrapper--sticky #block-commerce-overrides-shopping-cart-custom {
    right: 65px;
    min-width: 165px; }
  #block-commerce-overrides-shopping-cart-custom {
    margin-right: 0;
    min-width: auto;
    margin-left: 10px; }
  .view-shopping-cart-block-custom {
    margin-left: 0; }
  .sticky-page.double-sticky .l-header-wrapper--sticky .l-header {
    height: 70px; }
  #block-locale-language-content {
    position: absolute;
    right: 35px;
    top: 18px;
    width: 53px;
    margin-top: 0; }
  .site-logo img {
    width: 130px; }
  #block-system-user-menu {
    position: absolute;
    right: 100px;
    bottom: 19px; }
  .page-cart .l-content,
  .page-checkout .l-content,
  .logged-in.page-user-orders .l-content {
    padding-right: 10px;
    padding-left: 10px; }
  .l-what-for-you .small-box {
    float: none;
    margin-right: 13px;
    width: 30%;
    display: inline-block; }
    .l-what-for-you .small-box:last-child {
      margin-right: 0;
      float: none;
      width: 30%; } }

@media (max-width: 1200px) {
  .page-checkout .commerce_shipping,
  .page-checkout .commerce_payment {
    min-height: 386px; }
  .logged-in.page-user-orders .views-field-commerce-order-total {
    width: 10%; } }

@media (max-width: 1060px) {
  #block-block-52,
  .pane-block-52 {
    float: left; }
  .block--views-blog-taxonomy-block-1 {
    float: left;
    padding-left: 20px; }
  .label-mobile {
    display: inline-block; }
  .checkout-buttons .fieldset-wrapper {
    text-align: center; }
  #commerce-checkout-form-login .checkout-buttons .fieldset-wrapper {
    text-align: left; }
  #views-form-commerce-cart-form-default,
  #commerce-checkout-form-checkout,
  #commerce-checkout-form-review,
  .view-commerce-line-item-table,
  .view-commerce-user-orders {
    padding: 0;
    border: none; }
    #views-form-commerce-cart-form-default thead,
    #commerce-checkout-form-checkout thead,
    #commerce-checkout-form-review thead,
    .view-commerce-line-item-table thead,
    .view-commerce-user-orders thead {
      display: none; }
    #views-form-commerce-cart-form-default tr,
    #commerce-checkout-form-checkout tr,
    #commerce-checkout-form-review tr,
    .view-commerce-line-item-table tr,
    .view-commerce-user-orders tr {
      display: block;
      margin-bottom: 30px; }
      #views-form-commerce-cart-form-default tr.even td, #views-form-commerce-cart-form-default tr.odd td,
      #commerce-checkout-form-checkout tr.even td,
      #commerce-checkout-form-checkout tr.odd td,
      #commerce-checkout-form-review tr.even td,
      #commerce-checkout-form-review tr.odd td,
      .view-commerce-line-item-table tr.even td,
      .view-commerce-line-item-table tr.odd td,
      .view-commerce-user-orders tr.even td,
      .view-commerce-user-orders tr.odd td {
        display: block;
        width: 100%; }
        #views-form-commerce-cart-form-default tr.even td.views-field-edit-quantity, #views-form-commerce-cart-form-default tr.odd td.views-field-edit-quantity,
        #commerce-checkout-form-checkout tr.even td.views-field-edit-quantity,
        #commerce-checkout-form-checkout tr.odd td.views-field-edit-quantity,
        #commerce-checkout-form-review tr.even td.views-field-edit-quantity,
        #commerce-checkout-form-review tr.odd td.views-field-edit-quantity,
        .view-commerce-line-item-table tr.even td.views-field-edit-quantity,
        .view-commerce-line-item-table tr.odd td.views-field-edit-quantity,
        .view-commerce-user-orders tr.even td.views-field-edit-quantity,
        .view-commerce-user-orders tr.odd td.views-field-edit-quantity {
          width: 100%; }
        #views-form-commerce-cart-form-default tr.even td.views-field .field,
        #views-form-commerce-cart-form-default tr.even td.views-field .form-item, #views-form-commerce-cart-form-default tr.odd td.views-field .field,
        #views-form-commerce-cart-form-default tr.odd td.views-field .form-item,
        #commerce-checkout-form-checkout tr.even td.views-field .field,
        #commerce-checkout-form-checkout tr.even td.views-field .form-item,
        #commerce-checkout-form-checkout tr.odd td.views-field .field,
        #commerce-checkout-form-checkout tr.odd td.views-field .form-item,
        #commerce-checkout-form-review tr.even td.views-field .field,
        #commerce-checkout-form-review tr.even td.views-field .form-item,
        #commerce-checkout-form-review tr.odd td.views-field .field,
        #commerce-checkout-form-review tr.odd td.views-field .form-item,
        .view-commerce-line-item-table tr.even td.views-field .field,
        .view-commerce-line-item-table tr.even td.views-field .form-item,
        .view-commerce-line-item-table tr.odd td.views-field .field,
        .view-commerce-line-item-table tr.odd td.views-field .form-item,
        .view-commerce-user-orders tr.even td.views-field .field,
        .view-commerce-user-orders tr.even td.views-field .form-item,
        .view-commerce-user-orders tr.odd td.views-field .field,
        .view-commerce-user-orders tr.odd td.views-field .form-item {
          display: inline-block; }
        #views-form-commerce-cart-form-default tr.even td .field--name-field-commerce-product-quantity, #views-form-commerce-cart-form-default tr.odd td .field--name-field-commerce-product-quantity,
        #commerce-checkout-form-checkout tr.even td .field--name-field-commerce-product-quantity,
        #commerce-checkout-form-checkout tr.odd td .field--name-field-commerce-product-quantity,
        #commerce-checkout-form-review tr.even td .field--name-field-commerce-product-quantity,
        #commerce-checkout-form-review tr.odd td .field--name-field-commerce-product-quantity,
        .view-commerce-line-item-table tr.even td .field--name-field-commerce-product-quantity,
        .view-commerce-line-item-table tr.odd td .field--name-field-commerce-product-quantity,
        .view-commerce-user-orders tr.even td .field--name-field-commerce-product-quantity,
        .view-commerce-user-orders tr.odd td .field--name-field-commerce-product-quantity {
          display: inline-block; }
      #views-form-commerce-cart-form-default tr.views-row-first,
      #commerce-checkout-form-checkout tr.views-row-first,
      #commerce-checkout-form-review tr.views-row-first,
      .view-commerce-line-item-table tr.views-row-first,
      .view-commerce-user-orders tr.views-row-first {
        border-top: 1px solid #e1dfdf; }
      #views-form-commerce-cart-form-default tr.views-row-last,
      #commerce-checkout-form-checkout tr.views-row-last,
      #commerce-checkout-form-review tr.views-row-last,
      .view-commerce-line-item-table tr.views-row-last,
      .view-commerce-user-orders tr.views-row-last {
        margin-bottom: 0; }
    #views-form-commerce-cart-form-default .commerce-price-formatted-components tr,
    #commerce-checkout-form-checkout .commerce-price-formatted-components tr,
    #commerce-checkout-form-review .commerce-price-formatted-components tr,
    .view-commerce-line-item-table .commerce-price-formatted-components tr,
    .view-commerce-user-orders .commerce-price-formatted-components tr {
      display: table;
      width: 100%;
      margin-bottom: 0; }
      #views-form-commerce-cart-form-default .commerce-price-formatted-components tr td,
      #commerce-checkout-form-checkout .commerce-price-formatted-components tr td,
      #commerce-checkout-form-review .commerce-price-formatted-components tr td,
      .view-commerce-line-item-table .commerce-price-formatted-components tr td,
      .view-commerce-user-orders .commerce-price-formatted-components tr td {
        display: table-cell;
        width: auto; }
    #views-form-commerce-cart-form-default .line-item-summary,
    #commerce-checkout-form-checkout .line-item-summary,
    #commerce-checkout-form-review .line-item-summary,
    .view-commerce-line-item-table .line-item-summary,
    .view-commerce-user-orders .line-item-summary {
      width: 100%;
      text-align: center;
      margin: 0 auto; }
    #views-form-commerce-cart-form-default .commerce-line-item-actions,
    #commerce-checkout-form-checkout .commerce-line-item-actions,
    #commerce-checkout-form-review .commerce-line-item-actions,
    .view-commerce-line-item-table .commerce-line-item-actions,
    .view-commerce-user-orders .commerce-line-item-actions {
      text-align: center; }
  #commerce-checkout-form-review .commerce-order-handler-area-order-total .commerce-price-formatted-components {
    width: 100%;
    background: #dddddd; }
    #commerce-checkout-form-review .commerce-order-handler-area-order-total .commerce-price-formatted-components tbody {
      width: 38%;
      margin: 0 auto;
      display: block; }
  .l-main .commerce-checkout-progress.inline {
    display: none; }
  .logged-in.page-user-orders .views-field-commerce-order-total {
    width: 100%; }
  .l-what-for-you-wrapper {
    padding-top: 10px; }
  .l-what-for-you .small-box {
    float: none;
    margin-right: 25px;
    width: 30%;
    display: inline-block; }
    .l-what-for-you .small-box:nth-child(3) {
      margin-right: 0; }
    .l-what-for-you .small-box:last-child {
      display: block;
      margin: 0 auto; }
  .way-adv-wrapper .l-what-for-you .small-box:nth-child(2) {
    margin-right: 0; }
  .node--news.node--full .field--name-field-image, .node--news.node--full .field--name-field-images {
    top: 50px; }
  .node--news.node--full .group-banner {
    min-height: 300px; }
    .node--news.node--full .group-banner .field--name-title-field {
      max-width: 470px; }
  .advertisers-categories-block {
    padding: 50px 0 40px; }
    .advertisers-categories-block .pane-title {
      margin-bottom: 60px; }
    .advertisers-categories-block .view-content {
      max-width: 640px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 10px; }
      .advertisers-categories-block .view-content:after {
        content: "";
        display: table;
        clear: both; }
    .advertisers-categories-block .views-row {
      width: 48.93617%;
      float: left;
      margin-right: 2.12766%;
      max-width: 286px; }
      .advertisers-categories-block .views-row .field--name-name-field {
        width: auto; }
      .advertisers-categories-block .views-row:nth-child(2n) {
        width: 48.93617%;
        float: right;
        margin-right: 0; }
      .advertisers-categories-block .views-row:nth-child(2n+1) {
        clear: both;
        width: 48.93617%;
        float: left;
        margin-right: 2.12766%; }
  .adv-with .pane-block.pane-block-19.priv-block {
    margin: 40px 0 30px; } }

@media (max-width: 860px) {
  .page-checkout .commerce_shipping,
  .page-checkout .commerce_payment {
    min-height: 424px; }
  .user-login #user_login_form {
    width: 100%;
    float: none;
    margin-right: 0;
    padding-right: 0;
    border-right: none; }
  .user-login .create-account {
    float: none;
    height: auto;
    margin: 0 auto;
    width: auto; }
    .user-login .create-account .content-wrapper {
      transform: none;
      position: static; }
      .user-login .create-account .content-wrapper a {
        display: inline-block; } }

@media (max-width: 753px) {
  .block--views-blog-taxonomy-block-1 {
    float: left;
    padding-left: 0; } }

@media (max-width: 768px) {
  .page-checkout .commerce_payment,
  .page-checkout .commerce_shipping,
  .page-checkout .accept_terms,
  .page-checkout .commerce_fieldgroup_pane__group_comments {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    min-height: auto; }
  #commerce-checkout-form-review .commerce-order-handler-area-order-total .commerce-price-formatted-components tbody {
    width: 47%; }
  .page-checkout #commerce-checkout-form-checkout .accept_terms {
    top: 0; }
  #edit-account-login input {
    width: 100%; }
  .l-content ul.tabs--primary {
    border-bottom: none; }
    .l-content ul.tabs--primary li {
      width: 100%;
      text-align: center;
      margin-bottom: 10px; }
  .l-header__left {
    width: 130px; }
  .l-header__right {
    width: 68%; }
  .l-what-for-you .small-box {
    display: block; }
    .l-what-for-you .small-box.small-box--absolute {
      margin: 0 auto;
      margin-bottom: 40px; }
  .way-adv-wrapper .l-what-for-you .small-box:nth-child(2) {
    margin: 0 auto;
    margin-bottom: 40px; }
  .node--news.node--full .field--name-field-image, .node--news.node--full .field--name-field-images {
    top: 15px; }
  .node--news.node--full .group-banner {
    min-height: 260px; }
    .node--news.node--full .group-banner .field--name-title-field {
      max-width: 420px;
      padding-top: 50px;
      padding-bottom: 50px; }
  .l-what-for-you .l-what-for-you-wrapper #block-block-5 .ptitle {
    margin-top: 0; }
  #user_register_form .form-item {
    width: 70%; }
  #commerce-checkout-form-login .login_pane {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  #commerce-checkout-form-login .nonregistered_pane {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    min-height: 0; }
  .adv-with .pane-block.pane-block-19.priv-block {
    margin-bottom: 30px; }
    .adv-with .pane-block.pane-block-19.priv-block .banner__left .center-wrapper {
      padding-top: 40px; }
  .pane-block-47 h2 {
    font-size: 1.25rem; } }

@media (max-width: 630px) {
  #commerce-checkout-form-review .commerce-order-handler-area-order-total .commerce-price-formatted-components tbody {
    width: 100%; }
  .l-header__left {
    width: 110px;
    margin-left: -5px;
    margin-top: 10px; }
  .l-header__right {
    width: 72%; }
  /*#block-commerce-overrides-shopping-cart-custom {
    min-width: 152px;
  }*/
  .node--news.node--full .group-wrapper-inner {
    padding: 20px 10px; }
  .node--news.node--full .group-banner-left {
    max-height: none; }
  .node--news.node--full .field--name-field-image {
    padding: 20px 10px 20px 10px;
    width: 100%;
    position: static;
    max-width: none; }
  .node--news.node--full .group-banner {
    min-height: auto; }
    .node--news.node--full .group-banner .field--name-title-field {
      float: none;
      padding-top: 40px;
      padding-bottom: 20px;
      padding-right: 10px;
      width: 100%;
      max-width: 100%; }
  .advertisers-categories-block .views-row {
    float: none;
    margin: 30px auto; }
    .advertisers-categories-block .views-row:nth-child(2n) {
      float: none;
      margin: 30px auto;
      width: 100%; }
    .advertisers-categories-block .views-row:nth-child(2n+1) {
      float: none;
      width: 100%;
      margin: 20px auto;
      width: 100%; }
  #block-commerce-overrides-shopping-cart-custom {
    position: absolute;
    right: 4px;
    top: 48px; } }

@media (max-width: 500px) {
  .phone-top__text {
    margin-right: 60px; }
  #views-form-commerce-cart-form-default .form-actions input,
  #commerce-checkout-form-checkout .form-actions input,
  #commerce-checkout-form-review .form-actions input,
  .view-commerce-line-item-table .form-actions input,
  .view-commerce-user-orders .form-actions input {
    display: block;
    margin-left: auto;
    margin-right: 0; }
  #views-form-commerce-cart-form-default .form-actions input[type="submit"],
  #commerce-checkout-form-checkout .form-actions input[type="submit"],
  #commerce-checkout-form-review .form-actions input[type="submit"],
  .view-commerce-line-item-table .form-actions input[type="submit"],
  .view-commerce-user-orders .form-actions input[type="submit"] {
    margin: 0 auto;
    margin-bottom: 15px; }
  .l-main .commerce-checkout-progress.inline li {
    margin-right: 0;
    width: 100%; }
  .page-checkout .customer_profile_billing .group-invoice .form-wrapper:nth-child(n) {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .page-checkout .field-name-commerce-customer-address div.name-block:nth-child(n), .page-checkout .field-name-commerce-customer-address div.street-block:nth-child(n),
  .page-checkout .field-name-commerce-customer-address div.locality-block .form-item.form-type-textfield:nth-child(n) {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2.12766%; }
  #commerce-checkout-form-review .commerce-order-handler-area-order-total .commerce-price-formatted-components {
    width: 100%; }
    #commerce-checkout-form-review .commerce-order-handler-area-order-total .commerce-price-formatted-components tr td:nth-child(2n) {
      width: 34%; }
  .l-header {
    padding-left: 20px;
    padding-right: 20px; }
    .l-header .l-header__right {
      width: 60%;
      right: 20px; }
    .l-header .l-header__left {
      margin-top: 15px; }
    .l-header .slicknav_menu .slicknav_menutxt {
      right: 20px;
      top: -55px; }
  .site-logo img {
    width: 80px;
    margin-left: 2px; }
  #block-locale-language-content {
    right: 20px; }
  .view-shopping-cart-block-custom .total-wrapper-custom {
    font-size: 0.875rem; }
  .view-shopping-cart-block-custom .total-items-wrapper {
    margin-top: 2px; }
  #block-commerce-overrides-shopping-cart-custom {
    position: absolute;
    right: 65px;
    top: 48px; }
  .l-header-wrapper--sticky .l-header {
    padding-left: 20px;
    padding-right: 20px; }
    .l-header-wrapper--sticky .l-header .l-header__left {
      margin-top: 4px; }
  .l-header-wrapper--sticky #block-commerce-overrides-shopping-cart-custom {
    right: 55px;
    top: 30px; }
  .user-login .create-account .content-wrapper a {
    min-width: auto;
    font-size: 1.125rem;
    padding: 8px 10px; }
  .user-login #user_login_form input[type="text"], .user-login #user_login_form input[type="password"] {
    width: 100%; }
  #user_register_form .form-type-password-confirm {
    width: 100%; }
  #user_register_form .form-item {
    width: 100%; }
  .node--slideshow.node-teaser .group-wrapper .group-left-side {
    min-height: 330px; }
  #commerce-checkout-form-login .nonregistered_pane a.register {
    width: auto;
    display: inline-block;
    height: auto;
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
    padding: 6px 12px; }
  #commerce-checkout-form-login .nonregistered_pane #non-registered-user-btn {
    margin-right: 30px; }
  .order-completed-succesfully .oc-msg-txt {
    display: block;
    padding-left: 35px; } }

@media (max-width: 380px) {
  #block-commerce-overrides-shopping-cart-custom {
    margin-right: 0;
    min-width: 155px;
    margin-left: 10px; }
  .phone-top__text {
    margin-right: 45px; }
  .messages.only-cart:before {
    margin-bottom: 30px; }
  .l-header {
    padding-left: 10px;
    padding-right: 10px; }
    .l-header .slicknav_menu .slicknav_menutxt {
      right: 10px; }
  .l-header-wrapper--sticky .l-header {
    padding-left: 10px;
    padding-right: 10px; }
  #block-locale-language-content {
    right: 10px; }
  #block-commerce-overrides-shopping-cart-custom {
    right: 45px; }
  .captcha {
    margin-left: -10px; } }

@media (max-width: 350px) {
  .captcha {
    margin-left: -30px; } }

/* border-box properties */
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

img {
  height: auto;
  max-width: 100%; }

.feed-icon {
  display: none; }

ul.tabs--primary {
  margin: 0 0 20px 0;
  padding: 0; }
  ul.tabs--primary li {
    list-style-type: none;
    margin-bottom: 0; }
    ul.tabs--primary li a {
      color: #9bb528;
      font-weight: 700;
      font-size: 0.875rem; }
      ul.tabs--primary li a.active, ul.tabs--primary li a:hover, ul.tabs--primary li a:focus {
        background: #9bb528;
        color: #ffffff; }

html {
  font: 400 16px/1.5 "Roboto", sans-serif;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  line-height: inherit; }

.mini-text {
  font-size: 0.75rem;
  text-align: right;
  color: #ffffff;
  margin-top: 5px; }

a:link, a:visited, a:active {
  text-decoration: none;
  color: #9bb528; }

a:hover, a:focus {
  color: #9bb528;
  text-decoration: none; }

@media (max-width: 1440px) {
  .l-header-wrapper--sticky .l-header__bottom {
    margin-right: 1%; }
  .sticky-page .l-region--navigation ul.menu > li a {
    padding: 14px 0 24px 0; }
  .sticky-page .l-region--navigation ul.menu ul.menu > li a {
    padding: 13px 0 14px 0; } }

#sliding-popup.sliding-popup-bottom {
  padding: 20px 12px; }
  #sliding-popup.sliding-popup-bottom .popup-content #popup-buttons button {
    background: #ffffff;
    border: none;
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 0.75rem;
    padding: 5px 10px;
    color: #000; }

.l-callus-wrapper {
  max-width: 625px !important; }
