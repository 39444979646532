.advertisers-benefits {

  .where-advert {
    padding: 0;
   // @include container($discount-coupons-container);
    @include container($gf-container);
    position: relative;
  }

  .where-advert-wrapper {
    background: $med-green;
    color: $white;
    position: relative;
    @include clearfix;
    height: $height-stopper-with-menu;

    .wa-left {
    //  max-width: 750px;
    //  padding: 100px 0 84px;
      @include left_green; //5.2

      .ptitle--coloured-huge {
      //  font-size: $big;
        @include title_green;
      }

      p {
    //    line-height: 1.4;
      }

    }

    .wa-right-icon {
      @include right_green; //5.2
/*      padding: 70px 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      width: 29%;*/
      @include outbox;
    }

    .ptitle {
      @include title_green;
    }
  }

  .wa-right-icon {
    @include right_green;
    //padding: 0 !important;
    //img {
    //  display: block; //5.2
    //}
/*    padding: 40px 0;
    position: absolute;
    right: -10%;
    top: 0;
    z-index: 10;
    width: 53%;*/
  }

  .l-socialpromo-wrapper {
    padding: 90px 0; //27.2
    @include container($green-second);

    .l-socialpromo__text {
      @include container($socialpromo-container);
      margin-bottom: 50px;//27.2
    }
  }

}

.advertisers-benefits-block {
  @include container($green-second);//$normal-container);
  padding-top: 100px;
  padding-bottom: 60px;
  

  .advertisers-benefits {
    @include span(6);
    padding-left: 60px;
    padding-right: 60px;
    text-align: center;
    margin-bottom: 100px;
    &:nth-child(2n) {
      @include span(6 last);
    }
    &:nth-child(2n+1) {
      @include span(6 break);
    }
  }

  .advertisers-benefits-title {
    font-size: $mini-title;
    line-height: 1.3;
    margin-bottom: 25px;
  }

  .advertisers-benefits-image {
    margin: 0 auto;
    margin-bottom: 20px;
    &.advertisers-image-1 {
      @include sprite($sprite-benefit-1);
    }
    &.advertisers-image-2 {
      @include sprite($sprite-benefit-2);
    }
    &.advertisers-image-3 {
      @include sprite($sprite-benefit-3);
    }
    &.advertisers-image-4 {
      @include sprite($sprite-benefit-4);
    }
    &.advertisers-image-5 {
      @include sprite($sprite-benefit-5);
    }
    &.advertisers-image-6 {
      @include sprite($sprite-benefit-6);
    }
  }

}
