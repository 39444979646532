.advertisers-categories-block {
  background: $wf-grey;
  padding: 90px 0 50px;

  .view-content {
    @include container($adv-new-container);
  }

  .pane-title {
    text-align: center;
    font-size: $big;
    line-height: 1.2;
    margin-bottom: 90px;
  }

  .views-row {
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
    background: $white;
    @include span(3);
    margin-bottom: gutter();

    &:nth-child(4n) {
      @include span(3 last);
    }

    &:nth-child(4n+1) {
      @include span(3 break);
    }

    .views-field-name {
      float: left;
      a {
        color: $black;
      }
    }

    .field {

      &--name-field-artc-icon {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 0px;
      }

      img {
        display: block;
      }

      &--name-name-field {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        font-size: $carousel;

        a {
          color: $med-black;
        }

      }
    }

    .group {

      &-wrapper {
        background: $white;
        padding: 20px;
      }

      &-inner {
        position: relative;
        height: 60px;
        &:hover, &:focus {
          .field--name-name-field a {
            color: $green;
          }
          .field--name-field-artc-icon {
            .field__item {
              background: $green;
            }
          }          
        }
      }

    }

  }

}

.panel-2col {

  .panel-col-first, .panel-col-last {
    float: none;
    width: 100%;
    .inside {
      margin: 0;
    }
  }

}