.taxonomy-term {
  &--full {
    background: $white;
    .field {
      &--name-description-field {
        font-size: $slight;
      }
    }
  }
}

.page-taxonomy {
  background: $wf-grey;

  .l-socialpromo-wrapper {
    padding: 95px 0;
    @include container($green-second);

    .l-socialpromo__text {
      @include container($socialpromo-container);
      margin-bottom: 0;
    }
  }

}

.discounts-page-noarg {

  .l-socialpromo-wrapper {
    padding: 95px 0;
    @include container($green-second);

    .l-socialpromo__text {
      @include container($socialpromo-container);
      margin-bottom: 0;
    }
  }

}

.advertisers-page {
  .node {
    &--page {

      position: relative;
      .banner {

        /*        &__right {
                  &__hero-image--advertisers-image {
                    //background: url('/sites/default/files/taxonomy/artc/image/advertisers-image.jpg') center center no-repeat;
                    //background-size: cover;
                  }
                }*/

        &__left {
        //  @include container($advertisers-banner);

          .center-wrapper {
         //   padding: 140px 20px 120px 20px;
         //   max-width: 39%;
         //   margin-right: 61%;
         //   line-height: 1.6;
          }
          .field--name-description-field.banner__description {
            margin-top: 0;
          }
        }
      }
    }
  }

}

.sboxes-panel {
  padding-bottom: 100px;
}

.view-case-studies {
  &.view-display-id-panel_pane_2 {
    @include container ($discount-coupons-container);
    position: relative;
    margin-top: 85px; //6.2
    .view-header {
      float: left;
      padding: 59px;
      background: #ffffff;
      min-height: 533px;
      margin-bottom: 0;
      width: 50%;
      box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
      position: relative; //5.2


      /* Added upon request h2 tag -> samples */
      .about-discount-coupons {
        //position: absolute;
        //bottom: 45px;
        padding-top: 10px;
        font-size: $slight;  
      
      }
    }

    .view-content {
      width: 50%;
      float: left;

      .slick-list {
        box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.25);
      }

    }

    .arrow-wrapper--right {
      margin-top: -72px;
    }

    .arrow-wrapper--left {
      margin-top: -72px;
    }
    /* added title inside adv pages */
    .adv-inner-title {
      font-size: $what-for-you;
      margin-bottom: 20px;
      line-height: 1.4;
    }

    
  }
}