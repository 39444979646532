.adv-with {
  .pane {
    &-node {
      position: relative;
    }

    &-block-17 {
      background: $white;
    }

    &-block-20 {
      background: $basket;
    }
  }

  .l-socialpromo-wrapper {
    padding: 95px 0;
    @include container($green-second);

    .l-socialpromo__text {
      @include container($socialpromo-container);
      margin-bottom: 0;
    }
  }
  .l-page .key-points-list__title {
    font-size: $slight;
  }

  .edit-img {
    img {
      display: none;
    }
  }
}

.where-advert {

}

.read-more-1 {
  display: block;
  text-align: center;
}

.priv-block {
  background: $white;

  .key-points-list {
    li {
      margin-bottom: 12px;
    }

    &__title {
      margin-bottom: 12px;
    }
  }

  // .ptitle--large {
  //   font-size: $mini-title; //5.2 $big 
  // }

  .banner {

    min-height: 858px;

    &__right {

      &__hero-image--advantages {
        //background: url('../images/priv-block/adv-proof.jpg') center center no-repeat;
  /*      background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;*/
      }

      bottom: 0;
      top: 0;
      left: 0;
      position: absolute;
      width: 50%;
    }

    &__description {
      margin: 20px 0 20px;
      font-size: $slight;
    }

    &__left {
      .center-wrapper {
        padding: 90px 20px 45px 20px;
        max-width: 41%;
        margin-left: 59%;
        margin-right: 0;
      }

      .read-more {
        margin-top: 20px;
      }

        @include container($priv-banner);
    }

  }
}

.proof-block {
  .panels-outer-wrapper {
    @include container($banner-container);
  }

  .l-what-for-you-wrapper {
    padding: 40px 0 70px;
  }

  .small-box {
    text-align: left;
  }

  .lspacing {
    display: block;
    text-align: center;
  }

  .small-box__text {
    font-size: $what-for-you;
    line-height: 1.4;
  }

  .normal-text {
    font-size: $normal;
    padding: 0 28px;
    min-height: 72px;
  }
}

.way-adv-wrapper {
  text-align: center;
  padding-top: 90px;
}

.green-st-block {
  background: $med-green;

  .panels-outer-wrapper {
    @include container($what-for-you-container);
    text-align: center;
    padding: 90px 0;
  }

  .l-below-banner__text {
    margin-top: 30px;
    color: white;
    font-size: $slight;
  }
}

.basket-block {
  background: $basket;

 > .panels-outer-wrapper {
    @include container($basket-container);
    padding: 90px 30px;
  }

  .ptitle--medium {
    margin-bottom: 35px;
  }

  .left-side-basket {
    float: left;
    //width: 43%; 27.2
    //margin-right: 14%;
  }

  .info-banner {
    float: left;
    width: 50%;
  }

  .l-below-banner__text {
    line-height: 1.7;
  }

  .basket-icon {
    float: right; //27.2 -left-
    width: 43%;

    img {
    //  height: auto;
     // max-width: none;
    }
  }
}

.how-op-block {
  .panels-outer-wrapper {
    @include container($basket-container);
    padding: 90px 0 0;
  }

  .read-more {
    margin: 0;
  }

  .ptitle--medium {
    margin-bottom: 5px;
  }

  .intro-op {
    @include container($small-container);
    text-align: center;
    margin-bottom: 65px;
  }

  .text-op {
    font-size: $key-points;
  }

  .rmore {
    margin: 90px 0 60px;
    text-align: center;
  }

  .how-op {
    @include clearfix;

    &__icon {
      margin: 0 auto;
      margin-bottom: 40px;
      border-radius: 50%;
      transition: all 0.5s;
      &:hover, &:focus {
        box-shadow: 0 5px 10px rgba(0,0,0,0.6);
      }
    }

    &__item {
      text-align: center;
      @include span(4);

      &--first {
        .how-op__icon {
          @include sprite($sprite-pl-1);
        }
      }

      &--second {
        .how-op__icon {
          @include sprite($sprite-pl-2);
        }
      }

      &--third {
        .how-op__icon {
          @include sprite($sprite-pl-3);
        }
      }

      &:last-child {
        @include span(4 last);
      }
    }
  }
}


/////////////////

// .banner {
//   @include container(full);
//   min-height: 553px;
//   position: relative;

//   &--larger {
//     min-height: 600px;
//   }

//   &__right {

//     &__hero-image {
//       background: url('../images/banner/banner.jpg') center center no-repeat;
//       background-size: cover;
//       height: 100%;
//       left: 0;
//       position: absolute;
//       top: 0;
//       width: 100%;

//       // add variations to the main banner here!
//       &--adv-with-img {
//         background: url('/sites/default/files/page/image/adv-with-img.jpg') center center no-repeat;
//         background-size: cover;
//       }
//     }

//     bottom: 0;
//     top: 0;
//     right: 0;
//     position: absolute;
//     width: 50%;

//     &.inner {
//     }
//   }

//   &__left {
//     @include container($banner-container);

//     &.inner {
//       @include container($banner-container-inner);
//     }

//     .center-wrapper {
//       padding: 160px 20px 200px 20px;
//       max-width: 47%;
//       margin-right: 53%;

//       &.inner {
//         padding: 110px 100px 20px 20px;
//       }
//     }
//   }

//   &__description {
//     margin-top: 40px;
//     color: $med-black;

//     &--lheight {
//       line-height: 1.8;
//       font-size: $slight;
//     }

//     p {
//       margin-bottom: 5px;
//     }
//   }
// }

.where-advert {
  padding: 100px 0;
  @include container($large-container);

  .check-spots {
    font-size: $key-points;
  }

  .wa-left {
    font-size: $slight;
    float: left;
    max-width: 780px;
  }

  .wa-right-icon {
    float: right;
  }
}

.where-adv-block-1 {
  h2 {
    margin-bottom: 20px;
  }
}

.like-rm {
  min-width: 213px;
  text-align: center;
}

.advertising-receipts .node--page--vblock {
  .banner__description--lheight {
    font-size: $normal;
  }
}