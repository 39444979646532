// News Component

@include with-layout(12 1/3) {
  .l-news {
    background: $wf-grey;

    .ptitle {
      margin-bottom: 60px;
    }
  }

  .field--name-body {
    color: $black;
  }

    .l-news-wrapper {
      @include container($normal-container);
      padding: 50px 0;

    .views-row {
      text-align: center;
      @include span(3);

      &:nth-child(4n) {
        @include span(3 last);
      }

      &:nth-child(4n+1) {
        @include span(3 break);
      }
    }

    .field--name-title-field {
      margin-top: 30px;
      line-height: 1.35;
      font-size: $slight;
      margin-bottom: 15px;
      color: $black;
      font-weight: 700;

      a {
        transition: color 0.1s linear;
      }

      @include links($black, $med-green, none);
    }

    .field--type-image {
      img {
        //border-radius: 50%; 28.2
        box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.30);
      }
    }

    .view-footer {
      clear: both;
      text-align: center;
      .read-more {
        margin-top: 30px;
      }
      a {
        color: $white;
      }
    }
  }
}

.not-front {
  .l-news-wrapper {
    .views-row {
      margin-bottom: 40px;
    }
    .view-header {
      margin-bottom: 25px;
    }
  }
  .l-news-wrapper {
    padding: 0;
  }
  .view-display-id-news_page {

    &.l-news-wrapper {
      max-width: 100%;
      .field--name-title-field {
        font-weight: 700;
      }

      .view-content {
        @include container($normal-container);
        padding: 40px 0;
      }

      .view-header {
        background: $deep-silver;
        padding: 28px 0;

        h1 {
          @include container($normal-container);
          color: $dark-grey2;
        }

      }

    }

  }
}

.gback-news {

  @include links($black,$med-green,none);
  font-weight: 700;
  margin: 50px 0; 
}

.pager{
  padding: 30px 0 50px;
  .pager__item--current{
    font-weight: 400;
    padding: 8px 16px;
    display: inline-block;
    background: $deep-silver;
    border: 1px solid $deep-silver;
    color: $med-grey;
    margin-bottom: 0px;

  }
  .pager__item a{
    padding: 8px 16px;
    display: inline-block;
    border: 1px solid $deep-silver;
    color: $med-grey;
    margin: -6px;
    &:hover, &:focus{
      background: $deep-silver;
    }
  }
}

.node-type-news {
  h1 {
    display: none;
  }
}

.link-wrap-of-news {
  img {
    transition: all 0.3s;
  }

  &:hover {
      .field--type-image {
        img {
          border-radius: 0;
        //  box-shadow: 8px 18px 10px -4px rgba(0, 0, 0, 0.50);
        }
      }
  }
}

.node--news {

  &.node--full {
    .group-banner {
      background: $basket;
      .field--name-title-field {
        float: left;
        padding: 150px 0;
        width: 480px;
        h1 {
          display: block;
          font-size: $big;
          line-height: 110%;
          border-left: 15px solid $mustard;
          padding-left: 10px;
        }
      }
    }
    .field--name-field-image,
    .field--name-field-images {
      position: absolute;
      right: 0;
      top: 40px;
    }
    .group-banner-left {
      @include container ($discount-coupons-container);
      position: relative;
      max-height: 400px;
    }
    .group-wrapper-inner {
      @include container ($discount-coupons-container);
      padding: 140px 0 80px;
    }
    .field--name-field-video {
      margin-top: 50px;
      text-align: center;
    }
  }
}


//edit
#block-views-blog-block-2 {
  .l-news-wrapper {
    .field--name-title-field {
      font-weight: 700;
    }
  }
}