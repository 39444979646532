// Key Points Component

.front .l-key-points {

  &-wrapper {
    @include container($large-container);
    padding: 90px 0;
  }

  &__left {
    @include span(6 no-gutters);
    max-width: 607px;
  }

  &__right {
    padding-left: 90px;
    @include span(6 last);
  }

}