.selectboxit-container {
  width: 100%;

  .selectboxit-list {
    background-color: $white;
    border-radius: 2px;
    border: none;
  }

  .selectboxit-options {
    border-radius: 2px;

    .selectboxit-option-anchor {
      height: 36px;
      line-height: 38px;
    }
  }

  .selectboxit {
    width: 100%;
    border-radius: 2px;
    background: $white;
    border: none;
    float: none;
    height: 44px;
    //  margin-top: -3px;

    &:hover, &:focus {
      background: $white;
    }

    span, .selectboxit-options a {
      background: $white;
      border-radius: 2px;
      text-align: left;
      height: 50px;
      border: none;
      line-height: 44px;
      display: block;
      color: $placeholder;
      font-size: $small;
      font-weight: 400;
    }
  }
}

.selectboxit .selectboxit-arrow-container {
  .promoticket-select {
    margin-top: -4px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $green;
  }
}

.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  color: white;
  background: $green;
}