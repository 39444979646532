.page-search-results {

  .l-content {
    @include container($large-container);
    padding: 50px 0;

    h1 {
      font-size: $huge;
      line-height: 1.1;
      margin-bottom: 30px;
    }

  }

}

.search-results {

  .search-result-inner {
    min-height: 107px;
  }

  .search-result {
    border-top: 8px solid $med-green;
    @include span(3);
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
    margin-bottom: gutters();
    padding: 16px;

    &:nth-child(4n) {
      @include span(3 last);
    }

    &:nth-child(4n+1) {
      @include span(3 break);
    }

    .search-result__snippet {
      font-size: $small;
      line-height: 1.32;
      margin-bottom: 12px;
      color: $dark-grey2;
    }

    h3 {
      font-size: $slight;
      line-height: 1.32;
      margin-bottom: 12px;
      color: $dark-grey2;

      a {
        color: $black;
        transition: 0.2s linear;

        &:hover, &:focus {
          color: $med-green;
        }
      }
    }
    &:hover, &:focus {
      background: $wadv-grey;
    }
  }
}