// Import variables, abstractions, base styles and components.

@import "susy";
@import "breakpoint";

@import "variables/_grid.scss";
@import "variables/_typography.scss";
@import "variables/_sprites.scss";
@import "variables/_colors.scss";
@import "abstractions/_essentials.scss";
@import "components/_commerce.scss";

// Include Basic Elements, titles, lists etc
@import "base/_basic_elements.scss";

// Include Components
@import "components/_eshop.scss";
@import "components/_header.scss";
@import "components/_banner.scss";
@import "components/_below_banner.scss";
@import "components/_key_points.scss";
@import "components/_what_for_you.scss";
@import "components/_turntabled.scss";
@import "components/_news.scss";
@import "components/_prefooter.scss";
@import "components/_footer.scss";
@import "components/_copyright.scss";
@import "components/_taxonomy_full.scss";
@import "components/_view_categories.scss";
@import "components/_discount_coupons.scss";
@import "components/_contact_us.scss";
@import "components/_adv_proof.scss";
@import "components/_where_advert.scss";
@import "components/_cost.scss";
@import "components/_how_it_works.scss";
@import "components/_advertiser_benefits.scss";
@import "components/_about_us.scss";
@import "components/_ad_ways.scss";
@import "components/_receipt_points.scss";
@import "components/_research_efficiency.scss";
@import "components/_printed_rolls.scss";
@import "components/_node_products.scss";
@import "components/_search.scss";
@import "components/_rolls-central-view.scss";
@import "components/_blog.scss";

// Include scss from Libraries
@import "components/_gray.scss";
@import "components/_slick.scss";
@import "components/_selectBoxit.scss";
@import "components/_slicknav.scss";
@import "components/_superfish.scss";

// Responsive Styles as a partial
@import "components/_responsive.scss";
@import "components/_responsive_k.scss";

/* border-box properties */
@include border-box-sizing;

img {
  height: auto;
  max-width: 100%;
}

// temp feed fix
.feed-icon {
  display: none;
}

ul.tabs--primary {
  margin: 0 0 20px 0;
  padding: 0;
  li {
    list-style-type: none;
    margin-bottom: 0;
    a {
      color: $med-green;
      font-weight: 700;
      font-size: $small;

      &.active,
      &:hover, &:focus {
        background: $med-green;
        color: $white;
      }
    }
  }
}

html {
  font: 400 16px/1.5 $roboto;
  color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  line-height: inherit;
}

.mini-text {
  font-size: $very-small;
  text-align: right;
  color: $white;
  margin-top: 5px;
}

@include links($med-green,$med-green, none);

@include breakpoint(1440px) {

  .l-header-wrapper--sticky {
    .l-header__bottom {
      margin-right: 1%;
    }
  }

  .sticky-page {
    .l-region--navigation ul.menu {
      > li a {
        //padding: 14px 10px 24px 11px;
        padding: 14px 0 24px 0; //6.2
      }

      ul.menu {
        > li a {
          //padding: 13px 10px 14px 11px;
          padding: 13px 0 14px 0; //6.2
        }
      }
    }
  }

} //end of breakpoint

#sliding-popup.sliding-popup-bottom {
  padding: 20px 12px;
  .popup-content #popup-buttons button {
    background: $white;
    border: none;
    border-radius: 5px;
    font-family: $roboto;
    font-size: $very-small;
    padding: 5px 10px;
    color: $black;
  }
}


.l-callus-wrapper {
  max-width: 625px !important;
}